import React, { Component } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { Grid, Paper, Typography } from "@material-ui/core";
import FGroupBox from "../widgets/FGroupBox";

const AUTO_INTEGRATION_MESSAGE = `
     We offer seamless integrations for enhanced user experience. If you're a GoMotive user, we periodically and automatically collect trip data and 
     other related information. Furthermore, we also periodically and automatically import data 
     from FMCSA to collect your safety score, along with other vital compliance and safety information. 
     Simply click to connect your carrier to our platform and follow the process for seamless integration.
      This ensures we can serve you better.`;

const MANUAL_INTEGRATION_MESSAGE = `
    We offer extensive data integration capabilities for a seamless experience. 

    - Integrate with TCS, Fleet One Edge, or RTS Fuel: Manually upload your fuel transaction data from the respective portals. We use the fuel card number within the data to accurately match and assign each transaction to the correct driver in our system. 
    - Integrate with QuickBooks: Export transactions categorized by driver or company for effortless uploads to tax software and other financial systems, ensuring accurate matching and hassle-free accounting. 

    Whether it's bulk uploading from various fuel card providers or exporting bulk data to third-party systems, our integrations are designed to serve you efficiently.
    `;



export class BaseIntegrationComponent extends Component {
  getIntegrationData() {
      // This method should be overridden by child classes
      return {};
  }

  render() {
      const data = this.getIntegrationData();
      return (
          <React.Fragment>
              <div style={{ position: 'relative', display: 'inline-block' }}>
                  <a 
                      target='_blank' 
                      rel="noopener noreferrer" 
                      href={data.linkURL}
                  >
                      <img 
                          height= {data.imgHeight || "70px" }
                          width={data.imgWidth}
                          src={data.imgSrc}
                          style={data.imgStyle}
                      />
                  </a>
                  {/* <Tooltip title={data.tooltipText} placement="top-end">
                      <InfoIcon 
                          color="primary" 
                          style={{ 
                              position: 'absolute', 
                              top: '5px', 
                              right: '5px',
                              cursor: 'pointer'
                          }} 
                      />
                  </Tooltip> */}
              </div>
          </React.Fragment>
      );
  }
}



// FMCSAIntegration.js
export class FMCSAIntegration extends BaseIntegrationComponent {
  getIntegrationData() {
      return {
          linkURL: "https://safer.fmcsa.dot.gov/CompanySnapshot.aspx",
          // imgWidth: "250px",
          imgSrc: "/fmcsa.png",
          // imgHeight: "80px",
          imgStyle: {},
          tooltipText: "We periodically and automatically import data from FMCSA to collect your safety score, along with other vital compliance and safety information. This ensures we can serve you better."
      };
  }
}

// FMotiveIntegration.js
export class FMotiveIntegration extends BaseIntegrationComponent {
  getIntegrationData() {
      return {
          linkURL: "https://keeptruckin.com/oauth/authorize?client_id=1806c87dad880e5910fda1937951b3b960852a069d45abbf7b81ed74259b9389&redirect_uri=https://admin.ztrucking.com/integration/oauth-redirect/gomotive/&response_type=code&scope=users.read+fuel_purchases.read+hos_logs.hours_of_service+locations.vehicle_locations_list+locations.vehicle_locations_single+ifta_reports.trips+ifta_reports.summary+companies.read+hos_logs.read",
          // imgWidth: "200px",
          // imgHeight: "80px",
          imgSrc: "/motive.png",
          imgStyle: {},
          tooltipText: "If you're a GoMotive user, we periodically and automatically collect trip data and other related information to enhance your experience. Simply click to connect your carrier to our platform and follow the process for seamless integration"
      };
  }
}


export class TCSIntegration extends BaseIntegrationComponent {
  getIntegrationData() {
      return {
          linkURL: "https://safer.fmcsa.dot.gov/CompanySnapshot.aspx",
          // imgWidth: "250px",
          imgSrc: "/tcs.jpeg",
          // imgStyle: { backgroundColor: 'black', padding: '10px', borderRadius: '5px' },
          imgStyle: {},
          tooltipText: "Integrate with TCS: Manually upload your fuel transaction data from the TCS portal. We use the fuel card number within the data to accurately match and assign each transaction to the correct driver in our system"
      };
  }
}

export class FleetOneEdgeIntegration extends BaseIntegrationComponent {
  getIntegrationData() {
      return {
          linkURL: "https://member.fleetoneedge.com/Account/Login?ReturnUrl=%2F",
          // imgWidth: "250px",
          imgSrc: "/fleet_edge_one.png",
          // imgStyle: { backgroundColor: 'black', padding: '10px', borderRadius: '5px' },
          imgStyle: {},
          tooltipText: "Integrate with Fleet One Edge: Manually upload your fuel transaction data from the FleetOneEdge portal. We use the fuel card number within the data to accurately match and assign each transaction to the correct driver in our system"
      };
  }
}

// Formerly known as FlatRate currenly called RoadEx
export class FlatRateOrRodex extends BaseIntegrationComponent {
  getIntegrationData() {
      return {
          linkURL: "https://www.roadex.com/services/efs-fuel-card/",
          // imgWidth: "250px",
          imgSrc: "/flatrate.png",
          // imgStyle: { backgroundColor: 'black', padding: '10px', borderRadius: '5px' },
          imgStyle: {},
          tooltipText: "Integrate with Fleet One Edge: Manually upload your fuel transaction data from the FleetOneEdge portal. We use the fuel card number within the data to accurately match and assign each transaction to the correct driver in our system"
      };
  }
}

export class RTSIntegration extends BaseIntegrationComponent {
  getIntegrationData() {
      return {
          linkURL: "https://www.rtsinc.com",
          // imgWidth: "250px",
          imgSrc: "/rts.png",
          // imgStyle: { backgroundColor: 'black', padding: '10px', borderRadius: '5px' },
          imgStyle: {},
          tooltipText: "Integrate with RTS Fuel: Manually upload your fuel transaction data from the RTS portal. We use the fuel card number within the data to accurately match and assign each transaction to the correct driver in our system"
      };
  }
}

export class QuickBooksIntegration extends BaseIntegrationComponent {
  getIntegrationData() {
      return {
          linkURL: "https://quickbooks.intuit.com/accounting/",
          // imgWidth: "250px",
          imgSrc: '/quickbooks.png',
          // imgStyle: { backgroundColor: 'black', padding: '10px', borderRadius: '5px' },
          imgStyle: {},
          tooltipText: "Integrate with QuickBooks: Export transactions categorized by driver or company. This allows you to seamlessly upload data to tax software and other financial systems. We ensure accurate matching and assignment for hassle-free accounting."
        };
  }
}

export class APIIntegrations extends Component {
  render() {
    let wid = 6; // Take half the width (12/2=6)
    const gridItemStyle = {
      borderRight: '1px solid #d9d9d9', // example color; adjust as needed
    };

    return (
      <React.Fragment>
        <FGroupBox groupTitle={'API Integrations'} elevation={3} titlePaddingLeft='20px' info={AUTO_INTEGRATION_MESSAGE}>
          <Grid container spacing={3} xs={6} style={{ paddingLeft: '20px',  paddingBottom: '10px', paddingTop: '10px'}}>
            <Grid item xs={wid} style={gridItemStyle}>
                <FMCSAIntegration />
            </Grid>
            <Grid item xs={wid}> {/* No border for the last item */}
                <FMotiveIntegration />
            </Grid>
          </Grid>
        </FGroupBox>
      </React.Fragment>
    );
  }
}



export class ManualIntegrations extends Component {
  
  render() {
    let wid = 2;
    const gridItemStyle = {
      borderRight: '1px solid #d9d9d9', // example color; adjust as needed
    };

    return (
      <React.Fragment>
        <FGroupBox groupTitle={'Import / Export Supported Integration '} elevation={3} titlePaddingLeft='20px' info={MANUAL_INTEGRATION_MESSAGE}>
          <Grid container spacing={3} style={{ paddingLeft: '20px',  padding: '20px'}}>
            <Grid item xs={wid} style={gridItemStyle}>
                <TCSIntegration />
            </Grid>
            <Grid item xs={wid} style={gridItemStyle}>
                <FleetOneEdgeIntegration />
            </Grid>
            <Grid item xs={wid} style={gridItemStyle}>
                <FlatRateOrRodex />
            </Grid>
            <Grid item xs={wid} style={gridItemStyle}>
                <RTSIntegration />
            </Grid>
            <Grid item xs={wid}> {/* No border for the last item */}
                <QuickBooksIntegration />
            </Grid>
          </Grid>
        </FGroupBox>
      </React.Fragment>
    );
  }
}

