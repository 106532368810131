import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles'
import { InputAdornment } from '@material-ui/core';
import { IconButton } from 'material-ui';

const styles = theme => ({
  root: {
    marginTop: theme.spacing(1) ,
},
});


class FPassword extends React.Component {
  constructor() {
    super()
    this.state = {
      showPassword: false
    }
  }

  onChange = (evt) => {
    const value = evt.target.value
    this.props.notifyParent(this.props.name, { [this.props.name]: value })
  }

  componentDidMount = () => {
    // this.props.notifyParent(this.props.name, { [this.props.name]: this.props.defaultValue })
  }

  handleClickShowPassword = () => {
    this.setState({showPassword: this.state.showPassword });
  };


  render() {
    const { classes } = this.props
    return (
      <TextField
        name={this.props.name}
        error={this.props.hasError}
        helperText={
          this.props.hasError && this.props.helpMessage}
        disabled={this.props.disabled}
        required={this.props.required}
        label={this.props.label}
        onChange={this.onChange}
        type={this.state.showPassword ? 'text' : 'password'}
        onBlur={this.onBlur}
        InputLabelProps={{
          shrink: true,
        }}
        defaultValue={this.props.defaultValue}
        value={this.props.value}
        className={classes.root}
        // endAdornment={
        //   <InputAdornment position="end">
        //     <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowPassword}>
        //       {this.state.showPassword ? <visibility /> : <visibilityOff />}
        //     </IconButton>
        //   </InputAdornment>
        // }

        InputProps={{
          inputProps: {
            autoComplete: "new-password",
          },
          endAdornment: (
            <InputAdornment positionEnd position="end">
              <IconButton
                edge="end"
                aria-label="Toggle password visibility"
                onClick={this.handleClickShowPassword}
              >
                {this.state.showPassword ? <visibilityOff /> : <visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />);
  }
}


export default withStyles(styles)(FPassword)