import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";

import { Grid, Paper } from "@material-ui/core";
import FMessage from "../widgets/FMessage";
import FInput from "../widgets/FInput";
import { mapDispatchToProps, mapStateToProps } from "../../fredux/ActionMaker";
import { API_CARRIERS_URL, API_DOCUMENTS_URL, 
      CHOICE_CARRIER, TAB_ADMIN, ADMIN_CARRIERS } from "../../util/Const";
import DocumentView from "../document"
import { isValid } from "../../util/Validator";
import { VALIDATE_REQUIRED, VALIDATE_EMAIL, VALIDATE_PHONE_NUMBER, VALIDATE_ADDRESS } from "../../util/ValidatorConst";
import FDetailComponent from "../../util/FDetailComponent";
import FAddress from "../widgets/FAddress";
import FFileUpload from "../widgets/FFileUpload";


const styles = theme => ({
    root: {
        display: "flex",
         margin: theme.spacing(0.5),

    },
    padding: {
        marginTop: theme.spacing(4),
    },

    header: {
        background: '#f5f5f5'
    },
    button: {
        marginRight: theme.spacing(2),
    },
    root1: {
        backgroundColor: '#fff',
        padding: theme.spacing(2),
    },
});

const PROPS = {
    errorData: {
        carrierName: false,
        carrierEmail: false,
        carrierDOT: false,
        carrierMC: false,
        carrierPhoneNumber: false,
        carrierType: false,
        carrierLocation: false,
        status: false,
        carrierProfilePicture: false,
        address: false
    },
    errorMessage: {
        carrierName: 'Invalid Carrier Name',
        carrierEmail: 'Invalid Email',
        carrierDOT: 'Invalid DOT',
        carrierMC: 'Invalid MC',
        carrierPhoneNumber: 'Invalid Phone',
        carrierType: 'Invalid Type',
        carrierLocation: 'Invalid Location',
        carrierProfilePicture: 'Invalid File / type',
        status: 'Can\'t be empty'
    },
    touched: {},
    data: {
        carrierName: '',
        carrierEmail: '',
        carrierDOT: '',
        carrierMC: '',
        carrierPhoneNumber: '',
        carrierType:'',
        carrierLocation: '',
        status: '',
        address: '',
        carrierProfilePicture: ''
    },
    validators: {
        carrierName: [VALIDATE_REQUIRED],
        carrierEmail: [VALIDATE_REQUIRED, VALIDATE_EMAIL],
        carrierDOT: [VALIDATE_REQUIRED],
        carrierMC: [VALIDATE_REQUIRED],
        carrierPhoneNumber: [VALIDATE_PHONE_NUMBER],
        carrierType: [VALIDATE_REQUIRED],
        // carrierLocation: [VALIDATE_REQUIRED],
        status: [VALIDATE_REQUIRED],
        address: [VALIDATE_ADDRESS]
    }
}



class CarrierDetial extends FDetailComponent {
    constructor(props) {
        
        super(props, API_CARRIERS_URL, CHOICE_CARRIER, PROPS)
    }

    static contextTypes = {
        router: () => PropTypes.object, // replace with PropTypes.object if you use them
      }
   
    // getCarrierOnlyUrl = () =>{

    getCarrierOnlyUrl = () =>{
        return (`?carrier=${this.props.match.params.id}&employment_history__isnull=true&company__isnull=true&fleet__isnull=true&drug_test__isnull=true&accident_or_violation_register__isnull=true&driver__isnull=true&dispatcher__isnull=true&vehicle_inspection__isnull=true&vehicle__isnull=true&customer__isnull=true&fuel_card__isnull=true&payCheck__isnull=true&invoice__isnull=true&event__isnull=true&`)
    }

    getErrorData = () => {
        let errorData = {
            carrierName: !isValid(this.state.data.carrierName, this.state.validators.carrierName),
            carrierEmail: !isValid(this.state.data.carrierEmail, this.state.validators.carrierEmail),
            carrierDOT: !isValid(this.state.data.carrierDOT, this.state.validators.carrierDOT),
            carrierMC: !isValid(this.state.data.carrierMC, this.state.validators.carrierMC),
            // carrierLocation: !isValid(this.state.data.carrierLocation, this.state.validators.carrierLocation),
            // carrierType: !isValid(this.state.data.carrierType, this.state.validators.carrierType),
            carrierPhoneNumber: !isValid(this.state.data.carrierPhoneNumber, this.state.validators.carrierPhoneNumber),
            address: !isValid(this.state.data.address, this.state.validators.address)

        }
        return errorData
    }
    
    hasCustomErrors = () => {
        return !(this.isAddressValid(true, true, true, true))
     }

    getCurrentTitle = () => {
        let title = 'Carrier ' + this.state.data.carrierName
        this.props.updateCurrentTab(TAB_ADMIN)
        this.props.updateCurrentTitle && this.props.updateCurrentTitle(title)
    }

    

    render() {
        this.getCurrentTitle()
        const { classes } = this.props;
        return (
            <Paper className={classes.root1} elevation={0}>
                {/* <FTitle label={'Carrier -  ' + this.state.data.carrierName} /> */}
                <Grid container direction='column' alignItems='stretch' spacing={2} className={classes.root1} >
                    <Grid item xs>
                        <FMessage error={this.props.crudServerError}
                            success={this.props.crudServerSuccess} />
                    </Grid>
                    
                    <Grid container  spacing= {2} xs={12} >
                            <Grid item xs>
                                <FInput name='carrierName' required
                                    value={this.state.data.carrierName}
                                    hasError={this.state.errorData.carrierName}
                                    helpMessage={this.state.errorMessage.carrierName}
                                    label="Name"
                                    notifyParent={this.updateValues}
                                />
                             </Grid>
                            <Grid item xs>
                                <FInput name='carrierDOT' required
                                    value={this.state.data.carrierDOT}
                                    hasError={this.state.errorData.carrierDOT}
                                    helpMessage={this.state.errorMessage.carrierDOT}
                                    label="DOT" notifyParent={this.updateValues}
                                    // disabled={!this.state.isNew}
                                />
                            </Grid>
                            <Grid item xs>
                                <FInput name='carrierMC' required
                                    value={this.state.data.carrierMC}
                                    hasError={this.state.errorData.carrierMC}
                                    helpMessage={this.state.errorMessage.carrierMC}
                                    label="MC" notifyParent={this.updateValues}
                                    // disabled={!this.state.isNew}
                                />
                            </Grid>
                            <Grid item xs>

                                <FInput name='carrierEmail' required
                                    value={this.state.data.carrierEmail}
                                    hasError={this.state.errorData.carrierEmail}
                                    helpMessage={this.state.errorMessage.carrierEmail}
                                    label="Email" notifyParent={this.updateValues}
                                />
                            </Grid>
                            <Grid item xs>
                                <FInput name='carrierPhoneNumber' required
                                    value={this.state.data.carrierPhoneNumber}
                                    hasError={this.state.errorData.carrierPhoneNumber}
                                    helpMessage={this.state.errorMessage.carrierPhoneNumber}
                                    label="Phone Number" notifyParent={this.updateValues}
                                />
                            </Grid>
                            {/* <Grid item xs>
                                <FSelect
                                    name='carrierType'
                                    value={this.state.data.carrierType}
                                    hasError={this.state.errorData.carrierType}
                                    helpMessage={this.state.errorMessage.carrierType}
                                    label='Carrier Type'
                                    data={this.props.carrierTypeChoice}
                                    valueRef='prettyName'
                                    keyRef='prettyName'
                                    notifyParent={this.updateValues}
                                    disabled={true}
                                />
                            </Grid> */}
                           
                    </Grid>
                     <Grid container  spacing= {2} xs={12} >
                       <Grid item xs={10}>
                            <FAddress 
                                        name='address' 
                                        notifyParent={this.updateValues}
                                        address={this.state.data.address}
                                        hasError={this.state.errorData.address}
                                        place={true}
                                        city={true}
                                        state={true}
                                        zipCode={true}
                            />
                        </Grid>
                        <Grid item xs={2}>
                                <FFileUpload
                                    value={this.state.data.carrierProfilePicture}
                                    name='carrierProfilePicture'
                                    label="Company LOGO"
                                    hasError={this.state.errorData.carrierProfilePicture}
                                    helpMessage={this.state.errorMessage.carrierProfilePicture}
                                    notifyParent={this.updateValues} />
                            </Grid>
                    </Grid>
                    <Grid container  spacing= {2} xs={12} >
                         {/* 
                            <Grid item xs={4}>
                          
                               <FInput name='carrierLocation' required
                                    value={this.state.data.carrierLocation}
                                    hasError={this.state.errorData.carrierLocation}
                                    helpMessage={this.state.errorMessage.carrierLocation}
                                    label="Address" notifyParent={this.updateValues}
                                />
                            </Grid> */}

                            <Grid item xs>
                                 <FInput
                                    name='desc' value={this.state.data.desc} multiline={true}
                                    value={this.state.data.desc}
                                    hasError={this.state.errorData.desc}
                                    helpMessage={this.state.errorMessage.desc}
                                    label="Description" notifyParent={this.updateValues} />
                            </Grid>
                        </Grid>     
                    <Grid item container   xs={8} >
                        {this.getUpdateButton(this.props.profile, ADMIN_CARRIERS)}
                    </Grid>
                    {/* <Grid item container xs={8} >
                        <FButton color='primary'
                            label={this.state.isNew ? 'Save' : 'Update'}
                            loading={this.state.loading}
                            onClick={this.onSaveData}
                        />
                        <Grid item style={{ paddingTop: '9px' }}>
                            <FRouteBackLink variant='outlined' color='secondary' className={'backButton'} />
                        </Grid>
                    </Grid> */}
                    
                </Grid >
                <Grid item xs className={classes.padding}>
                        {!this.state.isNew &&
                            <DocumentView
                                ownerId={this.props.match.params.id}
                                owner={this.state.data}
                                // listUrl={API_DOCUMENTS_URL + `?&carrier=${this.props.match.params.id}`}
                                listUrl={API_DOCUMENTS_URL + this.getCarrierOnlyUrl()}
                                policy_id={ADMIN_CARRIERS}
                                ownerType='carrier'
                            />}
                    </Grid>
             </Paper>
        );
    };
}

CarrierDetial.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(connect(
    mapStateToProps, mapDispatchToProps)(CarrierDetial))
