import React, { Component } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table'
import { Button, Link, Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import FRouterLink from '../widgets/FRouterLink';
import { API_PERMISSION_URL, ROUTE_CARRIERS_URL } from '../../util/Const';
import {  UPDATE, getFormDatafromDict} from '../../util/FAPI';

import FPermission from '../widgets/FPermission';
import { FMaterialSimpleTable } from '../widgets/FMaterialTable';

class ProfilePermission extends Component {

  onRowEdit = (rowData) => {
    rowData['personnelId'] = this.props.personnel.id

    let data = getFormDatafromDict(rowData, null)
    UPDATE(API_PERMISSION_URL, 'post', data).
      then(response => {
      }).catch(error => {
        console.log(error)
      });
  }
  

  get_policy = () => {

    return this.props.personnel &&
        this.props.personnel.profile_summary && 
           this.props.personnel.profile_summary.policy || []
  }
  
  render() {
    return (
      <div>
        
        <FMaterialSimpleTable
          columns={[
            {
              render: rowData => {
                return (
                  <React.Fragment>
                      <FPermission data= {rowData} onRowEdit={this.onRowEdit}/>
                  </React.Fragment>
                )
              }
            },
          ]}
          
          // options={{
          //   actionsColumnIndex: -1,
          //   pageSize: 11,
          //   pageSizeOptions: [15],
          //   search: false,
          //   // paging: false,
          //   // toolbar: false,
          //   showTitle: true,
          //   // cellStyle:{height:10, overflowY:'scroll',verticalAlign:'top',fontSize:'2vh',overflow:'scroll',padding:1}
          //   cellStyle:{verticalAlign:'center', padding:1}

          // }}
          pageSize={12}
          pageSizeOptions={[12]}
          data={this.get_policy()}
          title={`Permissions`}
          components={{
            Toolbar: props => (
                <div style={{ backgroundColor: '' }}>
                    <MTableToolbar {...props} />
                </div>
            )
          }}
        />
      </div>
    );
  }
}

export default ProfilePermission;
