import React from 'react'

import { Grid } from "@material-ui/core"
import FSelect from '../widgets/FSelect';
import FDate from '../widgets/FDate';
import { connect } from 'react-redux'
import { mapDispatchToProps, mapStateToProps } from '../../fredux/ActionMaker';
import { withStyles } from '@material-ui/core/styles'
import FButton from '../widgets/FButton';
import { VALIDATE_REQUIRED, VALIDATE_CARRIER_REQUIRED} from '../../util/ValidatorConst';
import { isValid } from '../../util/Validator';
import { API_CARRIERS_URL} from '../../util/Const';
import FAutoComplete from '../widgets/FAutoComplete';
import FFileUpload from '../widgets/FFileUpload';
import {Labels} from "../../localizations/Resource"

const styles = theme => ({
  root: {
  },
  dropDown: {
  },

  textNumber: {
    marginTop: theme.spacing(1),
  },
  generateButon: {
    marginTop: theme.spacing(2),
  }

});

class FuelImportFilterView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        carrier: {},
        dataImportStartDate: '',
        dataImportEndDate: '',
        dataImportSource: '',
        dataImportRawFile: '',
        dataImportType: 'FUEL'
      },
      errorData: {
      },
      errorMessage: {
        carrier: 'Invalid Carrier',
        dataImportStartDate: 'Invalid Date Interval',
        dataImportEndDate: 'Invalid Date Interval',
        dataImportSource: 'Invalid Fuel Source',
        dataImportRawFile: 'Invalid Import (CSV file)'
      },
      validators: {
        carrier: [VALIDATE_CARRIER_REQUIRED],
        dataImportSource: [VALIDATE_REQUIRED],
        dataImportRawFile: [VALIDATE_REQUIRED],

      }
    }
  }
  

  validateIsValidInterval = (dateFrom, dateTo) => {
    return (new Date(dateFrom) <= new Date(dateTo))
  }

  getErrorData = () => {
    let errorData = {
      carrier: !isValid(this.state.data.carrier, this.state.validators.carrier),
      dataImportSource: !isValid(this.state.data.dataImportSource, this.state.validators.dataImportSource),
      dataImportStartDate: !this.validateIsValidInterval(this.state.data.dataImportStartDate, this.state.data.dataImportEndDate),
      dataImportEndDate: !this.validateIsValidInterval(this.state.data.dataImportStartDate, this.state.data.dataImportEndDate),
      dataImportRawFile: !isValid(this.state.data.dataImportRawFile, this.state.validators.dataImportRawFile),
  
    }
    return errorData
  }

  validate = () => {
    return new Promise((resolve, reject) => {
      let errorData = this.getErrorData()
      this.setState(prevState => {
        return { ...prevState, errorData: errorData }
      }, () => {
        let hasError = false
        for (const [key, value] of Object.entries(errorData)) {
          if (value) {
            hasError = true
          }
        }
        hasError ? reject() : resolve()
      })
    })
  }

  updateValues = (name, obj) => {
    const that = this
    this.setState(prevState => {
      let newState = {
        ...prevState,
        data: {
          ...prevState.data, ...obj
        }
      }
      return newState
    }, () => {
      that.props.notifyDataSrcUpdate(that.state.data.dataImportSource)
    })
  }

  onSaveData = (evt) => {
    this.validate().then(succ => {
      this.props.notifyParent(this.state.data)
    }).catch(error => {
      // showErroNotification(error, SAVE_FUEL_IMPORT)
    })
  }

  onCarrierChange = () => {
    // this.updateValues('customer', {driver: ''})
  }
  
  render() {
    const {classes} = this.props
    return (
      <React.Fragment>
        <Grid container className={classes.root} spacing= {2} xs={12} >
        <Grid item xs className={classes.dropDown}>
            <FAutoComplete
                url={API_CARRIERS_URL}
                name='carrier'
                hasError={this.state.errorData.carrier}
                helpMessage={this.state.errorMessage.carrier}
                label={Labels.CARRIER}
                // queryParam='search'
                value={this.state.data.carrier}
                defaultValue={this.state.data.carrier}
                notifyParent={this.updateValues}
                // disabled={!this.state.isNew}
                notifyDependency={this.onCarrierChange}

                />
          </Grid>
    
          <Grid item xs>
            <FDate
              name='dataImportStartDate'
              required={true}
              defaultValue={this.state.data.dataImportStartDate}
              hasError={this.state.errorData.dataImportStartDate}
              helpMessage={this.state.errorMessage.dataImportStartDate}
              // label="Fuel Transaction From "
              label={Labels.IMPORT_FUEL_FROM_DATE}
              notifyParent={this.updateValues} />
          </Grid>
          <Grid item xs>
            <FDate
              name='dataImportEndDate'
              required={true}
              defaultValue={this.state.data.dataImportEndDate}
              hasError={this.state.errorData.dataImportEndDate}
              helpMessage={this.state.errorMessage.dataImportEndDate}
              // label="Fuel Transaction To "
              label={Labels.IMPORT_FUEL_TO_DATE}
              notifyParent={this.updateValues} />
          </Grid>

          
           
          <Grid item xs  className={classes.dropDown}>
            <FSelect
              name='dataImportSource'
              value={this.state.data.dataImportSource}
              hasError={this.state.errorData.dataImportSource}
              helpMessage={this.state.errorMessage.dataImportSource}
              // label='Fuel Transaction Sourcee'
              label={Labels.IMPORT_FUEL_DATA_SOURCE}
              data={this.props.dataImportFuelSource}
              valueRef='prettyName'
              keyRef='id'
              notifyParent={this.updateValues} />
          </Grid>

          <Grid item xs>
            <FFileUpload
                value={this.state.data.dataImportRawFile}
                name='dataImportRawFile'
                hasError={this.state.errorData.dataImportRawFile}
                helpMessage={this.state.errorMessage.dataImportRawFile}
                // label="Upload Fuel Transactions"
                label={Labels.IMPORT_FUEL_UPLOAD_FILE}
                notifyParent={this.updateValues}
            />
        </Grid>
         
          <Grid item xs className={classes.generateButon}>
            <FButton color='primary'
              // label='Import Fuels'
              label={Labels.IMPORT_FUEL_UPLOAD_BUTTON}
              loading={this.state.loading}
              onClick={this.onSaveData}
            />
          </Grid>
      </Grid >
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps)(FuelImportFilterView))
