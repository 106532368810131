import React, { Component } from 'react'
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { ALL_COLORS } from '../../util/Const';

class FSmallPieChart extends React.Component {
   COLORS = this.props.colors || ALL_COLORS
  
   CustomTooltip = ({ active, payload, label }) => {
      if (active) {
         return (
         <div
            className="custom-tooltip"
            style={{
               backgroundColor: "#ffff",
               padding: "5px",
               border: "1px solid #cccc"
            }}
         >
            <label>{`${payload[0].name} : ${payload[0].value}%`}</label>
         </div>
      );
   }
   return null;
};
render() {
   if (!!!this.props.chartData) {
     return (<div/>)
   }
   return (
        <ResponsiveContainer
            width="70%"
            height={100}
            // className={classes.boxContainer}
          > 
          <PieChart width={this.props.width || 730} height={this.props.height||300}>
          <Pie
            data={this.props.chartData}
            color="#000000"
            dataKey="value"
            nameKey="name"
            cx="13%"
            cy="50%"
            outerRadius={this.props.radius ||120}
            fill="#8884d8"
          >
            {this.props.chartData && this.props.chartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={this.COLORS[index % this.COLORS.length]}
                />
            ))}
          </Pie>
          <Tooltip content={<this.CustomTooltip />} />
          {/* <Legend /> */}
          {/* <Legend layout="vertical" verticalAlign="middle" align="right" /> */}
          <Legend layout="horizontal" verticalAlign="bottom" align="left" />
          {/* <Legend layout="horizontal" verticalAlign="top" align="left" /> */}


              </PieChart>
          </ResponsiveContainer>
      );
   }
}
export default FSmallPieChart;