import React from 'react'
import { API_NON_COMPLIANCE } from '../../../util/Const';
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";
import { mapDispatchToProps, mapStateToProps } from "../../../fredux/ActionMaker";
import {  getOwnerLink } from '../../../util/icon_util';
import BaseCompliance from './index'

const styles = theme => ({
  reimbursablePercentage : {
      marginRight: theme.spacing(2),

  },
});


class  MissingDocuments  extends BaseCompliance {
    constructor(props) {
    super(props, API_NON_COMPLIANCE, 'Missing Compliance Document', 'MISSING_DOCUMENTS')
    this.showAdd = true
  }
  
  getOwnerLink = (rowData) => {
    return  getOwnerLink(rowData, this.props.profile)
    
  }
  
  render() {

    return (
        <React.Fragment> 
            {this.getDetail()}
        </React.Fragment>
    )
  }
}

export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps)(MissingDocuments))
