import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles'
import { connect } from "react-redux"
import { mapDispatchToProps, mapStateToProps } from "../../fredux/ActionMaker";


const style = theme => ({
  root: {
    marginTop: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: 'x-large'
  },
});

class FTitle extends Component {

  componentDidMount() {

    // this.props && this.props.updateCurrentTitle && this.props.updateCurrentTitle(this.props.label)
  }

  render() {
    const color = this.props.color || 'blue'
    const { classes } = this.props
    return (
      <React.Fragment>
        <span className={classes.root} style={{color: color}}>{this.props.label} </span>
      </React.Fragment>);
  }
}

export default withStyles(style)(connect(
  mapStateToProps, mapDispatchToProps)(FTitle))