import jsPDF from "jspdf";
import 'jspdf-autotable';
import { getPrettyDate, roundDecimal } from '../../util';
import React from "react";
import {
    func
} from "prop-types";
import {
    addBeltPayCheckType,
    keyValSummary,
    addFleets,
    addExpenses,
    addLogoAndCompanyInfo,
    addPayCheckInfo,
    payCheckPaymentInfo,
    addFooterToPDF
} from "./PdfCommon"
import { BASE_DOCUMENT_URL } from '../Const';

var GREEN = "#008000"
var BLUE = "#1E90FF"
var BLACK = "#000000"
var LIGHT_DARK = "#AFADAD"
var BELT_COLOR = GREEN


export const pdfPayCheckPDF = function (fleetData, expData, summaryData) {
    let fleetCol = [
        {
            title: "Load Number",
            dataKey: "fleetLoadNumber"
        },
        {
            title: "Date",
            dataKey: "fleetPickUpDate"
        },
        // {
        //     title: "Customer",
        //     dataKey: "fleetCustomer"
        // },
        // {
        //     title: "From",
        //     dataKey: "fleetPickupLocation"
        // },
        // {
        //     title: "To",
        //     dataKey: "fleetDeliveryLocation"
        // },
       
        {
            title: "Description",
            dataKey: "paycheckDescription"
        },
        {
            title: "Total",
            dataKey: "fleetLoadFee"
        }

    ];
    let expcol = [{
            title: "Transaction",
            dataKey: "expenseTransactionID"
        },
        {
            title: "Date",
            dataKey: "expenseTransactionDate"
        },
        // {
        //     title: "Expense",
        //     dataKey: "expenseType"
        // },
        {
            title: "Description",
            dataKey: "paycheckDescription"
        },
        {
            title: "Total",
            dataKey: "expenseAmount"
        },
        // {
        //     title: "Descriptions",
        //     dataKey: "desc"
        // },
    ];


    let doc = new jsPDF();
   
    addLogoAndCompanyInfo(doc, summaryData)
    addPayCheckInfo(doc, getPrettyDate(summaryData.payCheckFleetEndDate),  summaryData.payCheckID)
    addBeltPayCheckType(doc, 'PAYMENT')
    let lastY = payCheckPaymentInfo (doc, "Payment To: ", 
                        summaryData.driverDetailInfo.payCheckName, 
                        summaryData.driverDetailInfo.driverLocation, 
                        summaryData.driverDetailInfo.driverEmail, 
                        summaryData.driverDetailInfo.driverPhoneNumber)
    
    addFleets(doc, fleetCol, fleetData, lastY)
    addExpenses(doc, expcol, expData)
    addSummaryPayCheck(doc, summaryData)
    addFooterToPDF(doc)
    return doc;
}


export const addSummaryPayCheck = function (doc, summaryData) {

    // let keys = ['gross', 'fleetTotalMileage', 'otherPayments',
    //     'dispatchFee', 'fuelCharge', 'tollCharge', 'insurance', 'insuranceDPT', 'advance',
    //     'otherExpenses', 'reimbursable', 'payCheckExpense', 'net'
    // ]
    let keys = ['gross', 'fleetTotalMileage', 'otherPayments',
         'dispatchFee', 'fuelCharge',  'insurance', 'advance',
         'otherExpenses', 'reimbursable',  'net']
    
    let confs = {
        gross: {
            title: 'Gross',
            color: GREEN,
            value: roundDecimal(summaryData.gross),
            fontSize: {
                keyFontSize: 16,
                valueFontSize: 16
            }
        },
        fleetTotalMileage: {
            title: 'Total Mileage Fee',
            value: roundDecimal(summaryData.fleetTotalMileage),
            hide: true
        },
        otherPayments: {
            title: 'Other Payments',
            value: roundDecimal(summaryData.otherPayments),
            hide: true
        },
        dispatchFee: {
            title: 'Dispatch Fee',
            value: roundDecimal(summaryData.dispatchFee),
            hide: true
        },
        fuelCharge: {
            title: 'Fuel Fee',
            value: roundDecimal(summaryData.fuelCharge),
            hide: true
        },
        tollCharge: {
            title: 'Toll Fee',
            value: roundDecimal(summaryData.tollCharge),
            hide: true
        },
        insurance: {
            title: 'Insurance',
            value: roundDecimal(summaryData.insurance),
            hide: true
        },
        insuranceDPT: {
            title: 'Insurance Down Payment',
            value: roundDecimal(summaryData.insuranceDPT),
            hide: true
        },
        advance: {
            title: 'Advance',
            value: roundDecimal(summaryData.advance),
            hide: true
        },
        otherExpenses: {
            title: 'Other Expenses',
            value: roundDecimal(summaryData.otherExpenses),
            hide: true
        },
        reimbursable: {
            title: 'Total Reimbursable',
            value: roundDecimal(summaryData.reimbursable),
            hide: true
        },
        payCheckExpense: {
            title: 'Unpaid Paycheck',
            value: roundDecimal(summaryData.payCheckExpense),
            hide: true
        },
        net: {
            title: 'Net',
            value: roundDecimal(summaryData.net),
            color: BLUE,
            paddingBottom: 3,
            fontSize: {
                keyFontSize: 17,
                valueFontSize: 17
            }
        }
    }
    let finalY = doc.previousAutoTable.finalY;
    keyValSummary(doc, keys, confs, 120, finalY + 5 )
}