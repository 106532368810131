import React from 'react';
import FRouterLink from '../widgets/FRouterLink';
import { ROUTE_INVOICE_EDIT_URL, ROUTE_INVOICE_URL, API_INVOICE_URL, ROUTE_FLEET_URL, INVOICE_STATE_MACHINE, TAB_ACCOUNTING, FINANCE_INVOICE, CHOICE_INVOICE } from '../../util/Const';
import FListComponent from '../../util/FListComponent';
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import { connect } from "react-redux"
import { roundDecimal } from '../../util';
import FMaterialTable from '../widgets/FMaterialTable';
import FTableAction from '../widgets/FTableAction';
import { getInvoiceStatusIcon } from '../../util/icon_util';
import  FAttachment  from '../widgets/FAttachment';
import BlockIcon from '@material-ui/icons/Block';


class Invoices extends FListComponent {

  constructor(props) {
    super(props, API_INVOICE_URL, CHOICE_INVOICE)
    this.currentTitle = 'Invoices'
    this.currentTab = TAB_ACCOUNTING
    this.routeUrl = ROUTE_INVOICE_URL
    this.policyID = FINANCE_INVOICE
    this.includeStateAction = false
    // this.includeInvoiceStatus = false
  }


  render() {
    return (
      <div>
        
        <FMaterialTable
          tableRef={this.tableRef}
          columns={[            
            {
              title: 'Invoice',
              field: 'payCheckID',
              render: rowData => {
                return (
                  <React.Fragment>
                      <FRouterLink editIcon={true} label={rowData.payCheckID} to={ROUTE_INVOICE_EDIT_URL + rowData.id} />
                  </React.Fragment>
                )
              }
            },
            {
              title: 'Carrier', 
              render: this.getCarrierLink
            },
            // {
            //   title: 'Customer',
            //   field: 'prettyName',
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FRouterLink label={rowData.customer.prettyName} to={ROUTE_CUSTOMER_URL + rowData.customer.id} />
            //       </React.Fragment>
            //     )
            //   }
            // },
            {
              title: 'Customer', 
              render: this.getCustomerLink
            },
            { title: 'Invoice Created', field: 'created', width: 135,
                render: rowData => {
                    return (
                          <FAttachment url={rowData.payCheckFile} label= {this.getPrettyDate(rowData.created)}/>
                    )
                }
            },
            
            // {
            //   title: 'Carrier', 
            //   field: 'carrier.prettyName',
            //   render: rowData => {
                
            //     return (
            //       <React.Fragment>
            //         <FRouterLink label={rowData.carrier.prettyName} to={ROUTE_CARRIERS_URL + rowData.carrier.id} />
            //       </React.Fragment>
            //     )
            //   }
            // },
            

            {
              title: 'Fleets', 
              render: this.getFleetLink
            },
            // {
            //   title: 'Fleet', 
            //   field: 'fleet.prettyName',
            //   render: rowData => {
            //     if (!!!rowData.fleet){
            //       return '';
            //     }
            //     return (
            //       <React.Fragment>
            //         <FRouterLink label={rowData.fleet.prettyName} to={ROUTE_FLEET_URL + rowData.fleet.id} />
            //       </React.Fragment>
            //     )
            //   }
            // },
            
            // { title: 'Fleet Fr om', field: 'payCheckFleetStartDate', render: rowData => this.getPrettyDate(rowData.payCheckFleetStartDate) },
            // { title: 'Fleet To', field: 'payCheckFleetEndDate', render: rowData => this.getPrettyDate(rowData.payCheckFleetEndDate) },
            // { title: 'Expense From', field: 'payCheckExpenseStartDate', render: rowData => this.getPrettyDate(rowData.payCheckExpenseStartDate) },
            // { title: 'Expense To', field: 'payCheckExpenseEndDate', render: rowData => this.getPrettyDate(rowData.payCheckExpenseEndDate) },
            { title: 'Gross Fleet', field: 'payCheckFleetGrossPayment', render: rowData => roundDecimal(rowData.payCheckFleetGrossPayment) },
            { title: 'Total Expense', field: 'payCheckFleetTotalExpense', render: rowData => roundDecimal(rowData.payCheckFleetTotalExpense) },
            { title: 'Broker Fee (%)', field: 'payCheckExpenseCarrierFee' },
            { title: 'Net', field: 'payCheckNet',  render: rowData => roundDecimal(rowData.payCheckNet) },

           
            {
              title: 'Status', 
              render: rowData => {
                return (
                  <React.Fragment>
                      {getInvoiceStatusIcon(rowData)}
                  </React.Fragment>
                )
              }
            },

            // {
            //   title: 'Actions', 
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FTableAction 
            //             actions={this.getActions(rowData)}
            //             onTableAction = {this.onTableAction}
            //             rowData={rowData}
            //              />
            //       </React.Fragment>
            //     )
            //   }
            // },
            {
              title: 'Actions', 
              render: rowData => {
                let actions  = this.getActions(rowData, FINANCE_INVOICE)

                return (
                  <React.Fragment>
                    {!!actions.length && <FTableAction 
                        actions={actions}
                        onTableAction = {this.onTableAction}
                        rowData={rowData}
                         />
                    }
                    {!!!actions.length && <BlockIcon color="disabled" />}
                  </React.Fragment>
                )
              }
            },
          ]}
          actions={
            [
              // {
              //   icon: () => (
              //     <FRouterAddLink to={ROUTE_INVOICE_URL + 'add'} color='secondary' />
              //   ),
              //   isFreeAction: true
              // },
              this.getAddNewModelActions()
            ]}
          isLoading = {this.state.isLoading}
          options = {{
              // loading: true,
              pageSize: this.state.pageSize,
              pageSizeOptions: this.state.pageSizeOptions,
              actionsColumnIndex: -1
           }}
          data={this.fetchData}
          title="Invoices"
          listErrCode={this.state.listErrCode}
        />

      </div>
    );
  }
}

export default connect(
  mapStateToProps, mapDispatchToProps)(Invoices)
