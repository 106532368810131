import React from 'react'

import { Grid } from "@material-ui/core";
import FInput from '../widgets/FInput';

import FCheckBox from '../widgets/FCheckBox';
import FDate from '../widgets/FDate';
import FSelect from '../widgets/FSelect';


export default class RecurringExpenseConf extends React.Component {

  showIntervalFrequencyValue = () => {
    return (this.props.data.scheduleFrequencyStrategyType == 'DAYS')
  }
  

  showDay = () => {
    return (this.props.data.scheduleFrequencyStrategyType == 'WEEKLY' )
  }
  render() {
    return (
        <Grid container direction='column' spacing={2}>
          <Grid container spacing={2}>
                <Grid item xs={1.3}>
                     <FCheckBox
                      name='expenseIsRecurring'
                      value={this.props.data.expenseIsRecurring}
                      value={this.props.data.expenseIsRecurring}
                      hasError={this.props.errorData.expenseIsRecurring}
                      helpMessage={this.props.errorMessage.expenseIsRecurring}
                      label="Make Recurring"
                      notifyParent={this.props.notifyParent} />
              </Grid>
             
              <Grid item xs={3} hidden={!this.props.data.expenseIsRecurring}>
                    <FDate
                        name='expenseRecurringStartDate'
                        minDate={true}
                        disabled={!this.props.data.expenseRecurringStartDate}
                        required={true}
                        defaultValue={this.props.data.expenseRecurringStartDate}
                        hasError={this.props.errorData.expenseRecurringStartDate}
                        helpMessage={this.props.errorMessage.expenseRecurringStartDate}
                        label="Recurring Start Date "
                        notifyParent={this.props.notifyParent}
                    />
                </Grid>
              
                <Grid item xs={3} hidden={!this.props.data.expenseIsRecurring}>
                    <FDate
                        name='expenseRecurringEndDate'
                        minDate={true}
                        disabled={!this.props.data.expenseRecurringEndDate}
                        required={true}
                        defaultValue={this.props.data.expenseRecurringEndDate}
                        hasError={this.props.errorData.expenseRecurringEndDate}
                        helpMessage={this.props.errorMessage.expenseRecurringEndDate}
                        label="Recurring End Date (up to a year)"
                        notifyParent={this.props.notifyParent}
                    />
                </Grid>
                
                <Grid item  xs={2}  hidden={!this.props.data.expenseIsRecurring}>
                  <FSelect
                      name='scheduleFrequencyStrategyType'
                      required
                      value={this.props.data.scheduleFrequencyStrategyType}
                      hasError={this.props.errorData.scheduleFrequencyStrategyType}
                      helpMessage={this.props.errorMessage.scheduleFrequencyStrategyType}
                      label='Schedule By*'
                      data={this.props.scheduleFrequencyStrategyDayIntevalTypeChoices}
                      valueRef='prettyName'
                      keyRef='id'
                      // disabled={!this.state.isNew}
                      notifyParent={this.props.notifyParent} />
              </Grid>
              
              <Grid item xs xs={2} hidden={!this.props.data.expenseIsRecurring || !this.showDay()}>
                  <FSelect
                      name='scheduleDay'
                      value={this.props.data.scheduleDay}
                      hasError={this.props.errorData.scheduleDay}
                      helpMessage={this.props.errorMessage.scheduleDay}
                      label='Day of the Week'
                      data={this.props.weekDaysChoices}
                      valueRef='prettyName'
                      keyRef='id'
                      notifyParent={this.props.notifyParent} />
              </Grid>

                <Grid item xs={2} hidden={!this.props.data.expenseIsRecurring || !this.showIntervalFrequencyValue()}>
                        <FInput
                            name='expenseRecurringDays'
                            required={true}
                            type='number'
                            value={this.props.data.expenseRecurringDays}
                            hasError={this.props.errorData.expenseRecurringDays}
                            helpMessage={this.props.errorMessage.expenseRecurringDays}
                            label="Recurring Frequency (In Days) "
                            notifyParent={this.props.notifyParent} />
                    </Grid>
            </Grid>
      </Grid>
    )
  }
}

// export default connect(
//   mapStateToProps, mapDispatchToProps)(DispatcherProfile)