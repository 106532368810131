import React, { Component } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table'
import { Button, Link, Fab } from '@material-ui/core';
import FRouterLink from '../widgets/FRouterLink';
import {ROUTE_VEHICLE_MAINTENANCE_URL } from '../../util/Const';
import { getPrettyDate } from '../../util';
import { getScheduleStatusIcon } from '../../util/icon_util';
import { green } from '@material-ui/core/colors';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { FMaterialSimpleTable } from '../widgets/FMaterialTable';

class JobSchedules extends Component {

  
  
  showStg = (rowData, recuStg) => {
    if (recuStg == 'MILEAGE') {
      return rowData &&  rowData.scheduleJobFrequencyStrategyType == 'MILEAGE';
    }
    if (recuStg == 'DATE') {
      return rowData &&  rowData.scheduleJobFrequencyStrategyType == 'DATE';
    }
  }

  
  showEditScheduleJob = (show) => {
    this.setState({showEditScheduleJob: show})
  }

  render() {
    return (
      <div>
        <FMaterialSimpleTable
          columns={[
            {

              title: 'Schedules',
               field: 'id', render: rowData => {
                return (
                  <React.Fragment>
                    <Button color="primary" onClick={() => this.props.onEdit(rowData)}>
                       {this.showStg(rowData, 'DATE') && getPrettyDate(rowData.scheduledJobDate)}
                    </Button>
                    <Button color="primary" onClick={() => this.props.onEdit(rowData)}>
                       {this.showStg(rowData, 'MILEAGE') && rowData.scheduledMileage}
                    </Button>
                  </React.Fragment>
                )
              }
            },
            { title: 'Name', field: 'scheduledJobName'},

            {
              title: 'Maintenance', 
              field: 'maintenance.maintenancePrettyName',
              render: rowData => {
                if (!!!rowData.maintenance){
                  return '';
                }
                return (
                  <React.Fragment>
                    <FRouterLink label={rowData.maintenance.maintenancePrettyName} 
                        to={ROUTE_VEHICLE_MAINTENANCE_URL + rowData.maintenance.id}
                        />
                     {rowData.maintenance.expenseReceipt && <Link href={rowData.maintenance.expenseReceipt} target="_blank"> <AttachmentIcon style={{ fontSize: 25, color: green[500] }}/></Link>}

                  </React.Fragment>
                )
              }
            },
            // { 
            //     title: 'Transaction Date / Receipt',
            //      field: 'expenseTransactionDate', 
              
            //     render:  rowData => {
            //       const td = this.getPrettyDate(rowData.expenseTransactionDate)
            //       return (
            //         <React.Fragment>
                          
            //               {td} { rowData.expenseReceipt && <Link href={rowData.expenseReceipt} target="_blank"> <AttachmentIcon style={{ fontSize: 25, color: green[500] }}/></Link>}

            //         </React.Fragment>
            //       )
            //     }
            // },
            {
              title: 'State', 
              render: rowData => {
                return (
                  <React.Fragment>
                       {getScheduleStatusIcon(rowData)}
                  </React.Fragment>
                )
              }
            },
           
            
            { title: 'Description', field: 'desc' },


          ]}
          actions={
            [
              // {
              //   icon: () => (
              //     <Fab size="small" color="secondary" aria-label="Add">
              //       <AddIcon />
              //     </Fab>
              //   ),
              //   onClick: (event, rowData) => {
              //     this.props.onEdit()
              //   },
              //   isFreeAction: true
              // },
              rowData => ({
                icon: 'delete',
                iconProps: {color: 'action'},
                tooltip: 'Delete Schedule',
                onClick: (event, rowData) => {
                    this.props.onDelete(rowData)
                },
                // disabled: this.state.listData.length  <= 1
             })
            ]}
          // options={{
          //   actionsColumnIndex: -1
          // }}
          data={this.props.data}
          title={this.props.title}
          components={{
            Toolbar: props => (
                <div style={{ backgroundColor: '' }}>
                    <MTableToolbar {...props} />
                </div>
            )
          }}
        />
      </div>
    );
  }
}

export default JobSchedules;
