import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { TAB_DRIVER_APP, DRIVER_APP_EMPLOYMENT_APPLICATION, ROUTE_DRIVER_APP_EMPLOYMENT_APPLICATION_URL, API_DRIVER_UPLOAD_DOCUMENT_URL, CHOICE_DRIVER_UPLOAD_DOCUMENT, ROUTE_DRIVER_APP_UPLOAD_DOCUMENT_URL, DRIVER_APP_DOCUMENT_UPLOADS } from '../../util/Const';
import FListComponent from '../../util/FListComponent';
import { connect } from "react-redux"
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import FTableAction from '../widgets/FTableAction';
import FMaterialTable from '../widgets/FMaterialTable';
import BlockIcon from '@material-ui/icons/Block';
import { getDriverAppUploadIcon } from '../../util/icon_util';
import { Link } from '@material-ui/core';
import FAttachment from '../widgets/FAttachment';


const styles = theme => ({
  smallAvatar: {
    width: 10,
    height: 10,
    border: `2px solid ${theme.palette.background.paper}`,

  },
});

class DriverUploadDocuments extends FListComponent {
  constructor(props) {
    super(props, API_DRIVER_UPLOAD_DOCUMENT_URL, CHOICE_DRIVER_UPLOAD_DOCUMENT)
    this.currentTitle = 'Drive Uploads'
    this.currentTab = TAB_DRIVER_APP
    this.routeUrl = ROUTE_DRIVER_APP_UPLOAD_DOCUMENT_URL
    this.policyID = DRIVER_APP_DOCUMENT_UPLOADS
    this.includeStateAction = false

  }
  
  render() {
    const { classes } = this.props;
    return (
      <div>
   
        <FMaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: 'Driver', 
              render: this.getDriveProfilerLink
            },
           
            {
              title: 'Carrier', 
              render: this.getCarrierLink
            },
            { title: 'Document Type', field: 'documentType'},
            { title: 'Upload Date', field: 'documentIssuedDate', render: rowData => this.getPrettyDate(rowData.driverAppUploadDate) },
            { title: 'Document Upload ', width: 135,
             render: rowData => {
                return (
                      <FAttachment url={rowData.documentFile}
                          label= {getDriverAppUploadIcon(rowData)}/>
                    )
                }
            },            
            // {
            //   title: 'Upload Status ', 
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //           {getUploadDocumentStatusIcon(rowData)}
            //       </React.Fragment>
            //     )
            //   }
            // },
            
            {
              title: 'Actions', 
              render: rowData => {
                let actions  = this.getActions(rowData, DRIVER_APP_EMPLOYMENT_APPLICATION)

                return (
                  <React.Fragment>
                    {!!actions.length && <FTableAction 
                        actions={actions}
                        onTableAction = {this.onTableAction}
                        rowData={rowData}
                         />
                    }
                    {!!!actions.length && <BlockIcon color="disabled" />}
                  </React.Fragment>
                )
              }
            },
          ]}
          // actions={
          //   [
          //     this.getAddNewModelActions()
          //   ]
          //  }
            options = {{
              pageSize: this.state.pageSize,
              pageSizeOptions: this.state.pageSizeOptions,
              actionsColumnIndex: -1
           }}
          data={this.fetchData}
          title="Driver Employment Application"
          listErrCode={this.state.listErrCode}
        />
      </div>
    );
  }
}


export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps)(DriverUploadDocuments))
