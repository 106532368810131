import React, { Component } from 'react'
import ListItemIcon from '@material-ui/core/ListItemIcon';
import SecurityIcon from '@material-ui/icons/Security';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import { mapStateToProps } from '../../fredux/ActionMaker';
import { connect } from 'react-redux'
import {  has_view_permission} from '../../util/ACL';
import * as CONST from "../../util/Const";
import FListItem from '../widgets/FListItem';


class DashboardListItemss extends Component {
    render() {
        const profile = this.props.profile
        return (
            <div>
                <FListItem  
                     to={CONST.ROUTE_DASHBOARD_URL} label='Compliance Overview'>
                    <ListItemIcon><SecurityIcon /></ListItemIcon>
                </FListItem>
                
                <FListItem  
                  disabled={!has_view_permission(profile, CONST.DASHBOARD_FINANCIAL_OVERVIEW)}
                  to={CONST.ROUTE_FINANCIAL_DASHBOARD_URL} label='Financial Overview'>
                <ListItemIcon>< AttachMoneyIcon/></ListItemIcon>
                </FListItem>
             </div>
        );
    }
}

export default connect(mapStateToProps)(DashboardListItemss)
