import React from 'react';
import { Link } from '@material-ui/core';
import FRouterLink from '../widgets/FRouterLink';

import {ROUTE_EXPENSE_URL } from '../../util/Const';
import { getPrettyDate } from '../../util';
import { FMaterialSimpleTable } from '../widgets/FMaterialTable';


class Expenses extends React.Component {

  getExpenseFor = (rowData) => {
   let expenseFor = rowData.expenseFor
   return expenseFor
  }
  render() {
    return (
      <div>
        <FMaterialSimpleTable
          columns={[
            {
              title: 'Transaciton Id', render: rowData => {
                return (
                  <React.Fragment>
                    <FRouterLink label={rowData.expenseTransactionID} to={ROUTE_EXPENSE_URL + rowData.id} />
                  </React.Fragment>
                )
              }
            },
            { title: 'Transaction Date', render: rowData => getPrettyDate(rowData.expenseTransactionDate) },
           
            { title: 'Expense Type', field: 'expenseType'},
            {
              title: 'Invoice',
              field: 'expenseIsInvoiceable',
              render: rowData => {
                return (
                  <React.Fragment>
                    {/* {rowData.expenseIsReimbursable && <Icon>X</Icon>} */}
                    {rowData.expenseInvoicePercentage}%
                  </React.Fragment>
                )
              }
            },
            
            { title: 'Amount', field: 'expenseAmount' },
            
            {
              title: 'Receipt',
              render: rowData => {
                let url = rowData.expenseReceipt
                return (
                  <React.Fragment>
                    {url && <Link href={url} target="_blank"> Expense</Link>}
                  </React.Fragment>
                )
              }
            }
          ]}
           data={this.props.data}
          title={`Expensess`}
        />
      </div>
    );
  }
}

export default Expenses;