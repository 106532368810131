import React, { Component } from 'react'


import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import { connect } from 'react-redux';
import { FRouteExt } from '../app/FRoute';
import SignIn from './SignIn';
import SignUp from './SignUp';
import { AUTH_SIGN_UP, AUTH_SIGNED_IN, AUTH_SIGN_IN } from '../../util/Const';
import { getAuthToken } from '../../util/FAPI';

class Index extends Component {

  render() {
    // if (this.props.authStatus == AUTH_SIGNED_IN && getAuthToken()) {
    //   // window.location.href = '/dashboard'
    //  this.props.history.push('/dashboard')  
    // }
    // return (
    //   <div>
    //     <FRouteExt/>
    //     {this.props.authStatus == AUTH_SIGN_IN}<SignIn/>
    //     {this.props.authStatus == AUTH_SIGN_UP} <SignUp/>
    //   </div>
    return (<div> Index file may be</div>)

  }
}


export default connect(
  mapStateToProps, mapDispatchToProps)(Index)
