import React from 'react';
import FRouterLink, { FRouterWithAddLabelLink } from '../widgets/FRouterLink';
import { API_FORMER_EMPLOYER, ROUTE_COMPANY_URL, TAB_ADMIN, ADMIN_CONTACTS } from '../../util/Const';
import FListComponent, { StyledAssetLink } from '../../util/FListComponent';
import BlockIcon from '@material-ui/icons/Block';
import { connect } from "react-redux"
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import FMaterialTable from '../widgets/FMaterialTable';
import FTableAction from '../widgets/FTableAction';
import { getPrettyNameFromListDict } from '../../util/FAPI';
import { Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(2),
    borderRight: '1px solid #d9d9d9'
  }
}));
function RenderButtons() {
  const classes = useStyles();
  const url = `${ROUTE_COMPANY_URL}add`
  
  return (

<Paper elevation={3} style={{ padding: '5px', marginBottom: '5px' }}>
    <Typography variant="h6" gutterBottom color={'primary'} style={{ paddingBottom: '5px', paddingLeft: '20px' }}>
        Service Providers
    </Typography>

    <Grid container> {/* This container will make the items lay out in a row */}
        <Grid item  item xs={1.5} className={classes.submit}>
            <FRouterWithAddLabelLink 
              to={url} 
              label="Drug Test Prov."
            />
        </Grid>
        <Grid item   xs={1.5} className={classes.submit}>
            <FRouterWithAddLabelLink
              to={url}
              label="Fuel Card"
            />
        </Grid>
       
        <Grid item  xs={2}  className={classes.submit}>
            <FRouterWithAddLabelLink
              to={url}
              label="Auto Service"
            />
        </Grid>
        <Grid item  xs={0.9}  className={classes.submit}>
            <FRouterWithAddLabelLink
              to={url}
              label="ELD"
            />
        </Grid>
        <Grid item  xs={2}  className={classes.submit}>
            <FRouterWithAddLabelLink 
              to={url} 
              label="General Service"
            />
        </Grid>
        <Grid item  xs={1.2}  className={classes.submit}>
            <FRouterWithAddLabelLink
              to={url} 
              label="Freelancer"
            />
        </Grid>
        <Grid item   xs className={classes.submit}>
            <FRouterWithAddLabelLink
              to={url}
              label="Former Driver Employer"
            />
        </Grid>
    </Grid>
</Paper>


  );
}

class Contacts extends FListComponent {
  constructor(props) {
    super(props, API_FORMER_EMPLOYER, null)
    this.currentTitle = 'Contacts'
    this.currentTab = TAB_ADMIN
    this.routeUrl = ROUTE_COMPANY_URL
    this.policyID = ADMIN_CONTACTS
  }
  
  

  render() {
    return (
      <div>
        <RenderButtons />

        <FMaterialTable
          tableRef={this.tableRef}

          columns={[
            {
              title: 'Contact #', 
              field: 'comapyType',
              render: rowData => {
                return (
                  <StyledAssetLink>
                    <React.Fragment>
                      <FRouterLink editIcon={true} label={rowData.companyName} 
                        to={ROUTE_COMPANY_URL + rowData.id} />
                    </React.Fragment>
                  </StyledAssetLink>
                )
              }
            },
            {
              title: 'Carrier', 
              render: this.getCarrierLink
            },  
            {
              title: 'Contact Type', 
              field: 'comapyType',
              render: rowData => {
                let serviceType = getPrettyNameFromListDict(this.props.companyTypeChoice, rowData.companyType)
                return (
                  <React.Fragment>
                     {serviceType}
                  </React.Fragment>
                )
              }
            },
                    
            { title: 'Email', field: 'companyEmail' },
            { title: 'Phone', field: 'companyPhoneNumber' },
            {
              title: 'Actions', 
              render: rowData => {
                let actions  = this.getActions(rowData, ADMIN_CONTACTS)
                return (
                  <React.Fragment>
                    {!!actions.length && <FTableAction 
                        actions={actions}
                        onTableAction = {this.onTableAction}
                        rowData={rowData}
                         />
                    }
                    {!!!actions.length && <BlockIcon color="disabled" />}
                  </React.Fragment>
                )
              }
            },
            
          ]}
          actions={
            [
              this.getAddNewModelActions()
          
            ]}
          options = {{
              pageSize: this.state.pageSize,
              pageSizeOptions: this.state.pageSizeOptions,
              actionsColumnIndex: -1
           }}
          data={this.fetchData}
          title="Contacts"
          listErrCode={this.state.listErrCode}
         
        />

      </div>
    );
  }
}


export default connect(
  mapStateToProps, mapDispatchToProps)(Contacts)
