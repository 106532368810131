import React from "react";
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";


import { Grid, Paper } from "@material-ui/core";
import FSelect from "../widgets/FSelect";
import FMessage from "../widgets/FMessage";
import FInput from "../widgets/FInput";
import { mapDispatchToProps, mapStateToProps } from "../../fredux/ActionMaker";
import {API_DOCUMENTS_URL, TAB_SAFETY_AND_COMPLIANCE, API_EMPLOYEMENT_HISTORY, CHOICE_EMPLOYMENT_HISTORY, API_CARRIERS_URL, API_DRIVERS_URL, API_FORMER_EMPLOYER, CompanyCode, SAFETY_AND_COMPLINACE_EPLOYMENT_HISTORYS } from "../../util/Const";
import DocumentView from "../document"
import {VALIDATE_CARRIER_REQUIRED, VALIDATE_OBJECT_REQUIRED,  VALIDATE_REQUIRED, VALIDATE_ZERO_OR_POSITIVE, VALIDATE_FEE, VALIDATE_POSITIVE } from "../../util/ValidatorConst";
import FDetailComponent from "../../util/FDetailComponent";
import FDate from "../widgets/FDate";
import { isValid } from "../../util/Validator";
import FAutoComplete from "../widgets/FAutoComplete";
import CompanyPopUp from "../company/CompanyPopUp";

const styles = theme => ({
    root: {
        display: "flex",

    },
    padding: {
        marginTop: theme.spacing(4),
    },

    header: {
        background: '#f5f5f5'
    },
    button: {
        marginRight: theme.spacing(2),
    },
    root1: {
        backgroundColor: '#fff',
        padding: theme.spacing(2),
    },
});

const PROPS = {
    errorData: {
    },
    errorMessage: {
        employmentStartDate: 'Invalid Date',
        employmentEndDate: 'Invalid Date',
        employmentReasonForLeaveing: 'Invalid Location',
        employmentVerificationMethod: 'Invalid Type',
        employmentVerificationStatus: 'Invalid Data',
        employmentVerificationAttemptCount: 'Invalid Dataaa',
        totalMilesDrove: 'Invalid Data',
        truckType: 'Invalid Data',
        trailerType: 'Invalid Data',
        company: 'No Company Selected/Found  Under Carrier',
        driver: 'No Driver Selected/Found  Under Carrier',
        carrier: 'Invalid Carrier'
    },
    touched: {
    },
    data: {
        employmentStartDate: '',
        employmentEndDate: '',
        employmentReasonForLeaveing: '',
        employmentVerificationMethod: '',
        employmentVerificationStatus:'',
        employmentVerificationAttemptCount: '',
        totalMilesDrove: '',
        truckType: '',
        trailerType: '',
        company: {},
        driver: {},
        carrier: {}
    },
    validators: {
        employmentStartDate: [VALIDATE_REQUIRED],
        employmentEndDate: [VALIDATE_REQUIRED],
        employmentReasonForLeaveing: [VALIDATE_REQUIRED],
        employmentVerificationMethod: [VALIDATE_REQUIRED],
        employmentVerificationStatus:[VALIDATE_REQUIRED],
        employmentVerificationAttemptCount: [VALIDATE_REQUIRED],
        totalMilesDrove: [VALIDATE_POSITIVE],
        truckType: [VALIDATE_REQUIRED],
        trailerType: [VALIDATE_REQUIRED],
        company: [VALIDATE_OBJECT_REQUIRED],
        driver: [VALIDATE_OBJECT_REQUIRED],
        carrier: [VALIDATE_CARRIER_REQUIRED],
    }
}



class drugTestDetail extends FDetailComponent {
    constructor(props) {
        super(props, API_EMPLOYEMENT_HISTORY, CHOICE_EMPLOYMENT_HISTORY, PROPS)
    }
    
    isemploymentVerificationAttemptCountRequired = () => {
        return (this.state.data.employmentVerificationMethod == 'POST_ACCIDENT');
    }

    getErrorData = () => {
        let errorData = {
            employmentStartDate: !isValid(this.state.data.employmentStartDate, this.state.validators.employmentStartDate),
            employmentEndDate: !isValid(this.state.data.employmentEndDate, this.state.validators.employmentEndDate),

            employmentReasonForLeaveing: !isValid(this.state.data.employmentReasonForLeaveing, this.state.validators.employmentReasonForLeaveing),
            employmentVerificationMethod: !isValid(this.state.data.employmentVerificationMethod, this.state.validators.employmentVerificationMethod),
            employmentVerificationStatus: !isValid(this.state.data.employmentVerificationStatus, this.state.validators.employmentVerificationStatus),            

            // employmentVerificationAttemptCount: !isValid(this.state.data.employmentVerificationAttemptCount, this.state.validators.employmentVerificationAttemptCount),
            totalMilesDrove: !isValid(this.state.data.totalMilesDrove, this.state.validators.totalMilesDrove),
            company: !isValid(this.state.data.company, this.state.validators.company),
            truckType: !isValid(this.state.data.truckType, this.state.validators.truckType),
            trailerType: !isValid(this.state.data.trailerType, this.state.validators.trailerType),
            driver: !isValid(this.state.data.driver, this.state.validators.driver),
            carrier: !isValid(this.state.data.carrier, this.state.validators.carrier)
        }
        return errorData
    }

    onCarrierChange = () => {
        this.updateValues('driver', {driver: ''})
        this.updateValues('company', {company: ''})
    }

    getCurrentTitle = () => {
        let pName = (this.state.data.driver.prettyName && this.state.data.driver.prettyName + ' -  ' + this.state.data.employmentVerificationMethod)  || ''

        let title = 'Employment Hisory: '+  pName
        this.props.updateCurrentTab(TAB_SAFETY_AND_COMPLIANCE)
        this.props.updateCurrentTitle && this.props.updateCurrentTitle(title)
        return title
    }
    
    render() {
        // let pName = (this.state.data.driver.prettyName && this.state.data.driver.prettyName + ' -  ' + this.state.data.employmentVerificationMethod)  || ''
        this.getCurrentTitle()

        const { classes } = this.props;
        return (
            <Paper className={classes.root1}>
                {/* <FTitle label={title}/> */}
                <Grid container direction='column'  alignItems='stretch' spacing={3} className={classes.root1}>
                    <Grid item xs>
                        <FMessage error={this.props.crudServerError}
                            success={this.props.crudServerSuccess} />
                    </Grid>
                    <Grid container  spacing= {2} xs={12}>
                            <Grid item xs>
                                    <FAutoComplete
                                        url={API_CARRIERS_URL}
                                        name='carrier'
                                        hasError={this.state.errorData.carrier}
                                        helpMessage={this.state.errorMessage.carrier}
                                        label='Authority'
                                        // queryParam='search'
                                        value={this.state.data.carrier}
                                        defaultValue={this.state.data.carrier}
                                        notifyParent={this.updateValues}
                                        disabled={!this.state.isNew}
                                        notifyDependency={this.onCarrierChange}

                                        />
                                    {/* <FSelect
                                        name='carrier'
                                        value={this.state.data.carrier && this.state.data.carrier.id}
                                        hasError={this.state.errorData.carrier}
                                        helpMessage={this.state.errorMessage.carrier}
                                        label='Carrier *'
                                        data={getCarriers(this.props)}
                                        valueRef='prettyName'
                                        keyRef='id'
                                        notifyParent={this.updateValues} /> */}
                                </Grid>
                                <Grid item xs>
                                <FAutoComplete
                                        url={API_DRIVERS_URL}
                                        // queryParam={`carrier=${this.state.data.carrier && this.state.data.carrier.id}`}
                                        hasCarrier={true}
                                        carrier={this.state.data.carrier}
                                        name='driver'
                                        hasError={this.state.errorData.driver}
                                        helpMessage={this.state.errorMessage.driver}
                                        label='Driver'
                                        // queryParam='search'
                                        value={this.state.data.driver}
                                        defaultValue={this.state.data.driver}
                                        notifyParent={this.updateValues}
                                        // disabled={!this.state.isNew}
                                        />

                                    {/* <FSelect
                                        name='driver'
                                        value={this.state.data.driver && this.state.data.driver.id}
                                        hasError={this.state.errorData.driver}
                                        helpMessage={this.state.errorMessage.driver}
                                        label='Driver *'
                                        
                                        data={getDrivers(this.props, this.state.data.carrier)}
                                        valueRef='prettyName'
                                        keyRef='id'
                                        // disabled={!this.state.isNew}
                                        notifyParent={this.updateValues} /> */}
                                </Grid>
            
                                <Grid item xs>
                                    <FAutoComplete
                                        url={API_FORMER_EMPLOYER}
                                        queryParam={'companyCode=5'}
                                        hasCarrier={true}
                                        carrier = {this.state.data.carrier}
                                        name='company'
                                        hasError={this.state.errorData.company}
                                        helpMessage={this.state.errorMessage.company}
                                        label='Former Employer'
                                        // queryParam='search'
                                        value={this.state.data.company}
                                        defaultValue={this.state.data.company}
                                        notifyParent={this.updateValues}
                                        // disabled={!this.state.isNew}
                                        showAdd={true} 
                                        // Onclick the add button 
                                        onEdit={this.onShowAddCompany}
                                    />
                                    <CompanyPopUp
                                        
                                        dialogEditMode={this.state.showAddCompany}
                                        companyCode = {CompanyCode.FORMER_EMPLOYER}
                                        notifyDialogClose = {() => this.setState({showAddCompany: false})}
                                        choices={this.props.companyFormerEmployersContactTypeChoice}
                                        
                                        title={'Former Employer'}
                                        carrier = {this.state.data.carrier}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <FSelect
                                        name='employmentVerificationMethod'
                                        value={this.state.data.employmentVerificationMethod}
                                        hasError={this.state.errorData.employmentVerificationMethod}
                                        helpMessage={this.state.errorMessage.employmentVerificationMethod}
                                        label='Employment Verification Method *'
                                        data={this.props.employmentVerificationMethodChoices}
                                        valueRef='prettyName'
                                        keyRef='prettyName'
                                        notifyParent={this.updateValues}
                                    />
                            </Grid>
                    </Grid>
                    <Grid container className={classes.root} spacing= {2} xs={12} >

                               <Grid item xs>
                                    <FDate
                                        name='employmentStartDate'
                                        required={true}
                                        defaultValue={this.state.data.employmentStartDate}
                                        hasError={this.state.errorData.employmentStartDate}
                                        helpMessage={this.state.errorMessage.employmentStartDate}
                                        label="Employment Start Date *"
                                        notifyParent={this.updateValues} />
                               
                                </Grid>
                                <Grid item xs>
                                    <FDate
                                        name='employmentEndDate'
                                        required={true}
                                        defaultValue={this.state.data.employmentEndDate}
                                        hasError={this.state.errorData.employmentEndDate}
                                        helpMessage={this.state.errorMessage.employmentEndDate}
                                        label="Employment End Date  "
                                        notifyParent={this.updateValues} />
                               
                                </Grid>
                           
                           
                           
                                <Grid item xs={3}>
                                    <FInput
                                        name='employmentVerificationAttemptCount'
                                        required={true}
                                        type='number'
                                        value={this.state.data.employmentVerificationAttemptCount}
                                        hasError={this.state.errorData.employmentVerificationAttemptCount}
                                        helpMessage={this.state.errorMessage.employmentVerificationAttemptCount}
                                        label="Total Employment verification Attempts "
                                        notifyParent={this.updateValues} />
                                </Grid>
                            <Grid item xs>
                                <FSelect
                                    name='employmentVerificationStatus'
                                    value={this.state.data.employmentVerificationStatus}
                                    hasError={this.state.errorData.employmentVerificationStatus}
                                    helpMessage={this.state.errorMessage.employmentVerificationStatus}
                                    label='Verification Status'
                                    data={this.props.employmentVerificationStatusChoices}
                                    valueRef='prettyName'
                                    keyRef='prettyName'
                                    notifyParent={this.updateValues}
                                />
                            </Grid>
                            
                           
                    </Grid>     
                    <Grid container className={classes.root} spacing= {2} xs={12} >
                                <Grid item xs>
                                    <FSelect
                                        name='truckType'
                                        value={this.state.data.truckType}
                                        hasError={this.state.errorData.truckType}
                                        helpMessage={this.state.errorMessage.truckType}
                                        label='Truck class / Type *'
                                        data={this.props.vehicle_truck_type_choices}
                                        valueRef='prettyName'
                                        keyRef='prettyname'
                                        notifyParent={this.updateValues} />
                                </Grid>
                                <Grid item xs>
                                   <FSelect
                                        name='trailerType'
                                        value={this.state.data.trailerType}
                                        hasError={this.state.errorData.trailerType}
                                        helpMessage={this.state.errorMessage.trailerType}
                                        label='Trailer Type *'
                                        data={this.props.vehicle_trailer_type_choices}
                                        valueRef='prettyName'
                                        keyRef='prettyName'
                                        notifyParent={this.updateValues} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FInput
                                        name='totalMilesDrove'
                                        required={true}
                                        type='number'
                                        value={this.state.data.totalMilesDrove}
                                        hasError={this.state.errorData.totalMilesDrove}
                                        helpMessage={this.state.errorMessage.totalMilesDrove}
                                        label="APPROX # OF MILES DROVE"
                                        notifyParent={this.updateValues} />
                                </Grid>
                                <Grid item xs={4}>
                                <FInput name='employmentReasonForLeaveing' required
                                        value={this.state.data.employmentReasonForLeaveing}
                                        hasError={this.state.errorData.employmentReasonForLeaveing}
                                        helpMessage={this.state.errorMessage.employmentReasonForLeaveing}
                                        label="Reason For leaving" notifyParent={this.updateValues}
                                    />
                            </Grid>
            
                    </Grid>
                    <Grid container className={classes.root} spacing= {2} xs={12} >
                            
                           
                            <Grid item xs>
                                 <FInput
                                    name='desc' value={this.state.data.desc} multiline={true}
                                    value={this.state.data.desc}
                                    hasError={this.state.errorData.desc}
                                    helpMessage={this.state.errorMessage.desc}
                                    label="Description" notifyParent={this.updateValues} />
                            </Grid>
                    </Grid>     
                    <Grid item container xs={8} >
                            {this.getUpdateButton(this.props.profile, SAFETY_AND_COMPLINACE_EPLOYMENT_HISTORYS)}
                    </Grid>
                    {/* <Grid item container xs={8} >
                        <FButton color='primary'
                            label={this.state.isNew ? 'Save' : 'Update'}
                            loading={this.state.loading}
                            onClick={this.onSaveData}
                        />
                        <Grid item style={{ paddingTop: '9px' }}>
                            <FRouteBackLink variant='outlined' color='secondary' className={'backButton'} />
                        </Grid>
                    </Grid> */}
                </Grid >
                <Grid item xs className={classes.padding}>
                        {!this.state.isNew &&
                            <DocumentView
                                ownerId={this.props.match.params.id}
                                owner={this.state.data}
                                listUrl={API_DOCUMENTS_URL + `?&employment_history=${this.props.match.params.id}`}
                                ownerType='employment_history'
                                policy_id={SAFETY_AND_COMPLINACE_EPLOYMENT_HISTORYS}
                                documentChoices={this.props.document_employment_history_choices}
                            />}
                    </Grid>
             </Paper>
        );
    };
}


export default withStyles(styles)(connect(
    mapStateToProps, mapDispatchToProps)(drugTestDetail))
