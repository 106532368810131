import React from 'react';
import { UPDATE, GET, DELETE, GET_RESULT } from './FAPI';
import { isValid, isNotNullOrEmpty } from "./Validator";
import { getErrroMessage, getSuccessMessage, showSuccessNotification, showErroNotification } from "../components/widgets/FMessage";
import { VALIDATE_REQUIRED } from "./ValidatorConst";
import { has_create_permission, has_edit_permission } from './ACL';
import FButton from '../components/widgets/FButton';
import { Grid } from '@material-ui/core';
import { FRouteBackLink } from '../components/widgets/FRouterLink';
import * as CONST from './Const';


export class FListDetailView extends React.Component {
  constructor(props, addProps) {
    super(props);
    this.isNew = true
    this.state = {
      ...addProps
    };
  }

  componentDidMount = () => {
    this.fetchListData();
  };

  componentWillUnmount() {
    this.props.resetServerValidation();
  }


  //TODO(Estif) Revise doing noting
  resetForms = () => {
    this.setState({ formData: {}, data: {}, touched: {} });
};


  fetchListData = () => {
    this.props.clearServerError()
    GET(this.props.listUrl).then(res => {
      this.setState({ listData:  GET_RESULT(res)});
    }).catch(err => {
      this.props.updateServerError({ msg: getErrroMessage(err) })
    });
  };


  updateValues = (name, obj) => {
    
    this.setState(prevState => {
      let newState = {
        ...prevState,
        data: {
          ...prevState.data, ...obj
        },
        touched: {
          ...prevState.touched,
          [name]: true
        }
      }
      
      return newState
    }, () => {
      this.validateSingle(name)
    })
  }

  // TODO (Editied probably better one)
  getFormData = () => {
    
    let formData = new FormData();
    formData.append(this.props.ownerType, this.props.ownerId)
    let data = this.state.data
    let touchedData = this.state.touched
    Object.keys(data).forEach(key => {
      if (touchedData && touchedData[key]) {
         if (!data[key]) {
            formData.append(key, '')
         }
         else if (data[key] && data[key].hasOwnProperty("id")) {
          formData.append(key, data[key]['id'])
        } else {
          formData.append(key, data[key])
        }
      }
    })
    formData = this.updateFormData(formData)
    
    return formData
  }

  updateFormData = (formData) => {
     return formData
  }

  //TODO(Estifanos) some difference on this method
  onSaveData = (evt) => {
    this.validate().then(succ => {
      this._saveData()
    }).catch(e => {
      console.log(this.state.errorData)
    })
  }


  validateSingle = (name) => {
    let isV = !isValid(this.state.data[name], this.state.validators[name])
    this.setState(prevState => {
      let newState = {
        ...prevState,
        errorData: {
          ...prevState.errorData, ...{ [name]: isV }
        }
      }
      return newState
    })
  }

  getErrorData = () => {
    let errData = {}
    for (const [key, valids] of Object.entries(this.state.validators)) {
      errData[key] = !isValid(this.state.data[key], valids)
    }
    return errData
  }

  validate = () => {
    return new Promise((resolve, reject) => {

      let errorData = this.getErrorData()
      this.setState(prevState => {
        return { ...prevState, errorData: errorData }
      }, () => {
        let hasError = false
        
        for (const [key, value] of Object.entries(errorData)) {
          if (value) {
            hasError = true
          }
        }
        
        hasError ? reject() : resolve()
      })
    })
  }

  resetErrorMessage = () => {
    this.setState({
      errorData: {}
    });
  };

  onEdit = (rowData = null) => {
    if (rowData === null) {
      this.isNew = true;
      rowData = {};
    }
    else {
      this.isNew = false;
    }
    this.setState({ data: rowData });
    this.setState({ editMode: true });
  };

  notifyDialogClose = () => {
    this.setState({ editMode: false });
    this.resetErrorMessage();
  };
  
  getMessageModule = ()=> {
    return this.state.module
  }

  clearTouchedData = () => { 
     this.setState({touched: {}})
  }
  
  //TODO(Estifanos) some difference on this method
  _saveData = () => {
    this.setState({ loading: true })
    let data = this.getFormData()
    let url = this.isNew ? this.state.url : this.state.url + this.state.data.id
    let method = this.isNew ? 'post' : 'patch'
    UPDATE(url, method, data).
      then(response => {
        this.setState({ loading: false })
        // this.props.updateServerSuccess({ msg: getSuccessMessage(response) })
        showSuccessNotification(response, CONST.getModuleTitle(this.getMessageModule(), 'SAVE'))
        this.fetchListData()
        this.notifyDialogClose()
        this.clearTouchedData()
      }).catch(error => {
        this.setState({ loading: false })
        showErroNotification(error, CONST.getModuleTitle(this.getMessageModule(), 'SAVE'))
        // this.clearTouchedData()
      });
  }

  isAddressValid= (includePlace=false, includeCity=false, includeState=false, includezipCode=false) => {
    if (!isNotNullOrEmpty(this.state.data) || !isNotNullOrEmpty(this.state.data.address)) {
        return true
    }
    let address = this.state.data.address
    if (!!!address) return 
    if (typeof address === 'string' || address instanceof String) {
        address = JSON.parse(address) 
      }
    let isV = true
    if (includePlace) isV = isV && isValid(address.place, [VALIDATE_REQUIRED])
    if (includeCity) isV = isV &&  isValid(address.city, [VALIDATE_REQUIRED])
    if (includeState) isV = isV &&  isValid(address.state, [VALIDATE_REQUIRED])
    if (includezipCode) isV = isV &&  isValid(address.zipCode, [VALIDATE_REQUIRED])
     
    return isV
 }
 
 /**
  * Using owner's policy_id e.g. if the driver is owner of the documents then 
  * action on the documents is considered as editing on the owner's data.
  * @param {*} profile 
  * @param {*} policy_id 
  */
 getUpdateButton = (profile, policy_id, isOwonerPolicy=true) => {
  const isNewEntry = this.isNew ? true : false
  let disabled = true
  if (isOwonerPolicy) {
     disabled = !has_edit_permission(profile, policy_id)
  }
  else {
    if (isNewEntry ) {
          disabled = !has_create_permission(profile, policy_id)
      } else {
          disabled = !has_edit_permission(profile, policy_id)
      }
    }
  
  return (
      <React.Fragment>
          <FButton color='primary'
              label={isNewEntry ? 'Save' : 'Update'}
              disabled={disabled}
              loading={this.state.loading}
              onClick={this.onSaveData}
          />
          <Grid item >
              <FButton color='secondary' label='Cancel'
                    onClick={this.notifyDialogClose} />
          </Grid>
      </React.Fragment>
  )
}
onTableAction = (action, rowData) => {
  switch ( action) {
    case CONST.ACTION_TABLE_DELETE_ROW :
        this.deleteRow(rowData)
        break
    case CONST.ACTION_TABLE_ACTIVATE:
        this.updateRow(rowData, { state: 'ACTIVE'})
        break;
    case CONST.ACTION_TABLE_DEACTIVATE:
        this.updateRow(rowData, { state: 'INACTIVE'})
        break;
    case(CONST.ACTION_TABLE_COMPLETE):
        this.updateRow(rowData, { status: 'COMPLETE'})
        break; 
    case(CONST.ACTION_TABLE_INPROGRESS):
        this.updateRow(rowData, { status: 'IN_PROGRESS'})
        break;
    case(CONST.ACTION_TABLE_INVOICE_CREATED):
        this.updateRow(rowData, { invoiceStatus: CONST.INVOICE_STATE.CREATED})
        break;
    case(CONST.ACTION_TABLE_INVOICE_INVOICED):
        this.updateRow(rowData, { invoiceStatus:  CONST.INVOICE_STATE.INVOICED})
        break;
    case(CONST.ACTION_TABLE_INVOICE_FULLY_PAID):
        this.updateRow(rowData, { invoiceStatus:  CONST.INVOICE_STATE.PAID})
        break;
    case(CONST.ACTION_TABLE_INVOICE_CANCELED):
        this.updateRow(rowData, { invoiceStatus:  CONST.INVOICE_STATE.CANCELED})
        break;
    case(CONST.ACTION_TABLE_EMAIL_INVOICE):
        this.emailAPI(CONST.ACTION_TABLE_EMAIL_INVOICE, rowData)
        // this.updateRow(rowData, { invoiceStatus:  CONST.INVOICE_STATE.CANCELED})
        break;
  }
}
}
