import { getPrettyAddress } from "..";
import { AI_ZTRUCKING_TRUCK_IMG_URL } from "../Const"
import { BASE_DOCUMENT_URL } from '../Const';

var GREEN = "#008000"
var BLUE = "#1E90FF"
var PINK = "#1E90FF"
var RED = "#B22222"
var ORANGE = "#FF8C00"
var BLACK = "#000000"
var LIGHT_DARK = "#AFADAD"
var BELT_COLOR = LIGHT_DARK
var WHITE = "#FFFFFF"
var LIGHT_PURPLE = "#e8eaf5"



var FONT_ARIAL = 'arial'
var FONT_BOLD = 'bold'
var FONT_SIZE = 13
var COMPANY_INFO_FONT_SIZE = 15

var DEFAULT_LOGO_PATH = '/truck.jpg'

// var doc = new jsPDF();
// //let doc = new jsPDF('p', 'pt', "a4");  // For landscape


export function set_cell(doc, text, x = 0, y = 0, w = 0, h = 0, ln = 1, fontName = 'arial', fontStyle = 'bold', fontSize = 13, fontColor = BLACK) {

    doc.setFontSize(fontSize);
    doc.setTextColor(fontColor);
    doc.cell(x, y, w, h, text, ln);
    doc.save('hello_world.doc');
}

export function set_text(doc, text, x = 0, y = 0, fontSize = 13, fontName = 'times', fontColor = BLACK, fontStyle = 'italic') {
    doc.setFont(fontName, fontStyle)
    doc.setFontSize(fontSize);
    doc.setTextColor(fontColor);
    text = text != undefined ? text: ''
    doc.text(x, y, text.toString());
    return {x: x,  y: y}
}

export function addBeltPayCheckType(doc, type) { 
    doc.setFillColor(BELT_COLOR);
    doc.setLineWidth(1);
    doc.rect(0, 32, 130, 7, 'f');
    set_text(doc, type, 138, 39,  34, 'times', GREEN, 'italic')
    doc.setFillColor(BELT_COLOR);
    doc.rect(195, 32, 200, 7, 'f');
}


export const addFleets = function (doc, fleetCol, fleetData, lastY) {
    set_text(doc, "Fleets: ", 13, lastY + 3, 20, 'times', BLACK, 'italic')
    doc.autoTable(fleetCol, fleetData, {
        startY: lastY + 5,
        styles: {fillColor: LIGHT_PURPLE, textColor: BLACK},
        columnStyles: {
            fleetPickUpDate: {cellWidth: 22, fillColor: WHITE, textColor: BLACK},
            fleetLoadNumber: {cellWidth: 31, fillColor: WHITE, textColor: BLACK},
            fleetLoadFee: {cellWidth: 21, fillColor: WHITE, textColor: BLACK},
            totalFee: {cellWidth: 21, fillColor: WHITE, textColor: BLACK},
            
            paycheckDescription: {fillColor: WHITE, textColor: BLACK},
            invoiceDescription: {fillColor: WHITE, textColor: BLACK}
        },
    });
    return lastY + 5
}
 
export const addExpenses = function (doc, expcol, expData) {
    if (!expData || expData.length <=0 ) return
    let startY = doc.previousAutoTable.finalY + 15
    set_text(doc, "Expenses: ", 13, startY, 20, 'times', BLACK, 'italic')
    doc.autoTable(expcol, expData, {
        startY: startY + 2,
        styles: {fillColor: LIGHT_PURPLE, textColor: BLACK},
        columnStyles: {
            expenseTransactionDate: {cellWidth: 22, fillColor: WHITE, textColor: BLACK},
            expenseTransactionID: {cellWidth: 31, fillColor: WHITE, textColor: BLACK},
            expenseAmount: {cellWidth: 21, fillColor: WHITE, textColor: BLACK},
            paycheckDescription:{fillColor: WHITE, textColor: BLACK},
            invoiceDescription:{fillColor: WHITE, textColor: BLACK}
        },
    });
    return startY + 2
}

export function addFooterToPDF(doc, footerText) {
    footerText = footerText || "www.ztrucking.com - we help carriers save big!!"
    const pageHeight = doc.internal.pageSize.height;
    set_text(doc, footerText, 115, pageHeight - 5,  13,  'times',  GREEN,  'italic')

}


function getImageFormat(path) {
    const ext = path.split('.').pop().toLowerCase();
    switch (ext) {
        case 'jpg':
        case 'jpeg':
            return 'jpeg';
        case 'png':
            return 'png';
        case 'gif':
            return 'gif';
        default:
            console.warn("Unrecognized image format:", ext, "Defaulting to png.");
            return 'png';
    }
}

export const addLogoAndCompanyInfo = function (doc, summaryData) {
    let name = summaryData.carrierName
    let address =  getPrettyAddress(summaryData.address)
    // let imagePath = `${BASE_DOCUMENT_URL}${summaryData.carrierProfilePicture}`
    let imagePath = `${summaryData.carrierProfilePicture}`

    var img = new Image();
    img.src = imagePath;

    const imageFormat = getImageFormat(imagePath);

    try {
        doc.addImage(img, imageFormat, 5, 5, 20, 20);
    } catch (error) {
        console.error("Error adding image from path:", imagePath, "Using default image.");
        img.src = DEFAULT_LOGO_PATH;
        doc.addImage(img, getImageFormat(DEFAULT_LOGO_PATH), 5, 5, 20, 20);
    }

    set_text(doc, name, 30, 18, 25, 'times', GREEN, 'normal');
    set_text(doc, address, 30, 25, 13, 'times', BLACK, 'italic');
}


export const addPayCheckInfo = function (doc, payCheckDate, payCheckID) {
    set_text(doc, `Date:  ${payCheckDate}`, 160, 8, 13, 'times', BLACK, 'normal')
    set_text(doc, `Payment #: ${payCheckID}`, 160, 14, 13, 'times', BLACK, 'normal')
}


export const payCheckPaymentInfo = function (doc,type, name, address, email, phone) {
    let base_y = 50
    let base_x = 13
    set_text(doc, type , base_x, base_y, 20, 'times', BLACK, 'bold')
    set_text(doc, name, base_x, base_y + 8, 16, 'times', GREEN, 'italic')
    set_text(doc, phone, base_x, base_y + 15, 13, 'times', BLACK, 'italic')
    set_text(doc, email, base_x, base_y + 21, 13, 'times', BLACK, 'italic')
    set_text(doc, address, base_x, base_y + 27, 13, 'times', BLACK, 'italic')

    return base_y + 27 + 13
}

// Look used example for usage.
export function keyValSummary(doc, keys, confs, startX, startY) {
    let fs = {}
    let PADDING_BOTTOM = 7
    let paddingRight = 45
    let FONT_SIZE = {keyFontSize: 14, valueFontSize: 13}
   
    let conf = {}
    let key = {x:startX, y: startY}
    let color = BLACK
    let paddingBottom = PADDING_BOTTOM
    let prefix = '$'
    

    for (const value of keys) {
       conf = confs[value]
       color = conf.color == undefined ? BLACK : conf.color
       fs = conf.fontSize == undefined ?  FONT_SIZE : conf.fontSize
       paddingBottom = conf.paddingBottom == undefined? 0: conf.paddingBottom


       if (value == 'net') {
           if (conf.value < 0) {
              color = RED
              prefix = '-$'
              conf.value = conf.value * -1
           } 

       }
       if (conf.hide && conf.value == 0) continue
       
       // Bottom page is being cut, add new page when it is close to the end.
       let y = key.y
       if (y+10 >= doc.internal.pageSize.height) {
           doc.addPage()
           y = 5
       }

       key = set_text(doc, conf.title, key.x, y  + PADDING_BOTTOM + paddingBottom,  
            fs.keyFontSize, 'times', color, 'normal')

       set_text(doc, prefix + conf.value, key.x + paddingRight, y + PADDING_BOTTOM + paddingBottom,  fs.valueFontSize, 'times',
            color, 'italic')
    }
}
