import React, { Component } from 'react'
import { Grid, withStyles } from '@material-ui/core';
import FTotalAndStateSummary from '../widgets/FTotalAndStateSummary';
import { STATUS_COLORS } from '../../util/Const';
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
    


const styles = theme => ({
    root: {
        flexGrow: 1,
        color: theme.palette.text.secondary,
        width: '100%',
        background: '#fff',
    },
});

const getAsPercent = (num1, total) => {
  if (total == 0) {
      total = 1
  }
  let val = num1/total * 100
  return Math.round(val)

}
const getStatusChartData = (data) => {
    data = data || {}
    let actives = data && data.totalActive || 0
    let inActives = data && data.totalInActives || 0
    let total = actives + inActives
    return [ {
                name: "Active",
                value: getAsPercent(actives, total)
            },
            {
                name: "Inactive",
                value: getAsPercent(inActives, total)
        }]
    }
class TopAssetDashBaord extends  React.Component  {

    render() {

        const { classes, carrierDashboardPreview, profileDashBoardPreview } = this.props
        const {profileAssetSummary} = carrierDashboardPreview || {}
        // let account = profileAssetSummary && profileAssetSummary.account || {}
        let driver = profileAssetSummary && profileAssetSummary.driver || {}
        let truck = profileAssetSummary && profileAssetSummary.truck || {}
        let trailer = profileAssetSummary && profileAssetSummary.trailer || {}
        let dispatcher = profileAssetSummary && profileAssetSummary.dispatcher || {}
        let customer = profileAssetSummary && profileAssetSummary.customer || {}
        let fuelCard = profileAssetSummary && profileAssetSummary.fuelCard || {}

        let account = profileDashBoardPreview && profileDashBoardPreview.account || {}
        
        return (

                    <Grid container xs={12} className={classes.root}>
                        <Grid item xs  >
                            <FTotalAndStateSummary title="Account"
                                showPieChart={true}
                                latest={'2022/20/23'} 
                                colors={STATUS_COLORS}
                                value={account && account.total} 
                                chartData={getStatusChartData(account)}/>
                        </Grid>
                        <Grid item xs  >
                            <FTotalAndStateSummary title="Drivers"
                                showPieChart={true}
                                latest={'2022/20/23'} 
                                colors={STATUS_COLORS}
                                value={driver && driver.total} 
                                chartData={getStatusChartData(driver)}/>
                        </Grid>
                        <Grid item xs  >
                            <FTotalAndStateSummary title="Trucks" 
                                showPieChart={true}
                                latest={'2022/20/23'} 
                                colors={STATUS_COLORS}
                                value={truck && truck.total} 
                                chartData={getStatusChartData(truck)}/>
                        </Grid>
                        <Grid item xs  >
                            <FTotalAndStateSummary title="Trailers" 
                                showPieChart={true}
                                latest={'2022/20/23'} 
                                colors={STATUS_COLORS}
                                value={trailer && trailer.total} 
                                chartData={getStatusChartData(trailer)}/>
                        </Grid>
                       
                        <Grid item xs  >
                            <FTotalAndStateSummary title="Dispatchers" 
                                showPieChart={true}
                                latest={'2022/20/23'} 
                                colors={STATUS_COLORS}
                                value={dispatcher && dispatcher.total} 
                                chartData={getStatusChartData(dispatcher)}/>
                        </Grid>
                        <Grid item xs  >
                            <FTotalAndStateSummary title="Customers" 
                                showPieChart={true}
                                latest={'2022/20/23'} 
                                colors={STATUS_COLORS}
                                value={customer && customer.total} 
                                chartData={getStatusChartData(customer)}/>
                        </Grid>
                        <Grid item xs  >
                            <FTotalAndStateSummary title="Fuel Card" 
                                showPieChart={true}
                                latest={'2022/20/23'} 
                                colors={STATUS_COLORS}
                                value={fuelCard && fuelCard.total} 
                                chartData={getStatusChartData(fuelCard)}/>
                        </Grid>
                       
                </Grid>
            // </div >
        );

    }
}


export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TopAssetDashBaord));
