import * as React from 'react';
import { useState, forwardRef } from 'react';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
import { SQUARE_APPLICATION_ID, SQUARE_LOCATION_ID } from '../../util/Const';

export const SignupSQPaymentForm = forwardRef((props, ref) => {
    const [postalCode, setPostalCode] = useState('');

    const handlePostalCodeChanged = (event) => {
        try {
            if (!!event.detail.currentState.isCompletelyValid) {
                setPostalCode(event.detail.postalCodeValue);
            }
        } catch {
            setPostalCode(event.detail.postalCodeValue);
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <PaymentForm
                ref={ref}
                applicationId={SQUARE_APPLICATION_ID}
                cardTokenizeResponseReceived={(token, error) => {
                    if (token) {
                        props.notifyNonce(token.token, postalCode);
                    } else if (error) {
                        props.notifyNonceError(error);
                    }
                }}
                locationId={SQUARE_LOCATION_ID}
            >
                <CreditCard
                    callbacks={{
                        postalCodeChanged: handlePostalCodeChanged
                    }}
                    render={(Button) => (
                        <Button
                            css={{
                                backgroundColor: "#007BFF",
                                fontSize: "16px",
                                fontFamily: "'Roboto', sans-serif",
                                color: "#fff",
                                padding: "12px 20px",
                                borderRadius: "4px",
                                textTransform: "uppercase",
                                transition: "all 0.3s ease",
                                "&:hover": {
                                    backgroundColor: "#0056b3",
                                }
                            }}
                            isLoading={props.loading} 
                        >
                            {props.buttonLabel || "Register Free Trial"}
                        </Button>
                    )}
                />
            </PaymentForm>
        </div>
    );
});

export default SignupSQPaymentForm;
