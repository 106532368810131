import * as ACTIONS from './ActionTypes'
import initialState from './InitState'

export default function (state = initialState, action) {
    switch (action.type) {
        case ACTIONS.ACTION_FETCH_LOCATIONS: {
            return {
                ...state,
                locations: action.payload
            }
        }
        case ACTIONS.ACTION_FETCH_CARRIERS: {
            return {
                ...state,
                carriers: action.payload
            }
        }
        case ACTIONS.ACTION_FETCH_CUSTOMERS: {
            return {
                ...state,
                customers: action.payload
            }
        }
        case ACTIONS.ACTION_FETCH_DRIVERS: {
            return {
                ...state,
                drivers: action.payload
            }
        }
        case ACTIONS.ACTION_FETCH_VEHICLES: {
            return {
                ...state,
                vehicles: action.payload
            }
        }
        case ACTIONS.ACTION_FETCH_TRAILERS: {
            return {
                ...state,
                trailers: action.payload
            }
        }
       
        case ACTIONS.ACTION_FETCH_DISPATCHERS: {
            return {
                ...state,
                dispatchers: action.payload
            }
        }
        case ACTIONS.ACTION_UPDATE_DOCUMENT: {
            return {
                ...state,
                currentDocument: action.payload
            }
        }
        case ACTIONS.ACTION_UPDATE_FLEET_DASHBOARD_PREVIEW: {
            return {
                ...state,
                fleetDashboardPreview: action.payload
            }
        }
      
       
        case ACTIONS.ACTION_UPDATE_STATUS_CHOICES: {
            return {
                ...state,
                statusChoice: action.payload
            }
        }
        case ACTIONS.ACTION_UPDATE_SERVER_ERROR: {
            return {
                ...state,
                ...action.payload,
            }
        }
        case ACTIONS.ACTION_UPDATE_ERROR_STATE: {
            return {
                ...state,
                initErrorFieldState: {
                    ...state.initErrorFieldState, ...action.payload
                } 
            }
        }
        case ACTIONS.RESET_SERVER_ERROR_VALIDAITON_STATE: {
            return {
                ...state,
                ...action.payload
            }
        }
        case ACTIONS.RESET_ALL_ERROR_VALIDAITON_STATE: {
            return {
                ...state,
                initErrorFieldState: {
                    ...state.initErrorFieldState, ...action.payload
                } 
            }
        }
        case ACTIONS.ACTION_UPDATE_PARENT: {
            return {
                ...state,
                ...action.payload,
            }
        }
        case ACTIONS.ACTION_AUTH_SIGNED_OUT: {
            return initialState
        }
        default: {
            return state
        }

    }

}