import { AUTH_SIGN_IN, CARRIER_CUSTOMER_TYPES, PROIFE_SUBSCRIPTION_CHOICES, ZT_ADMIN_UI_VERSION_NAME } from "../util/Const";
import COUNTRY_STATES from "./Const"

const scheduleFrequencyStrategyMileageTypeChoices =  [
      {prettyName: 'By Mileage', id: 'MILEAGE'}]
const scheduleFrequencyStrategyDayIntevalTypeChoices =  [
        {prettyName: 'Day interval', id: 'DAYS'},
        {prettyName: 'Weekly', id: 'WEEKLY'},
        {prettyName: 'Bi Weekly', id: 'BI_WEEKLY'},
        {prettyName: '1st of Every Month', id: 'FIRST_DAY_OF_THE_MONTH'},   
        {prettyName: 'End of Every Month', id: 'LAST_DAY_OF_THE_MONTH'},
        ]

const initialState = {
    [ZT_ADMIN_UI_VERSION_NAME]: undefined,
    currentTitle: 'ZTrucking',
    currentTab: 'dashboard',
    appBarCollapse: false,
    authStatus: AUTH_SIGN_IN,
    authToken:null,
    updateProfileData: true,
    profile: {},
    currentCarrier: {},
    // List of items associated with current carrier
    drivers: [],
    vehicles: [],
    trailers: [],
    dispatchers: [],

    //Updating document is singleton
    currentDocument: {
        title: '',
        ref: '',
        owner: {},
    },
    
    countryStates: COUNTRY_STATES,
    
    //TODO(Estifanos) Read this from choices paycheck calc type 
    paycheckPaymentChoice:[{prettyName: 'BY_PERCENTAGE'}, {prettyName: 'BY_MILEAGE'}, {prettyName: 'CONSTANT'}],
    dataImportFuelSource:[{prettyName: 'FLAT_RATE_V1', id: 'FLAT_RATE_V1'},
                          {prettyName: 'FLAT_RATE', id: 'FLAT_RATE'},
                          {prettyName: 'FLEET_ONE_EDGE', id: 'FLEET_ONE_EDGE'}, 
                          {prettyName: 'TCS', id: 'TCS'}, 
                          {prettyName: 'RTS', id: 'RTS'}, 
                          {prettyName: 'ZTRUCKING', id: 'ZTRUCKING'}],
    dataImportMileageSource:[{prettyName: 'MOTIVE'}, {prettyName: 'UN_KNOWN'}],
    fuelCardFuelTypes: [{prettyName: 'DIESEL', id: 'DIESEL'},
                        {prettyName: 'GASOLINE', id: 'GASOLINE'},
                        {prettyName: 'ETHANOL', id: 'ETHANOL'}, 
                        {prettyName: 'PROPANE', id: 'PROPANE'}, 
                        {prettyName: 'CNG', id: 'CNG'}, 
                        {prettyName: 'A_55', id: 'A_55'}, 
                        {prettyName: 'E_85', id: 'E_85'}, 
                        {prettyName: 'M_85', id: 'M_85'}, 
                        {prettyName: 'GASOHOL', id: 'GASOHOL'}, 
                        {prettyName: 'LNG', id: 'LNG'}, 
                        {prettyName: 'METHANOL', id: 'METHANOL'}, 
                        {prettyName: 'BIODIESEL', id: 'BIODIESEL'}, 
                        {prettyName: 'ELECTRICITY', id: 'ELECTRICITY'}, 
                        {prettyName: 'HYDROGEN', id: 'HYDROGEN'}, 
                        {prettyName: 'UN_KNOWN', id: 'UN_KNOWN'}
                        ],
    fuelCardCashAdvanceTypes: [{prettyName: 'CASH_ADVANCE', id: 'CASH_ADVANCE'},
                        {prettyName: 'SCALE', id: 'SCALE'},
                        {prettyName: 'CHECK', id: 'CHECK'}, 
                        {prettyName: 'MONEY_CODE', id: 'MONEY_CODE'}, 
                        {prettyName: 'E_CHECK', id: 'E_CHECK'}, 
                        {prettyName: 'MAINTENANCE', id: 'MAINTENANCE'}, 
                        {prettyName: 'SPARE_PART_PURCHASE', id: 'SPARE_PART_PURCHASE'}, 
                        {prettyName: 'TRUCK_STOP_PURCHASE', id: 'TRUCK_STOP_PURCHASE'}, 
                        {prettyName: 'OIL_CHANGE', id: 'OIL_CHANGE'}, 
                        {prettyName: 'MISC_PURCHASE', id: 'MISC_PURCHASE'}, 
                        {prettyName: 'UN_KNOWN', id: 'UN_KNOWN'}
                        ],

    locations: [],
    carriers: [],
    
    //Key Val
    driverTypeChoice_choices: [],
    document_driver_choices: [],
    document_dispatcher_choices: [],
    vehicle_truck_type_choices: [],
    vehicle_trailer_type_choices: [],
    document_vehicle_choices: [],
    document_carrier_choices: [],
    statusChoice:[{prettyName: 'IN_PROGRESS'}, {prettyName: 'COMPLETE'}, {prettyName: 'IN_COMPLETE'}],
    jobScheduleStatusChoice:[
                            //   {id: 'IN_PROGRESS', prettyName: 'Scheduled'}, 
                             {id: 'COMPLETE', prettyName: 'Complete'}, 
                             {id: 'IN_COMPLETE', prettyName: 'Cancel / Incomplete'}],
    documentStateChoice:[{prettyName: 'DRAFT'}, {prettyName: 'COMPLETE'}, {prettyName: 'ARCHIVED'}],
    driverStateChoice:[{prettyName: 'ACTIVE'}, {prettyName: 'INACTIVE'}],
    vehicleStateChoice: [{prettyName: 'ACTIVE'}, {prettyName: 'INACTIVE'}],
    vehicleTypeChoice: [{prettyName: 'TRUCK'}, {prettyName: 'TRAILER'}],
    invoiceStatusChoice: [{prettyName: 'CREATED'}, {prettyName: 'INVOICED'},  {prettyName: 'PAID'},  {prettyName: 'CANCELED'}],

    recurringExpense: [{prettyName: 'WEEKLY'}, {prettyName: 'BI_WEEKLY'},  {prettyName: 'MONTHLY'}],

    document_carrier_choices: [],
    document_fleet_choices: [],
    
    reportDocumentForChoice: [
                        {prettyName: 'Driver'},
                        {prettyName: 'Vehicle'}],

    document_company_factoring_choices: [],
    document_company_broker_choices: [],
    document_company_carrier_choices: [],
    document_company_shipper_choices: [],
    document_company_eld_choices: [],
    document_company_fuel_card_choices: [],
    document_company_drug_test_choices: [],
    document_company_auto_shop_choices: [],
    document_company_general_service_choices: [],
    
    document_accident_or_violation_register_accident_choices: [],
    document_former_employer_choices: [],
    document_accident_or_violation_register_violation_choices: [],
    document_employment_history_choices: [],
    document_drug_test_choices: [],
    document_fuel_card_choices: [],


    accident_or_violation_register_accident_type_choices: [],
    accident_or_violation_register_violation_type_choices: [],

    profileSubscriptionTypeChoicesLegacy: [{prettyName: 'ONE_TRUCK', prettyName: 'ONE_TRUCK'}, {prettyName: 'PREMIUM'}],
    profileSubscriptionTypeChoices: PROIFE_SUBSCRIPTION_CHOICES,

    clinetDiscoverySourceChoices: [
                {prettyName: 'Google (Search or Ads)', id: 'SOURCE_GOOGLE'}, 
                {prettyName: 'Facebook', id: 'SOURCE_FACEBOOK'}, 
                {prettyName: 'Instagram', id: 'SOURCE_INSTAGRAM'}, 
                {prettyName: 'Twitter', id: 'SOURCE_TWITTER'}, 
                {prettyName: 'LinkedIn', id: 'SOURCE_LINKEDIN'},
                {prettyName: 'YouTube', id: 'SOURCE_YOUTUBE'},
                {prettyName: 'Online Article or Blog', id: 'SOURCE_BLOG'},
                {prettyName: 'Email', id: 'SOURCE_EMAIL'},
                {prettyName: 'Word of Mouth (Friend/Family)', id: 'SOURCE_WORD_OF_MOUTH'},
                {prettyName: 'Print Media (Newspaper/Magazine)', id: 'SOURCE_PRINT_MEDIA'},
                {prettyName: 'Event/Trade Show', id: 'SOURCE_EVENT'},
                {prettyName: "I'm not sure", id: 'SOURCE_UNSURE'},
                {prettyName: 'Other', id: 'SOURCE_OTHER'}
        ],

    carrierTypeChoice: [{prettyName: 'ADMIN'}, {prettyName: 'LEASED'}],
    // customerTypeChoice: [{prettyName:'FACTORY'}, {prettyName: 'BROKER'}, {prettyName: 'SHIPPER'}, {prettyName: 'CARRIER'}],
    customerTypeChoice: CARRIER_CUSTOMER_TYPES,
    companyTypeChoice: [
                        // {prettyName: 'FACTORING'}, 
                        // {prettyName: 'BROKER'}, 
                        // {prettyName: 'CARRIER'},
                        // {prettyName: 'SHIPPER'}, 
                        {id: 'DRUG_TEST_SERVICE_PROVIDER', prettyName: 'Drug Test Provider', companyCode: 4},
                        {id: 'ELD_SERVICE_PROVIDER', prettyName: 'ELD Povider', companyCode: 4},
                        {id: 'FUEL_CARD_SERVICE_PROVIDER', prettyName: 'Fuel Card Service Provider', companyCode: 4},
                        {id: 'GENERAL_SERVICE_PROVIDER', prettyName: 'General Service Provider', companyCode: 4},
                        {id: 'AUTO_SERVICE_PROVIDER', prettyName: 'Auto Service Provider', companyCode: 4},
                        {id: 'FORMER_EMPLOYER', prettyName: 'Former Employer', companyCode: 5},
                        {id: 'FREELANCER', prettyName: 'Freelancer', companyCode: 6}
                        ],
    // companyCodeChoice: [
    //                         // {prettyName: 'FACTORING'}, 
    //                         // {prettyName: 'BROKER'}, 
    //                         // {prettyName: 'CARRIER'},
    //                         // {prettyName: 'SHIPPER'}, 
    //                         {id: 4, prettyName: 'Service Provider'},
    //                         {id: 'ELD_SERVICE_PROVIDER', prettyName: 'ELD Povider'},
    //                         {id: 'FUEL_CARD_SERVICE_PROVIDER', prettyName: 'Fuel Card Service Provider'},
    //                         {id: 'GENERAL_SERVICE_PROVIDER', prettyName: 'General Service Provider'},
    //                         {id: 'AUTO_SERVICE_PROVIDER', prettyName: 'AUTO_SERVICE_PROVIDER'},
    //                         {id: 'FORMER_EMPLOYER', prettyName: 'Former Employer'},
    //                         {id: 'FREELANCER', prettyName: 'Freelancer'}
    // ],
    companyServiceProviderContactTypeChoice: [
                    {id: 'AUTO_SERVICE_PROVIDER', prettyName: 'AUTO_SERVICE_PROVIDER'},
        ],
    companyFormerEmployersContactTypeChoice: [
                {id: 'FORMER_EMPLOYER', prettyName: 'Former Employer'},
            ],
    accidentOrViolationIncidentResolutionChoices: [ {prettyName: 'CORRECTABLE'},
                                            {prettyName: 'AT_FAULT'},
                                            {prettyName: 'NO_FAULT'}],
    accidentOrViolationIncidentResolutionStatusChoices: [{prettyName: 'CLOSED'}, 
                                            {prettyName: 'OPEN'},
                                            {prettyName: 'CORRECTED'}],
    accidentOrViolationCategory:[{prettyName: 'NO_VIOLATION'}, 
                                {prettyName: 'MINOR'}, 
                                {prettyName: 'MAJOR'},
                                {prettyName: 'CRTICAL'},
                                {prettyName: 'UN_KNOWN'},],
    vehicleInspectionResultChoices: [{prettyName: 'PASS'}, 
                                    {prettyName: 'FAIL'},
                                    {prettyName: 'NOT_APPLICABLE'}],
    
    //(TODO(Estifanos Remove)) {prettyName: 'PRE_TRIP'},  {prettyName: 'POST_TRIP'}, this should be submitted from driver
    vehicleInspectionTypeChoices:  [    {prettyName: 'PRE_TRIP'},  {prettyName: 'POST_TRIP'}, {prettyName: 'YEARLY_INSPECTION'},
                                        {prettyName: 'DOT_INSPECTION'}, {prettyName: 'SCHEDULED_INSPECTION'}],
    drugTestTypeChoices:  [{prettyName: 'PRE_EMPLOYMENT'}, 
                           {prettyName: 'RANDOM'},
                           {prettyName: 'POST_ACCIDENT'},
                           {prettyName: 'REASONABLE_SUSPICION'},
                           {prettyName: 'RETURN_TO_DUTY'},                           
                           ],
    drugTestResultChoices:[{prettyName: 'NEGATIVE'},
                                {prettyName: 'POSITIVE'}, 
                                {prettyName: 'DISPUTED'},
                                {prettyName: 'FAILED'},
                                {prettyName: 'PENDING_RESULT'}],
    employmentVerificationMethodChoices: [
                           {prettyName: 'EMAIL'},
                           {prettyName: 'MAIL'}, {prettyName: 'PHONE'}],
    employmentVerificationStatusChoices: [
                            {prettyName: 'VERIFIED'},
                            {prettyName: 'PENDING_VERIFICATION'}],   
    scheduleFrequencyStrategyDayIntevalTypeChoices: scheduleFrequencyStrategyDayIntevalTypeChoices,        
    scheduleFrequencyStrategyTypeChoices: scheduleFrequencyStrategyDayIntevalTypeChoices.concat(
                                 scheduleFrequencyStrategyMileageTypeChoices),
    scheduleByChoices : [
        {prettyName: 'Date', id: 'DATE'},
        {prettyName: 'Mileage', id: 'MILEAGE'}
    ],
    weekDaysChoices: [
                        {prettyName: 'Monday', id: 'MONDAY'},
                        {prettyName: 'Tuesday', id: 'TUESDAY'},
                        {prettyName: 'Wednesday', id: 'WEDNESDAY'},   
                        {prettyName: 'Thursday', id: 'THURSDAY'},
                        {prettyName: 'Friday', id: 'FRIDAY'},
                        {prettyName: 'Saturday', id: 'SATURDAY'},
                        {prettyName: 'Sunday', id: 'SUNDAY'}],
    scheduleTypes: [
        {prettyName: 'RECURRING_EXPENSE', id: 'RECURRING_EXPENSE'},
        {prettyName: 'PREVENTIVE_MAINTENANCE', id: 'PREVENTIVE_MAINTENANCE'},
        {prettyName: 'PERIODIC_INSPECTION', id: 'PERIODIC_INSPECTION'},
    ],
    personnelProfileTypeChoices: [
        {prettyName: 'Personnel', id: 'PERSONNEL'},
        // {prettyName: 'Driver', id: 'DRIVER'},
    ],
    personnelProfileSubscriptionTypeChoices: [
        {prettyName: 'Personnel', id: 'PERSONNEL'},
    ],
    adminProfileTypeChoices: [
        {prettyName: 'Carrier', id: 'CARRIER'},
        {prettyName: 'Admin', id: 'ADMIN'},
    ],
    eventTypes: [
        {prettyName: 'Driver Safety Violation', id: 'DRIVER_SAFETY_VIOLATION'},
        {prettyName: 'Carrier Tax Compliance', id: 'CARRIER_TAX_COMPLIANCE'},
        {prettyName: 'Todo', id: 'TODO'},
    ],
    driverEventTypes: [
        {prettyName: 'Hard Event', id: 'event_driver_hard_event_choices'},
        {prettyName: 'ELD Violation', id: 'event_driver_eld_violation_choices'},
        {prettyName: 'Inspection Violation', id: 'event_driver_inspection_violation_choices'},
    ],
    eventCorrectiveActions: [
        {prettyName: 'Pending Investigation', id: 0}, // NO_ACTION
        {prettyName: 'No Action', id: 5}, // NO_ACTION
        {prettyName: 'Verbal Warning', id: 10}, // VERBAL_WARNING
        {prettyName: 'Written Warning', id: 20}, //WRITTEN_WARNING
        {prettyName: 'Money Penalty', id: 30}, //MONEY_PENALITY
        {prettyName: 'Suspension', id: 40}, //SUSPENSION
        {prettyName: 'Money Penalty & Suspension', id: 50}, //MONEY_PENALITY_AND_SUSPENSION
        {prettyName: 'Termination', id: 60}, //TERMINATION
        {prettyName: 'Money Penalty & Termination', id: 70}, //MONEY_PENALITY_AND_TERMINATION
    ],
    eventAlarmLevelChoices: [
        // {prettyName: 'Ok', id: 0}, // OK
        {prettyName: 'Info', id: 10}, // INFO
        {prettyName: 'Warning', id: 20}, //WARNING
        {prettyName: 'Severe', id: 30}, //SEVERE
        // {prettyName: 'Critical', id: 40}, //CRITICAL
    ],

    //Expenses
    expense_vehicle_choices: [],
    expense_personnel_choices: [],
    brokerExpenseChoice: [],
    expense_fleet_choices: [],
    
    // Company expense implies profile expense not associated to any carrier but to company as a whole, remove
    expenseForModuleChoices: [ {prettyName: 'fleet'}, {prettyName: 'company'}],
    
    
    //Dashboard preview data
    dashboardFilterDate: {
        start: '',
        endDate: ''
    },

    documentDashboardPreview: {
        nonComplianceDocumentCount: 25,
        totalDocumentsCount: 100,
    },
    fleetDashboardPreview: {
        fleetsFeeTotal: 0,
    },
    expenseDashboardPreview: {
        expenseAmountotal: 0,
        expenseCounts: 0,
        expenseReimbursable: 0,
        expeseDeducatables: 0
    },
    driverDashboardPreview: {
        driverCounts: 0,
    },
    vehicleDashboardPreview: {
        trucksCount: 0,
        trailersCount: 0,
    },
    payCheckDashboardPreview: {
        netCarrierFee: 0.0,
    },
    carrierDashboardPreview: {
        carriersCount: 0,
    },
    profileDashBoardPreview: {},
    nonDOTCompliance: {
        drivers: 20,
        vehicles: 30
    },

    dashBoardPersonnelPaymentData: [
        {prettyName: 'Estifanos G', paymentAmount: 500},
        {prettyName: 'Estifanos H', paymentAmount: 600},
    ],
    dashBoardExpensePerCatagoryChartData: [
        {name: 'Fetching ....', value: 100}
    ],
    //Determine list of items documents per catagory e.g
    dashBoardAttentionRequireChartData: [
        {
            vehicle: 2, driver: 2, carrier: 2, fleet: 2,
        }
    ],
    dashBoardMaintenanceFrequency: [ // Fleet/Dispatch .. per expense
        {
            name: 'Date - 1', net: 100, pv: 2400, amt: 2400,
        }
    ],
    dashBoardCarrierNetGrowth: [ // Growth .. Number of trucks added, more dispatcher .. 
        {
            name: 'Date - 1', net: 100, pv: 2400, amt: 2400,
        },
        {
            name: 'Date - 2', net: 200, pv: 1398, amt: 2210,
        },
        {
            name: 'Date - 3', net: 300, pv: 9800, amt: 2290,
        }, {
            name: 'Date - 4', net: 400, pv: 2400, amt: 2400,
        },
        {
            name: 'Date - 5', net: 500, pv: 1398, amt: 2210,
        },
        {
            name: 'Date - 6', net: 200, pv: 9800, amt: 2290,
        }, {
            name: 'Date - 7', net: 500, pv: 2400, amt: 2400,
        },
        {
            name: 'Date - 8', net: 500, pv: 1398, amt: 2210,
        },
        {
            name: 'Date - 9', net: 5000, pv: 9800, amt: 2290,
        }
    ],
    dashBoardCarrierNetRevenu: [ // Fleet/Dispatch .. per expense
        {
            name: 'Date - 1', net: 2000, pv: 2400, amt: 2400,
        },
        {
            name: 'Date - 2', net: 1500, pv: 1398, amt: 2210,
        },
        {
            name: 'Date - 3', net: 1600, pv: 9800, amt: 2290,
        }, {
            name: 'Date - 4', net: 1700, pv: 2400, amt: 2400,
        },
        {
            name: 'Date - 5', net: 7000, pv: 1398, amt: 2210,
        },
        {
            name: 'Date - 6', net: 2500, pv: 9800, amt: 2290,
        }, {
            name: 'Date - 7', net: 3000, pv: 2400, amt: 2400,
        },
        {
            name: 'Date - 8', net: 3000, pv: 1398, amt: 2210,
        },
        {
            name: 'Date - 9', net: 5000, pv: 9800, amt: 2290,
        }
    ],
    dashBoardFleetPerDispatcherChartData: [
        {
            name: 'Disptacher ...', id: 1, dispatchCount: 0, loadFee: 0,
        }
    ],
    incomeExpenseDashboardPreview: [
        {
            name: '01/2019', expense: 0, fleetFee: 0,
        },
        {
            name: '02/2019', expense: 0, fleetFee: 0,
        },
        {
            name: '03/2019', expense: 0, fleetFee: 0,
        },
        {
            name: '04/2019', expense: 0, fleetFee: 0,
        },
        {
            name: '05/2019', expense: 0, fleetFee: 0,
        },
        {
            name: '06/2019', expense: 0, fleetFee: 0,
        },
        {
            name: '07/2019', expense: 0, fleetFee: 0,
        },
        {
            name: '08/2019', expense: 0, fleetFee: 0,
        },
    ],

    //Error Field Store
    crudServerError: '',
    crudServerSuccess: '',
}
export default initialState