import React, { Component } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table'
import { Button } from '@material-ui/core';
import FListComponent from '../../util/FListComponent';
import { API_DASHBOARD_CARRIER_SUMMARY } from '../../util/Const';


class CarriersPreview extends FListComponent {
  constructor(props) {
    super(props, API_DASHBOARD_CARRIER_SUMMARY)
  }

  getData  = () => {
     let listData = this.state.listData
     listData = listData && listData.carrierDashboardPreview && listData.carrierDashboardPreview.carriersSummary || []
     return listData
     
  }
  componentDidMount() {
    this.fetchListData()
  }

  render() {
    return (
      <React.Fragment>
       {/* <div style={{paddingLeft: '30px', maxHeight: 100}}> */}

        <MaterialTable 
          columns={[
            {
              title: 'Id', field: 'id', hidden:true, field: 'id', render: rowData => {
                return (
                  <React.Fragment>
                    <Button color="primary" onClick={() =>     this.props.onEdit(rowData)}>
                      {rowData.id}
                    </Button>
                  </React.Fragment>
                )
              }
            },
            { title: 'Carrier', field: 'carrierPrettyName' },
            { title: 'Load Fees', field: 'fleetAmount', render: rowData => {
              return (
                <React.Fragment>
                   ${rowData.fleetAmount}
                 </React.Fragment>
              )
             }
            },
            // { title: 'Load Fees', field: 'fleetAmount' },
            // { title: 'Expenses', field: 'expenseAmout' },
            { title: 'Total Expenses', field: 'expenseAmout', render: rowData => {
              return (
                <React.Fragment>
                   ${rowData.expenseAmout}
                 </React.Fragment>
              )
             }
            },
            { title: 'Total Drivers',  render: rowData => {
              return (
                <React.Fragment>
                   {rowData.driversCount}
                 </React.Fragment>
              )
             }
            },
            { title: 'Active Drivers',  render: rowData => {
              return (
                <React.Fragment>
                  {rowData.activeDriversCount} 
                 </React.Fragment>
              )
             }
            },
            { title: 'Total Trucks',  render: rowData => {
              return (
                <React.Fragment>
                   {rowData.trucksCount}
                 </React.Fragment>
              )
             }
            },
            { title: 'Active Trucks',  render: rowData => {
              return (
                <React.Fragment>
                   {rowData.activeTrucksCount} 
                 </React.Fragment>
              )
             }
            },
            { title: 'Total Trailers',  render: rowData => {
              return (
                <React.Fragment>
                   {rowData.trailersCount} 
                 </React.Fragment>
              )
             }
            },
            { title: 'Active Trailers',  render: rowData => {
              return (
                <React.Fragment>
                  {rowData.activeTrailersCount} 
                 </React.Fragment>
              )
             }
            },
            // { title: 'Drivers', field: 'driversCount' },
            // { title: 'Trucks', field: 'trucksCount' },
            // { title: 'Trailers', field: 'trailersCount' },
            { title: 'Dispatchers', field: 'dispatchersCount' },
          ]}
          options = {{
              search: false,
              showTitle: true,
              toolbar: true,
              paging: true,
              maxBodyHeight: this.props.height || 500,
              pageSize:  6,
              pageSizeOptions: [6, 10, 25]
            //   headerStyle: {color: 'red', marginTop: 0, paddingTop: 0}
              
          }}
          // data={this.props.data}
          data={this.getData()}
          title="Profile Preview"
          // components={{
          //   Toolbar: props => (
          //       <div style={{ backgroundColor: '#e8eaf5' }}>
          //           <MTableToolbar {...props} />
          //       </div>
          //   )
          // }}
        />

      </React.Fragment>
    );
  }
}

export default CarriersPreview;
