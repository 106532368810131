import React from 'react'
import { connect } from 'react-redux'

import { Grid } from "@material-ui/core";
import { mapDispatchToProps, mapStateToProps } from '../../fredux/ActionMaker';
import FTitle from '../widgets/FTitle';
import FLabel from '../widgets/FLabel';
import { isPaymentPastDue } from '../../util';
import FGroupBox from '../widgets/FGroupBox';
import { FRouterLink } from '../widgets/FRouterLink';
import { ROUTE_PROFILE_URL } from '../../util/Const';

class PaymentSummary extends React.Component {

  

  render() {
     
    let profileSubscriptionType = this.props.profile.ownerSubscription && this.props.profile.ownerSubscription.profileSubscriptionType
    let ownerSubscriptionStatus = this.props.profile.ownerSubscriptionStatus

    let nextPaymentDate = this.props.profile.ownerSubscription && this.props.profile.ownerSubscription.nextPaymentDate
    let paymentAmount = this.props.profile.ownerSubscription && this.props.profile.ownerSubscription.paymentAmount || '0.00'
    let paymentColor = isPaymentPastDue(nextPaymentDate) ?  'red': 'blue'
    let profileFirstName = this.props.profile.profileFirstName || ""
    let profileLastName = this.props.profile.profileLastName || ""
    let fullName = profileFirstName + ' ' + profileLastName
    let pofileLocation = this.props.profile.profileLocationAsText || ""
    let pofileName = this.props.profile.profileName
    let profileId = this.props.profile.id
    

    return(            
        <FGroupBox elevation={2} groupTitle="Subscription Summary">
          <div style={{padding:'21px'}}>
            
            <Grid container direction='column' xs={12} style={{paddingBottom: '20px'}}>
                <Grid item xs={12} >
                    <FLabel label={'   Subscription Type: ............'}/>
                    <FTitle label={profileSubscriptionType} color={'black'}/>      
                </Grid>

                <Grid item xs={12} >
                    <FLabel label={'   Subscription Status: ...........'}/>
                    <FTitle label={ownerSubscriptionStatus} color={'black'}/>      
                </Grid>
               
                <Grid item xs={12} >
                    <FLabel label={'Company: .........................'}/>
                    <FLabel label={pofileName} color={'black'}/>      
                </Grid>

                
                

                <Grid item xs={12} >
                    <FLabel label={'Name : ..............................'}/>
                    <FLabel label={fullName} color={'black'}/>      
                </Grid>

                <Grid item xs={12} >
                    <FLabel label={'Billing Address : .................'}/>
                    <FLabel label={pofileLocation } color={'black'}/>      
                </Grid>
                <Grid item xs={12} >
                    <FLabel label={'Due Date: .........................'}/>
                    <FLabel label={nextPaymentDate} color='red'/>
                    <FRouterLink editIcon={true} label={'update Billing Address'} to={ROUTE_PROFILE_URL+ profileId} />   
                </Grid>
                <Grid item xs={12}>
                    <FLabel label={'Balance On Due Date:.......'}/>
                    <FTitle label={`$${paymentAmount}`} color={paymentColor}/> 
                </Grid>
                
            </Grid>
            </div>
            </FGroupBox>
    )
  }
}

export default connect(
  mapStateToProps, mapDispatchToProps)(PaymentSummary)

// export default PaymentSummary