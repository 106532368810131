
export const VALIDATE_REQUIRED = 'VALIDATE_REQUIRED'
export const VALIDATE_OBJECT_REQUIRED = 'VALIDATE_OBJECT_REQUIRED'
export const VALIDATE_CARRIER_REQUIRED = 'VALIDATE_CARRIER_REQUIRED'
export const VALIDATE_UPTO_CURRENT_YEAR = 'VALIDATE_UPTO_CURRENT_YEAR'
export const VALIDATE_FUEL_CARD_ID = 'VALIDATE_FUEL_CARD_ID'
export const VALIDATE_EMAIL = 'VALIDATE_EMAIL'
export const VALIDATE_PASSWORD = 'VALIDATE_PASSWORD'
export const VALIDATE_PHONE_NUMBER = 'VALIDATE_PHONE_NUMBER'
export const VALIDATE_DATE = 'VALIDATE_DATE'
export const MAX_30_DAYS = 'MAX_30_DAYS'
export const VALIDATE_YEAR_FORMAT = 'VALIDATE_YEAR_FORMAT'
export const VALIDATE_FEE = 'VALIDATE_FEE'
export const VALIDATE_VIN_REQUIRED = 'VALIDATE_VIN_REQUIRED'
export const VALIDATE_ZERO_OR_POSITIVE = 'VALIDATE_ZERO_OR_POSITIVE'
export const VALIDATE_POSITIVE = 'VALIDATE_POSITIVE'
export const VALIDATE_DAYS_FREQUENCY = 'VALIDATE_DAYS_FREQUENCY'
export const VALIDATE_ADDRESS = 'VALIDATE_ADDRESS'
export const VALIDATE_ADDRESS_CITY_STATE = 'VALIDATE_ADDRESS_CITY_STATE'
export const VALIDATE_STR_ADDRESS_LOCATION = 'VALIDATE_STR_ADDRESS_LOCATION'

export const VALIDATE_FILE_SIZE = 'VALIDATE_FILE_SIZE'
export const VALIDATE_0_100_PERCENTAGE = 'VALIDATE_0_100_PERCENTAGE'
export const VALIDATE_FILE = 'VALIDATE_FILE'
export const VALIDATE_CHOICE_WITH_PRETTY_NAME = 'VALIDATE_CHOICE_WITH_PRETTY_NAME'
export const VALIDATE_CARRIER_CUSTOMER_TYPE= 'VALIDATE_CARRIER_CUSTOMER_TYPE'












