import React from 'react';
import FRouterLink, { FRouterAddLink } from '../widgets/FRouterLink';
import { API_USER_URL, ROUTE_USERS_URL, TAB_PROFILE } from '../../util/Const';
import FListComponent from '../../util/FListComponent';
import { connect } from "react-redux"
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import FMaterialTable from '../widgets/FMaterialTable';
import FTableAction, {ACTION_TABLE_ACTIVATE, ACTION_TABLE_DEACTIVATE, ACTION_TABLE_DELETE_ROW } from '../widgets/FTableAction';
import { getStateIcon } from '../../util/icon_util';
import FMessage from '../widgets/FMessage';
import FLabel from '../widgets/FLabel';


class Users extends FListComponent {

  constructor(props) {
    super(props, API_USER_URL, null)
    this.currentTitle = 'Users'
    this.currentTab = TAB_PROFILE
  }

  getActions = (rowData) => {
    let actions = []
    rowData.state == 'ACTIVE'? actions.push(ACTION_TABLE_DEACTIVATE): 
          actions.push(ACTION_TABLE_ACTIVATE)
    // actions.push(ACTION_TABLE_DELETE_ROW)
    return actions
  }

  render() {
    return (
      <div>
        <FMaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: 'Profile id', 
              render: rowData => {
                return (
                  <React.Fragment>
                    <FRouterLink editIcon={true} label={rowData.profileId} to={ROUTE_USERS_URL + rowData.id} />
                  </React.Fragment>
                )
              }
            },
           
            {
              title: 'Name', 
              render: rowData => {
                return (
                  <React.Fragment>
                      {`${rowData.profileFirstName} ${rowData.profileLastName}`}
                  </React.Fragment>
                )
              }
            },
            { title: 'Username', field: 'profile_summary.username' },
            { title: 'Account Type', field: 'profile_summary.profileType' },

            {
              title: 'State', 
              render: rowData => {
                return (
                  <React.Fragment>
                      {getStateIcon(rowData)}
                  </React.Fragment>
                )
              }
            },
            {
              title: 'Actions', 
              render: rowData => {
                return (
                  <React.Fragment>
                    <FTableAction 
                        actions={this.getActions(rowData)}
                        onTableAction = {this.onTableAction}
                        rowData={rowData}
                         />
                  </React.Fragment>
                )
              }
            },
            
          ]}
          actions={
            [
              {
                icon: () => (
                  <FRouterAddLink to={ROUTE_USERS_URL + 'add'} color='secondary' />
                ),
                isFreeAction: true
              }
            ]}
          options = {{
              pageSize: this.state.pageSize,
              pageSizeOptions: this.state.pageSizeOptions,
              actionsColumnIndex: -1
           }}
          data={this.fetchData}
          title="Accounts"
        />

      </div>
    );
  }
}

export default connect(
  mapStateToProps, mapDispatchToProps)(Users)
