import React from 'react'
import Trips from './Trips'

import { Grid } from "@material-ui/core"
import MileageImportSummary from './Summary';
import MileageImportFilterView from './MileageImportFilterView';
import { API_MILEAGE_IMPORT_UPLOAD_URL, API_MILEAGE_IMPORT_SYNC, TAB_FLEET, SAVE_MILEAGE_IMPORT_MSG } from '../../util/Const';
import { getErrroMessage,  getSuccessMessage, showSuccessNotification, showErroNotification  } from '../widgets/FMessage';
import { GET_RESULT, UPDATE} from '../../util/FAPI';
import FButton from '../widgets/FButton';
import { connect } from 'react-redux'
import { mapDispatchToProps, mapStateToProps } from '../../fredux/ActionMaker';
import { isValidFee } from '../../util/Validator';
import { FRouteBackLink } from '../widgets/FRouterLink';
import FDialog from '../widgets/FDialog';
import FMaterialTable from '../widgets/FMaterialTable';
import {Labels} from "../../localizations/Resource"


const initState = {
  dataImportFilter: {},
  dataImportMetaData: {},
  dataSrc: 'UN_KNOWN',
  showErrors: false
}

class MileageImportDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = initState

  }

  getFormData = () => {
    let formData = new FormData();
    formData.append('dataImportStartDate', this.state.dataImportFilter.dataImportStartDate)
    formData.append('dataImportEndDate', this.state.dataImportFilter.dataImportEndDate)
    formData.append('dataImportSource', this.state.dataImportFilter.dataImportSource)
    formData.append('dataImportRawFile', this.state.dataImportFilter.dataImportRawFile)
    formData.append('dataImportType', this.state.dataImportFilter.dataImportType)
    formData.append('carrier', this.state.dataImportFilter.carrier.id)

    return formData
  }

  saveMileageImport = () => {
    let data = new FormData();
    data.append('dataImport', this.state.id)

    this.setState({ loading: true })
    UPDATE(API_MILEAGE_IMPORT_SYNC, 'post', data).
      then(response => {
        this.setState({ loading: false })
        showSuccessNotification(response, SAVE_MILEAGE_IMPORT_MSG)
        
      }).catch(error => {
        showErroNotification(error, SAVE_MILEAGE_IMPORT_MSG)
        this.setState({ loading: false })
      });
  }

  fetchFilter = (dataImportFilter) => {
    this.setState(s => {
      return { ...initState, dataImportFilter: dataImportFilter }
     }, () => {
      UPDATE(API_MILEAGE_IMPORT_UPLOAD_URL, 'post', this.getFormData()).then(res => {
        let data = GET_RESULT(res)
        this.setState(prevState => {
          let newState = { ...prevState, ...data }
          return newState
        }, () => {

        }, this)
      }).catch(error => {
          showErroNotification(error, SAVE_MILEAGE_IMPORT_MSG)
      })
    })
  }

  isValidMileageImport = () =>{
    if (!!!this.state.dataImportMetaData) {
      return false
    }
    const hasTrips =  isValidFee(this.state.dataImportMetaData.totalDistance)

    return (hasTrips)
    
  }

  getTrips = () =>{
    const data =  this.state.dataImportParsedData || []
    return data
  }


  getTripsSummary = () =>{
    return this.state.dataImportMetaData || {}
  }


  getCurrentTitle = () => {
    let title = Labels.IMPORT_MILEAGE_PAGE_TITLE
  
    this.props.updateCurrentTab(TAB_FLEET)
    this.props.updateCurrentTitle && this.props.updateCurrentTitle(title)
  }
  
  
  keepTruckingFormatdisplay = () => {
    return (
      <Grid container  xs={12} > 
          <Grid item xs={12}>
            <p dangerouslySetInnerHTML={{__html: Labels.IMPORT_MILEAGE_HELP_KT}}/>
          </Grid>
      </Grid>
    )
  }

  unkownFormatdisplay = () => {
    return (
      <Grid container  xs={12} > 
          <Grid item xs={12}>
            <p dangerouslySetInnerHTML={{__html: Labels.IMPORT_MILEAGE_HELP_KT}}/>
          </Grid>
      </Grid>
    )
  }


  onDataSrcUpdate = (src) => {
    this.setState({dataSrc: src})
  }
  
  onShowErrors =  (show) => {
    this.setState({showErros: show})
  }
  

  getErrorSummary = () => {
    return (<FDialog
            // maxWidth='xl'
            title={Labels.IMPORT_MILEAGE_ERROR_TITLE}
            // owner={this.props.owner}
            openDialog={this.state.showErros}
            width={500}
            titleColor={'secondary'}
            notifyDialogClose = {() => this.setState({showErros: false})}>
          
                <FMaterialTable
                        columns = {[ 
                            {title: 'Error', field: 'error',
                             cellStyle: {
                              // backgroundColor: '#039be5',
                              color: '#f50057'
                            },},
                            {title: Labels.DATE, field: 'date'},
                            {title: Labels.TRUCK, field: 'truck'},
                            {title: Labels.JURISDICTION, field: 'jurisdiction'},
                            {title: Labels.DISTANCE_MILE, field: 'distance'}]}
                        data={this.state.dataImportMetaData && this.state.dataImportMetaData.errors || []}
                        options = {{
                            search: true,
                            showTitle: false,
                            toolbar: true,
                            paging: true,
                            sorting: false,
                            // maxBodyHeight: 400,
                            pageSize:  5,
                            pageSizeOptions: [5],
                            headerStyle: {
                              backgroundColor: '#01579b',
                              color: '#FFF'
                            }

                }}
          />
        </FDialog>)
  }
  render() {
    this.getCurrentTitle()
    return (
      <React.Fragment>

        {this.getErrorSummary()}
        <Grid container xs={12} spacing={2}>
          {(this.state.dataSrc == 'MOTIVE' || this.state.dataSrc == 'KEEPTRUCKIN' || this.state.dataSrc == 'UN_KNOWN' || this.state.dataSrc == '') && this.keepTruckingFormatdisplay()}
          <MileageImportFilterView notifyParent={this.fetchFilter} notifyDataSrcUpdate={this.onDataSrcUpdate} />

          <Grid item xs>
            <Trips data={this.getTrips()} />
          </Grid>
          
          <Grid item xs={4}>
            <MileageImportSummary dataImportMetaData={this.getTripsSummary()} onShowErros={this.onShowErrors} />
          </Grid>
        </Grid>

        <Grid container xs={12} justify="center">
         
          {/* <Grid item xs={3} > */}
            <FButton color='primary'
              disabled={!this.isValidMileageImport()}
              label={Labels.IMPORT_MILEAGE_SAVE_TRIPS}
              loading={this.state.loading}
              onClick={this.saveMileageImport}
            />
        {/* </Grid> */}

        <Grid item style={{ paddingTop: '9px' }}>
              <FRouteBackLink variant='outlined' label={Labels.BACK_BUTTON} color='secondary' className={'backButton'} />
          </Grid>

        
        </Grid>
        </React.Fragment>
    )
  }
}


export default connect(
  mapStateToProps, mapDispatchToProps)(MileageImportDetail)
