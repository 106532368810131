import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { DialogContent } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';


const styles = theme => ({

  toolBar: {
    background: '#f5f5f5',
    marginBottom: '20px'
  },

  flex: {
    flex: 1,
  },
  closeButton: {
    maxWidth: '50px',
    float: 'right',
    align: 'right'
  },
  dialogContent: {
     marginTop: theme.spacing(2),
     width: 600
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class FDialog extends React.Component {

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        maxWidth={this.props.maWidth || 'xl'}
        // fullScreen={true}
        fullWidth={this.props.fullWidth}
        scroll='body'

        open={this.props.openDialog}
        // onClose={this.props.notifyDialogClose} // This prevents from closing on outside click event
        onClose={() => {}}
        
        TransitionComponent={Transition}
      >


        {/* <IconButton color="inherit" className={classes.closeButton} onClick={this.props.notifyDialogClose} aria-label="Close">
          <CloseIcon />
        </IconButton> */}
        <Toolbar className={classes.toolBar}>
          <Typography variant="h6" color={this.props.titleColor || "inherit"} className={classes.flex}>
            {this.props.title}
          </Typography>
          <IconButton color="inherit" onClick={this.props.notifyDialogClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <DialogContent className={classes.dialogContent}>
          {this.props.loading && <CircularProgress className={classes.progress} />}

          {this.props.children}
        </DialogContent>
      </Dialog>
    );
  }
}

FDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool
};

export default withStyles(styles)(FDialog);
