import React, { Component } from 'react';
import { Grid, Paper, Button, TextField, Typography, IconButton } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

class CancelProfileSubscription extends Component {
    state = {
        confirmationText: '',
        feedback: '',
        isConfirmed: false,
        showCancelUI: false
    };

    handleConfirmationChange = (e) => {
        this.setState({ confirmationText: e.target.value }, this.checkValidity);
    };

    handleFeedbackChange = (e) => {
        this.setState({ feedback: e.target.value }, this.checkValidity);
    };

    checkValidity = () => {
        if (this.state.confirmationText === this.props.profile.profile_summary.username && this.state.feedback) {
            this.setState({ isConfirmed: true });
        } else {
            this.setState({ isConfirmed: false });
        }
    };

    toggleCancelUI = () => {
        this.setState((prevState) => ({ showCancelUI: !prevState.showCancelUI }));
    }

    generateCancelEmail = () => {
        const subject = encodeURIComponent("Cancellation Request for Profile");
        const body = encodeURIComponent(
            `Dear ZTrucking team,\n\n` +
            `I wish to cancel my subscription. Below are the details:\n\n` +
            `Profile ID: ${this.props.profile.profileId}\n` +
            `Username: ${this.props.profile.profile_summary.username}\n\n` +
            `Reason for my decision: ${this.state.feedback}\n\n` +
            `Thank you for understanding.`
        );
        return `mailto:service@ztrucking.com?subject=${subject}&body=${body}`;
    };

    render() {
        const { isAdminOrCarrier } = this.props;

        return (
            isAdminOrCarrier ? (
                <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button onClick={this.toggleCancelUI}>
                                Account Options
                            </Button>
                        </Grid>
                        {this.state.showCancelUI && (
                            <>
                                <Grid item xs={12}>
                                    <Typography variant="h6" style={{ color: '#d32f2f' }}>
                                        <WarningIcon style={{ marginRight: '10px', verticalAlign: 'middle' }} />
                                        Cancel My Account
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        To confirm your intention to cancel, please enter your profile username and share your reasons.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Confirm by typing your profile username"
                                        fullWidth
                                        value={this.state.confirmationText}
                                        onChange={this.handleConfirmationChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="May we know your reasons?"
                                        fullWidth
                                        multiline
                                        rows={4}
                                        value={this.state.feedback}
                                        onChange={this.handleFeedbackChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {this.state.isConfirmed ? (
                                        <a href={this.generateCancelEmail()} target="_blank" rel="noopener noreferrer">
                                            <Button 
                                                style={{ 
                                                    backgroundColor: '#d32f2f',
                                                    color: 'white', 
                                                    fontWeight: 'bold'
                                                }}
                                                startIcon={<WarningIcon />}
                                            >
                                                Confirm Cancellation
                                            </Button>
                                        </a>
                                    ) : (
                                        <Button 
                                            disabled
                                            style={{ 
                                                backgroundColor: '#aaa',
                                                color: 'white', 
                                                fontWeight: 'bold'
                                            }}
                                            startIcon={<WarningIcon />}
                                        >
                                            Confirm Cancellation
                                        </Button>
                                    )}
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Paper>
            ) : null
        );
    }
}

export default CancelProfileSubscription;
