import React from 'react';
import FRouterLink, { FRouterAddLink } from '../widgets/FRouterLink';
import { API_FLEET_URL, ROUTE_DRIVER_URL, ROUTE_VEHICLE_URL, ROUTE_DISPATCHER_URL, ROUTE_FLEET_URL, ROUTE_CARRIERS_URL, ROUTE_CUSTOMER_URL, TAB_FLEET, FLEET_FLEETS, CHOICE_FLEET } from '../../util/Const';
import FListComponent from '../../util/FListComponent';
import { Link } from '@material-ui/core';
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import { connect } from "react-redux"
import { roundDecimal } from '../../util';
import FTableAction, { ACTION_TABLE_DELETE_ROW } from '../widgets/FTableAction';
import FMaterialTable from '../widgets/FMaterialTable';
import BlockIcon from '@material-ui/icons/Block';
import { getStatusIcon } from '../../util/icon_util';

class Fleets extends FListComponent {
  constructor(props) {
    super(props, API_FLEET_URL, CHOICE_FLEET)
    this.currentTitle = 'Fleets'
    this.currentTab = TAB_FLEET
    this.routeUrl = ROUTE_FLEET_URL
    this.policyID = FLEET_FLEETS
    this.includeStateAction = false
  }
  


  render() {

    return (
      <div>

        <FMaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: 'Reference #', field: 'fleetLoadNumber',
              render: rowData => {
                return (
                  <React.Fragment>
                    <FRouterLink editIcon={true} label={rowData.fleetLoadNumber} to={ROUTE_FLEET_URL + rowData.id} />
                  </React.Fragment>
                )
              }
            },
            {
              title: 'Carrier', 
              render: this.getCarrierLink
            },
          
            {
              title: 'Dispatcher', 
              render: this.getDispatcherLink
            },
            {
              title: 'Driver', 
              render: this.getDriverLink
            },
           
            {
              title: 'Customer', 
              render: this.getCustomerLink
            },
            
            { title: 'Pickup Date', field: 'fleetPickUpDate',
             render: rowData => this.getPrettyDate(rowData.fleetPickUpDate) },

            {
              title: 'Trip Location', field: 'fleetPickupLocation',
              width: '20%',
              render: rowData => {
                return (
                  <React.Fragment>
                     <b>{rowData.fleetPickupLocation}</b> to <b>{rowData.fleetDeliveryLocation}</b>
                  </React.Fragment>
                )
              }
            },

            { title: 'Fee', field: 'fleetLoadFee', render: rowData => roundDecimal(rowData.fleetLoadFee) },
            { title: 'Lumper Fee', field: 'fleetLumperFee', render: rowData => roundDecimal(rowData.fleetLumperFee) },
            {
              title: 'Confirmation / BOL',
              render: rowData => {
                return (
                  <React.Fragment>
                    {rowData.fleetConfirmation && <Link href={rowData.fleetConfirmation} target="_blank">Confirmation</Link>}
                    &nbsp; &nbsp; | &nbsp; &nbsp;
                    {rowData.fleetBOL && <Link href={rowData.fleetBOL} target="_blank">BOL</Link>}
                  </React.Fragment>
                )
              }
            },
            {
              title: 'Status', 
              render: rowData => {
                return (
                  <div style={{ 
                    display: 'flex', 
                    flexDirection: 'row', 
                    alignItems: 'left',
                    height: '50px', // Set height for larger row
                    whiteSpace: 'nowrap', // Prevent text wrapping
                    overflow: 'hidden',   // Hide overflow text
                    textOverflow: 'ellipsis', // Add ellipsis for overflow text
                     }}>
                  <React.Fragment>
                      
                      {getStatusIcon(rowData)}
                  </React.Fragment>
                  </div>
                )
              }
            },
          
            {
              title: 'Actions', 
              render: rowData => {
                let actions  = this.getActions(rowData, FLEET_FLEETS)

                return (
                  <React.Fragment>
                    {!!actions.length && 
                      <FTableAction 
                        actions={actions}
                        onTableAction = {this.onTableAction}
                        rowData={rowData}
                         />
                    }
                    {!!!actions.length && <BlockIcon color="disabled" />}
                  </React.Fragment>
                )
              }
            },
          ]}
          actions={
            [
              this.getAddNewModelActions()
            ]} 
          options = {{
              pageSize: this.state.pageSize,
              pageSizeOptions: this.state.pageSizeOptions,
              actionsColumnIndex: -1
           }}
          data={this.fetchData}
          title="Fleets"
          listErrCode={this.state.listErrCode}
        />

      </div>
    );
  }
}


export default connect(
  mapStateToProps, mapDispatchToProps)(Fleets)
