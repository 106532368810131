import React from 'react';
import FRouterLink, { FRouterAddLink } from '../widgets/FRouterLink';
import { ROUTE_VEHICLE_URL, ROUTE_MAINTENANCE_SCHEDULE_URL, API_VEHICLE_MAINTENANCE_SCHEDULE_URL, TAB_SAFETY_AND_COMPLIANCE, SAFETY_AND_COMPLINACE_MAINTENANCE_SCHEDULES, CHOICE_MAINTENANCE_SCHEDULE } from '../../util/Const';
import FListComponent from '../../util/FListComponent';
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import { connect } from "react-redux"
import {  getPrettyDate } from '../../util';
import FMaterialTable from '../widgets/FMaterialTable';
import FTableAction, { ACTION_TABLE_DELETE_ROW } from '../widgets/FTableAction';
import { isProfileDispatcher } from '../../util/ACL';
import {  getStatusIcon } from '../../util/icon_util';
import BlockIcon from '@material-ui/icons/Block';


class MaintenanceSchedule extends FListComponent {

  constructor(props) {
    super(props, API_VEHICLE_MAINTENANCE_SCHEDULE_URL, CHOICE_MAINTENANCE_SCHEDULE)
    this.currentTitle = 'Maintenance Schedule'
    this.currentTab = TAB_SAFETY_AND_COMPLIANCE
    this.routeUrl = ROUTE_MAINTENANCE_SCHEDULE_URL
    this.policyID = SAFETY_AND_COMPLINACE_MAINTENANCE_SCHEDULES
    this.includeStateAction = false
  }
  
  // getActions = (rowData) => {
  //   let actions = []
  //   // rowData.status == 'COMPLETE'? actions.push(ACTION_TABLE_INPROGRESS): 
  //   //       actions.push(ACTION_TABLE_COMPLETE)
  //   actions.push(ACTION_TABLE_DELETE_ROW)
  //   return actions
  // }

  getJobDueInfo = (rowData, jobDueInfo) => {
       if (! jobDueInfo) return ''
                
        if (rowData.scheduleFrequencyStrategyType != 'MILEAGE') {
          jobDueInfo = getPrettyDate(jobDueInfo)

          }else{
            jobDueInfo = `${jobDueInfo} mi`
          }
        return jobDueInfo
  }

  render() {
    return (
      <div>

        <FMaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: 'Schedule', render: rowData => {
                return (
                  <React.Fragment>
                    <FRouterLink editIcon={true} label={rowData.scheduleName} to={ROUTE_MAINTENANCE_SCHEDULE_URL + rowData.id} />
                  </React.Fragment>
                )
              }
            },
            // {
            //   title: 'Vehicle', field: 'vehicle.prettyName',
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FRouterLink label={rowData.vehicle.prettyName} 
            //            to={ROUTE_VEHICLE_URL + rowData.vehicle.id}
            //            disabled={isProfileDispatcher(this.props.profile)}/>
            //       </React.Fragment>
            //     )
            //   }
            // },
            {
              title: 'Vehicle', 
              render: this.getVehicleLink
            },
            {
              title: 'Schedule Started',

              render: rowData => {
                let scheduleBegun =  rowData.scheduleInfo && rowData.scheduleInfo.scheduleBegun
                
                return (
                  <React.Fragment>
                    {this.getJobDueInfo(rowData, scheduleBegun)}
                  </React.Fragment>
                )
              }
            },
            {
              title: 'Upcoming Schedule',

              render: rowData => {
                let nextScheduleJob =  rowData.scheduleInfo && rowData.scheduleInfo.nextScheduleJob
                
                return (
                  <React.Fragment>
                    {this.getJobDueInfo(rowData, nextScheduleJob)}
                  </React.Fragment>
                )
              }
            },


            { title: 'Desc', field: 'scheduleInfo.scheduleIntervalDesc'},
            { title: 'Initial Total Schedules', field: 'scheduleInfo.totalScheduledJobs'},
            { title: 'Total Completed Schedules', field: 'scheduleInfo.totalCompleteJobs'},
            { title: 'Total Upcomings Schedules', field: 'scheduleInfo.totalUpComingJobs'},
            { title: 'Total Passdue Schedules', field: 'scheduleInfo.totalIncompleteJobs'},
            { title: 'Total Discarded Schedules', field: 'scheduleInfo.totalRemoved'},

  
            {
              title: 'Status', 
              render: rowData => {
                return (
                  <React.Fragment>
                      {getStatusIcon(rowData)}
                  </React.Fragment>
                )
              }
            },
            // {
            //   title: 'Actions', 
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FTableAction 
            //             actions={this.getActions(rowData)}
            //             onTableAction = {this.onTableAction}
            //             rowData={rowData}
            //              />
            //       </React.Fragment>
            //     )
            //   }
            // },
            {
              title: 'Actions', 
              render: rowData => {
                let actions  = this.getActions(rowData, SAFETY_AND_COMPLINACE_MAINTENANCE_SCHEDULES)

                return (
                  <React.Fragment>
                    {!!actions.length && <FTableAction 
                        actions={actions}
                        onTableAction = {this.onTableAction}
                        rowData={rowData}
                         />
                    }
                    {!!!actions.length && <BlockIcon color="disabled" />}
                  </React.Fragment>
                )
              }
            },

          ]}
          actions={
            [
            //   {
            //     icon: () => (
            //       <FRouterAddLink to={ROUTE_MAINTENANCE_SCHEDULE_URL + 'add'} color='secondary' />
            //     ),
            //     isFreeAction: true
            // }
            this.getAddNewModelActions()
            ]}
          options = {{
              pageSize: this.state.pageSize,
              pageSizeOptions: this.state.pageSizeOptions,
              actionsColumnIndex: -1
           }}
          data={this.fetchData}
          title="Paychecks"
          listErrCode={this.state.listErrCode}
        />

      </div>
    );
  }
}



export default connect(
  mapStateToProps, mapDispatchToProps)(MaintenanceSchedule)
