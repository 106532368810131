import React, { Component } from 'react';
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const style = theme => ({
  root: {
    // marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'flex-end', // Align items at the bottom
    height: '100%', // Ensure the container has height
  },
  checkbox: {
    padding: 0, // Remove padding to maintain square shape
    marginBottom: theme.spacing(1.5), // Ensure no extra space below the checkbox
  },
  label: {
    display: 'flex',
    flexDirection: 'column', // Stack the checkbox and label vertically
    alignItems: 'center', // Center the label horizontally
    marginLeft: theme.spacing(1), // Space between checkbox and label
    marginBottom: theme.spacing(1.5), // Prevent extra space below label
    height: '100%', // Ensure label takes full height
    justifyContent: 'flex-end', // Align label text to the bottom
  },
  labelText: {
    marginTop: 'auto', // Push the label text to the bottom
  },
});
class FCheckBox extends Component {

  onChange = (evt) => {
    const checked = evt.target.checked
    this.props.notifyParent(this.props.name, {[this.props.name]:checked})
  }

  render() {
    const { classes } = this.props
    return (

      <div className={classes.root} style={{color: this.props.hasError? 'red': ''}}>
        <FormControlLabel
          control={
            <Checkbox
              classes={{ root: classes.checkbox }}
              disabled={this.props.disabled}
              name={this.props.name}
              labelPlacement={"top"}
              checked={!!this.props.value}
              value={!!this.props.value}
              onChange={this.onChange}
              />
          }
          label={<span className={classes.label}>{this.props.label}</span>}
        />
      </div>);
  }
}

export default withStyles(style)(FCheckBox)