import React from 'react';
import FRouterLink, { FRouterAddLink } from '../../widgets/FRouterLink';
import { Link } from '@material-ui/core';
// import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import { connect } from "react-redux"
import { roundDecimal, getPrettyDate } from '../../../util';
import FMaterialTable from '../../widgets/FMaterialTable';

export default class IftaSummarys extends React.Component {
  constructor(props) {
    super(props)
  }
  

  render() {

    return (
      <div>

        <FMaterialTable
          tableRef={this.props.tableRef}
          columns={[
            { title: 'State', field: 'jurisdiction'},
            { title: 'Jurisdiction', field: 'jurisdictionCode'},
            { title: 'Distance (Mi)', field: 'miles'},
            { title: 'Total Quantity (Fuel)', field: 'fuelQuantity'},
            { title: 'Expense Amount ($)', field: 'fuelExpenseAmount'},
          
          ]}
          
          options = {{
              pageSize: 57,
              pageSizeOptions: [57],
              actionsColumnIndex: -1,
              search: false
           }}
          data={this.props.fetchData}
          listErrCode={this.props.listErrCode}
        />

      </div>
    );
  }
}
