import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles'
import { connect } from "react-redux"
import { mapDispatchToProps, mapStateToProps } from "../../fredux/ActionMaker";
import { Box } from '@material-ui/core';


const style = theme => ({
  root: {
    background: '#f5f7f8',
    margin: '5px',
    boxShadow: "3px",
    borderRadius: '10px',
    p: 1,
    minWidth: 200,
    padding: theme.spacing(1) ,
  },
  value: {
    fontSize: 40, 
    paddingLeft: theme.spacing(2) ,
    fontWeight: 'medium'
  },
  root1: {
    bgcolor: 'red',
    boxShadow: 1,
    borderRadius: 2,
    p: 2,
    minWidth: 300,
    border: 1
},
});
// export default function FSummaryDash() {
  class FSummaryDash extends Component {
  
  getValue = ()=> {
    if (!!this.props.data && !!this.props.data.total){
      return `$${this.props.data.total}`
    }
    return '$0.00'
  }

  render() {
    const { classes } = this.props
    const color = '#173A5E'

    return (
        <Box className={classes.root}>
          <Box style={{ color: '#46505A', fontSize: 18}}>{this.props.title}</Box>
          
          <Box className={classes.value}
             style={{ color: `${this.props.valueColor || color}` }}
             >
            {this.getValue()}
          </Box>
         
          <hr/>
          <Box
            sx={{
              color: 'success.dark',
              display: 'inline',
              fontWeight: 'bold',
              mx: 0.5,
              fontSize: 14,
            }}
          >
            Latest: 
          </Box>
          <Box sx={{ color: '#46505A', display: 'inline', fontSize: 14 }}>
            {this.props.data && this.props.data.latest}
          </Box>
        
        </Box>
    );
          }
}


export default withStyles(style)(connect(
  mapStateToProps, mapDispatchToProps)(FSummaryDash))