import React from 'react';
import MaterialTable from 'material-table'
import { getPrettyDate } from '../../util';
import { FMaterialSimpleTable } from '../widgets/FMaterialTable';


class PaymentHistory extends React.Component {


  render() {
    return (
      <div>
        <FMaterialSimpleTable
          columns={[
            { title: 'Transaction ID', field: 'paymentTransactionID' },         
            
            { title: 'Transaction Date', render: rowData => getPrettyDate(rowData.paymentTransactionDate) },
            { title: 'Payment Type', field: 'paymentType' },
            { title: 'Billing For', field: 'desc' },
            {
              title: 'Amount', render: rowData => {
                return (
                  <React.Fragment>
                     {`$${rowData.paymentAmount}`}
                  </React.Fragment>
                )
              }
            },
            
          ]}
           data={this.props.data}
          title={`Transaction History`}
        />
      </div>
    );
  }
}

export default PaymentHistory;