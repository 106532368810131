import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";

import { Grid, Paper } from "@material-ui/core";
import FSelect from "../widgets/FSelect";
import FMessage from "../widgets/FMessage";
import FInput from "../widgets/FInput";
import { mapDispatchToProps, mapStateToProps } from "../../fredux/ActionMaker";
import {API_DOCUMENTS_URL, API_COMPANY, CHOICE_COMPANY, API_CARRIERS_URL,  TAB_ADMIN, ADMIN_CONTACTS } from "../../util/Const";
import DocumentView from "../document"
import { isValid } from "../../util/Validator";
import { VALIDATE_REQUIRED, VALIDATE_EMAIL, VALIDATE_PHONE_NUMBER, VALIDATE_ADDRESS_CITY_STATE } from "../../util/ValidatorConst";
import FDetailComponent from "../../util/FDetailComponent";
import FAutoComplete from "../widgets/FAutoComplete";
import FAddress from "../widgets/FAddress";


const styles = theme => ({
    root: {
        // display: "flex",

    },
    padding: {
        marginTop: theme.spacing(4),
    },

    header: {
        background: '#f5f5f5'
    },
    button: {
        marginRight: theme.spacing(2),
    },
    dropDown: {
      },
    root1: {
        backgroundColor: '#fff',
        padding: theme.spacing(2),
    },
});

const PROPS = {
    errorData: {
        companyName: false,
        companyMailingAddress: false,
        companyBillingAddress: false,
        companyPhoneNumber: false,
        companyEmail: false,
        // status: false,
        companyType: false,
        // companyDOT: false,
        // companyMC: false,
        companyIsInvoiceable: false,
        address: false,
        carrier: false
    },
    errorMessage: {
        companyName: 'Invalid Comany Name',
        companyMailingAddress: 'Invalid Mailing Address',
        companyBillingAddress: 'Invalid Billing Address',
        companyPhoneNumber: 'Invalid Phone Number',
        companyEmail: 'Invalid Email',
        // status: false,
        companyType: 'Invalid Company Type',
        // companyDOT: 'Invald DOT',
        // companyMC: 'Invalid MC',
        companyIsInvoiceable: 'Invald Selection',
        carrier: 'Invalid Carrier'
    },
    touched: {
        // companyCode: true,
        companyType: true
     },
    data: {
        companyName: '',
        companyMailingAddress: '',
        companyBillingAddress: '',
        companyPhoneNumber: '',
        companyEmail: '',
        // status: '',
        // companyType: 'FORMER_EMPLOYER',
        // companyCode: CompanyCode.FORMER_EMPLOYER,
        companyType: '',
        // companyCode:'',
        // companyDOT: '',
        // companyMC: '',
        address: '',
        carrier: ''
    },
    validators: {
        companyName: [VALIDATE_REQUIRED],
        companyMailingAddress: [VALIDATE_REQUIRED],
        companyBillingAddress: [],
        companyPhoneNumber: [VALIDATE_PHONE_NUMBER],
        companyEmail: [VALIDATE_REQUIRED, VALIDATE_EMAIL],
        companyType: [VALIDATE_REQUIRED],
        // companyDOT: [VALIDATE_REQUIRED],
        // companyMC: [VALIDATE_REQUIRED],
        address: [VALIDATE_ADDRESS_CITY_STATE],
        carrier: [VALIDATE_REQUIRED]
    }
}

class ContactDetail extends FDetailComponent {
    constructor(props) {
        super(props, API_COMPANY, CHOICE_COMPANY, PROPS)
        // this.setState({ showDOTInfo: this.showDOTInfo() })
    }

    getErrorData = () => {
        let errorData = {
            companyName: !isValid(this.state.data.companyName, this.state.validators.companyName),
            companyPhoneNumber: !isValid(this.state.data.companyPhoneNumber, this.state.validators.companyPhoneNumber),
            companyType: !isValid(this.state.data.companyType, this.state.validators.companyType),
            companyEmail: !isValid(this.state.data.companyEmail, this.state.validators.companyEmail),
            address: !isValid(this.state.data.address, this.state.validators.address),
            carrier: !isValid(this.state.data.carrier, this.state.validators.carrier),
        }
        return errorData
    }

    showDOTInfo = () => {
        let companyType = this.state.data.companyType
        if (companyType == 'BROKER' || companyType == 'CARRIER') {
            return true
        } else {
            return false
        }
    }

    hasCustomErrors = () => {
        if (!!this.props.validateFullAddress) {
            return !(this.isAddressValid(true, true, true, true))
        }
        else {
            return !(this.isAddressValid(false, true, true, false))
        }
     }

    
    getCurrentTitle = () => {
        let title = this.state.data.companyName
        this.props.updateCurrentTab(TAB_ADMIN)
        this.props.updateCurrentTitle && this.props.updateCurrentTitle(title)
    }

    render() {
        const { classes } = this.props;
        this.getCurrentTitle()
        return (
            <Paper className={classes.root1} elevation={0}>
                {/* <FTitle label={'Former Employer -  ' + this.state.data.companyName} /> */}
                <Grid container direction='column'  alignItems='stretch' spacing={2} className={classes.root1}>
                    <Grid item xs>
                        <FMessage error={this.props.crudServerError}
                            success={this.props.crudServerSuccess} />
                    </Grid>
                    
                    <Grid container  spacing= {2} xs={12} >
                            <Grid  item xs className={classes.dropDown} >
                                 <FAutoComplete
                                        url={API_CARRIERS_URL}
                                        name='carrier'
                                        hasError={this.state.errorData.carrier}
                                        helpMessage={this.state.errorMessage.carrier}
                                        label='Authority'
                                        // queryParam='search'
                                        value={this.state.data.carrier}
                                        defaultValue={this.state.data.carrier}
                                        notifyParent={this.updateValues}
                                        disabled={!this.state.isNew}
                                        // notifyDependency={this.onCarrierChange}
                                        />
            
                            </Grid>
                            <Grid item xs className={classes.dropDown}>
                                <FSelect
                                    name='companyType' required
                                    value={this.state.data.companyType}
                                    hasError={this.state.errorData.companyType}
                                    helpMessage={this.state.errorMessage.companyType}
                                    label='Contact Type / Service Provided *'
                                    data={this.props.companyTypeChoice}
                                    valueRef='prettyName'
                                    keyRef='id'
                                    notifyParent={this.updateValues}
                                />
                            </Grid>
                            <Grid item xs>
                                <FInput name='companyName' required
                                    value={this.state.data.companyName}
                                    hasError={this.state.errorData.companyName}
                                    helpMessage={this.state.errorMessage.companyName}
                                    label="Name"
                                    notifyParent={this.updateValues}
                                />
                            </Grid>
                            <Grid item xs hidden={!this.showDOTInfo()}>
                                <FInput name='companyDOT' required
                                    value={this.state.data.companyDOT}
                                    hasError={this.state.errorData.companyDOT}
                                    helpMessage={this.state.errorMessage.companyDOT}
                                    label="DOT" notifyParent={this.updateValues}
                                />
                            </Grid>
                            <Grid item xs hidden={!this.showDOTInfo()}>
                                <FInput name='companyMC' required
                                    value={this.state.data.companyMC}
                                    hasError={this.state.errorData.companyMC}
                                    helpMessage={this.state.errorMessage.companyMC}
                                    label="MC" notifyParent={this.updateValues}
                                />
                            </Grid>
                            
                           
                    </Grid>
                    <Grid container className={classes.root} spacing= {4} xs={12} > 
                            <Grid item xs>
                                <FInput name='companyEmail' required
                                    value={this.state.data.companyEmail}
                                    hasError={this.state.errorData.companyEmail}
                                    helpMessage={this.state.errorMessage.companyEmail}
                                    label="Email" notifyParent={this.updateValues}
                                />
                            </Grid>
                            <Grid item xs>
                                <FInput name='companyPhoneNumber' required
                                    value={this.state.data.companyPhoneNumber}
                                    hasError={this.state.errorData.companyPhoneNumber}
                                    helpMessage={this.state.errorMessage.companyPhoneNumber}
                                    label="Phone Number" notifyParent={this.updateValues}
                                />
                            </Grid>
                            {/* <Grid item xs>
                                <FInput name='companyMailingAddress' required
                                    value={this.state.data.companyMailingAddress}
                                    hasError={this.state.errorData.companyMailingAddress}
                                    helpMessage={this.state.errorMessage.companyMailingAddress}
                                    label="Address" notifyParent={this.updateValues}
                                    notifyParent={this.updateValues}
                                />
                            </Grid> */}
                            
                    </Grid>
                    <Grid container className={classes.root} spacing= {2} xs={12} >
                       <Grid item xs>
                                <FAddress
                                            name='address' 
                                            notifyParent={this.updateValues}
                                            address={this.state.data.address}
                                            hasError={this.state.errorData.address}
                                            place={false}
                                            city={true}
                                            state={true}
                                            zipCode={false}
                                />
                        </Grid>
                    </Grid>
                    <Grid  container spacing={2} xs={12}>
                             {/* <Grid item xs={2}>
                                    <FCheckBox
                                        name='companyIsInvoiceable'
                                        value={this.state.data.companyIsInvoiceable}
                                        value={this.state.data.companyIsInvoiceable}
                                        hasError={this.state.errorData.companyIsInvoiceable}
                                        helpMessage={this.state.errorMessage.companyIsInvoiceable}
                                        label="Company Is Invoiceable"
                                        notifyParent={this.updateValues} />
                            </Grid> */}
                            <Grid item xs>
                                <FInput
                                    name='desc' value={this.state.data.desc} multiline={true}
                                    value={this.state.data.desc}
                                    hasError={this.state.errorData.desc}
                                    helpMessage={this.state.errorMessage.desc}
                                    label="Description" notifyParent={this.updateValues} />
                            </Grid>
                    </Grid>
                       
                    <Grid container  spacing= {4} xs={12} >
                        {/* <Grid item container xs={8} >
                            <FButton color='primary'
                                label={this.state.isNew ? 'Save' : 'Update'}
                                loading={this.state.loading}
                                onClick={this.onSaveData}
                            />
                            <Grid item style={{ paddingTop: '9px' }}>
                                <FRouteBackLink variant='outlined' color='secondary' className={'backButton'} />
                            </Grid>
                        </Grid> */}
                         <Grid item container xs={8} >
                            {this.getUpdateButton(this.props.profile, ADMIN_CONTACTS)}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs className={classes.padding}>
                    {!this.state.isNew &&
                        <DocumentView
                            ownerId={this.props.match.params.id}
                            owner={this.state.data}
                            listUrl={API_DOCUMENTS_URL + `?&company=${this.props.match.params.id}`}
                            ownerType='company'
                            policy_id={ADMIN_CONTACTS}
                            documentChoices={this.props.document_former_employer_choices}
                        />}
                </Grid>

            </Paper >
        );
    };
}

ContactDetail.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(connect(
    mapStateToProps, mapDispatchToProps)(ContactDetail))
