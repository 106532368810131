import React from 'react'
import Integrations from './Integrations';


export default class IntegrationView extends React.Component {
  render() {
    return (
      <div>
          <Integrations/> 
      </div>
    )
  }
}
