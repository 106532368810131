import React from 'react'
import { connect } from 'react-redux'

import { Grid } from "@material-ui/core";
import { mapDispatchToProps, mapStateToProps } from '../../fredux/ActionMaker';
import PaymentHistory from '../square_payment/PaymentHistory';
import PaymentSummary from '../square_payment/PaymentSummary';
import { FETCH_PAYMENT_HISTORY, PROCESS_PAYMENT_SQUARE, TAB_PROFILE, API_BANK_CARDS_URL } from '../../util/Const';
import { GET, UPDATE, GET_RESULT } from '../../util/FAPI';
import { showSuccessNotification, showErroNotification } from '../widgets/FMessage';
import Alert from '@material-ui/lab/Alert';

import BankCards from '../square_payment/BankCards'
import FButton from '../widgets/FButton';
import FDialog from '../widgets/FDialog';
import FReloadButton from '../widgets/FReloadButton';
import SignupSQPaymentForm from '../square_payment/SignupSQPaymentForm';


class AccountPayment extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      data: [],
      cardData: [],
      paymentProcessing: false,
      showPayment: false,
      paymentSuccess: false,
      loading: false
    }

  }
  
  componentDidMount() {
    this.fetchPaymentHistory();
    this.fetchBankCards()
    this.props.updateCurrentTitle && this.props.updateCurrentTitle('Account Payment')
    this.props.updateCurrentTab(TAB_PROFILE)
  }
  
  fetchPaymentHistory = () => {
    GET(FETCH_PAYMENT_HISTORY).then(res => {
      let data = GET_RESULT(res)
      this.setState({ data: data })
    }).catch(err => {
       alert('Ooops we are having some problem, please try again!')
    })
  }

  fetchBankCards = () => {
    GET(API_BANK_CARDS_URL).then(res => {
      let cardData = GET_RESULT(res)
      this.setState({ cardData: cardData })
    }).catch(err => {
       alert('Ooops we are having some problem, please try again!')
    })
  }
  processPayment = (nonce, paymentZipCode) =>{
    let formData = new FormData();
    formData.append('nonce', nonce)
    formData.append('paymentZipCode', paymentZipCode)
    
    formData.append('paymentAmount', parseFloat(this.props.profile.ownerSubscription.paymentAmount))
    this.setState({ paymentProcessing: true, loading: true })
    UPDATE(PROCESS_PAYMENT_SQUARE, 'post', formData).
      then(response => {
        this.setState({ paymentProcessing: false, paymentSuccess: true, loading: false })
        this.notifyPaymentDialogClose()
        this.fetchPaymentHistory()
        this.fetchBankCards()

        showSuccessNotification('Thanks for making the payment, It might take few minutes to get processed.')
        // alert ( 'Thanks for making the payment, It might take few minutes to get processed.')
      }).catch(error => {
        showErroNotification(error, "Oops. card error, make sure zipcode matches ")
        this.setState({ paymentProcessing: false, loading: false })
    });

  }


  notifyPaymentDialogClose = () => {
    this.setState({showPayment: false})
  };



  render() {
    let ownerSubscriptionStatus = this.props.profile.ownerSubscriptionStatus 

    return(
          <Grid container xs={12} direction='column'    spacing={2} >
              {ownerSubscriptionStatus && ownerSubscriptionStatus != 'VALID' && 
                      <Grid container xs   pacing={2} >
                            <Grid item xs={12}>
                              <FReloadButton color='secondary'
                                label={'Please Make a Payment to Avoid Service Interruption or a Re-Activation Fee. If You Made a Payment  Reload for An Immediate Effect' }/>
                            </Grid>
                          
                      </Grid>
                  }
              
              <Grid item xs>
                   <PaymentHistory data={this.state.data} 
                      subscription={this.props.profile && this.props.profile.ownerSubscription}/>
              </Grid>
              
              <Grid container xs={12} spacing={2} >

                  <Grid item xs>
                      <BankCards data={this.state.cardData}/>
                  </Grid>
                  <Grid item xs> 
                      <Grid container xs direction='column'    spacing={2} >
                          <Grid item xs>
                              <PaymentSummary
                              />
                          {this.state.paymentSuccess && 
                              <Grid container xs   pacing={2} >
                                  <Grid item xs>
                                      <Alert severity="success">New Payment Method Added, Reload For an immediate effect..</Alert>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <FReloadButton variant="contained" />
                                    </Grid>
                                  
                              </Grid>
                          }
                          </Grid>

                          <Grid item xs style={{width: '50%'}}>
                              <FButton label={"Add Payment Method"}  
                               onClick= {() => this.setState({showPayment: true})}
                               color="primary"
                               variant="contained"
                               />
                          </Grid>
                      </Grid>
                  </Grid>

              </Grid>

              <FDialog
                    maxWidth='xl'
                    fullWidth={false}
                    title={"Billing Information"}
                    // owner={this.props.owner}
                    openDialog={this.state.showPayment}
                    notifyDialogClose = {this.notifyPaymentDialogClose}
                    loading={this.state.loading}
                    > 
                    <SignupSQPaymentForm
                                      buttonLabel='Add Pay Method'
                                      loading={this.state.loading}
                                      validate={() => {}}
                                      notifyNonce={this.processPayment}
                                      notifyNonceError={() => {}}
                                  />
            </FDialog>
          </Grid>
    )
  }
}

export default connect(
  mapStateToProps, mapDispatchToProps)(AccountPayment)