import React from 'react'
import MaintenanceScheduleReportHeader from './MaintenanceScheduleFilter';
import { TAB_REPORT, API_MAINTENANCE_SCHEDULE_REPORT_URL } from '../../../util/Const';
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";
import { mapDispatchToProps, mapStateToProps } from "../../../fredux/ActionMaker";
import MaintenanceSchedules from './MaintenanceSchedules';
import BaseReport from '../BaseReport';
import { getDate } from '../../../util';


const styles = theme => ({
  reimbursablePercentage : {
      marginRight: theme.spacing(2),

  },
});

class MaintenanceScheduleReport extends BaseReport {
  // tableRef = React.createRef();

  constructor(props) {
    let state = {
      listUrl: API_MAINTENANCE_SCHEDULE_REPORT_URL, 
      downloadUrl: API_MAINTENANCE_SCHEDULE_REPORT_URL + 'csv/?',
      reportFileName: 'maintenance_schedule_report',
      data: {
        reportStartDate: getDate(new Date(), 90), // Three months ago
        reportEndDate: getDate(new Date()),
        carrier: {},
        vehicle: {},
        expenseType: ''
      },
      errorData: {},
      errorMessage: {},
      validators: {}
    }
    super(props, state)
    this.currentTitle = 'Maintenance Schedule Report'
    this.currentTab = TAB_REPORT
  }

  

  updateFilter = () => {
    let url =  `created__gte=${this.state.data.reportStartDate}`
    url += `&created__lte=${this.state.data.reportEndDate}`
    if (!!(this.state.data.carrier && this.state.data.carrier.id)) url += `&carrier=${this.state.data.carrier.id}`
    if (!!(this.state.data.carrier &&  this.state.data.vehicle && this.state.data.vehicle.id)) url += `&schedule__vehicle=${this.state.data.vehicle.id}`
    if (!!(this.state.data.status)) url += `&status=${this.state.data.status}`

    const that = this
    this.setState({filter: url}, ()=>{
      that.tableRef.current && that.tableRef.current.onQueryChange();
    })
  }


  render() {
    // this.getCurrentTitle( 'Maintenance  Schedule', TAB_REPORT )
    const { classes } = this.props;

    return (
      <div >

          {/* {this.state.listErrCode != 403 &&   */}
          <MaintenanceScheduleReportHeader 
                disabled={this.state.listErrCode == 403}
                data={this.state.data} 
                errorMessage={this.state.errorMessage}
                errorData={this.state.errorData}
                validators={this.state.validators}
                updateValues={this.updateValues}
                onDownload={this.onDownload}
            />
          {/* } */}

            <MaintenanceSchedules 
                pstate={this.state} 
                tableRef={this.tableRef}
                fetchData={this.fetchData}
                listErrCode={this.state.listErrCode} />
      </div>
    )
  }
}


export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps)(MaintenanceScheduleReport))
