import React from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export default function ScheduleJobTab(props) {
  const [value, setValue] = React.useState(0);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  return (
    // <Paper>
      <Tabs
        value={props.value}
        indicatorColor="primary"
        textColor="primary"
        onChange={props.onJobScheduleChange}
        aria-label="disabled tabs example"
        centered
      >
        <Tab label="Upcoming Schedules" />
        {/* <Tab label="Disabled" disabled /> */}
        <Tab label="History" />
      </Tabs>
    // </Paper>
  );
}