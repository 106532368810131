import React, { Component } from 'react'
import ListItemIcon from '@material-ui/core/ListItemIcon';

import { mapStateToProps } from '../../fredux/ActionMaker';
import { connect } from 'react-redux'
import {  has_view_permission} from '../../util/ACL';
import * as CONST from "../../util/Const";
import FListItem from '../widgets/FListItem';


class DriverAppListItems extends Component {

    render() {
        const profile = this.props.profile
        return (
            <div>
        
            <FListItem  
                disabled={!has_view_permission(profile, CONST.DRIVER_APP_DRIVER_PROFILES)}
                to={CONST.ROUTE_DRIVER_APP_DRIVER_PROFILE_URL} label='Driver Profile'>
                <ListItemIcon>DP</ListItemIcon>
            </FListItem>
            <FListItem  
                 disabled={!has_view_permission(profile, CONST.DRIVER_APP_EMPLOYMENT_APPLICATION)}
                 to={CONST.ROUTE_DRIVER_APP_EMPLOYMENT_APPLICATION_URL} label='Emp Application'>
                 <ListItemIcon>DEA</ListItemIcon>
            </FListItem>

            <FListItem  
                disabled={!has_view_permission(profile, CONST.DRIVER_APP_DOCUMENT_UPLOADS)}
                to={CONST.ROUTE_DRIVER_APP_UPLOAD_DOCUMENT_URL} 
                 label='Uploaded Documents'>
                 <ListItemIcon>UD</ListItemIcon>
            </FListItem>

            {/* {has_view_user_permission(this.props, CONST.ROUTE_DRIVER_APP_DRIVER_PROFILE_URL) && 
            <FListItem  to={CONST.ROUTE_DRIVER_APP_DRIVER_PROFILE_URL} label='Driver Account'>
                <PeopleIcon />
            </FListItem>
            } */}
        </div>
        );
    }
}

export default connect(mapStateToProps)(DriverAppListItems)
