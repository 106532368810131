import React from 'react'
import Fleets from './Fleets'
import Expenses from './Expenses'

import { Grid, Paper } from "@material-ui/core"
import InvoiceSummary from './Summary';
import PayCheckFilterView from './InvoiceFilterView';
import { API_INVOICE_FILTER_URL, API_INVOICE_URL, CREATE_PAYCHEK_MSG, PAYCHECK_TYPE_INVOICE } from '../../util/Const';
import { showSuccessNotification, showErroNotification  } from '../widgets/FMessage';
import { GET, UPDATE, getModelByID, GET_RESULT} from '../../util/FAPI';
import { getPrettyDate, getRand } from '../../util';
import FButton from '../widgets/FButton';
import { invoicePDF } from '../../util/pdf/InvoicePDF';
import { connect } from 'react-redux'
import { mapDispatchToProps, mapStateToProps } from '../../fredux/ActionMaker';
import { withStyles } from '@material-ui/core/styles'


const initState = {
  totalCalcReady: 0,
  net: 0,
  payCheckID: '',
  paycheckFilter: {
    payCheckExpenseCarrierFee: 0,
    payCheckFleetGrossPayment: 0, 
  },
  payCheckFleets: [],
  payCheckExpenses: [],
  payCheckTotal: {
    payCheckFleetTotalExpense:0,
  }
}

class InvoiceCreateDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = initState
  }

  getFormData = () => {
    console.log(this.state)
    let formData = new FormData();
    formData.append('customer', this.state.customer.id)
    formData.append('payCheckID', this.state.payCheckID)
    formData.append('payCheckType', PAYCHECK_TYPE_INVOICE)
    formData.append('carrier', this.state.paycheckFilter.carrier.id)
    formData.append('fleet', this.state.paycheckFilter.fleet.id)


    // formData.append('payCheckFleetStartDate', this.state.paycheckFilter.payCheckFleetStartDate)
    // formData.append('payCheckFleetEndDate', this.state.paycheckFilter.payCheckFleetEndDate)
    // formData.append('payCheckExpenseStartDate', this.state.paycheckFilter.payCheckExpenseStartDate)
    // formData.append('payCheckExpenseEndDate', this.state.paycheckFilter.payCheckExpenseEndDate)
    formData.append('payCheckExpenseCarrierFee', this.state.paycheckFilter.payCheckExpenseCarrierFee)
    formData.append('payCheckFleetGrossPayment', this.state.payCheckTotal.gross)
    formData.append('payCheckFleetTotalExpense', this.state.payCheckTotal.toalPayCheckExpense)
    formData.append('payCheckNet',  this.state.payCheckTotal.net)


    return formData
}
  getPaycheckSummary = () => {
    let summary = {customerDetailInfo: this.state.customer,
                   carrierDetailInfo:  this.state.carrier,
                   profileSummary:  this.props.profile, 
                   payCheckID: this.state.payCheckID,
                   payCheckDate: this.state.payCheckDate}
    
    return Object.assign(summary, this.state.paycheckFilter, this.state.payCheckTotal, this.state.carrier);
  }

  savePaycheck = () => {
    let data = this.getFormData()

    let pdfFile = invoicePDF(this.state.payCheckFleets, 
        this.state.payCheckExpenses, 
        this.getPaycheckSummary())

    data.append("payCheckFile" , pdfFile.output('blob'));
    this.setState({ loading: true })
    UPDATE(API_INVOICE_URL, 'post', data).
      then(response => {
        this.setState({ loading: false })
        showSuccessNotification(response, CREATE_PAYCHEK_MSG)
        //this.props.updateServerSuccess({ msg: getSuccessMessage(response) })
      }).catch(error => {
        showErroNotification(error, CREATE_PAYCHEK_MSG)
        this.setState({ loading: false })
        // this.props.updateServerError({ msg: getErrroMessage(error) })
      });
  }

  getPaycheckFilter = () => {
    let url = `?carrier=${this.state.paycheckFilter.carrier.id}&`
        url += `fleet=${this.state.paycheckFilter.fleet && this.state.paycheckFilter.fleet.id}&`
        url += `status=${'COMPLETE'}&`
        // url += `expenseIsInvoiceable=${true}&`
        url += `brokerOrCarrierFee=${this.state.paycheckFilter.payCheckExpenseCarrierFee}&`
        
        // url += `expenseInvoiceTransactionDate__gte=${getPrettyDate(this.state.paycheckFilter.payCheckExpenseStartDate)}&`
        // url += `expenseInvoiceTransactionDate__lte=${getPrettyDate(this.state.paycheckFilter.payCheckExpenseEndDate)}&`
        // url += `fleetInvoiceProcessingDate__gte=${getPrettyDate(this.state.paycheckFilter.payCheckFleetStartDate)}&`
        // url += `fleetInvoiceProcessingDate__lte=${getPrettyDate(this.state.paycheckFilter.payCheckFleetEndDate)}`
    return url
      }

  fetchFilter = (paycheckFilter) => {
    this.setState(s => {
      return { ...initState, paycheckFilter: paycheckFilter }
     }, () => {
      GET(API_INVOICE_FILTER_URL + this.getPaycheckFilter()).then(res => {
        let data = GET_RESULT(res)
        this.setState(prevState => {
          let newState = { ...prevState, ...data }
          return newState
        }, () => {
          // this.calcFleetTotals()
        }, this)
      }).catch(error => {
        // this.props.updateServerError({ msg: getErrroMessage(error) })
      })
    })
  }

  validateInvoice = () =>{
    return (
            !!this.state.payCheckID)
  }
  render() {
    return (
      <React.Fragment>
        <Grid container xs={12} spacing={4}>
          <PayCheckFilterView notifyParent={this.fetchFilter} />
          <Grid item xs>
            <Fleets data={this.state.payCheckFleets} />
          </Grid>
          <Grid item xs={8}>
            <Expenses data={this.state.payCheckExpenses} />
          </Grid>
          <Grid item xs={4}>
            <InvoiceSummary data={this.state.payCheckTotal} />
          </Grid>
        </Grid>
        <Grid item xs >
          <FButton color='primary'
            disabled={!this.validateInvoice()}
            label='Save Invoice'
            variant='contained'
            loading={this.state.loading}
            onClick={this.savePaycheck}
          />
        </Grid>
        </React.Fragment>
    )
  }
}


export default connect(
  mapStateToProps, mapDispatchToProps)(InvoiceCreateDetail)
