import React from 'react';
import {getPrettyDate} from '../../util';
import { FMaterialSimpleTable } from '../widgets/FMaterialTable';
 
class FuelExpenses extends React.Component {
  render() {
    return (
      <div>

        <FMaterialSimpleTable
          columns={[
            
            { title: 'Card #', field: 'fuelCardSourceID' },
            { title: 'Transaction Id', field: 'fuelExpenseSourceTransactionID' },
            { title: 'Transaction Date', render: rowData => getPrettyDate(rowData.fuelExpenseTransactionDate) },
            {
              title: 'Location', render: rowData => {
                return (
                  <React.Fragment>
                     <b>{rowData.location}</b>, <b>{rowData.locationCity}</b>  <b>{rowData.locationState}</b>
                  </React.Fragment>
                )
              }
            },
            { title: 'Fuel Type', field: 'fuelExpenseFuelType' },
            { title: 'Quantity', field: 'fuelExpenseFuelQuantity' },
            { title: 'Unit Price', field: 'fuelExpenseFuelUnitPrice' },
            { title: 'Fee', field: 'fuelExpenseTransactionFee' },
            { title: 'Total', field: 'fuelExpenseFuelTotalAmount' },

            
          ]}
          data={this.props.data}
          title="Fuel Transactions"
        //   options = {{
        //     pageSize: SMALL_PAGE_SIZE,
        //     pageSizeOptions: SMALL_PAGE_SIZE_OPTIONS,
        //     search: true
        //  }}
        />

      </div>
    );
  }
}

export default FuelExpenses;
