import *  as Validation from './ValidatorConst'
import moment from 'moment';
import { CARRIER_CUSTOMER_TYPES, FMAX_FILE_ALLOWED } from './Const';
import {isValidStringAddress} from './location_util'

function cleanup(val) {
    try {
       return val.trim()
    } catch(e) {
       return val
    }
}

export function isNotNullOrEmpty(val) {
    val = cleanup(val)
    const notNullOrEmpty = (val !== '' && val !== undefined && val !== null && val != {})
    const re = !!notNullOrEmpty
    return re
}

export function isObjectNotNullOrEmpty(val) {
    val = cleanup(val)
    if ( typeof(val) == 'object' ){
        return (JSON.stringify(val) !== '{}')
    } 
    return false
}


function isValidPhoneNumber(elementValue) {
    elementValue = cleanup(elementValue)
    let phoneNumberPatternLocal = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    let phoneNumberPatternInt = /^\+(?:[0-9] ?){6,14}[0-9]$/;
    const re = (
        phoneNumberPatternLocal.test(elementValue) ||
        phoneNumberPatternInt.test(elementValue)
    )
    return !!re
}

export function isObjEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

function isValidEmail(email) {
    email = cleanup(email)

    // const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
    return !!re
}

function isValidPassword(password) {
    return isNotNullOrEmpty(cleanup(password))
}

function max30ReminderDays(value) {
    return value <= 30 && value > 0
}

function isValidDate(d) {
    return isNotNullOrEmpty(d)
}

function isValidYear(year) {
    return year >= 1999 && year <= 2030
}

function isValidUptoCurrentYear(year) {
    return isValidYear(year) && (year <= moment().year())
}


function isValidZeroOrPositive(val) {
    return isValidNumber(val) && val >= 0 
}

function isValidZerotToHundrad(val) {
    return isValidNumber(val) && val >= 0 && val <=100
}

function isValidPositive(val) {
    return isValidNumber(val) && val > 0 
}
export function isValidFee(fee) {
    // return !isNaN(fee);
    return isValidNumber(fee) && fee >= 0
}

function isValidNumber(val) {
    return isNotNullOrEmpty(val) && !isNaN(val)
}

function isValidFuelCardNumber(val) {
    return isValidNumber(val)
}


export function isValidZipCode(val) {
    if (isNotNullOrEmpty(val)){
    //    return  val.length == 5 ||   val.length == 10
       return /^\d{5}(-\d{4})?$/.test(val);
    } 
    return false
          
}

function isValidDayFrequency(val, max=360) {
    let valid =  isNotNullOrEmpty(val) && !isNaN(val) && val >=1 && val<max
    valid = valid &&  Number.isInteger(Number.parseFloat(val))
    return valid
}

function isValidVIN(vin) {
    vin = cleanup(vin)
    return  (isNotNullOrEmpty(vin) && vin.length >= 11)
}


function isValidFileSize(file) {
    if (file && file.size ){
        return (file.size > 0 && file.size < FMAX_FILE_ALLOWED)
    }
    return false
}

function isValidFile(file) {
    if (typeof file === 'undefined' || file === '') {
        return false;
    }

    if (file instanceof File) {
        return isValidFileSize(file)
        // return input.size > 0 && input.size < MAX_FILE_ALLOWED;
    }

    if (typeof file === 'string') {
        try {
            new URL(file);
            return true;
        } catch (e) {
            return false;
        }
    }
    
}


function isValidChoiceWithPrettyName(choice) {
    return (choice && choice.id && !!choice.prettyName)
}

function isValidCarrierCustomerType(choice) {
    let isValidChoice =  (CARRIER_CUSTOMER_TYPES.some(item => item.prettyName === choice))
    return isValidChoice
}



function isValidAddress(address, onlyCityState=false) {
    if (!isNotNullOrEmpty(address)){
         return false
    }
    if (typeof address === 'string' || address instanceof String) {
      address = JSON.parse(address) 
    }
    
    if (onlyCityState) {
        return (
            isNotNullOrEmpty(address.city) && 
            isNotNullOrEmpty(address.state)
           )
    }
    return (isNotNullOrEmpty(address.place) && 
            isNotNullOrEmpty(address.city) && 
            isNotNullOrEmpty(address.state) &&
            isValidZipCode(address.zipCode) )
}



export function isValid(value, validators) {
    let isV = true
    validators && validators.map(vals => {
        switch (vals) {
            case Validation.VALIDATE_REQUIRED:
                isV = isNotNullOrEmpty(value)
                break
            case Validation.VALIDATE_OBJECT_REQUIRED:
                    isV = isObjectNotNullOrEmpty(value)
                    break
            case Validation.VALIDATE_CARRIER_REQUIRED:
                isV = isObjectNotNullOrEmpty(value)
                break
            case Validation.VALIDATE_EMAIL:
                isV = isValidEmail(value)
                break
            case Validation.VALIDATE_PHONE_NUMBER:
                isV = isValidPhoneNumber(value)
                break
            case Validation.VALIDATE_DATE:
                isV = isValidDate(value)
                break
            case Validation.MAX_30_DAYS:
                isV = max30ReminderDays(value)
                break
            case Validation.VALIDATE_YEAR_FORMAT:
                isV = isValidYear(value)
                break
            case Validation.VALIDATE_PASSWORD:
                isV = isValidPassword(value)
                break
            case Validation.VALIDATE_FEE:
                isV = isValidFee(value)
                break
            case Validation.VALIDATE_ZERO_OR_POSITIVE:
                isV = isValidZeroOrPositive(value)
                break
            case Validation.VALIDATE_POSITIVE:
                isV = isValidPositive(value)
                break
            case Validation.VALIDATE_VIN_REQUIRED:
                isV = isValidVIN(value)
                break
            case Validation.VALIDATE_DAYS_FREQUENCY:
                isV = isValidDayFrequency(value)
                break
            case Validation.VALIDATE_ADDRESS:
                isV = isValidAddress(value)
                break
            case Validation.VALIDATE_ADDRESS_CITY_STATE:
                isV = isValidAddress(value, true)
                break
            case Validation.VALIDATE_FILE_SIZE:
                isV = isValidFileSize(value)
                break
            case Validation.VALIDATE_FILE:
                isV = isValidFile(value)
                break
            case Validation.VALIDATE_UPTO_CURRENT_YEAR:
                isV = isValidUptoCurrentYear(value)
                break
            case Validation.VALIDATE_FUEL_CARD_ID:
                isV = isValidFuelCardNumber(value)
                break
            case Validation.VALIDATE_0_100_PERCENTAGE:
                    isV = isValidZerotToHundrad(value)
                break
            case Validation.VALIDATE_STR_ADDRESS_LOCATION:
                isV = isValidStringAddress(value)
                break
            case Validation.VALIDATE_CHOICE_WITH_PRETTY_NAME:
                isV = isValidChoiceWithPrettyName(value)
                break
            case Validation.VALIDATE_CARRIER_CUSTOMER_TYPE:
                isV = isValidCarrierCustomerType(value)
                break

            default:
                return isV
        }
    })
    return isV
}

