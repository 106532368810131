// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import 'isomorphic-fetch';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { GET } from '../../util/FAPI';
import { FormControl, FormHelperText, withStyles, InputLabel, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types';



const styles = theme => ({
  formControl: {
    marginTop: theme.spacing(1.2),
    paddingTop: theme.spacing(2.7),
 }
});

const FAutoComplete = (props) => {
  const { classes } = props
  const [open, setOpen] = React.useState(false);
  // const [addNewOpen, setAddNewOpen] = React.useState(false);
  const [filter, setFilter] = React.useState('');

  const [options, setOptions] = React.useState([]);
  const [selected, setSelected] = React.useState(props.value);
  const loading = open && options.length === 0;
  const [loaded, setLoaded] = React.useState(loading);

  
  const fetchOptions = async (filterValue) => {
    setLoaded(false)
      let queryParam = ''
      if (!!filterValue) {
        queryParam += `search=${filterValue}`
      }
      if (props.hasCarrier) {
        queryParam += `&carrier=${props.carrier && props.carrier.id}`
      }
      // PrettyName on drop down doesn't require user permission.
      if (!props.excludePrettyName) {
        queryParam += `&prettyName=true`
      }
      if (props.queryParam) {
        queryParam+= '&' + props.queryParam
      }
      if (queryParam.includes('=undefined') || queryParam.includes('=null')){
        setLoaded(true)
        return []
      
      }
      try {
            const response = await GET(props.url + `?${queryParam}`)
            const options = response.data && response.data.results || []
            setOptions(options);
            setLoaded(true)
            
      } catch (error) {
            setOptions([]);
            setLoaded(true)
      }
      
  }

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }
    fetchOptions(filter)
  
    return () => {
      active = false;
    };
  }, [loading, filter]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);
  
  
  const getOptionSelected = (option, value) => {
    if( option.id === value.id) {
      setSelected(option)
      return true;
    }
  }


  const getOptionLabel = (option) => {
    let prettyName = props.prettyName || 'prettyName'
    return option[prettyName] || ''
  }

  const onSelect = (value) => {
  }

  const onChange = (evt, value) => {
    setSelected(value)
    props.notifyParent(props.name, { [props.name]: value || '' })
    if (props.notifyDependency) {
        props.notifyDependency()
    }
  }

  const handleAddNewClick = (e) =>  {
    // if (props.disabledAddNew) {
    //      e.preventDefault();
    // }
   // Change show modal
   props.onEdit(true)
  }
   
  return (
    <Grid container alignItems='flex-end' spacing={1.3} wrap="nowrap">
          <Grid item xs  style={{ display: 'flex', flex: '0 1 auto' }} >
            <FormControl   className={classes.formControl} error={props.hasError || (props.required && props.value && !props.value.id)}>
            
              <InputLabel shrink htmlFor={props.name}>{!props.required ? props.label : props.label + " *"}</InputLabel>
              <Autocomplete
                style={props.style || { minWidth: 400 }}
                open={open}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                loadingText={loaded && <div>No Data</div> || <div>Loading</div>}
                onSelect={onSelect}
                defaultValue={props.value}
                value={props.value}
                onChange={onChange}
                getOptionSelected={getOptionSelected}
                getOptionLabel={getOptionLabel}
                options={options}
                loading={loading}
                disabled={props.disabled}
                renderInput={params => (
                  <React.Fragment>
                   
                    <TextField
                      {...params}

                      fullWidth
                      onChange={evt => {
                        setFilter(evt.target.value)
                      }}
                    
                      InputProps={{
                        ...params.InputProps,
                      
                        endAdornment: (
                          <React.Fragment>
                            {(loading && !loaded)? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      // style={{ margin: 0 }}
                    />
                    
                  </React.Fragment>
                )}
              />
              
              <FormHelperText >{props.hasError && props.helpMessage}</FormHelperText>
              
        </FormControl>
      </Grid>
       <Grid item xs hidden={!props.showAdd} >

          {/* Show Add new button for some to add missing data on the fly */}
           <Link 
                  // hidden={!loaded && !!props.value}
                  component={RouterLink} to={props.to} 
                  onClick={handleAddNewClick} >
                  <AddIcon className={classes.addNew} color='secondary' fontSize="large" />
            </Link>
            
          </Grid>
     </Grid>
  );
}


FAutoComplete.propTypes = {
  required: PropTypes.bool
};

export default withStyles( styles )( FAutoComplete );
// export default  FAutoComplete;
