import React from 'react'
import Carriers from './Carriers';


export default class CarrierView extends React.Component {
  render() {
    return (
      <div>
          <Carriers/> 
      </div>
    )
  }
}
