import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from "@material-ui/core/styles";

import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import moment from 'moment';


const styles = theme => ({
  root: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // paddingBottom: 2 * theme.spacing(1),
    width: '100%',

    marginTop: theme.spacing(1.2),
    paddingTop: theme.spacing(1),
  },

});


class FDate extends Component {
  constructor(props) {
    super(props)
    let today = new Date()
    if (props.defaultValue) {
      today = new Date(props.defaultValue)
    }
    this.state = {
      [props.name]: (today).toISOString().slice(0, 10)
    }
  }

  notifyParent = () => {
    try {
      let dt = JSON.stringify(new Date(this.state[this.props.name]))
      dt = new Date(this.state[this.props.name])
      if (this.props.type === 'date') {
        this.props.notifyParent(this.props.name,
           { [this.props.name]: `${dt.getMonth() + 1}-${dt.getDate()}-${dt.getFullYear()}` })
      } else {
        this.props.notifyParent(this.props.name, { [this.props.name]: dt.toISOString() })
      }
    }
    catch (error) {
      this.props.notifyParent(this.props.name, { [this.props.name]: null })
    }
  }

  onChange = (evt) => {
    const v = evt.target.value
    if (v == null || v == undefined) alert(v)
    this.setState(prevState => {
      return { [this.props.name]: v }
    }, () => {
      this.notifyParent()
    })
  }
  
  componentDidMount() {
    this.notifyParent()
  }

  getPrettyName = () => {
    let d  = new Date(this.props.defaultValue||null)
    let dv =   d && d.toISOString().slice(0, 10)
    return dv
  }
  getMinDate = () => {
    //  return "2020-08-19"
     return moment().format('YYYY-MM-DD')
  }
  getMaxDate = () => {
    // return "2021-08-19"
    return  moment().format('YYYY-MM-DD')
  }

  render() {
    const { classes } = this.props;
    let inputProps = {}
    if (!!this.props.minDate){
      inputProps['min'] = this.getMinDate()
    }
    if (!!this.props.maxDate){
      inputProps['max'] =  this.getMaxDate()
    }
    return (
      
      <TextField
         
        name={this.props.name}
        errorName={this.props.errorName}
        error={this.props.hasError}
        label={this.props.label}
        helperText={
          this.props.hasError && this.props.helpMessage}
        disabled={this.props.disabled}
        required={this.props.required}
        onChange={this.onChange}
        type='date'
        InputProps={{inputProps: inputProps }}
        className={this.props.className ||  classes.root}
        InputLabelProps={{
          shrink: true,
        }}
        value={this.getPrettyName()}
        margin="normal" />);
  }
}



export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps)(FDate)
)
