import React from 'react';
import MaterialTable, { MTableToolbar } from 'material-table'
import FRouterLink, { FRouterAddLink } from '../widgets/FRouterLink';
import {ROUTE_CARRIERS_URL, API_MILEAGE_IMPORT_URL, ROUTE_MILEAGE_IMPORT_URL, TAB_FLEET, FLEET_MILEAGE_IMPORTS } from '../../util/Const';
import FListComponent from '../../util/FListComponent';
import { Link } from '@material-ui/core';
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import { connect } from "react-redux"
import FMaterialTable from '../widgets/FMaterialTable';
import FTableAction, { ACTION_TABLE_DELETE_ROW} from '../widgets/FTableAction';
import BlockIcon from '@material-ui/icons/Block';
import { roundDecimal } from '../../util';
import { getDataImportSyncStatusIcon, redForPositive, redForZero } from '../../util/icon_util';
import FDialog from '../widgets/FDialog';
import { FButtonErrorWithDataLink } from '../widgets/FButtonLink';

import {Labels} from "../../localizations/Resource"

class MileageImports extends FListComponent {

  constructor(props) {
    super(props, API_MILEAGE_IMPORT_URL, null,  `dataImportType=MILEAGE&dataImportSyncStatus=COMPLETE&`)
    this.currentTitle = 'Import Mileages'
    this.currentTab = TAB_FLEET
    this.routeUrl = ROUTE_MILEAGE_IMPORT_URL
    this.policyID = FLEET_MILEAGE_IMPORTS
    this.includeStateAction = false
  }

  // getActions = (rowData) => {
  //   let actions = []
  //   let invoiceStatus = rowData.dataImportSyncStatus
  //   // actions = actions.concat(INVOICE_STATE_MACHINE[dataImportSyncStatus])
    
  //   actions.push(ACTION_TABLE_DELETE_ROW)
  //   // actions.push(ACTION_TABLE_EMAIL_INVOICE)
    
  //   return actions
  // }
  
  // customApi = (rowData) => {
  //   alert('custome apii')
  // }
  
  showErrorSummary = () => {
    return (<FDialog
            // maxWidth='xl'
            title={Labels.IMPORT_MILEAGE_ERROR_TITLE}
            // owner={this.props.owner}
            openDialog={this.state.showImportEror}
            width={700}
            titleColor={'secondary'}
            notifyDialogClose = {() => this.setState({showImportEror: false})}>
          
                <FMaterialTable
                        columns = {[ 
                            {title: 'Error', field: 'error',
                             cellStyle: {
                              // backgroundColor: '#039be5',
                              color: '#f50057'
                            },},
                            {title: Labels.DATE, field: 'date'},
                            {title: Labels.TRUCK, field: 'truck'},
                            {title: Labels.DISTANCE_MILE, field: 'distance'},
                            {title: Labels.JURISDICTION, field: 'jurisdiction'},
                          ]}
                        
                        data={this.state.errors}
                        options = {{
                            search: true,
                            showTitle: false,
                            toolbar: true,
                            paging: true,
                            sorting: false,
                            // maxBodyHeight: 400,
                            pageSize:  5,
                            pageSizeOptions: [5],
                            headerStyle: {
                              backgroundColor: '#01579b',
                              color: '#FFF'
                            }

                        }}
          />
        </FDialog>)
  }
  
  onShowError = (rowData) => {
    let errors = rowData && rowData.dataImportMetaData && rowData.dataImportMetaData.errors || []
    let lengthOfErrors = errors ? errors.length : 0;
    if (lengthOfErrors <= 0) {
      return
     }
    this.setState(prevState => {
      let newState = {
        ...prevState,
        showImportEror: true,
        errors: errors
      }
      return newState
    }, () => {
      this.showErrorSummary(rowData);
    })
  };

  render() {
    return (
      <div>
        {this.showErrorSummary()}
        <FMaterialTable
          tableRef={this.tableRef}
          columns={[
            { title: Labels.IMPORTE_MILEAGE_ID, field: 'dataImportID'},
            {
              title: Labels.CARRIER, 
              render: this.getCarrierLink
            },
            { title: Labels.IMPORTE_MILEAGE_IMPORT_DATE, field: 'created', render: rowData => this.getPrettyDate(rowData.created) },

            { title: Labels.IMPORT_MILEAGE_DATA_SOURCE, field: 'prettyName'},
            { title: Labels.IMPORT_MILEAGE_FROM_DATE, field: 'dataImportStartDate', render: rowData => this.getPrettyDate(rowData.dataImportStartDate) },
            { title: Labels.IMPORT_MILEAGE_TO_DATE, field: 'dataImportEndDate', render: rowData => this.getPrettyDate(rowData.dataImportEndDate) },

            // {
            //   title: 'Carrier', 
            //   field: 'carrier.prettyName',
            //   render: rowData => {
                
            //     return (
            //       <React.Fragment>
            //         <FRouterLink label={rowData.carrier.prettyName} to={ROUTE_CARRIERS_URL + rowData.carrier.id} />
            //       </React.Fragment>
            //     )
            //   }
            // },
            
            // { title: 'Amount ($)', field: 'expenseAmount',  render: rowData => roundDecimal(rowData.expenseAmount)},
            // { title: 'Total Distance (Mi)', field: 'dataImportMetaData.totalDistance'},
            { title:  Labels.IMPORT_MILEAGE_SUMMARY_TOTAL_DISTANCE, render: rowData => roundDecimal(rowData.dataImportMetaData && rowData.dataImportMetaData.totalDistance)},
            { title:  Labels.IMPORT_MILEAGE_SUMMARY_TOTAL_TRIPS, field: 'dataImportMetaData.totalTrips'},
            { title:  Labels.IMPORT_MILEAGE_SUMMARY_TOTAL_JURISDICTION, field: 'dataImportMetaData.totalJurisdictions'},
            { title: Labels.IMPORT_MILEAGE_SUMMARY_TOTAL_UNKNOWN_VEHICLES,  field: 'dataImportMetaData.totalUnRecogonizedVehicles',  render: rowData => redForPositive(roundDecimal(rowData.dataImportMetaData.totalUnRecogonizedVehicles, 0))},

            // { title:  Labels.IMPORT_MILEAGE_SUMMARY_TOTAL_UNKNOWN_VEHICLES, field: 'dataImportMetaData.totalUnRecogonizedVehicles'},
            // { title:  Labels.IMPORT_MILEAGE_SUMMARY_TOTAL_DUPLICATES , field: 'dataImportMetaData.totalDuplicates'},
            { title: Labels.IMPORT_MILEAGE_SUMMARY_TOTAL_DUPLICATES, field: 'dataImportMetaData.totalDuplicates', render: rowData => redForPositive(roundDecimal(rowData.dataImportMetaData.totalDuplicates, 0))},

            // { title: Labels.IMPORT_MILEAGE_SUMMARY_TOTAL_ERRORS, field: 'dataImportMetaData.totalErrors'},
            // { title: Labels.IMPORT_MILEAGE_SUMMARY_TOTAL_ERRORS, field: 'dataImportMetaData.totalErrors', render: rowData => redForPositive(roundDecimal(rowData.dataImportMetaData.totalErrors, 0))},
            {
              title: 'Total Errors', 
              render: rowData => {
                let totalErrors = roundDecimal(rowData.dataImportMetaData.totalErrors, 0)
                let errors = redForPositive(totalErrors)
                return (
                  <React.Fragment>
                    {totalErrors > 0 && <FButtonErrorWithDataLink  
                      notifyParent={this.onShowError} data={rowData}
                      label={errors}/>
                    }
                    {totalErrors == 0 && 0}
                  </React.Fragment>
                )
              }
            },
            { 
              title: 'Imported Method',
              render: rowData => {
                return (
                  <React.Fragment>
                    {rowData.dataImportRawFile && <Link href={rowData.dataImportRawFile} target="_blank">file</Link>}
                    { rowData.dataImportMethod == 'API'  && 'AUTO'}
                  </React.Fragment>
                )
              }
            },
            {
              title: 'Status', 
              render: rowData => {
                return (
                  <React.Fragment>
                      {getDataImportSyncStatusIcon(rowData)}
                  </React.Fragment>
                )
              }
            },
            
            {
              title: 'Actions', 
              render: rowData => {
                let actions  = this.getActions(rowData, FLEET_MILEAGE_IMPORTS)

                return (
                  <React.Fragment>
                    {!!actions.length && <FTableAction 
                        actions={actions}
                        onTableAction = {this.onTableAction}
                        rowData={rowData}
                         />
                    }
                    {!!!actions.length && <BlockIcon color="disabled" />}
                  </React.Fragment>
                )
              }
            },
          ]}
          actions={
            [
              // {
              //   icon: () => (
              //     <FRouterAddLink to={ROUTE_MILEAGE_IMPORT_URL + 'add'} color='secondary' />
              //   ),
              //   isFreeAction: true
              // }
              this.getAddNewModelActions()
            ]}
          isLoading = {this.state.isLoading}
          options = {{
              // loading: true,
              pageSize: this.state.pageSize,
              pageSizeOptions: this.state.pageSizeOptions,
              actionsColumnIndex: -1
           }}
          data={this.fetchData}
          title={ Labels.IMPORT_MILEAGE_PAGE_TITLE}
          listErrCode={this.state.listErrCode}
        />

      </div>
    );
  }
}


export default connect(
  mapStateToProps, mapDispatchToProps)(MileageImports)
