import React from 'react'

import { Grid } from "@material-ui/core"
import FDate from '../../widgets/FDate';
import { connect } from 'react-redux'
import { mapDispatchToProps, mapStateToProps } from '../../../fredux/ActionMaker';
import { withStyles } from '@material-ui/core/styles'
import FAutoComplete from "../../widgets/FAutoComplete";
import { API_CARRIERS_URL, API_VEHICLES_URL, API_DRIVERS_URL, API_DISPATCHERS_URL, API_CHOICE_URL } from '../../../util/Const';
import FButton from "../../widgets/FButton";
import FSelect from '../../widgets/FSelect';


const styles = theme => ({
  root: {
  
     width: '100%'
  },
  dropDown: {
  },

  textNumber: {
    marginTop: theme.spacing(1),
  },
  generateButon: {
    marginTop: theme.spacing(2),
  },

  date: {
    width: '100%'
  },

  button: {
    marginTop: theme.spacing(3),
  },
}); 

class DocumentReportHeader extends React.Component {

  showFilter = (caller) => {
    if (this.props.data.documentFor == caller) {
      return true
    }
    return false
  }

  getQueryParamDocFor = () => {
    switch (this.props.data.documentFor) {
      // case 'Carrier':
      //   return 'choiceCategory=document&choiceSubCategory=carrier'
      case 'Vehicle':
        return 'choiceCategory=document&choiceSubCategory=vehicle'
      case 'Driver':
        return 'choiceCategory=document&choiceSubCategory=driver'
    }
    return 'choiceCategory=document '
  }

  render() {
    const {classes} = this.props
    return (
      <React.Fragment>
      <Grid container className={classes.root} spacing= {1}  xs={12} >
      <Grid item xs>
            <FDate
              name='reportStartDate'
              className={classes.date}
              required={true}
              defaultValue={this.props.data.reportStartDate}
              hasError={this.props.errorData.reportStartDate}
              helpMessage={this.props.errorMessage.reportStartDate}
              label="Date From "
              notifyParent={this.props.updateValues} />
          </Grid>
          <Grid item xs>
            <FDate
              name='reportEndDate'
              className={classes.date}
              required={true}
              defaultValue={this.props.data.reportEndDate}
              hasError={this.props.errorData.reportEndDate}
              helpMessage={this.props.errorMessage.reportEndDate}
              label="Date To "
              notifyParent={this.props.updateValues} />
          </Grid>
          <Grid item xs className={classes.dropDown}>
            <FAutoComplete
                  url={API_CARRIERS_URL}
                  name='carrier'
                  style={{width: 150}}
                  hasError={this.props.errorData.carrier}
                  helpMessage={this.props.errorMessage.carrier}
                  label='Authority'
                  // queryParam='search'
                  value={this.props.data.carrier}
                  defaultValue={this.props.data.carrier}
                  notifyParent={this.props.updateValues}
                  // disabled={!this.props.isNew}
                  notifyDependency={this.onCarrierChange}
              />
          </Grid>
          <Grid item xs>
                <FSelect
                    name='documentFor'
                    value={this.props.data.documentFor}
                    hasError={this.props.errorData.documentFor}
                    helpMessage={this.props.errorMessage.documentFor}
                    label='Filter Carrier By'
                    data={this.props.reportDocumentForChoice}
                    valueRef='prettyName'
                    keyRef='prettyName'
                    notifyParent={this.props.updateValues}
                />
            </Grid>

          
          <Grid item xs className={classes.dropDown} hidden={!this.showFilter('Driver')}>
          <FAutoComplete
                  url={API_DRIVERS_URL}

                  // queryParam={`carrier=${this.props.data.carrier && this.props.data.carrier.id}`}
                  hasCarrier={true}
                  style={{width: 150}}
                  carrier={this.props.data.carrier}
                  name='driver'
                  hasError={this.props.errorData.driver}
                  helpMessage={this.props.errorMessage.driver}
                  label='Driver'
                  // queryParam='search'
                  value={this.props.data.driver}
                  defaultValue={this.props.data.driver}
                  notifyParent={this.props.updateValues}
                  // disabled={!this.props.isNew}
            />
          </Grid>
          <Grid item xs className={classes.dropDown}  hidden={!this.showFilter('Vehicle')}>
            <FAutoComplete
                    url={API_VEHICLES_URL}
                    style={{width: 150}}
                    // queryParam={'vehicleType=TRUCK'}
                    hasCarrier={true}
                    carrier = {this.props.data.carrier}
                    name='vehicle'
                    hasError={this.props.errorData.vehicle}
                    helpMessage={this.props.errorMessage.vehicle}
                    label='Vehicle #'
                    // queryParam='search'
                    value={this.props.data.vehicle}
                    defaultValue={this.props.data.vehicle}
                    notifyParent={this.props.updateValues}
                    // disabled={!this.props.isNew}
                />
          </Grid>
          <Grid item xs>
              <FAutoComplete
                        url={API_CHOICE_URL}
                        style={{width: 300}}
                        queryParam = {this.getQueryParamDocFor()}
                        // queryParam={'choiceCategory=document'}
                        // hasCarrier={true}
                        // carrier = {this.props.data.carrier}
                        name='documentType'
                        hasError={this.props.errorData.documentType}
                        helpMessage={this.props.errorMessage.documentType}
                        label='Document Type'
                        // queryParam='search'
                        value={this.props.data.documentType && this.props.data.documentType}
                        defaultValue={this.props.data.documentType}
                        notifyParent={this.props.updateValues}
                        // disabled={!this.props.isNew}
                    />
             
          </Grid>

          <Grid item xs>
          <FButton color='primary'
                  className={classes.button}
                  label={'Download CSV'}
                  loading={this.props.loading}
                  onClick={this.props.onDownload}
              />
          </Grid>
          
        </Grid>
        
        </React.Fragment>    
    )
  }
}


export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps)(DocumentReportHeader))
