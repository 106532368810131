import React, { Component } from 'react'
import { render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App';
import NotSupportedBrowserSize from './components/app/NotSupportedBrowserSize';
import NotSupportedBrowser from './components/app/NotSupportedBrowser'
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { MuiThemeProvider } from 'material-ui/styles';
import { Provider } from 'react-redux'
import store from './fredux/Store'


const theme = getMuiTheme()

export default class AppProvider extends Component {
    getChildContext() {
        return {
            muiTheme: theme
        };
    }
}

function isMobileSupportedUrl() {
    const currentPath = window.location.pathname;
    if (currentPath === '/signupm') {
        return true;
    }
    return false;
}

function isMobile() {
    const isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
    return isMobile
}

function fnBrowserDetect(){
                 
        let userAgent = navigator.userAgent;
        let browserName;
        
        if(userAgent.match(/chrome|chromium|crios/i)){
            browserName = "chrome";
        }else if(userAgent.match(/firefox|fxios/i)){
            browserName = "firefox";
        }  else if(userAgent.match(/safari/i)){
            browserName = "safari";
        }else if(userAgent.match(/opr\//i)){
            browserName = "opera";
        } else if(userAgent.match(/edg/i)){
            browserName = "edge";
        }else{
            browserName="No browser detection";
        }
        return browserName
  }

function isBrowserSupported() {
    let browserName = fnBrowserDetect()
    return (browserName == 'chrome' || browserName == 'firefox')
}

function isDisplaySupported () {
  

    if (isMobile()){
        return <NotSupportedBrowserSize/>
    }

    if (!isBrowserSupported()){
        return <NotSupportedBrowser/>
    }
}

function isRenderAllowed() {
    // If it's a mobile supported URL, allow rendering
    if (isMobileSupportedUrl()) {
        return true;
    }
    // Otherwise, it must not be mobile and must be a supported browser
    return !isMobile() && isBrowserSupported();
}

render((
    <Provider store={store}>
        <MuiThemeProvider muiTheme={theme}>
            <BrowserRouter>
                {/* {!isMobile() && isBrowserSupported() && <App dispatch={store.dispatch}/> }
                {isDisplaySupported()} */}
                {isRenderAllowed() ? <App dispatch={store.dispatch}/> : isDisplaySupported()}

            </BrowserRouter>
        </MuiThemeProvider>
    </Provider >
), document.getElementById('root'));
