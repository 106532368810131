import React from "react";
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";


import { Grid, Paper } from "@material-ui/core";
import FSelect from "../widgets/FSelect";
import FMessage from "../widgets/FMessage";
import FInput from "../widgets/FInput";
import { mapDispatchToProps, mapStateToProps } from "../../fredux/ActionMaker";
import {API_SERVICE_PROVIDER ,  API_DOCUMENTS_URL, API_DRUG_TEST, CHOICE_DRUG_TEST, API_DRIVERS_URL, API_CARRIERS_URL, API_ACCIDENT_REGISTER, TAB_SAFETY_AND_COMPLIANCE, SAFETY_AND_COMPLINACE_DRUG_TESTS } from "../../util/Const";
import DocumentView from "../document"
import { VALIDATE_CARRIER_REQUIRED, VALIDATE_REQUIRED, VALIDATE_OBJECT_REQUIRED } from "../../util/ValidatorConst";
import FDetailComponent from "../../util/FDetailComponent";
import FDate from "../widgets/FDate";
import { isValid } from "../../util/Validator";
import FAutoComplete from "../widgets/FAutoComplete";

const styles = theme => ({
    root: {
        display: "flex",

    },
    padding: {
        marginTop: theme.spacing(2),
    },

    header: {
        background: '#f5f5f5'
    },
    button: {
        marginRight: theme.spacing(2),
    },
    root1: {
        backgroundColor: '#fff',
        padding: theme.spacing(2),
    },
});

const PROPS = {
    errorData: {
    },
    errorMessage: {
        drugTestDate: 'Invalid Date',
        drugTestResultDate: 'Invalid Date',
        drugTestLocation: 'Invalid Location',
        drugTestType: 'Invalid Type',
        drugTestResult: 'Invalid Drug Test Result',
        accidentRegister: 'No Accident Register Selected/Found for post accident',
        company: 'No Service Provider Selected/Found  Under Carrier',
        driver: 'No Driver Selected/Found  Under Carrier',
        carrier: 'Invalid Carrier'
    },
    touched: {
    },
    data: {
        drugTestDate: '',
        drugTestResultDate: '',
        drugTestLocation: '',
        drugTestType: '',
        drugTestResult:'',
        accidentRegister: '',
        company: {},
        driver: {},
        carrier: {}
    },
    validators: {
        drugTestDate: [VALIDATE_REQUIRED],
        drugTestResultDate: [VALIDATE_REQUIRED],
        drugTestLocation: [VALIDATE_REQUIRED],
        drugTestType: [VALIDATE_REQUIRED],
        drugTestResult:[VALIDATE_REQUIRED],
        accidentRegister: [VALIDATE_OBJECT_REQUIRED],
        company: [VALIDATE_OBJECT_REQUIRED],
        driver: [VALIDATE_OBJECT_REQUIRED],
        carrier: [VALIDATE_CARRIER_REQUIRED],
    }
}



class drugTestDetail extends FDetailComponent {
    constructor(props) {
        super(props, API_DRUG_TEST, CHOICE_DRUG_TEST, PROPS)
    }
    
    isAccidentRegisterRequired = () => {
        return (this.state.data.drugTestType == 'POST_ACCIDENT');
    }

    getErrorData = () => {
        let errorData = {
            drugTestDate: !isValid(this.state.data.drugTestDate, this.state.validators.drugTestDate),
            drugTestResultDate: !isValid(this.state.data.drugTestResultDate, this.state.validators.drugTestResultDate),

            drugTestLocation: !isValid(this.state.data.drugTestLocation, this.state.validators.drugTestLocation),
            drugTestLocation: !isValid(this.state.data.drugTestLocation, this.state.validators.drugTestLocation),
            drugTestType: !isValid(this.state.data.drugTestType, this.state.validators.drugTestType),
            drugTestResult: !isValid(this.state.data.drugTestResult, this.state.validators.drugTestResult),            

            accidentRegister: this.isAccidentRegisterRequired() && !isValid(this.state.data.accidentRegister, this.state.validators.accidentRegister),
            company: !isValid(this.state.data.company, this.state.validators.company),
            driver: !isValid(this.state.data.driver, this.state.validators.driver),
            carrier: !isValid(this.state.data.carrier, this.state.validators.carrier)
        }
        return errorData
    }

    onCarrierChange = () => {
        this.updateValues('driver', {driver: ''})
        this.updateValues('company', {company: ''})
        // this.updateValues('accidentRegister', {accidentRegister: ''})
    }
    
    getCurrentTitle = () => {
        let title = (this.state.data.driver && this.state.data.driver.prettyName && this.state.data.driver.prettyName + ' -  ' + this.state.data.drugTestType)  || ''
        title = 'Drug Tests: ' +  title
        this.props.updateCurrentTab(TAB_SAFETY_AND_COMPLIANCE)
        this.props.updateCurrentTitle && this.props.updateCurrentTitle(title)
        return title
    }


    render() {
        // let pName = (this.state.data.driver && this.state.data.driver.prettyName && this.state.data.driver.prettyName + ' -  ' + this.state.data.drugTestType)  || ''
        const { classes } = this.props;
        this.getCurrentTitle()

        return (
            <Paper className={classes.root1} >
                {/* <FTitle label={title}/> */}
                <Grid container direction='column' className={classes.root1}  spacing={4} >
                    <Grid item xs>
                        <FMessage error={this.props.crudServerError}
                            success={this.props.crudServerSuccess} />
                    </Grid>
                    <Grid container  xs={12}  spacing={4} >
                            <Grid item xs={3}>
                            <FAutoComplete
                                        url={API_CARRIERS_URL}
                                        name='carrier'
                                        hasError={this.state.errorData.carrier}
                                        helpMessage={this.state.errorMessage.carrier}
                                        label='Authority'
                                        // queryParam='search'
                                        value={this.state.data.carrier}
                                        defaultValue={this.state.data.carrier}
                                        notifyParent={this.updateValues}
                                        disabled={!this.state.isNew}
                                        notifyDependency={this.onCarrierChange}
                                        />
                                    {/* <FSelect
                                        name='carrier'
                                        value={this.state.data.carrier && this.state.data.carrier.id}
                                        hasError={this.state.errorData.carrier}
                                        helpMessage={this.state.errorMessage.carrier}
                                        label='Carrier *'
                                        data={getCarriers(this.props)}
                                        valueRef='prettyName'
                                        keyRef='id'
                                        notifyParent={this.updateValues} /> */}
                                </Grid>
                                <Grid item xs={3}>
                                    <FAutoComplete
                                        url={API_SERVICE_PROVIDER}
                                        queryParam={'companyType=DRUG_TEST_SERVICE_PROVIDER'}
                                        hasCarrier={true}
                                        carrier = {this.state.data.carrier}
                                        name='company'
                                        hasError={this.state.errorData.company}
                                        helpMessage={this.state.errorMessage.company}
                                        label='Drug Test Service Provider'
                                        // queryParam='search'
                                        value={this.state.data.company}
                                        defaultValue={this.state.data.company}
                                        notifyParent={this.updateValues}
                                        // disabled={!this.state.isNew}
                                />
                                    {/* <FSelect
                                        name='company'
                                        value={this.state.data.company && this.state.data.company.id}
                                        hasError={this.state.errorData.company}
                                        helpMessage={this.state.errorMessage.company}
                                        label='Test Service Provider *'
                                        data={getDrugTestCompany(this.props, this.state.data.carrier)}
                                        valueRef='prettyName'
                                        keyRef='id'
                                        notifyParent={this.updateValues} /> */}
                                 </Grid>
                               {/* <Grid item xs={3}>
                                    <FSelect
                                        name='trailer'
                                        value={this.state.data.trailer && this.state.data.trailer.id}
                                        hasError={this.state.errorData.trailer}
                                        helpMessage={this.state.errorMessage.trailer}
                                        label='Trailer'
                                        data={getVehicles(this.props, this.state.data.carrier, 'TRAILER')}
                                        valueRef='prettyName'
                                        keyRef='id'
                                        notifyParent={this.updateValues} />
                                </Grid> */}
                            <Grid item xs={3}>
                                    <FAutoComplete
                                        url={API_DRIVERS_URL}
                                        // queryParam={`carrier=${this.state.data.carrier && this.state.data.carrier.id}`}
                                        hasCarrier={true}
                                        carrier={this.state.data.carrier}
                                        name='driver'
                                        hasError={this.state.errorData.driver}
                                        helpMessage={this.state.errorMessage.driver}
                                        label='Driver'
                                        // queryParam='search'
                                        value={this.state.data.driver}
                                        defaultValue={this.state.data.driver}
                                        notifyParent={this.updateValues}
                                        // disabled={!this.state.isNew}
                                        />
                                    {/* <FSelect
                                        name='driver'
                                        value={this.state.data.driver && this.state.data.driver.id}
                                        hasError={this.state.errorData.driver}
                                        helpMessage={this.state.errorMessage.driver}
                                        label='Driver *'
                                        
                                        data={getDrivers(this.props, this.state.data.carrier)}
                                        valueRef='prettyName'
                                        keyRef='id'
                                        // disabled={!this.state.isNew}
                                        notifyParent={this.updateValues} /> */}
                                </Grid>
                            <Grid item xs>
                                <FSelect
                                    name='drugTestType'
                                    value={this.state.data.drugTestType}
                                    hasError={this.state.errorData.drugTestType}
                                    helpMessage={this.state.errorMessage.drugTestType}
                                    label='Test Type *'
                                    data={this.props.drugTestTypeChoices}
                                    valueRef='prettyName'
                                    keyRef='prettyName'
                                    notifyParent={this.updateValues}
                                />
                            </Grid>
                           
                         
                    </Grid>
                    <Grid container   xs={12}  spacing={4} >

                               <Grid item xs>
                                    <FDate
                                        name='drugTestDate'
                                        required={true}
                                        defaultValue={this.state.data.drugTestDate}
                                        hasError={this.state.errorData.drugTestDate}
                                        helpMessage={this.state.errorMessage.drugTestDate}
                                        label="Test Date "
                                        notifyParent={this.updateValues} />
                               
                                </Grid>
                                <Grid item xs>
                                    <FDate
                                        name='drugTestResultDate'
                                        required={true}
                                        defaultValue={this.state.data.drugTestResultDate}
                                        hasError={this.state.errorData.drugTestResultDate}
                                        helpMessage={this.state.errorMessage.drugTestResultDate}
                                        label="Test Result Date "
                                        notifyParent={this.updateValues} />
                               
                                </Grid>
                           
                            <Grid item xs>

                                  <FAutoComplete
                                        url={API_ACCIDENT_REGISTER}
                                        queryParam={`driver=${this.state.data.driver && this.state.data.driver.id}`}
                                        hasCarrier={true}
                                        carrier={this.state.data.carrier}
                                        name='accidentRegister'
                                        hasError={this.state.errorData.accidentRegister}
                                        helpMessage={this.state.errorMessage.accidentRegister}
                                        label='Accident Register'
                                        // queryParam='search'
                                        value={this.state.data.accidentRegister}
                                        defaultValue={this.state.data.accidentRegister}
                                        notifyParent={this.updateValues}
                                        // disabled={!this.state.isNew}
                                        />

                                    {/* <FSelect
                                        name='accidentRegister'
                                        value={this.state.data.accidentRegister && this.state.data.accidentRegister.id}
                                        hasError={this.state.errorData.accidentRegister}
                                        helpMessage={this.state.errorMessage.accidentRegister}
                                        label='Accident Register'
        
                                        data={getAccidentRegisters(this.props, this.state.data.carrier, this.state.data.driver)}
                                        valueRef='prettyName'
                                        keyRef='id'
                                        // disabled={!this.state.isNew}
                                        notifyParent={this.updateValues} /> */}
                                </Grid>
                            <Grid item xs>
                                <FSelect
                                    name='drugTestResult'
                                    value={this.state.data.drugTestResult}
                                    hasError={this.state.errorData.drugTestResult}
                                    helpMessage={this.state.errorMessage.drugTestResult}
                                    label='Test Result'
                                    data={this.props.drugTestResultChoices}
                                    valueRef='prettyName'
                                    keyRef='prettyName'
                                    notifyParent={this.updateValues}
                                />
                            </Grid>
                            
                           
                    </Grid>     
                   
                    <Grid container  xs={12}  spacing={4}>
                    <Grid item xs={3}>
                               <FInput name='drugTestLocation' required
                                    value={this.state.data.drugTestLocation}
                                    hasError={this.state.errorData.drugTestLocation}
                                    helpMessage={this.state.errorMessage.drugTestLocation}
                                    label="Address" notifyParent={this.updateValues}
                                />
                            </Grid>
                            <Grid item xs>
                                 <FInput
                                    name='desc' value={this.state.data.desc} multiline={true}
                                    value={this.state.data.desc}
                                    hasError={this.state.errorData.desc}
                                    helpMessage={this.state.errorMessage.desc}
                                    label="Description" notifyParent={this.updateValues} />
                            </Grid>
                    </Grid>     
                    
                    {/* <Grid item container xs={8} >
                        <FButton color='primary'
                            label={this.state.isNew ? 'Save' : 'Update'}
                            loading={this.state.loading}
                            onClick={this.onSaveData}
                        />
                        <Grid item style={{ paddingTop: '9px' }}>
                            <FRouteBackLink variant='outlined' color='secondary' className={'backButton'} />
                        </Grid>
                    </Grid> */}
                    <Grid item container xs={12} >
                            {this.getUpdateButton(this.props.profile, SAFETY_AND_COMPLINACE_DRUG_TESTS)}
                    </Grid>
                </Grid >
                
                <Grid item xs className={classes.padding}>
                        {!this.state.isNew &&
                            <DocumentView
                                ownerId={this.props.match.params.id}
                                owner={this.state.data}
                                listUrl={API_DOCUMENTS_URL + `?&drug_test=${this.props.match.params.id}`}
                                ownerType='drug_test'
                                documentChoices={this.props.document_drug_test_choices}
                                policy_id={SAFETY_AND_COMPLINACE_DRUG_TESTS}
                            />}
                    </Grid>
             </Paper>
        );
    };
}


export default withStyles(styles)(connect(
    mapStateToProps, mapDispatchToProps)(drugTestDetail))
