import React, { Component } from 'react'
import ListItemIcon from '@material-ui/core/ListItemIcon';

import { mapStateToProps } from '../../fredux/ActionMaker';
import { connect } from 'react-redux'
import {  has_view_permission} from '../../util/ACL';
import * as CONST from "../../util/Const";
import FListItem from '../widgets/FListItem';


class FinanceListItems extends Component {

    render() {
        const profile = this.props.profile
        return (
            <div>
                    <FListItem  
                    disabled={!has_view_permission(profile, CONST.FINANCE_PAYCHECK)}

                    to={CONST.ROUTE_PAY_CHECK_URL} label='Paycheck'>
                        <ListItemIcon>PCH</ListItemIcon>
                    </FListItem>

                    <FListItem  
                    disabled={!has_view_permission(profile, CONST.FINANCE_INVOICE)}

                    to={CONST.ROUTE_INVOICE_URL} label='Invoice'>
                    <ListItemIcon>INV</ListItemIcon>
            </FListItem>
        </div>
        );
    }
}
export default connect(mapStateToProps)(FinanceListItems)
