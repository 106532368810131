import React from 'react'
import { connect } from 'react-redux'

import { Grid } from "@material-ui/core";
import FCheckBox from './FCheckBox';
import FPermissionPolicyTitle from './FPermissionPolicyTitle';

export default class FPermission extends React.Component {

  constructor(props) {
      super(props)
      const data = props.data
      this.state = {id: data.id, 
                    prettyName: data.prettyName,
                    view: data.view,
                    create: data.create,
                    delete: data.delete, 
                    edit: data.edit}
  }
  
  updateValues = (name, obj) => {
    this.setState(prevState => {
      let newState = prevState
       newState[name] = obj[name]
      return newState;
    }, ()=>{
       this.props.onRowEdit(this.state)
    })
  }
  

  render() {

    return(
      <Grid container xs={12} justify='center' alignContent='center' >
      <Grid item xs={4}> 
         
         <FPermissionPolicyTitle policy_id={this.state.id}/>
    
      </Grid>    
       
      <Grid item xs>
       
         <FCheckBox
              name='view'
              value={this.state.view}
              label="View"
              notifyParent={this.updateValues} />
      </Grid>

      <Grid item xs>
         <FCheckBox
              name='create'
              value={this.state.create}
              label="Create"
              notifyParent={this.updateValues} />
      </Grid>
      <Grid item xs>
         <FCheckBox
              name='edit'
              value={this.state.edit}
              label="Modify / Change"
              notifyParent={this.updateValues} />
      </Grid>
      <Grid item xs>
         <FCheckBox
              name='delete'
              value={this.state.delete}
              label="Delete / Disable"
              notifyParent={this.updateValues} />
      </Grid>

      
</Grid>
    )
  }
}

// export default connect(
//   mapStateToProps, mapDispatchToProps)(FPermission)