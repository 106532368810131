import React from 'react'

import Fleets from './Fleets'
import Expenses from './Expenses'

import { Grid} from "@material-ui/core"
import PaycheckSummary from './Summary';
import PayCheckFilterView from './PayCheckFilterView';
import { API_PAYCHECK_FILTER_URL, API_PAY_CHECK_URL, CREATE_INVOICE_MSG, CREATE_PAYCHEK_MSG, PAYCHECK_TYPE_PAYMENT } from '../../util/Const';
import { showSuccessNotification, showErroNotification } from '../widgets/FMessage';
import { GET, UPDATE, GET_RESULT } from '../../util/FAPI';
import { getPrettyDate} from '../../util';
import FButton from '../widgets/FButton';
import { pdfPayCheckPDF } from '../../util/pdf/FPDF';
import { connect } from 'react-redux'
import { mapDispatchToProps, mapStateToProps } from '../../fredux/ActionMaker';

const initState = {
  totalCalcReady: 0,
  net: 0,
  payCheckID: '',

  paycheckFilter: {
    payCheckExpenseCarrierFee: 0,
    payCheckFleetGrossPayment: 0, 
  },
  payCheckFleets: [],
  payCheckExpenses: [],
  payCheckTotal: {
    payCheckFleetTotalExpense:0,
   
  },
}

class PaycheckDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = initState
  }

  getFormData = () => {
    let formData = new FormData();
    formData.append('payCheckID', this.state.payCheckID)
    formData.append('driver', this.state.paycheckFilter.driver.id)
    formData.append('carrier', this.state.paycheckFilter.carrier.id)
    formData.append('vehicle', this.state.paycheckFilter.vehicle.id)
    formData.append('payCheckType', PAYCHECK_TYPE_PAYMENT)
    formData.append('payCheckFleetStartDate', this.state.paycheckFilter.payCheckFleetStartDate)
    formData.append('payCheckFleetEndDate', this.state.paycheckFilter.payCheckFleetEndDate)
    formData.append('payCheckExpenseStartDate', this.state.paycheckFilter.payCheckExpenseStartDate)
    formData.append('payCheckExpenseEndDate', this.state.paycheckFilter.payCheckExpenseEndDate)
    formData.append('payCheckExpenseCarrierFee', this.state.paycheckFilter.payCheckExpenseCarrierFee)
    formData.append('payCheckFleetGrossPayment', this.state.payCheckTotal.gross)
    formData.append('payCheckFleetTotalExpense', this.state.payCheckTotal.fuelCharge)
    formData.append('payCheckNet',  this.state.payCheckTotal.net)


    return formData
}
  getPaycheckSummary = () => {
    
    let summary = {  driverDetailInfo: this.state.driver,
                     profileSummary:  this.props.profile, 
                     payCheckID: this.state.payCheckID,
                   }
    
    return Object.assign(summary, this.state.paycheckFilter, this.state.payCheckTotal, this.state.carrier);
  }

  savePaycheck = () => {
    let data = this.getFormData()

    let pdfFile = pdfPayCheckPDF(
        this.state.payCheckFleets, 
        this.state.payCheckExpenses, 
        this.getPaycheckSummary()
        )


    data.append("payCheckFile" , pdfFile.output('blob'));
    this.setState({ loading: true })
    UPDATE(API_PAY_CHECK_URL, 'post', data).
      then(response => {
        this.setState({ loading: false })
        showSuccessNotification(response, CREATE_PAYCHEK_MSG)
        //this.props.updateServerSuccess({ msg: getSuccessMessage(response) })
      }).catch(error => {
        showErroNotification(error, CREATE_PAYCHEK_MSG)
        this.setState({ loading: false })
        // this.props.updateServerError({ msg: getErrroMessage(error) })
      });

  }

  
  getPaycheckFilter = () => {
    let url = `?carrier=${this.state.paycheckFilter.carrier.id}&`
        url += `driver=${this.state.paycheckFilter.driver.id}&`
        url += `vehicle=${this.state.paycheckFilter.vehicle.id}&`
        url += `status=${'COMPLETE'}&`
        url += `expenseIsReimbursable=${true}&`
        url += `expenseIsDeductable=${true}&`
        url += `payCheckDriverCut=${this.state.paycheckFilter.payCheckDriverCut}&`
        url += `payCheckPaymentStrategy=${this.state.paycheckFilter.payCheckPaymentStrategy}&`
        url += `carrierFee=${this.state.paycheckFilter.payCheckExpenseCarrierFee}&`
        url += `expensePayCheckTransactionDate__gte=${getPrettyDate(this.state.paycheckFilter.payCheckExpenseStartDate)}&`
        url += `expensePayCheckTransactionDate__lte=${getPrettyDate(this.state.paycheckFilter.payCheckExpenseEndDate)}&`
        url += `fleetPayCheckProcessingDate__gte=${getPrettyDate(this.state.paycheckFilter.payCheckFleetStartDate)}&`
        url += `fleetPayCheckProcessingDate__lte=${getPrettyDate(this.state.paycheckFilter.payCheckFleetEndDate)}`
    return url
      }

  fetchFilter = (paycheckFilter) => {
    this.setState(s => {
      return { ...initState, paycheckFilter: paycheckFilter }
    }, () => {
      GET(API_PAYCHECK_FILTER_URL + this.getPaycheckFilter()).then(res => {
        let data =  GET_RESULT(res)
        this.setState(prevState => {
          let newState = { ...prevState, ...data }
          return newState
        }, () => {
          // this.calcFleetTotals()
        }, this)
      }).catch(error => {
        // this.props.updateServerError({ msg: getErrroMessage(error) })
      })
    })
  }

  render() {
    return (
      <React.Fragment>
        <Grid container xs={12} spacing={4}>
          <PayCheckFilterView notifyParent={this.fetchFilter} />
          <Grid item xs>
            <Fleets data={this.state.payCheckFleets} />
          </Grid>
          <Grid item xs={8}>
            <Expenses data={this.state.payCheckExpenses} profile={this.props.profile}/>
          </Grid>
          <Grid item xs={4}>
            <PaycheckSummary data={this.state.payCheckTotal} />
          </Grid>
        </Grid>
        <Grid item xs >
          <FButton color='primary'
            label='Save paycheck'
            variant='contained'
            loading={this.state.loading}
            onClick={this.savePaycheck}
          />
        </Grid>
        </React.Fragment>
    )
  }
}


export default connect(
  mapStateToProps, mapDispatchToProps)(PaycheckDetail)
