import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles'


const style = theme => ({
  root: {
    fontSize: 'medium'
  }
});

class FLabel extends Component {
  
  render() {
    const { classes, color, fontSize } = this.props
    let style = {color: color, fontSize: fontSize || 'medium'}

    return (
      <React.Fragment>
        <span className={classes.root} style={style}>{this.props.label} </span>
      </React.Fragment>);
  }
}

export default withStyles(style)(FLabel)