import React from 'react';
import FRouterLink, { FRouterAddLink } from '../widgets/FRouterLink';
import { ROUTE_DISPATCHER_URL, API_DISPATCHERS_URL, ROUTE_CARRIERS_URL, TAB_ADMIN, ADMIN_DISPATCHERS, CHOICE_DISPATCHER } from '../../util/Const';
import FListComponent from '../../util/FListComponent';
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import { connect } from "react-redux"
import FMaterialTable from '../widgets/FMaterialTable';
import FTableAction, { ACTION_TABLE_DELETE_ROW, ACTION_TABLE_DEACTIVATE, ACTION_TABLE_ACTIVATE } from '../widgets/FTableAction';
import { getStateIcon } from '../../util/icon_util';
import BlockIcon from '@material-ui/icons/Block';


class Dispatchers extends FListComponent {

  constructor(props) {
    super(props, API_DISPATCHERS_URL, CHOICE_DISPATCHER)
    this.currentTitle = 'Dispatchers'
    this.currentTab = TAB_ADMIN
    this.routeUrl = ROUTE_DISPATCHER_URL
    this.policyID = ADMIN_DISPATCHERS
 
  }
  
  // getActions = (rowData) => {
  //   let actions = []
  //   rowData.state == 'ACTIVE'? actions.push(ACTION_TABLE_DEACTIVATE): 
  //         actions.push(ACTION_TABLE_ACTIVATE)
  //   actions.push(ACTION_TABLE_DELETE_ROW)
  //   return actions
  // }

  render() {
    return (
      <div>

        <FMaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: 'Dispatcher #', 
              render: this.getSelfDispatcherLink
            },
            // {
            //   title: 'Dispatcher #', 
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FRouterLink editIcon={true} label={rowData.dispatcherAccessID} to={ROUTE_DISPATCHER_URL + rowData.id} />
            //       </React.Fragment>
            //     )
            //   }
            // },
           
            // { title: 'Name', field: 'prettyName' },
            // {
            //   title: 'Name', 
            //   field: 'prettyName',
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FRouterLink label={rowData.prettyName} to={ROUTE_DISPATCHER_URL + rowData.id} />
            //       </React.Fragment>
            //     )
            //   }
            // },
            // {
            //   title: 'Carrier', 
            //   field: 'carrier.prettyName',
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FRouterLink  label={rowData.carrier.prettyName} 
            //         to={ROUTE_CARRIERS_URL + rowData.carrier.id} />
            //       </React.Fragment>
            //     )
            //   }
            // },
            {
              title: 'Carrier', 
              render: this.getCarrierLink
            },
            { title: 'Phone', field: 'dispatcherPhoneNumber' },
            {
              title: 'Email', 
              render: rowData => {
                return (
                  <React.Fragment>
                      {rowData.dispatcherEmail.toLowerCase()}
                  </React.Fragment>
                )
              }
            },
            // { title: 'UserName', field: 'username' },
            // { title: 'Dispatcher ID', field: 'dispatcherAccessID' },
            {
              title: 'State', 
              render: rowData => {
                return (
                  <React.Fragment>
                      {getStateIcon(rowData)}
                  </React.Fragment>
                )
              }
            },
            {
              title: 'Actions', 
              render: rowData => {
                let actions  = this.getActions(rowData, ADMIN_DISPATCHERS)
                return (
                  <React.Fragment>
                    {!!actions.length && <FTableAction 
                        actions={actions}
                        onTableAction = {this.onTableAction}
                        rowData={rowData}
                         />
                    }
                    {!!!actions.length && <BlockIcon color="disabled" />}
                  </React.Fragment>
                )
              }
            },

          ]}


          actions={
            [
              this.getAddNewModelActions()
            ]}
          options = {{
              pageSize: this.state.pageSize,
              pageSizeOptions: this.state.pageSizeOptions,
              actionsColumnIndex: -1
           }}
          data={this.fetchData}
          title="Dispatchers"
          listErrCode={this.state.listErrCode}
        />

      </div>
    );
  }
}

export default connect(
  mapStateToProps, mapDispatchToProps)(Dispatchers)
