import React from 'react'
import { Grid } from "@material-ui/core";
import { withStyles } from '@material-ui/core';
import chrome from '../images/chrome.jpeg';
import firefox from '../images/firefox.jpeg'
import ZLogo from './ZLogo';


const style = theme => ({
  header1: {
    margin: theme.spacing(1),
    fontSize: '30px',
    paddingBottom: 10,
    textAlign: 'center',

  },
  header2: {
    margin: theme.spacing(1),
    fontSize: '13px',
    textAlign: 'center',
    paddingBottom: 10,
    letterSpacing: 2
  },

  chrome:  {
    marginTop: 10,
    width: 150,
    height: 100
  }
});

/**
 * Page to display for unsupported browser size
 */
 class NotSupportedBrowser extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <div>
          <Grid container direction='column' spacing={12} justifyContent="center" alignItems="center">
              <Grid item xs>
              <Grid item xs justifyContent="center" alignItems="center"> 
              <ZLogo/>
              </Grid>
                <p className={classes.header1}> <b>Unsupported browser</b></p>
                <p className={classes.header2}> You are using a webbrowser we don't support yet. 
                     <br/> <br/> Please use latest version of  one of these options for a smooth experience!</p>
                 <Grid item container justifyContent="center" alignItems="center" spacing={4}> 
                    <Grid item xs> <img src={chrome} alt="Chrome" /></Grid>
                    <Grid item xs> <img src={firefox}  alt="Chrome" /></Grid>
                 </Grid>
                
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withStyles(style)(NotSupportedBrowser)