import React from 'react'
import { TAB_PROFILE } from '../../util/Const';
import { mapDispatchToProps, mapStateToProps } from "../../fredux/ActionMaker";

import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import FGroupBox from "../widgets/FGroupBox";



const styles = theme => ({
  padding: {
      marginTop: theme.spacing(4),
  },
  helpTitle: {
    color:'green',
    fontSize: '1.2rem'
  },
  videoGrid: {
    marginLeft: theme.spacing(1) ,
  },
});


class HelpView extends React.Component {
  
  componentDidMount() {
    this.props.updateCurrentTitle && this.props.updateCurrentTitle('Help')
    this.props.updateCurrentTab(TAB_PROFILE)
  }

  getVideoGrid = (url, title, xs) =>{
    const { classes } = this.props;
    return (
      <Grid item xs={xs || 3} className={classes.videoGrid}>
         <div className={classes.videoGrid}>
            <iframe width="500" height="340"  frameborder="0" allowfullscreen="allowfullscreen"
              src={url}>
              </iframe>
              <span className={classes.videoGrid}>&nbsp;</span>

            <span className={classes.helpTitle} > {title} </span>
        </div>
      </Grid>
    )

  }
  getVideoGridVideoTag = (url, title, xs) =>{
    const { classes } = this.props;
    return (
      <Grid item xs={xs || 3} className={classes.videoGrid}>
         <div >
            <video width="100%" height="100%" controls>
                <source src={url} type="video/youtube" />
            </video>
            <span className={classes.helpTitle} > {title} </span>
        </div>
      </Grid>
    )

  }

  render() {
    const { classes } = this.props;

    return (
      <div>
          <Grid container  direction='column' className={classes.root} spacing= {2} xs={12} >
               <FGroupBox groupTitle={"Transforming Your Business: How Our Software Can Streamline Your Operations and Boost Your Bottom Line"}/ >
                  <Grid container className={classes.root} spacing= {2} xs={12} >
                      {
                        this.getVideoGrid(
                            "https://youtube.com/embed/wXOHcRlMd7Y?autoplay=0&rel=0", 
                            "Introduction to ZTrucking: Safety & Compliance empowered all-in-one carrier management software",
                            4
                            )
                      }
                 </Grid>
                 <br/>
                 <FGroupBox groupTitle={"A Step-by-Step Guide to Navigating Our User-Friendly Interface and Maximizing Your Experience"}/ >
                  <Grid container className={classes.root} spacing= {2} xs={12} >
                     {
                        this.getVideoGrid(
                            "https://youtube.com/embed/FFBZzrSgvM8?rel=0", 
                            "UI-101: Understanding Menus, Submenus, and How to Use Your Interface" 
                            )
                      } 
                       <Grid item xs={1}/>

                      {
                        this.getVideoGrid(
                            "https://youtube.com/embed/fi_wivgfp3U?rel=0",
                            "Maximizing Your Experience: Navigating and Interacting with Our Platform's UI" 
                            )
                      }
                      <Grid item xs={1}/>
                      {
                        this.getVideoGrid(
                            "https://youtube.com/embed/BISSokb8iek?rel=0", 
                            "How to Use and Analyze Dashboards and Graphs in Ztrucking" 
                            )
                      }               

                    
                 </Grid>
                 <br/>
                 <FGroupBox groupTitle={"Effortlessly Import and Export Data with Our Software: A Step-by-Step Guide"}/ >
                  <Grid container className={classes.root} spacing= {2} xs={12} >
                      {
                        this.getVideoGrid(
                            "https://youtube.com/embed/mVgq_5Hp16E?rel=0", 
                            "How to Use Reporting Tools for Effective Asset and Record Management" 
                            )
                      }
                       <Grid item xs={1}/>
                      {
                        this.getVideoGrid(
                            "https://youtube.com/embed/6bqGVwkL_Q4?rel=0", 
                            "Streamline Your Data Entry with Our Importing Tools: How to Import Fuel Transactions" 
                            )
                      }
                 </Grid>
          </Grid>
      </div>
    )
  }
}


export default withStyles(styles)(connect(
    mapStateToProps, mapDispatchToProps)(HelpView))
