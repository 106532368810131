import React, { Component } from 'react';
import FRouterLink, { FRouterAddLink } from '../widgets/FRouterLink';
import { ROUTE_CARRIERS_URL,
         ROUTE_ACCIDENT_REGISTER_URL, ROUTE_DRIVER_URL, API_DRUG_TEST, ROUTE_DRUG_TEST_URL, ROUTE_COMPANY_URL, TAB_SAFETY_AND_COMPLIANCE, SAFETY_AND_COMPLINACE_DRUG_TESTS, CHOICE_DRUG_TEST } from '../../util/Const';
import FListComponent from '../../util/FListComponent';
import { connect } from "react-redux"
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import { isProfileDispatcher } from '../../util/ACL';

import FMaterialTable from '../widgets/FMaterialTable';
import FTableAction, { ACTION_TABLE_DELETE_ROW} from '../widgets/FTableAction';
import { getDrugTestResultIcon } from '../../util/icon_util';
import BlockIcon from '@material-ui/icons/Block';

class DrugTests extends FListComponent {
  constructor(props) {
    super(props, API_DRUG_TEST, CHOICE_DRUG_TEST)
    this.currentTitle = 'Drug Tests'
    this.currentTab = TAB_SAFETY_AND_COMPLIANCE
    this.routeUrl = ROUTE_DRUG_TEST_URL
    this.policyID = SAFETY_AND_COMPLINACE_DRUG_TESTS
    this.includeStateAction = false
  }

  
  // getActions = (rowData) => {
  //   let actions = []
  //   // rowData.state == 'ACTIVE'? actions.push(ACTION_TABLE_DEACTIVATE): 
  //   //       actions.push(ACTION_TABLE_ACTIVATE)
  //   actions.push(ACTION_TABLE_DELETE_ROW)
  //   return actions
  // }

  render() {
    return (
      <div>
   
        <FMaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: 'Test Type  ', 
              field: 'drugTestType',
              render: rowData => {
                return (
                  <React.Fragment>
                    <FRouterLink editIcon={true} label={rowData.drugTestType} to={ROUTE_DRUG_TEST_URL + rowData.id} />
                  </React.Fragment>
                )
              }
            },
            {
              title: 'Carrier', 
              render: this.getCarrierLink
            },
            // {
            //   title: 'Driver', field: 'driver.prettyName',
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FRouterLink label={rowData.driver.prettyName} 
            //            to={ROUTE_DRIVER_URL + rowData.driver.id}
            //            disabled={isProfileDispatcher(this.props.profile)}/>
            //       </React.Fragment>
            //     )
            //   }
            // },
            {
              title: 'Driver', 
              render: this.getDriverLink
            },
            { title: 'Drug Test Type', field: 'drugTestType' },

            { title: 'Test Date', field: 'drugTestDate', 
                     render: rowData => this.getPrettyDate(rowData.drugTestDate) },
            
            { title: 'Result Date', field: 'drugTestResultDate', 
                     render: rowData => this.getPrettyDate(rowData.drugTestResultDate) },
            // { title: 'Test Location', field: 'drugTestLocation' },
            // {
            //   title: 'Carrier', field: 'driver.prettyName',
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FRouterLink label={rowData.carrier.prettyName} 
            //            to={ROUTE_CARRIERS_URL + rowData.carrier.id}
            //            disabled={isProfileDispatcher(this.props.profile)}/>
            //       </React.Fragment>
            //     )
            //   }
            // },
            
            {
              title: 'Drug Test Company', 
              render: this.getCompanyLink
            },
            // {
            //   title: 'Company', field: 'company.prettyName',
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FRouterLink label={rowData.company.prettyName} 
            //            to={ROUTE_COMPANY_URL + rowData.company.id}
            //            disabled={isProfileDispatcher(this.props.profile)}/>
            //       </React.Fragment>
            //     )
            //   }
            // },
            {
              title: 'Accident Register', field: 'accidentRegister.prettyName',
              render: rowData => {
                if (!rowData.accidentRegister){
                  return 'N/A'
                }
                return (
                  <React.Fragment>
                    <FRouterLink label={rowData.accidentRegister.prettyName} 
                       to={ROUTE_ACCIDENT_REGISTER_URL + rowData.accidentRegister.id}
                       disabled={isProfileDispatcher(this.props.profile)}/>
                  </React.Fragment>
                )
              }
            },
            { title: 'Result', field: 'drugTestResult', 
                   render: rowData => getDrugTestResultIcon(rowData) },

            // {
            //   title: 'Actions', 
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FTableAction 
            //             actions={this.getActions(rowData)}
            //             onTableAction = {this.onTableAction}
            //             rowData={rowData}
            //              />
            //       </React.Fragment>
            //     )
            //   }
            // },
            {
              title: 'Actions', 
              render: rowData => {
                let actions  = this.getActions(rowData, SAFETY_AND_COMPLINACE_DRUG_TESTS)

                return (
                  <React.Fragment>
                    {!!actions.length && <FTableAction 
                        actions={actions}
                        onTableAction = {this.onTableAction}
                        rowData={rowData}
                         />
                    }
                    {!!!actions.length && <BlockIcon color="disabled" />}
                  </React.Fragment>
                )
              }
            },
          ]}
          actions={
            [
              // {
              //   icon: () => (
              //     <FRouterAddLink to={ROUTE_DRUG_TEST_URL+ 'add'} color='secondary' />
              //   ),
              //   isFreeAction: true
              // },
              this.getAddNewModelActions()
    
            ]}
          options = {{
              pageSize: this.state.pageSize,
              pageSizeOptions: this.state.pageSizeOptions,
              actionsColumnIndex: -1
           }}
          data={this.fetchData}
          title="Drug Test"
          listErrCode={this.state.listErrCode}
        />

      </div>
    );
  }
}

export default connect(
  mapStateToProps, mapDispatchToProps)(DrugTests)
