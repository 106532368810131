import React from "react";
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";


import { Grid } from "@material-ui/core";
import FSelect from "../widgets/FSelect";
import FMessage from "../widgets/FMessage";
import FInput from "../widgets/FInput";
import { mapDispatchToProps, mapStateToProps } from "../../fredux/ActionMaker";
import {CHOICE_TRIP, API_DRIVERS_URL, API_CARRIERS_URL, API_VEHICLES_URL, TAB_FLEET, API_TRIPS_URL, FLEET_TRIPS } from "../../util/Const";
import {VALIDATE_OBJECT_REQUIRED, VALIDATE_CARRIER_REQUIRED, VALIDATE_REQUIRED, VALIDATE_ZERO_OR_POSITIVE } from "../../util/ValidatorConst";
import FDetailComponent from "../../util/FDetailComponent";
import FDate from "../widgets/FDate";
import { isValid } from "../../util/Validator";
import FAutoComplete from "../widgets/FAutoComplete";
import { COUNTRY_STATES_NAMES } from "../../fredux/Const";
import { getFloor } from "../../util";

const styles = theme => ({
    root: {
        display: "flex",

    },
    padding: {
        marginTop: theme.spacing(1) ,
    },

    header: {
        background: '#f5f5f5'
    },
    button: {
        marginRight: theme.spacing(2),
    },
    root1: {
        backgroundColor: '#fff',
        padding: theme.spacing(2),
    },
});

const PROPS = {
    errorData: {
    },
    errorMessage: {
        tripSourceId: 'Invalid ID',
        tripDate: 'Invalid Trip Date',
        tripDistance: 'Invalid Distance / Odometer difference',
        tripOdometerStart: 'Invalid Odometer Reading',
        tripOdometerEnd: 'Invalid Odometer Reading',
        company: 'No Service Provider Selected/Found  Under Carrier',
        driver: 'No Driver Selected/Found  Under Carrier',
        vehicle: 'No Vehicle Selected/Found  Under Carrier',
        tripJurisdiction: 'Invalid Jurisdiction',
        carrier: 'Invalid Carrier'
    },
    touched: {
    },
    data: {
        tripSourceId: '',
        tripDate: '',
        tripDistance: '',
        tripOdometerStart: '',
        tripOdometerEnd: '',
        tripJurisdiction: '',
        company: {},
        driver: {},
        vehicle: {},
        carrier: {}
    },
    validators: {
        tripSourceId: [],
        tripDate: [VALIDATE_REQUIRED],
        tripDistance: [VALIDATE_REQUIRED],
        tripOdometerStart: [VALIDATE_REQUIRED],
        tripOdometerEnd: [VALIDATE_REQUIRED],
        company: [VALIDATE_OBJECT_REQUIRED],
        driver: [VALIDATE_OBJECT_REQUIRED],
        vehicle: [VALIDATE_OBJECT_REQUIRED],
        carrier: [VALIDATE_CARRIER_REQUIRED],
        tripJurisdiction: [VALIDATE_REQUIRED]
    }
}



class TripDetail extends FDetailComponent {
    constructor(props) {
        super(props, API_TRIPS_URL, CHOICE_TRIP, PROPS)
    }
  
    getErrorData = () => {
        let errorData = {
            tripSourceId: !isValid(this.state.data.tripSourceId, this.state.validators.tripSourceId),

            tripDistance: !this.isValidDistance() || !isValid(this.state.data.tripDistance, this.state.validators.tripDistance),
            tripDate: !isValid(this.state.data.tripDate, this.state.validators.tripDate),
            tripOdometerStart: !this.isValidDistance() || !isValid(this.state.data.tripOdometerStart, this.state.validators.tripOdometerStart),
            tripOdometerEnd: !this.isValidDistance() || !isValid(this.state.data.tripOdometerEnd, this.state.validators.tripOdometerEnd),

            tripJurisdiction: !isValid(this.state.data.tripJurisdiction, this.state.validators.tripJurisdiction),

            driver: !isValid(this.state.data.driver, this.state.validators.driver),
            vehicle: !isValid(this.state.data.vehicle, this.state.validators.vehicle),
            carrier: !isValid(this.state.data.carrier, this.state.validators.carrier)
        }
        return errorData
    }
    
    isValidDistance = () => {
        if (!this.state.data.tripDistance) {
            return true;
        }
        if (!this.state.data.tripOdometerStart || !this.state.data.tripOdometerEnd ) {
            return true;
        }
        let odometerDifference =  this.state.data.tripOdometerEnd -  this.state.data.tripOdometerStart
        return  (getFloor(odometerDifference) >= getFloor(this.state.data.tripDistance))
       
     }


    onCarrierChange = () => {
        this.updateValues('driver', {driver: ''})
        this.updateValues('vehicle', {vehicle: ''})
    }
    
    getCurrentTitle = () => {
        let title = `${this.state.data.driver && this.state.data.driver.prettyName && this.state.data.driver.prettyName}
                     - ${this.state.data.tripJurisdiction} (${this.state.data.tripDistance})`
        // let title = (this.state.data.driver && this.state.data.driver.prettyName &&
        //             this.state.data.driver.prettyName + ' -  ' + 
        //             this.state.data.tripJurisdiction  
        //         )  || ''
        title = 'Trip: ' +  title
        this.props.updateCurrentTab(TAB_FLEET)
        this.props.updateCurrentTitle && this.props.updateCurrentTitle(title)
        return title
    }


    render() {
        const { classes } = this.props;
        this.getCurrentTitle()

        return (
            <React.Fragment>
                <Grid container direction='column' className={classes.root1} alignItems='stretch' spacing={4} >
                    <Grid item xs>
                        <FMessage error={this.props.crudServerError}
                            success={this.props.crudServerSuccess} />
                    </Grid>
                    <Grid container className={classes.root} spacing= {6} xs={12} >
                            <Grid item xs={3}>
                                <FAutoComplete
                                            url={API_CARRIERS_URL}
                                            name='carrier'
                                            hasError={this.state.errorData.carrier}
                                            helpMessage={this.state.errorMessage.carrier}
                                            label='Authority'
                                            // queryParam='search'
                                            value={this.state.data.carrier}
                                            defaultValue={this.state.data.carrier}
                                            notifyParent={this.updateValues}
                                            disabled={!this.state.isNew}
                                            notifyDependency={this.onCarrierChange}
                                            />
                            </Grid>
                            <Grid item xs={3}>
                                <FAutoComplete
                                    url={API_DRIVERS_URL}
                                    // queryParam={`carrier=${this.state.data.carrier && this.state.data.carrier.id}`}
                                    hasCarrier={true}
                                    carrier={this.state.data.carrier}
                                    name='driver'
                                    hasError={this.state.errorData.driver}
                                    helpMessage={this.state.errorMessage.driver}
                                    label='Driver'
                                    // queryParam='search'
                                    value={this.state.data.driver}
                                    defaultValue={this.state.data.driver}
                                    notifyParent={this.updateValues}
                                    // disabled={!this.state.isNew}
                                    />
                                   
                                </Grid>
                                <Grid item xs>
                                    <FAutoComplete
                                        url={API_VEHICLES_URL}
                                        queryParam={'vehicleType=TRUCK'}
                                        hasCarrier={true}
                                        carrier = {this.state.data.carrier}
                                        name='vehicle'
                                        hasError={this.state.errorData.vehicle}
                                        helpMessage={this.state.errorMessage.vehicle}
                                        label='Truck'
                                        // queryParam='search'
                                        value={this.state.data.vehicle}
                                        defaultValue={this.state.data.vehicle}
                                        notifyParent={this.updateValues}
                                        // disabled={!this.state.isNew}
                                        />
                            </Grid> 
                            
                    </Grid>
                    <Grid container className={classes.padding} spacing= {6} xs={12} >
                                <Grid item xs={3}>
                                    <FDate
                                        name='tripDate'
                                        required={true}
                                        defaultValue={this.state.data.tripDate}
                                        hasError={this.state.errorData.tripDate}
                                        helpMessage={this.state.errorMessage.tripDate}
                                        label="Trip Date "
                                        notifyParent={this.updateValues} />
                                        
                                </Grid>
                                <Grid item xs={3}>
                                    <FInput name='tripDistance' required
                                            type='number'
                                            value={this.state.data.tripDistance}
                                            hasError={this.state.errorData.tripDistance}
                                            helpMessage={this.state.errorMessage.tripDistance}
                                            label="Distance (Mi)"
                                            notifyParent={this.updateValues}
                                        />
                                </Grid>
                                <Grid item xs={3} >
                                        <FSelect
                                            name='tripJurisdiction' required
                                            value={this.state.data.tripJurisdiction}
                                            hasError={this.state.errorData.tripJurisdiction}
                                            helpMessage={this.state.errorMessage.tripJurisdiction}
                                            label={'Jurisdiction'}
                                            data={COUNTRY_STATES_NAMES}
                                            valueRef='prettyName'
                                            keyRef='prettyName'
                                            notifyParent={this.updateValues}
                                        />
                            </Grid>
                    </Grid>
                    <Grid container className={classes.padding} spacing= {6} xs={12} >
                                <Grid item xs={3}>
                                    <FInput 
                                            name='tripSourceId'
                                            value={this.state.data.tripSourceId}
                                            hasError={this.state.errorData.tripSourceId}
                                            helpMessage={this.state.errorMessage.tripSourceId}
                                            label="Trip  ID" notifyParent={this.updateValues}
                                    />
                                        
                                </Grid>
                                <Grid item xs={3}>
                                    <FInput 
                                            name='tripOdometerStart'
                                            value={this.state.data.tripOdometerStart}
                                            hasError={this.state.errorData.tripOdometerStart}
                                            helpMessage={this.state.errorMessage.tripOdometerStart}
                                            label="Odometer (Start)" notifyParent={this.updateValues}
                                    />   
                                </Grid>
                                <Grid item xs={3} >
                                    <FInput 
                                            name='tripOdometerEnd' 
                                            value={this.state.data.tripOdometerEnd}
                                            hasError={this.state.errorData.tripOdometerEnd}
                                            helpMessage={this.state.errorMessage.tripOdometerEnd}
                                            label="Odometer (End)" notifyParent={this.updateValues}
                                    />   
                            </Grid>
                    </Grid>
                    
                    <Grid item container xs={8} >
                            {this.getUpdateButton(this.props.profile, FLEET_TRIPS)}
                    </Grid>
                    {/* <Grid item container xs={8} >
                        <FButton color='primary'
                            label={this.state.isNew ? 'Save' : 'Update'}
                            loading={this.state.loading}
                            onClick={this.onSaveData}
                        />
                        <Grid item style={{ paddingTop: '9px' }}>
                            <FRouteBackLink variant='outlined' color='secondary' className={'backButton'} />
                        </Grid>
                    </Grid> */}
                    {/* <Grid item xs className={classes.padding}>
                        {!this.state.isNew &&
                            <DocumentView
                                ownerId={this.props.match.params.id}
                                owner={this.state.data}
                                listUrl={API_DOCUMENTS_URL + `?&fuel_card=${this.props.match.params.id}`}
                                ownerType='fuel_card'
                                documentChoices={this.props.document_fuel_card_choices}
                            />}
                    </Grid> */}
                </Grid >
             </React.Fragment>
        );
    };
}


export default withStyles(styles)(connect(
    mapStateToProps, mapDispatchToProps)(TripDetail))
