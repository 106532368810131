import React from 'react';
import { getPrettyDate } from '../../util';
import { FMaterialSimpleTable } from '../widgets/FMaterialTable';


class Advances extends React.Component {

  getExpenseFor = (rowData) => {
   let expenseFor = rowData.expenseFor
   return expenseFor
  }
  render() {
    return (
      <div>
        <FMaterialSimpleTable
          columns={[
            { title: 'Card #', field: 'fuelCardSourceID' },
            { title: 'Transaction Id', field: 'fuelExpenseSourceTransactionID' },
            { title: 'Transaction Date', render: rowData => getPrettyDate(rowData.fuelExpenseTransactionDate) },
            { title: 'Advance', field: 'fuelExpenseFuelUnitPrice' },
            { title: 'Fee', field: 'fuelExpenseTransactionFee' },
            { title: 'Total', field: 'fuelExpenseFuelTotalAmount' },
            // { title: 'Advance', field: 'fuelCashAdvance' },
            // { title: 'Advance Fee', field: 'fuelCashAdvanceFee' },
            // { title: 'Advance Total', field: 'fuelCashAdvanceTotal' },
                      
          ]}
          // options = {{
          //   pageSize: SMALL_PAGE_SIZE,
          //   pageSizeOptions: SMALL_PAGE_SIZE_OPTIONS,
          //   search: true
          // }}
           data={this.props.data}
          title={`Advances & Out of Network Fees`}
        />
      </div>
    );
  }
}

export default Advances;