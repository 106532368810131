import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Paper } from 'material-ui';
import { roundDecimal } from '../../util';

const styles = theme => ({
  root: {
    margin: theme.spacing(1),
  },
  key: {
    margin: theme.spacing(1),    
  },
  val: {
    margin: theme.spacing(2),
  }
});

const getTotalLists = function (key, val, classes) {
  return (
    <Grid container xs={12} spacing={24} classname={classes.root}>
      <Grid item xs={8} >
        <Typography variant="h7" gutterBottom className={classes.key}>
          {key}
        </Typography>
      </Grid>
      <Grid item xs >
        <Typography variant="h7" gutterBottom className={classes.val}>
          {val}
        </Typography>
      </Grid>
       <hr/>
    </Grid>)
}

const getNet = function (key, val, classes) {
  return (
    <Grid container xs={12} spacing={24} classname={classes.root}>
      <Grid item xs={8} >
        <Typography variant="h6" gutterBottom className={classes.key}>
          {key}
        </Typography>
      </Grid>
      <Grid item xs >
        <Typography variant="h6" gutterBottom className={classes.val}>
          {val}
        </Typography>
      </Grid>
       <hr/>
    </Grid>)
}


class InvoiceSummary extends React.Component {

  render() {
    const { classes } = this.props;

    let invoiceNetDisplay = `Net `
    return (
      <React.Fragment>
        <Paper>

          <Grid container justify="flex-start">
            <Typography variant="h5" gutterBottom className={classes.root}>
              Totals
            </Typography>
          </Grid>
          <hr />
          <Grid container xs={12} spacing={24} direction='column'>
          {getTotalLists('Gross', roundDecimal(this.props.data.gross), classes)}
          {getTotalLists('Broker Fee', roundDecimal(this.props.data.brokerOrCarrierFee), classes)}
          {getTotalLists('Total Expense', roundDecimal(this.props.data.toalPayCheckExpense), classes)} 

          {getNet(invoiceNetDisplay, roundDecimal(this.props.data.net), classes)}
          </Grid>
        </Paper>
        
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(InvoiceSummary);