import React from 'react'
import { connect } from 'react-redux'

import { Grid } from "@material-ui/core";
import Documents from './Documents';
import FDialog from '../widgets/FDialog';
import { mapDispatchToProps, mapStateToProps } from '../../fredux/ActionMaker';
import FSelect from "../widgets/FSelect";
import FDate from "../widgets/FDate";
import FFileUpload from "../widgets/FFileUpload";
import * as CONST from "../../util/Const";
import { VALIDATE_REQUIRED, VALIDATE_FILE } from "../../util/ValidatorConst";
import FInput from '../widgets/FInput';
import { FListDetailView } from '../../util/FListDetailView';
import { isValid } from "../../util/Validator";
import { DELETE, getChoice } from '../../util/FAPI';
import FCheckBox from '../widgets/FCheckBox';
import FAutoComplete from '../widgets/FAutoComplete';
import { showSuccessNotification, showErroNotification } from '../widgets/FMessage';
import FMemo from "../widgets/FMemo";
import { withStyles } from "@material-ui/core/styles";
import {FGroupDocumentBox} from "../widgets/FGroupBox";


const styles = theme => ({
  detailContainer: {
      padding: theme.spacing(1)
  },

});


const PROPS = {
  module: "document",
  editMode: false,
  isNew: true,
  url: CONST.API_DOCUMENTS_URL,
  listData: [],
  formData: {},
  errorData: {
  },
  touched: {

  },
  errorMessage: {
    documentID: "Document ID required",
    state: 'Invald State',
    documentType: 'Invalid Document Type',
    documentIssuedState: 'Document Issued State',
    documentIssuedDate: 'Invalid Date',
    documentExpirationDate: 'Invalid Date',
    documentFile: 'File Can not be Empty',
    documentIsGOVIssued: 'Invalid Data',
    documentIdRequired: 'Invalid Data',
    documentExpires: 'Invalid Data',
    desc: '',
  },
  data: {
    carrier: {},
    documentID: '',
    documentType: '',
    state: '',
    documentIssuedState: '',
    documentIssuedDate: '',
    documentExpirationRemindDays: 0,
    documentFile: '',
    documentIsGOVIssued: '',
    documentIdRequired: '',
    documentExpires: '',
    desc: ''
  },
  validators: {
    carrier: [VALIDATE_REQUIRED],
    documentType: [VALIDATE_REQUIRED],
    documentID: [VALIDATE_REQUIRED],
    state: [VALIDATE_REQUIRED],
    documentType: [VALIDATE_REQUIRED],
    documentIssuedState: [VALIDATE_REQUIRED],
    documentIssuedDate: [VALIDATE_REQUIRED],
    documentExpirationDate: [],
    documentFile: [VALIDATE_FILE],
    
    desc: [],
  }
}

class DocumentView extends FListDetailView {

  constructor(props) {
    super(props, PROPS)
    this.setState({ data: { [this.props.ownerType]: this.props.ownerId} })
    this.documentChoices = this.getDocumentChoices()
  }

  getDocumentChoices = () => {
    if (!!this.props.documentChoices) {
      return this.props.documentChoices
    }
    return this.props[`document_${this.props.ownerType}_choices`]
  }

  showCarrier = () => {
    return false
  }
  
  isDocumentTypeValid = () => {
    let choicess  = this.getDocumentChoices()
    let found = choicess.find(function(choice) { 
         return choice.choiceValue == this.state.data.documentType; 
    }, this); 
    return !!found;
   }

  getErrorData = () => {
    let errorData = {

          // carrier: !isValid(this.state.data.carrier, this.state.validators.carrier),

          documentType: !isValid(this.state.data.documentType,
                                 this.state.validators.documentType),
          documentFile:  !isValid(this.state.data.documentFile, 
                                  this.state.validators.documentFile),
          documentID: this.showID(this.state.data.documentType) &&
                       !isValid(this.state.data.documentID, this.state.validators.documentID),
          documentType: !isValid(this.state.data.documentType, 
                               this.state.validators.documentType),
          documentIssuedState: this.showIssuedState(this.state.data.documentType) &&
                        !isValid(this.state.data.documentIssuedState, this.state.validators.documentIssuedState),
          documentIssuedDate: !isValid(this.state.data.documentIssuedDate,
                     this.state.validators.documentIssuedDate)
      }
      return errorData
    }

  deleteRow = (rowData) => {
      let url = CONST.API_DOCUMENTS_URL + `${rowData.id}`
      DELETE(url).then(response => {
        showSuccessNotification(response, CONST.DELETE_DOCUMENT_RECORD)
        this.fetchListData();
      }).catch(err => {
        showErroNotification(err, CONST.DELETE_DOCUMENT_RECORD)
      })
    }
  
  getCarrier = () => {
       
  }
  getChoice = (dType) => {
      let documentChoices = this.getDocumentChoices()
      let choiceConf = getChoice(documentChoices, dType)
      return choiceConf || {}
  }
  
  showIssuedState = (dType) => {
    let showState =  this.state.data.documentIsGOVIssued ||  this.getChoice(dType).choiceIsGOVIssued
    return showState
  }
  
  showExpirationDate = (dType) => {
    return this.state.data.documentExpires ||  this.getChoice(dType).choiceExpires
  }
  
  showID = (dType) => {
       return this.state.data.documentIdRequired ||  this.getChoice(dType).choiceIdRequired
  }

  getLabelID = (dType) => {
    return this.getChoice(dType).choiceIdLabel
  }
  

    
  render() {
    const title = this.isNew ? 'Add Document' : 'Update Document'
    const { classes } = this.props;
    return (
      <div>
        <div>                   
          <FGroupDocumentBox >
              <Documents
                profile={this.props.profile}
                policy_id={this.props.policy_id}
                listUrl={this.props.listUrl}
                owner={this.props.owner}
                ownerType={this.props.ownerType}
                onEdit={this.onEdit}
                onDelete={this.deleteRow}
                data={this.state.listData}
                onTableAction = {this.onTableAction}
              />
          </FGroupDocumentBox>
        </div>

        <div>
          <FDialog
            // maxWidth='xl'
            title={title}
            owner={this.props.owner}
            openDialog={this.state.editMode}
            notifyDialogClose={this.notifyDialogClose}>
              <Grid container direction='column' spacing={12} className={classes.detailContainer}>
                  <Grid container  spacing={12}>
                        <Grid item xs >
                                  <FSelect
                                    name='documentType'
                                    value={this.state.data.documentType}
                                    hasError={this.state.errorData.documentType}
                                    helpMessage={this.state.errorMessage.documentType}
                                    label='Document Type'
                                    data={this.getDocumentChoices()}
                                    valueRef='prettyName'
                                    keyRef='prettyName'
                                    notifyParent={this.updateValues} />
                          </Grid>
                 </Grid>
                 <Grid container  spacing={12}>
                      <Grid item xs hidden={!this.showCarrier()}>
                           <FAutoComplete
                                url={CONST.API_CARRIERS_URL}
                                name='carrier'
                                hasError={this.state.errorData.carrier}
                                helpMessage={this.state.errorMessage.carrier}
                                label='Authority'
                                // queryParam='search'
                                value={this.state.data.carrier}
                                defaultValue={this.state.data.carrier}
                                notifyParent={this.updateValues}
                                disabled={!this.state.isNew}
                                notifyDependency={this.onCarrierChange}
                            />
                            {/* <FSelect
                              name='carrier'
                              value={this.state.data.carrier && this.state.data.carrier.id}
                              hasError={this.state.errorData.carrier}
                              helpMessage={this.state.errorMessage.carrier}
                              label='Authority'
                              data={getCarriers(this.props)}
                              valueRef='prettyName'
                              keyRef='id'
                              notifyParent={this.updateValues} /> */}
                      </Grid>
                      <Grid item xs>
                          <FDate
                            name='documentIssuedDate'
                            required={true}
                            defaultValue={this.state.data.documentIssuedDate}
                            hasError={this.state.errorData.documentIssuedDate}
                            helpMessage={this.state.errorMessage.documentIssuedDate}
                            label="Document Issued Date "
                            notifyParent={this.updateValues} />
                      </Grid>

                  </Grid>
                  <Grid container  spacing={12}>
                       <Grid item xs>
                          <FCheckBox
                              name='documentIdRequired'
                              // value={this.state.data.documentIdRequired ||  this.showID(this.state.data.documentType)}
                              value={ this.showID(this.state.data.documentType)}
                              notifyDefault={true}
                              hasError={this.state.errorData.documentIdRequired}
                              helpMessage={this.state.errorMessage.documentIdRequired}
                              label="Document Has ID"
                              notifyParent={this.updateValues} />
                        </Grid>
                        <Grid item xs hidden={!this.showID(this.state.data.documentType)}>
                            <FInput
                              name='documentID'
                              disabled ={!(this.state.data.documentIdRequired || this.showID(this.state.data.documentType)) }
                              value={this.state.data.documentID}
                              hasError={this.state.errorData.documentID}
                              helpMessage={this.state.errorMessage.documentID}
                              label={this.getLabelID(this.state.data.documentType)}
                              notifyParent={this.updateValues} />
                        </Grid>
                  </Grid>
                  <Grid container  spacing={12}>
                       <Grid item xs>
                          <FCheckBox
                              name='documentIsGOVIssued'
                              notifyDefault={true}
                              value={this.showIssuedState(this.state.data.documentType)}
                              hasError={this.state.errorData.documentIsGOVIssued}
                              helpMessage={this.state.errorMessage.documentIsGOVIssued}
                              label="State issued"
                              notifyParent={this.updateValues} />
                        </Grid>
                      <Grid item xs hidden={!this.showIssuedState(this.state.data.documentType)}>
                            <FSelect
                              name='documentIssuedState'
                              disabled ={!(this.state.data.documentIsGOVIssued || this.showIssuedState(this.state.data.documentType)) }

                              value={this.state.data.documentIssuedState}
                              hasError={this.state.errorData.documentIssuedState}
                              helpMessage={this.state.errorMessage.documentIssuedState}
                              label='Document Issued State'
                              data={this.props.countryStates}
                              valueRef='value'
                              keyRef='prettyName'
                              notifyParent={this.updateValues} />
                      </Grid>
               </Grid>
               <Grid container  spacing={12}>
                      <Grid item xs>
                          <FCheckBox
                              name='documentExpires'
                              notifyDefault={true}
                              value={this.showExpirationDate(this.state.data.documentType)}
                              hasError={this.state.errorData.documentExpires}
                              helpMessage={this.state.errorMessage.documentExpires}
                              label="Document Expires"
                              notifyParent={this.updateValues} />
                        </Grid>
                      <Grid item xs hidden={!this.showExpirationDate(this.state.data.documentType)}>
                        <FDate
                          disabled ={!this.showExpirationDate(this.state.data.documentType) }

                          name='documentExpirationDate'
                          defaultValue={this.state.data.documentExpirationDate}
                          hasError={this.state.errorData.documentExpirationDate}
                          helpMessage={this.state.errorMessage.documentExpirationDate}
                          label="Document Expire Date "
                          notifyParent={this.updateValues} />
                      </Grid>
              </Grid>
              
              {/* <Grid item xs>
                <FInput name='documentExpirationRemindDays' type='number'
                  value={this.state.data.documentExpirationRemindDays}
                  hasError={this.state.errorData.documentExpirationRemindDays}
                  helpMessage={this.state.errorMessage.documentExpirationRemindDays}
                  label="Reminder before days (max 30 days)" notifyParent={this.updateValues}
                />
              </Grid> */}
              <Grid item xs>
                <FFileUpload
                  value={this.state.data.documentFile}
                  name='documentFile'
                  label="Upload Document"
                  hasError={this.state.errorData.documentFile}
                  helpMessage={this.state.errorMessage.documentFile}
                  notifyParent={this.updateValues} />
              </Grid>
              <Grid item xs>
                <FSelect
                  name='state'
                  value={this.state.data.state}
                  hasError={this.state.errorData.state}
                  helpMessage={this.state.errorMessage.state}
                  label='Document state'
                  data={this.props.documentStateChoice}
                  valueRef='prettyName'
                  keyRef='prettyName'
                  notifyParent={this.updateValues} />
              </Grid>
              {/* <Grid item xs>
                <FInput
                  name='desc' value={this.state.data.desc} multiline={true}
                  value={this.state.data.desc}
                  hasError={this.state.errorData.desc}
                  helpMessage={this.state.errorMessage.desc}
                  label="Description" 
                  notifyParent={this.updateValues} />
              </Grid> */}
              <FMemo
                    name='desc' value={this.state.data.desc} multiline={true}
                    value={this.state.data.desc}
                    hasError={this.state.errorData.desc}
                    helpMessage={this.state.errorMessage.desc}
                    // label="Description" 
                    rows={3}
                    // label={Labels.DESCRIPTION_MEMO} 
                    notifyParent={this.updateValues} 
              />
              <Grid container direction='row' xs={8} justify='space-around'>
                {/* <Grid item xs={4}>
                  <FButton color='primary' label={this.isNew ? 'Save' : 'Update'} loading={this.state.loading}
                    onClick={this.onSaveData} />
                </Grid>
                <Grid item xs={4}>
                  <FButton color='secondary' label='Cancel'
                    onClick={this.notifyDialogClose} />
                </Grid> */}
                {this.getUpdateButton(this.props.profile, this.props.policy_id)}

              </Grid>
            </Grid>
          </FDialog>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps)(DocumentView))