import React, { Component } from 'react';
import FRouterLink, { FRouterAddLink } from '../widgets/FRouterLink';
import {  API_ACCIDENT_REGISTER,
         ROUTE_ACCIDENT_REGISTER_URL, ROUTE_DRIVER_URL, ROUTE_EMPLOYEMENT_HISTORY_URL, SAFETY_AND_COMPLINACE_ACCIDENT_REGISTERS, TAB_SAFETY_AND_COMPLIANCE } from '../../util/Const';
import FListComponent from '../../util/FListComponent';
import { connect } from "react-redux"
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import { isProfileDispatcher } from '../../util/ACL';
import { Icon } from '@material-ui/core';
import FMaterialTable from '../widgets/FMaterialTable';
import FTableAction, { ACTION_TABLE_DELETE_ROW, ACTION_TABLE_DEACTIVATE, ACTION_TABLE_ACTIVATE } from '../widgets/FTableAction';
import BlockIcon from '@material-ui/icons/Block';

class AccidentRegisters extends FListComponent {
  constructor(props) {
    super(props, API_ACCIDENT_REGISTER, null, 'accidentOrViolationIncidentType=ACCIDENT&')
    this.currentTitle = 'Accident Registers'
    this.currentTab = TAB_SAFETY_AND_COMPLIANCE
    this.routeUrl = ROUTE_ACCIDENT_REGISTER_URL
    this.policyID = SAFETY_AND_COMPLINACE_ACCIDENT_REGISTERS
    this.includeStateAction = false
  }
  
  // getActions = (rowData) => {
  //   let actions = []
  //   // rowData.state == 'ACTIVE'? actions.push(ACTION_TABLE_DEACTIVATE): 
  //   //       actions.push(ACTION_TABLE_ACTIVATE)
  //   actions.push(ACTION_TABLE_DELETE_ROW)
  //   return actions
  // }

  render() {
    return (
      <div>
   
        <FMaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: 'Accident  ', 
              field: 'Accident',
              render: rowData => {
                return (
                  <React.Fragment>
                    <FRouterLink editIcon={true} label={rowData.accidentOrViolationType} to={ROUTE_ACCIDENT_REGISTER_URL + rowData.id} />
                  </React.Fragment>
                )
              }
            },
            // {
            //   title: 'Driver', field: 'driver.prettyName',
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FRouterLink label={rowData.driver.prettyName} 
            //            to={ROUTE_DRIVER_URL + rowData.driver.id}
            //            disabled={isProfileDispatcher(this.props.profile)}/>
            //       </React.Fragment>
            //     )
            //   }
            // },
            {
              title: 'Driver', 
              render: this.getDriverLink
            },
            {
              title: 'Former Employment', field: 'employmentHistory.prettyName',
              render: rowData => {
                if (!rowData.employmentHistory){
                  return 'N/A'
                }
                return (
                  <React.Fragment>
                    <FRouterLink label={rowData.employmentHistory.prettyName} 
                       to={ROUTE_EMPLOYEMENT_HISTORY_URL+ rowData.employmentHistory.id}
                       disabled={isProfileDispatcher(this.props.profile)}/>
                  </React.Fragment>
                )
              }
            },
            { title: 'Date', field: 'accidentOrViolationDate', render: rowData => this.getPrettyDate(rowData.accidentOrViolationDate) },
            // { title: 'Location of Accident', field: 'accidentOrViolationLocation' },
            { title: 'Total Fatal', field: 'numFatals' },
            { title: 'Total non Fatal', field: 'numNonFatals' },
            {
              title: 'H/M',
              field: 'hazardousSpilled',
              render: rowData => {
                return (
                  <React.Fragment>
                    {rowData.hazardousSpilled && <Icon>X</Icon>}
                  </React.Fragment>
                )
              }
            },
            { title: 'Incident', field: 'accidentOrViolationIncidentResolution' },
            { title: 'Violation', field: 'accidentOrViolationCategory' },
            { title: 'Status', field: 'accidentOrViolationIncidentResolutionStatus' },

            // {
            //   title: 'Actions', 
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FTableAction 
            //             actions={this.getActions(rowData)}
            //             onTableAction = {this.onTableAction}
            //             rowData={rowData}
            //              />
            //       </React.Fragment>
            //     )
            //   }
            // },
            {
              title: 'Actions', 
              render: rowData => {
                let actions  = this.getActions(rowData, SAFETY_AND_COMPLINACE_ACCIDENT_REGISTERS)

                return (
                  <React.Fragment>
                    {!!actions.length && <FTableAction 
                        actions={actions}
                        onTableAction = {this.onTableAction}
                        rowData={rowData}
                         />
                    }
                    {!!!actions.length && <BlockIcon color="disabled" />}
                  </React.Fragment>
                )
              }
            },
            
          ]}
          actions={
            [
              // {
              //   icon: () => (
              //     <FRouterAddLink to={ROUTE_ACCIDENT_REGISTER_URL + 'add'} color='secondary' />
              //   ),
              //   isFreeAction: true
              // },
              this.getAddNewModelActions()
            ]}
          options = {{
              pageSize: this.state.pageSize,
              pageSizeOptions: this.state.pageSizeOptions,
              actionsColumnIndex: -1
           }}
          data={this.fetchData}
          title="Accident Registers"
          listErrCode={this.state.listErrCode}
        />

      </div>
    );
  }
}


export default connect(
  mapStateToProps, mapDispatchToProps)(AccidentRegisters)
