import React, { Component } from 'react'
import { Grid, withStyles } from '@material-ui/core';
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import FKeyValTable from '../widgets/FKeyValTable';
import { fetchAndUpdateStoreWithUrl } from '../../util/FAPI';
import { ACTION_UPDATE_PARENT } from '../../fredux/ActionTypes';
import { has_link_permission } from '../../util/ACL';
import * as CONST  from '../../util/Const';


const styles = theme => ({
    root: {
        flexGrow: 1,
        color: theme.palette.text.secondary,
        width: '100%',

    },
    padding: {
        paddingBottom: theme.spacing(4),
    },

});


class ComplianceDashboard extends  React.Component  {
    
    componentDidMount() {
        fetchAndUpdateStoreWithUrl(CONST.API_DASHBOARD_SUMMARY, ACTION_UPDATE_PARENT)
    }

    render() {
        this.props.updateCurrentTitle && this.props.updateCurrentTitle('Compliance Overview')

        // TODO(Estifanos) Update title with Dashboard
        this.props.updateCurrentTab(CONST.TAB_DASHBOARD)
        // this.props.updateCurrentTitle && this.props.updateCurrentTitle('Overview')
        const { classes } = this.props
        return (
            // <div className={classes.root}>
                <Grid container direction="column"  xs>
                    

                     <Grid container spacing= {2} xs={12} className={classes.padding}>
                        <Grid item xs className={classes.padding}>
                            <FKeyValTable
                                has_permission={has_link_permission(this.props.profile, CONST.SAFETY_AND_COMPLINACE_MISSING_DOCS)}
                                title='Document Compliance'
                                titleKey='DOCUMENT_COMPLIANCE_TITLE'
                                data={this.props.allComplianceSummary} 
                            />
                        </Grid>
                        <Grid item xs className={classes.padding}>
                            <FKeyValTable
                                has_permission={has_link_permission(this.props.profile, CONST.SAFETY_AND_COMPLINACE_DRUG_TESTS)}
                                title='Drug Test Compliance'
                                titleKey='DRUG_TEST_COMPLIANCE_TITLE'
                                data={this.props.drugTestSummary} 
                            />
                        </Grid>
                        <Grid item xs className={classes.padding}>
                            <FKeyValTable
                                has_permission={has_link_permission(this.props.profile, CONST.SAFETY_AND_COMPLINACE_EPLOYMENT_HISTORYS)}
                                title='Driver Employment Compliance'
                                titleKey='DRIVER_EMPLOYMENT_HISTORY_COMPLIANCE_TITLE'
                                data={this.props.employmentHistorySummary} 
                            />
                        </Grid>
                        <Grid item xs className={classes.padding}>
                            <FKeyValTable
                                has_permission={has_link_permission(this.props.profile, CONST.SAFETY_AND_COMPLINACE_TRAFFIC_VIOLATIONS)}
                                title='Traffic Violations'
                                titleKey='TRAIFC_VIOLAITON_COMPLIANCE_TITLE'
                                data={this.props.trafficViolationSummary} 
                            />
                        </Grid>
                        
                        
                    </Grid>
                    <Grid container spacing= {2} xs={12} className={classes.padding}>
                        <Grid item xs className={classes.padding}>
                                <FKeyValTable
                                    has_permission={has_link_permission(this.props.profile, CONST.SAFETY_AND_COMPLINACE_VEHICLE_INSPECTIONS)}
                                    title='Truck Inspection Compliance'
                                    titleKey='TRUCK_INSPECTION_COMPLIANCE_TITLE'
                                    data={this.props.truckSafetySummary} 
                                />
                            </Grid>

                            <Grid item xs className={classes.padding}>
                                <FKeyValTable
                                    has_permission={has_link_permission(this.props.profile, CONST.SAFETY_AND_COMPLINACE_VEHICLE_INSPECTIONS)}
                                    title='Trailer Inspection Compliance'
                                    titleKey='TRAILER_INSPECTION_COMPLIANCE_TITLE'
                                    data={this.props.trailerSafetySummary} 
                                />
                            </Grid>

                        <Grid item xs className={classes.padding}>
                            <FKeyValTable
                                has_permission={has_link_permission(this.props.profile, CONST.SAFETY_AND_COMPLINACE_VEHICLE_MAINTENANCES)}
                                title='Truck Maintenance Schedule'
                                titleKey='TRUCK_MAINTENANCE_SCHEDULE_COMPLIANCE_TITLE'
                                data={this.props.truckMaintenanceScheduleSummary} 
                            />
                        </Grid>
                        <Grid item xs className={classes.padding}>
                            <FKeyValTable
                                has_permission={has_link_permission(this.props.profile, CONST.SAFETY_AND_COMPLINACE_VEHICLE_MAINTENANCES)}
                                title='Trailer Maintenance Schedule'
                                titleKey='TRAILER_MAINTENANCE_SCHEDULE_COMPLIANCE_TITLE'
                                data={this.props.trailerMaintenanceScheduleSummary} 
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing= {2} xs={12} className={classes.padding}>
                        
                        <Grid item xs className={classes.padding}>
                                <FKeyValTable
                                    has_permission={has_link_permission(this.props.profile, CONST.SAFETY_AND_COMPLINACE_DISCIPLINARY_EVENTS)}
                                    title='Driver Disciplinary Summary'
                                    titleKey='DRIVER_DISCIPLINARY_COMPLIANCE_TITLE'
                                    data={this.props.driverDisciplinaryEventSummary} 
                                />
                            </Grid>

                        <Grid item xs className={classes.padding}>
                                <FKeyValTable
                                    has_permission={has_link_permission(this.props.profile, CONST.FINANCE_INVOICE)}
                                    title='Invoice Summary'
                                    titleKey='INVOICE_DASHBOARD_SUMMARY_TITLE'
                                    data={this.props.invoiceSummary} 
                                />
                            </Grid>

                        <Grid item xs className={classes.padding}>
                            <FKeyValTable
                                has_permission={has_link_permission(this.props.profile, CONST.FLEET_FLEETS)}
                                title='Fleets Summary'
                                titleKey='FLEET_SUMMARY_DASHBOARD_TITLE'
                                data={this.props.fleetDashBoardSummary} 
                            />
                        </Grid>

                        <Grid item xs className={classes.padding}>
                            <FKeyValTable
                               has_permission={has_link_permission(this.props.profile, CONST.SAFETY_AND_COMPLINACE_ACCIDENT_REGISTERS)}
                                title='Accidents Violations'
                                titleKey='ACCIDENT_VIOLAITON_COMPLIANCE_TITLE'
                                data={this.props.accidentSummary} 
                            />
                        </Grid>
                        

                    </Grid>
                
                </Grid>
            // </div >
        );

    }
}


export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ComplianceDashboard));