import React from 'react';
import { Link } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';

// import FRouterLink, { FRouterAddLink } from '../widgets/FRouterLink';

import { API_EXPENSE_URL, ROUTE_EXPENSE_URL } from '../../../util/Const';
import { roundDecimal, getPrettyDate } from '../../../util';
import FMaterialTable from '../../widgets/FMaterialTable';
// import FTableAction, { ACTION_TABLE_DELETE_ROW } from '../widgets/FTableAction';

export default class Expenses extends React.Component {

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div>
        <FMaterialTable
          tableRef={this.props.tableRef}
          columns={[
            {
              title: 'Transaciton Id', 
              field: 'expenseTransactionID',
              // render: rowData => {
              //   return (
              //     <React.Fragment>
              //       <FRouterLink label={rowData.expenseTransactionID} to={ROUTE_EXPENSE_URL + rowData.id} />
              //     </React.Fragment>
              //   )
              // }
            },
            { title: 'Transaction Date', field: 'expenseTransactionDate',
               render: rowData => getPrettyDate(rowData.expenseTransactionDate) },
            // {
            //   title: 'Expense For',
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         {this.getExpenseFor(rowData)}
            //       </React.Fragment>
            //     )
            //   }
            // },
            // {
            //   title: 'Deductable',
            //   field: 'expenseIsDeductable',
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         {rowData.expenseIsDeductable && <Icon>X</Icon>}
            //       </React.Fragment>
            //     )
            //   }
            // },
            // {
            //   title: 'Reimberse',
            //   field: 'expenseIsReimbursable',
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         {rowData.expenseIsReimbursable && <Icon>X</Icon>}
            //       </React.Fragment>
            //     )
            //   }
            // },
            // {
            //   title: 'Invoice',
            //   field: 'expenseIsInvoiceable',
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         {rowData.expenseIsInvoiceable && <Icon>X</Icon>}
            //       </React.Fragment>
            //     )
            //   }
            // },
            { title: 'Maintenance Type', field: 'expenseMaintenanceCategory'},
            // {
            //   title: 'Receipt',
            //   render: rowData => {
            //     let url = rowData.expenseReceipt
            //     return (
            //       <React.Fragment>
            //         {url && <Link href={url} target="_blank"> Expense</Link>}
            //       </React.Fragment>
            //     )
            //   }
            // },
            { title: 'Service Provider', field: 'maintenaceServiceProviderPrettyName' },
            { title: 'Amount ($)', field: 'expenseAmount',  render: rowData => roundDecimal(rowData.expenseAmount)},
            // { title: 'Description', field: 'desc' },
          //   {
          //     title: 'Actions', 
          //     render: rowData => {
          //       return (
          //         <React.Fragment>
          //           <FTableAction 
          //               actions={this.getActions(rowData)}
          //               onTableAction = {this.onTableAction}
          //               rowData={rowData}
          //                />
          //         </React.Fragment>
          //       )
          //     }
          //   },
          ]}
          
          options = {{
            pageSize: this.props.pstate.pageSize,
            pageSizeOptions: this.props.pstate.pageSizeOptions,
            actionsColumnIndex: -1,
            search: false
         }}
          data={this.props.fetchData}
          listErrCode={this.props.listErrCode}
          // title={`Expenses`}
        
        />
      </div>
    );
  }
}


// export default connect(
//   mapStateToProps, mapDispatchToProps)(Expenses)
