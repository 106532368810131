import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import BlockIcon from '@material-ui/icons/Block';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import  *  as CONST from '../../util/Const';

import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import SendIcon from '@material-ui/icons/Send';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

export const  ACTION_TABLE_DELETE_ROW = CONST.ACTION_TABLE_DELETE_ROW
export const  ACTION_TABLE_ACTIVATE = CONST.ACTION_TABLE_ACTIVATE
export const  ACTION_TABLE_DEACTIVATE = CONST.ACTION_TABLE_DEACTIVATE
export const  ACTION_TABLE_COMPLETE= CONST.ACTION_TABLE_COMPLETE
export const  ACTION_TABLE_INPROGRESS = CONST.ACTION_TABLE_INPROGRESS

export const  ACTION_TABLE_INVOICE_CREATED = CONST.ACTION_TABLE_INVOICE_CREATED
export const  ACTION_TABLE_INVOICE_INVOICED=CONST.ACTION_TABLE_INVOICE_INVOICED
export const  ACTION_TABLE_INVOICE_PARTIALLY_PAID= CONST.ACTION_TABLE_INVOICE_PARTIALLY_PAID
export const  ACTION_TABLE_INVOICE_FULLY_PAID = CONST.ACTION_TABLE_INVOICE_FULLY_PAID
export const  ACTION_TABLE_INVOICE_CANCELED = CONST.ACTION_TABLE_INVOICE_CANCELED
export const  ACTION_TABLE_EMAIL_INVOICE = CONST.ACTION_TABLE_EMAIL_INVOICE
export const  ACTION_TABLE_EXPENSIFY_PAYCHECK = CONST.ACTION_TABLE_EXPENSIFY_PAYCHECK



const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
    //   backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.black,
      },
    },
  },
}))(MenuItem);

const getActionIcon = (action) => {
  switch(action) {
    case(CONST.ACTION_TABLE_DELETE_ROW):
       return (<DeleteIcon fontSize="small" />) 
    case(CONST.ACTION_TABLE_ACTIVATE):
       return (<RadioButtonUncheckedIcon fontSize="small" />) 
    case(CONST.ACTION_TABLE_DEACTIVATE):
       return (<RemoveCircleIcon fontSize="small" />)
    case(CONST.ACTION_TABLE_COMPLETE):
       return (<RadioButtonUncheckedIcon fontSize="small" />) 
    case(CONST.ACTION_TABLE_INPROGRESS):
       return (<RemoveCircleIcon fontSize="small" />)     
    case(CONST.ACTION_TABLE_INVOICE_FULLY_PAID):
        return (<CheckCircleIcon fontSize="small" />) 
    case(CONST.ACTION_TABLE_INVOICE_CANCELED):
       return (<CancelIcon fontSize="small" />)
    case(CONST.ACTION_TABLE_INVOICE_PARTIALLY_PAID):
       return (<CheckCircleIcon fontSize="small" />) 
    case(CONST.ACTION_TABLE_INVOICE_INVOICED):
      return (<MailOutlineIcon fontSize="small" />)
    case(CONST.ACTION_TABLE_EMAIL_INVOICE):
      return (<SendIcon fontSize="small" />)
    case(CONST.ACTION_TABLE_EXPENSIFY_PAYCHECK):
      return (<AttachMoneyIcon fontSize="small" />)
  }
}

export default function FTableAction(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
   
  };

  const handleClose = () => {
    setAnchorEl(null);

  };

  const has_actions = () => {
    let actions = props.actions
    if (actions && actions.length > 0 ) return true
  };

  const onTableAction = event => {
    setAnchorEl(null);
    props.onTableAction(event.currentTarget.innerText, props.rowData)
  };

  
  return (
    <div>
      {has_actions() && <Button aria-controls="simple-menu" aria-haspopup="true" size="small" onClick={handleClick}>
                    <MoreVertIcon/>
          </Button>
      }
      {!has_actions() && <BlockIcon color="disabled" tooltip='No Permission!' />
      }
      <StyledMenu

        id="simple-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
       
       >
       {props.actions.map((option, index) => (
          <StyledMenuItem
            key={option}
            onClick={onTableAction}>
              <ListItemIcon>
                    {getActionIcon(option)}
              </ListItemIcon>
              <ListItemText primary={option} />
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </div>
  );
}
