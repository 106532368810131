import React, { Component } from 'react'
import { Grid, withStyles } from '@material-ui/core';
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import CarriersPreview from './CarrierPreview';
import {  API_DASHBOARD_CARRIER_SUMMARY,  EXPENSE_COLORS, INCOME_COLORS, INVOICE_COLORS, MAINTENANCE_COLORS, TAB_DASHBOARD } from '../../util/Const';
import DispatchersPreview from './DispatchersPreview';
import { fetchAndUpdateStoreWithUrl } from '../../util/FAPI';
import { ACTION_UPDATE_PARENT } from '../../fredux/ActionTypes';
import TopFinancialDashBaord from './TopFinancialDashBaord';
import TopAssetDashBaord from './TopAssetDashBaord';
import FPieDashboardInline from '../widgets/FPieDashboardInline';
import FBarDashboardInline from '../widgets/FBarDashboardInline';
import FLineDashboardInline from '../widgets/FLineDashboardInline';




const styles = theme => ({
    root: {
        flexGrow: 1,
        color: theme.palette.text.secondary,
        width: '100%',
    },
    padding: {
        paddingBottom: theme.spacing(4),
    }
});


class FinancialDashboard extends  React.Component  {
    
    componentDidMount() {
        fetchAndUpdateStoreWithUrl(API_DASHBOARD_CARRIER_SUMMARY, ACTION_UPDATE_PARENT)

        this.props.updateCurrentTitle && this.props.updateCurrentTitle('Financial Overview')
        this.props.updateCurrentTab(TAB_DASHBOARD)
    }

    render() {

      const { classes, profileDashBoardPreview } = this.props
      const {customerFleetIncomDistribution, expenseDistribution, maintenanceExpenseDistribution,
             driverFleetIncomDistribution, invoiceByType,
             driverSummaryDistribution, driverFuelSummaryDistribution} = profileDashBoardPreview || {}

      return (
               <Grid container direction="column"  >
                <Grid item xs>
                    <TopFinancialDashBaord/>
                </Grid>
                <Grid item xs>
                    <TopAssetDashBaord/>
                </Grid>
                <Grid item xs>
                    <Grid container spacing={2} xs={12}  >
                    <Grid item   xs>
                            <FBarDashboardInline 
                               title={" Income by Drivers"}
                               nameKey={"driver"}
                               valueKey={"value"}
                               color={INCOME_COLORS[0]}
                               colors={INCOME_COLORS}
                               height={475}
                               chartData={driverFleetIncomDistribution}/>
                        </Grid>     
                    
                        <Grid item xs={4}>
                            <FPieDashboardInline 
                                title={"Invoice by Type"}
                                nameKey={"name"}
                                valueKey={"value"}
                                height={475}
                                colors={INVOICE_COLORS}
                                fontSize={27}
                                chartData={invoiceByType}/>
                        </Grid>
                        
                        
                    </Grid>
                </Grid>
                <Grid container spacing= {2} xs={12} >
                       <Grid item   xs >
                            <FLineDashboardInline 
                               title={"Driver Advance/Income ratio"}
                                nameKey={"driver"}
                                valueKey={"Advance Income Ratio"}
                                colors={EXPENSE_COLORS}
                                color={EXPENSE_COLORS[0]}
                                fontSize={27}
                               chartData={driverSummaryDistribution}/>
                        </Grid>
                        <Grid item   xs >
                            <FLineDashboardInline
                               title={"Driver Fuel/Income ratio"}
                                nameKey={"driver"}
                                valueKey={"Fuel Income Ratio"}
                                colors={EXPENSE_COLORS}
                                color={EXPENSE_COLORS[0]}
                                fontSize={27}
                               chartData={driverSummaryDistribution}/>
                        </Grid>

                        <Grid item   xs>
                            <FLineDashboardInline
                               title={"Driver Maintenance/Income ratio"}
                               nameKey={"driver"}
                               valueKey={"driverMaintenanceExpenseRatio"}
                               valueKey={"Maintenance Income Ratio"}
                               color={INCOME_COLORS[0]}
                               colors={INCOME_COLORS}
                               fontSize={27}
                               chartData={driverSummaryDistribution}/>
                        </Grid>  
                        <Grid item  xs >
                            <DispatchersPreview height={600}/>
                        </Grid>
                </Grid>
                <Grid container spacing= {2} xs={12}  >
                       <Grid item  xs >
                            <FBarDashboardInline 
                               title={"Expenses by Type"}
                                nameKey={"expenseType"}
                                valueKey={"value"}
                                colors={EXPENSE_COLORS}
                                color={EXPENSE_COLORS[0]}
                                fontSize={27}
                                chartData={expenseDistribution}/>
                        </Grid>

                        <Grid item  xs>
                            <FBarDashboardInline 
                               title={"Income by Customers"}
                               nameKey={"customer__customerName"}
                               valueKey={"value"}
                               color={INCOME_COLORS[0]}
                               colors={INCOME_COLORS}
                               fontSize={27}
                               chartData={customerFleetIncomDistribution}/>
                        </Grid>  
                      
                </Grid>
                <Grid container spacing= {2} xs={12} >
                       <Grid item xs={6} >
                            <CarriersPreview   height={500} />
                        </Grid>   
                        <Grid item   xs >
                            <FBarDashboardInline 
                               title={"Expense by Maintenance Type"}
                                nameKey={"expenseType"}
                                valueKey={"value"}
                                colors={MAINTENANCE_COLORS}
                                color={MAINTENANCE_COLORS[0]}
                                fontSize={27}
                               chartData={maintenanceExpenseDistribution}/>
                        </Grid>

         
                </Grid>
            </Grid>
            // </div >
        );

    }
}


export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(FinancialDashboard));