import React, { Component } from 'react'
import ListItemIcon from '@material-ui/core/ListItemIcon';

import { mapStateToProps } from '../../fredux/ActionMaker';
import { connect } from 'react-redux'
import {  has_view_permission} from '../../util/ACL';
import * as CONST from "../../util/Const";
import FListItem from '../widgets/FListItem';


class AdminListItems extends Component {

    render() {
        const profile = this.props.profile
        return (
            <div>
        
            <FListItem  
                disabled={!has_view_permission(profile, CONST.ADMIN_CARRIERS)}
                to={CONST.ROUTE_CARRIERS_URL} label='Carriers'>
                <ListItemIcon>CAR</ListItemIcon>
            </FListItem>
            {/* <FListItem  
                 disabled={!has_view_permission(profile, CONST.ADMIN_DRIVERS)}
                 to={CONST.ROUTE_DRIVER_URL} label='Drivers'>
                 <ListItemIcon>DRI</ListItemIcon>
            </FListItem> */}

            <FListItem  
                disabled={!has_view_permission(profile, CONST.ADMIN_FUEL_CARDS)}
                to={CONST.ROUTE_FUEL_CARD_URL} 
                 label='Fuel Cards'>
                 <ListItemIcon>FC</ListItemIcon>
            </FListItem>
            <FListItem 
                 disabled={!has_view_permission(profile, CONST.ADMIN_VEHICLES)}
                 to={CONST.ROUTE_VEHICLE_URL} label='Vehicles'>
               <ListItemIcon>VHI</ListItemIcon>
            </FListItem>

             <FListItem  
                 disabled={!has_view_permission(profile, CONST.ADMIN_DISPATCHERS)}
                to={CONST.ROUTE_DISPATCHER_URL} label='Dispatchers'>
                <ListItemIcon>DIS</ListItemIcon>
             </FListItem>
            
            
            <FListItem  
                disabled={!has_view_permission(profile, CONST.ADMIN_COMPANYS)}
                to={CONST.ROUTE_COMPANY_URL} label='Contacts'>
               <ListItemIcon>FE</ListItemIcon>
            </FListItem>

            <FListItem  
                disabled={!has_view_permission(profile, CONST.ADMIN_INTEGRATION)}
                to={CONST.ROUTE_INTEGRATION_URL} label='Integrations'>
                <ListItemIcon>INT</ListItemIcon>
            </FListItem>
        </div>
        );
    }
}

export default connect(mapStateToProps)(AdminListItems)
