import React from 'react';


import { roundDecimal, getPrettyDate } from '../../../util';
import FMaterialTable from '../../widgets/FMaterialTable';
import { getStatusIcon } from '../../../util/icon_util';

export default class MaintenanceSchedules extends React.Component {

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div>
        <FMaterialTable
          tableRef={this.props.tableRef}
          columns={[

            { title: 'Date Created', render: rowData => getPrettyDate(rowData.created) },
          
            { title: 'Schedule Date/Mileage', field: 'maintenance_report_info.description'},
            { title: 'Vehicle', field: 'maintenance_report_info.vehicle.prettyName'},
            { title: 'Current Odometer', field: 'maintenance_report_info.vehicle.vehicleCurrentOdometer'},

            
            { title: 'Maintenance', field: 'maintenance_report_info.maintenance.prettyName'},
            // { title: 'TimeLine', field: 'maintenance_report_info.timeLine'},

            {
              title: 'Status', 
              render: rowData => {
                return (
                  <React.Fragment>
                      {getStatusIcon(rowData)}
                  </React.Fragment>
                )
              }
            },
            // {
            //   title: 'Receipt',
            //   render: rowData => {
            //     let url = rowData.expenseReceipt
            //     return (
            //       <React.Fragment>
            //         {url && <Link href={url} target="_blank"> Expense</Link>}
            //       </React.Fragment>
            //     )
            //   }
            // },
         
          ]}
          
          options = {{
            pageSize: this.props.pstate.pageSize,
            pageSizeOptions: this.props.pstate.pageSizeOptions,
            actionsColumnIndex: -1,
            search: false
         }}
          data={this.props.fetchData}
          listErrCode={this.props.listErrCode}
          // title={`Expenses`}
        
        />
      </div>
    );
  }
}


// export default connect(
//   mapStateToProps, mapDispatchToProps)(Expenses)
