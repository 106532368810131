import React, { Component } from 'react'
import ListItemIcon from '@material-ui/core/ListItemIcon';

import { mapStateToProps } from '../../fredux/ActionMaker';
import { connect } from 'react-redux'
import {  has_view_permission} from '../../util/ACL';
import * as CONST from "../../util/Const";
import FListItem from '../widgets/FListItem';

class FleetListItems extends Component {

    render() {
        const profile = this.props.profile
        return (
        <div>

            <FListItem 
                disabled={!has_view_permission(profile, CONST.FLEET_FLEETS)}
                to={CONST.ROUTE_FLEET_URL} label='Fleets'>
                    <ListItemIcon>FLE</ListItemIcon>
            </FListItem>
        
        
            <FListItem  
                disabled={!has_view_permission(profile, CONST.FLEET_CUSTOMERS)}
                to={CONST.ROUTE_CUSTOMER_URL} label='Customers'>
                <ListItemIcon>CUS</ListItemIcon>
            </FListItem>
        
                    <hr/>
            <FListItem  
                disabled={!has_view_permission(profile, CONST.FLEET_TRIPS)}
                to={CONST.ROUTE_TRIP_URL} label='Mileages (Trips)'>
                <ListItemIcon>TR</ListItemIcon>
            </FListItem>
    
        
            <FListItem 
                disabled={!has_view_permission(profile, CONST.FLEET_MILEAGE_IMPORTS)}
                to={CONST.ROUTE_MILEAGE_IMPORT_URL} label='Import Mileages'>
                <ListItemIcon>IM</ListItemIcon>
            </FListItem>
           
        </div>
        );
    }
}

export default connect(mapStateToProps)(FleetListItems)
