import React from 'react';
import FRouterLink, { FRouterAddLink } from '../widgets/FRouterLink';
import { API_VEHICLES_URL, ROUTE_VEHICLE_URL, CHOICE_VEHICLE, ROUTE_CARRIERS_URL, TAB_ADMIN, ADMIN_VEHICLES } from '../../util/Const';
import FListComponent from '../../util/FListComponent';
import { connect } from "react-redux"
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import FMaterialTable from '../widgets/FMaterialTable';
import FTableAction, {ACTION_TABLE_ACTIVATE, ACTION_TABLE_DEACTIVATE, ACTION_TABLE_DELETE_ROW } from '../widgets/FTableAction';
import { getStateIcon } from '../../util/icon_util';
import BlockIcon from '@material-ui/icons/Block';


class Vehicles extends FListComponent {

  constructor(props) {
    super(props, API_VEHICLES_URL, CHOICE_VEHICLE)
    this.currentTitle = 'Vehicles'
    this.currentTab = TAB_ADMIN
    this.routeUrl = ROUTE_VEHICLE_URL
    this.policyID = ADMIN_VEHICLES
  }

  // getActions = (rowData) => {
  //   let actions = []
  //   rowData.state == 'ACTIVE'? actions.push(ACTION_TABLE_DEACTIVATE): 
  //         actions.push(ACTION_TABLE_ACTIVATE)
  //   actions.push(ACTION_TABLE_DELETE_ROW)
  //   return actions
  // }

  render() {
    return (
      <div>
        <FMaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: 'Vehicle #', 
              render: this.getSelfVehicleLink
            },
            // {
            //   title: 'Vehicle #', 
            //   render: rowData => {
            //     const imageUrl = rowData.vehiclePicture ? rowData.vehiclePicture : "/truck.jpg";
            //     return (
            //       <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'left' }}>
            //         <img 
            //             src={imageUrl}
            //             style={{width: 50, borderRadius: '20%', marginBottom: '10px'}}
            //         />
            //         <FRouterLink editIcon={true} label={rowData.prettyName} to={ROUTE_VEHICLE_URL + rowData.id} />
            //       </div>
            //     )
            //   }
            // },
            // {
            //   title: 'Vehicle #', 
            //   field: 'prettyName',
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FRouterLink editIcon={true} label={rowData.prettyName} to={ROUTE_VEHICLE_URL + rowData.id} />
            //       </React.Fragment>
            //     )
            //   }
            // },
            // {
            //   title: 'Carrier', 
            //   field: 'carrier.prettyName',
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FRouterLink label={rowData.carrier.prettyName} to={ROUTE_CARRIERS_URL + rowData.carrier.id} />
            //       </React.Fragment>
            //     )
            //   }
            // },
            {
              title: 'Carrier', 
              render: this.getCarrierLink
            },
            { title: 'Date Activated', field: 'vehicleStartDate',
               render: rowData => this.getPrettyDate(rowData.vehicleStartDate) },
            { title: 'Plate #', field: 'vehiclePlateNumber' },
            { title: 'Plate Issued  #', field: 'vehiclePlateState' },

            { title: 'Type', field: 'vehicleType' },
            { title: 'VIN', field: 'vehicleVIN' },
            { title: 'Odometer', field: 'vehicleCurrentOdometer' },
            
            {
              title: 'State', 
              render: rowData => {
                return (
                  <React.Fragment>
                      {getStateIcon(rowData)}
                  </React.Fragment>
                )
              }
            },
            {
              title: 'Actions', 
              render: rowData => {
                let actions  = this.getActions(rowData, ADMIN_VEHICLES)
                return (
                  <React.Fragment>
                    {!!actions.length && <FTableAction 
                        actions={actions}
                        onTableAction = {this.onTableAction}
                        rowData={rowData}
                         />
                    }
                    {!!!actions.length && <BlockIcon color="disabled" />}
                  </React.Fragment>
                )
              }
            },
          ]}
          actions={
            [
              this.getAddNewModelActions()
            ]}
          options = {{
              pageSize: this.state.pageSize,
              pageSizeOptions: this.state.pageSizeOptions,
              actionsColumnIndex: -1
           }}
          data={this.fetchData}
          title="Vehicles"
          listErrCode={this.state.listErrCode}
        />

      </div>
    );
  }
}

export default connect(
  mapStateToProps, mapDispatchToProps)(Vehicles)
