import React from 'react';
import MaterialTable from 'material-table'
import FRouterLink from '../widgets/FRouterLink';
import { ROUTE_FLEET_URL, ROUTE_CUSTOMER_URL } from '../../util/Const';
import { Link } from '@material-ui/core';
import {getPrettyDate} from '../../util';
import { getInvoiceFleetInvoiceStatusIcon } from '../../util/icon_util';
import { FMaterialSimpleTable } from '../widgets/FMaterialTable';
 
class Fleets extends React.Component {
  render() {

    return (
      <div>

        <FMaterialSimpleTable
          columns={[
            {
              title: 'Reference', render: rowData => {
                return (
                  <React.Fragment>
                    <FRouterLink label={rowData.fleetLoadNumber} to={ROUTE_FLEET_URL + rowData.id} />
                  </React.Fragment>
                )
              }
            },
            // { title: 'Customer', field: 'customer'},
            {
              title: 'Customer',
              render: rowData => {
                if (!!!rowData.customer){
                  return 'No Customer';
                }
                return (
                  <React.Fragment>
                    <FRouterLink label={rowData.customer.prettyName} to={ROUTE_CUSTOMER_URL + rowData.customer.id} />
                  </React.Fragment>
                )
              }
            },
            {
              title: 'Invoice', 
              render: rowData => {
                return (
                  <React.Fragment>
                      {getInvoiceFleetInvoiceStatusIcon(rowData.metaData)}
                  </React.Fragment>
                )
              }
            },
            { title:  'Date', render: rowData => getPrettyDate(rowData.fleetPickUpDate) },
            
            {
              title: 'Trip', render: rowData => {
                return (
                  <React.Fragment>
                     <b>{rowData.fleetPickupLocation}</b> to <b>{rowData.fleetDeliveryLocation}</b>
                  </React.Fragment>
                )
              }
            },
           
            { title: 'Fee', field: 'fleetLoadFee' },
            { title: 'Lumper Fee', field: 'fleetLumperFee' },
            {
              title: 'Total Fees', render: rowData => {
                let fees = rowData.totalFee
                return (
                  <React.Fragment>
                     {fees}
                  </React.Fragment>
                )
              }
            },
            {
              title: 'Confirmation / BOL',
              render: rowData => {
                return (
                  <React.Fragment>
                    {rowData.fleetConfirmation && <Link href={rowData.fleetConfirmation} target="_blank">Confirmation</Link>}
                    &nbsp; &nbsp; | &nbsp; &nbsp;
                    {rowData.fleetBOL && <Link href={rowData.fleetBOL} target="_blank">BOL</Link>}
                  </React.Fragment>
                )
              }
            },
            { title: 'Fleet Status', field: 'status' },
            
          ]}
          data={this.props.data}
          title="Fleets"
        />

      </div>
    );
  }
}

export default Fleets;
