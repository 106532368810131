import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Paper } from 'material-ui';
import { roundDecimal } from '../../util';

const styles = theme => ({
  root: {
    margin: theme.spacing(1),
  },
  key: {
    margin: theme.spacing(2),    
  },
  val: {
    margin: theme.spacing(2),
  }
});

const getTotalLists = function (key, val, classes) {
  return (
    <Grid container xs={12} spacing={24} classname={classes.root}>
      <Grid item xs={8} >
        <Typography variant="h7" gutterBottom className={classes.key}>
          {key}
        </Typography>
      </Grid>
      <Grid item xs >
        <Typography variant="h7" gutterBottom className={classes.val}>
          {val}
        </Typography>
      </Grid>
       <hr/>
    </Grid>)
}

const getNet = function (key, val, classes) {
  return (
    <Grid container xs={12} spacing={24} classname={classes.root}>
      <Grid item xs={8} >
        <Typography variant="h6" gutterBottom className={classes.key}>
          {key}
        </Typography>
      </Grid>
      <Grid item xs >
        <Typography variant="h6" gutterBottom className={classes.val}>
          {val}
        </Typography>
      </Grid>
       <hr/>
    </Grid>)
}


class PaycheckSummary extends React.Component {

  render() {
    const { classes } = this.props;

    // let driverPaycheckCutDisplay = `Driver Net  ${this.props.data.payCheckPaymentStrategy}`
    let driverPaycheckCutDisplay = `Driver Net`
    return (
      <React.Fragment>
        <Paper>

          <Grid container justify="flex-start">
            <Typography variant="h5" gutterBottom className={classes.root}>
              Totals
            </Typography>
          </Grid>
          <hr />
          <Grid container xs={12} spacing={24} direction='column'>
          {getTotalLists('Gross', roundDecimal(this.props.data.gross), classes)}
          {getTotalLists('Load Fee', roundDecimal(this.props.data.fleetLoadFee), classes)}

          {getTotalLists('Total Mileage Fee', roundDecimal(this.props.data.fleetTotalMileage), classes)}
          {getTotalLists('Dispatch Fee', roundDecimal(this.props.data.dispatchFee), classes)}
          {getTotalLists('Net after Dispatch', roundDecimal(this.props.data.totalNet), classes)}
          {getTotalLists('Driver Lumper Fee', roundDecimal(this.props.data.driverLumperFee), classes)}
          {getTotalLists('Other Payments', roundDecimal(this.props.data.otherPayments), classes)}
          <hr/>
          {getTotalLists('Fuel Charge', roundDecimal(this.props.data.fuelCharge), classes)}
          {getTotalLists('Toll', roundDecimal(this.props.data.tollCharge), classes)}
          {getTotalLists('Insurance', roundDecimal(this.props.data.insurance), classes)}
          {getTotalLists('Insurance DPT', roundDecimal(this.props.data.insuranceDPT), classes)}
          {getTotalLists('Advances', roundDecimal(this.props.data.advance), classes)}
          {getTotalLists('Other Expenses', roundDecimal(this.props.data.otherExpenses), classes)}
          {getTotalLists('Pay Check Expense', roundDecimal(this.props.data.payCheckExpense), classes)}

          {getTotalLists('Total Reimbursable', roundDecimal(this.props.data.reimbursable), classes)}

          {getNet(driverPaycheckCutDisplay, roundDecimal(this.props.data.net), classes)}
          </Grid>
        </Paper>
        
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(PaycheckSummary);