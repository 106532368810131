import { getObjectFromList } from "./FAPI";

import * as CONST from "./Const";
import { isNotNullOrEmpty } from "./Validator";


export const has_tab_permission = function (props, tab) {
  let tabNavLists = CONST.TAB_ROUTER_POLICY_LIST[tab]

  const tabIsNull = !isNotNullOrEmpty(tabNavLists)
  if (tabIsNull) { // Unregistered tab fail!
    return false
  }
  let urlPermission = {
      urlRoute: undefined,
  }
  tabNavLists.forEach(function (item, index) {
    if (has_view_permission(props.profile, item.policy_id)) {
        urlPermission['urlRoute'] = item.urlRoute
        return 
     }
   });
  
  // If you can see all the navlist under the tab return true, false otherwise.
  return urlPermission
}

export const hasPermission = function (props, route) {
  return true
}

export const has_view_user_permission = function (props, route) {
  if (props.profile.profileType == 'ADMIN' || props.profile.profileType == 'CARRIER') {
    return true;
  }
  return false
}

export const isUserAdminOrCarrier = function (props) {
  if (props.profile.profileType == 'ADMIN' || props.profile.profileType == 'CARRIER') {
    return true;
  }
  return false
}

export const   isProfileActive = function (profile) {
  let isProfileActive = profile && profile.state == CONST.INVOICE_STATE.ACTIVE
  let isParentProfileActive =  profile && (!!!profile.ownerState || profile.ownerState == CONST.INVOICE_STATE.ACTIVE)
  return !!(isParentProfileActive && isProfileActive)
}

export const isProfileDispatcher = function (profile) {
  return false
  // return !!(profile && profile.profileType == 'DISPATCHER') ||
  //     !!(profile && profile.profileType == 'PERSONNEL')
}


// Array of policies get target id and return object
const _get_policy = function (policies,  target) {
  let policy =  getObjectFromList(policies, target , "id")
  return policy
}
export const has_permission = function(profile, target, action) {
  if (!!!profile ){
    return false
  }
 
  if (profile.profileType == 'ADMIN' || profile.profileType == 'CARRIER') {
    return true;
  }
  
  if (!!!target || target === '*' ){
    return true
  }
  if (profile.profileType == 'PERSONNEL') {
    if (!!!profile.profile_summary) {
      return false;
    }
    let policies = profile.profile_summary.policy
    let policy = _get_policy(policies, target)
    if (!!policy && policy[action]){
       return true;
    }
    return false
  }
}

export const has_view_permission = function(profile, target) {
 return has_permission(profile, target, 'view')
}

export const has_create_permission = function(profile, target) {
  return has_permission(profile, target, 'create')
 }

 export const has_edit_permission = function(profile, target) {
  return has_permission(profile, target, 'edit')
 }

 export const has_delete_permission = function(profile, target) {
  return has_permission(profile, target, 'delete')
 }

 export const has_link_permission = function(profile, target) {
  return has_permission(profile, target, 'view') 
    // || has_permission(profile, target, 'edit')
 }