import React from 'react';
import FMaterialTable from '../../widgets/FMaterialTable';
import { getStateIcon } from '../../../util/icon_util';

export default class Vehicles extends React.Component {
  constructor(props) {
    super(props)
  }
  

  render() {

    return (
      <div>

        <FMaterialTable
          tableRef={this.props.tableRef}
          columns={[
            // {
            //   title: 'Name', 
            //   field: 'prettyName',
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         {`${rowData.driverFirstName} ${rowData.driverMiddleName || ''} ${rowData.driverLastName}`}
            //       </React.Fragment>
            //     )
            //   }
            // },
            {
              title: 'State', 
              render: rowData => {
                return (
                  <React.Fragment>
                      {getStateIcon(rowData)}
                  </React.Fragment>
                )
              }
            },
            {title: 'Vehicle #', field: 'vehicleNumber'},
            {title: 'Vehicle Type', field: 'vehicleType'},
            {title: 'VIN #', field: 'vehicleVIN'},
            {title: 'Driver', field: 'driverPrettyName'}
           
          ]}
          // actions={
          //   [
          //     {
          //       icon: () => (
          //         <FRouterAddLink to={ROUTE_FLEET_URL + 'add'} color='secondary' />
          //       ),
          //       isFreeAction: true
          //     }
          //   ]} 
          options = {{
              pageSize: this.props.pstate.pageSize,
              pageSizeOptions: this.props.pstate.pageSizeOptions,
              actionsColumnIndex: -1,
              search: false
           }}
          data={this.props.fetchData}
          listErrCode={this.props.listErrCode}
          // title="Vehicles"
          
          // components={{
          //   Toolbar: props => (
          //       <div style={{ backgroundColor: '#e8eaf5' }}>
          //           <MTableToolbar {...props} />
          //       </div>
          //   )
          // }}
        />

      </div>
    );
  }
}


// export default connect(
//   mapStateToProps, mapDispatchToProps)(Vehicles)
