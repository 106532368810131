import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import FMessage from "../widgets/FMessage";
import FInput from "../widgets/FInput";
import { mapDispatchToProps, mapStateToProps } from "../../fredux/ActionMaker";
import { API_DOCUMENTS_URL, TAB_ADMIN,  API_DISPATCHERS_URL, API_CARRIERS_URL, ADMIN_DISPATCHERS } from "../../util/Const";
import DocumentView from "../document"
import { isValid } from "../../util/Validator";
import { VALIDATE_CARRIER_REQUIRED, VALIDATE_REQUIRED, VALIDATE_PHONE_NUMBER, VALIDATE_EMAIL, VALIDATE_ADDRESS } from "../../util/ValidatorConst";
import FDetailComponent from "../../util/FDetailComponent";
import FAutoComplete from "../widgets/FAutoComplete";
import FAddress from "../widgets/FAddress";
import FFileUpload from "../widgets/FFileUpload";



const styles = theme => ({
    root: {
        padding: theme.spacing(1),

    },
    padding: {
        marginTop: theme.spacing(4),
    },
    root1: {
        backgroundColor: '#fff',
        padding: theme.spacing(2),
    },
});

const PROPS = {
    errorData: {
        dispatcherFirstName: false,
        dispatcherLastName: false,
        dispatcherPhoneNumber: false,
        dispatcherEmail: false,
        dispatcherAccessID: false,
        // dispatcherLocation: false,
        carrier: false,
        address: false,
        dispatcherProfilePicture: false
    },
    errorMessage: {
        dispatcherFirstName: 'Invalid Name',
        dispatcherLastName: 'Invalid Name',
        dispatcherPhoneNumber: 'Invalid Phone Number',
        dispatcherEmail: 'Invalid Email',
        dispatcherAccessID: 'Invalid Dispatcher ID',
        // dispatcherLocation: 'Invalid Address',
        carrier: 'Invalid Carrier',
        dispatcherProfilePicture: 'Invalid Photo'
    },
    data: {
        dispatcherFirstName: '',
        dispatcherLastName: '',
        dispatcherPhoneNumber: '',
        dispatcherEmail: '',
        dispatcherAccessID: '',
        // dispatcherLocation: '',
        dispatcherProfilePicture: '',
        carrier: {},
        address: ''
    },
    validators: {
        dispatcherFirstName: [VALIDATE_REQUIRED],
        dispatcherLastName: [VALIDATE_REQUIRED],
        dispatcherPhoneNumber: [VALIDATE_PHONE_NUMBER],
        dispatcherEmail: [VALIDATE_EMAIL],
        dispatcherAccessID: [],
        // dispatcherLocation: [VALIDATE_REQUIRED],
        carrier: [VALIDATE_CARRIER_REQUIRED],
        address: [VALIDATE_ADDRESS]
    },
    touched: {}
}

class DispatcherDetial extends FDetailComponent {

    constructor(props) {
        super(props, API_DISPATCHERS_URL, 'dispatcher', PROPS)
    }

    getErrorData = () => {
        let errorData = {
            dispatcherFirstName: !isValid(this.state.data.dispatcherFirstName, this.state.validators.dispatcherFirstName),
            dispatcherLastName: !isValid(this.state.data.dispatcherLastName, this.state.validators.dispatcherLastName),
            dispatcherPhoneNumber: !isValid(this.state.data.dispatcherPhoneNumber, this.state.validators.dispatcherPhoneNumber),
            dispatcherEmail: !isValid(this.state.data.dispatcherEmail, this.state.validators.dispatcherEmail),
            // dispatcherLocation: !isValid(this.state.data.dispatcherLocation, this.state.validators.dispatcherLocation)
            address: !isValid(this.state.data.address, this.state.validators.address),
            carrier: !isValid(this.state.data.carrier, this.state.validators.carrier),

        }
        return errorData
    }
    getCurrentTitle = () => {
        let title = 'Dispatchers ' + this.state.data.dispatcherFirstName + ' ' + this.state.data.dispatcherLastName
        this.props.updateCurrentTab(TAB_ADMIN)
        this.props.updateCurrentTitle && this.props.updateCurrentTitle(title)
    }

    hasCustomErrors = () => {
        return !(this.isAddressValid(true, true, true, true))
     }

    render() {
        // this.props.updateCurrentTitle && this.props.updateCurrentTitle(this.getCurrentTitle())
        this.getCurrentTitle()
        const { classes } = this.props;
        return (
            <React.Fragment>
                {/* <FTitle label={'Dispatcher -  ' + this.state.data.dispatcherFirstName + ' ' + this.state.data.dispatcherLastName} /> */}
                <Grid container direction='column'  alignItems='stretch' spacing={2} className={classes.root1} >
                    <Grid item xs>
                        <FMessage error={this.props.crudServerError}
                            success={this.props.crudServerSuccess} />
                    </Grid>
                    <Grid container className={classes.root} spacing= {2} xs={12} >
                            <Grid item xs={4}>
                                        <FAutoComplete
                                                url={API_CARRIERS_URL}
                                                name='carrier'
                                                hasError={this.state.errorData.carrier}
                                                helpMessage={this.state.errorMessage.carrier}
                                                label='Authority'
                                                // queryParam='search'
                                                value={this.state.data.carrier}
                                                defaultValue={this.state.data.carrier}
                                                notifyParent={this.updateValues}
                                                disabled={!this.state.isNew}
                                                // notifyDependency={this.onCarrierChange}
                                                />
                                
                                    </Grid>
                            
                    </Grid>
                    <Grid container className={classes.root} spacing= {4} xs={12} >
                            <Grid item xs  hidden={this.state.isNew}>
                                <FInput name='dispatcherAccessID' required
                                    value={this.state.data.dispatcherAccessID}
                                    hasError={this.state.errorData.dispatcherAccessID}
                                    helpMessage={this.state.errorMessage.dispatcherAccessID}
                                    disabled={true}
                                    label="Dispatcher Access ID"
                                    notifyParent={this.updateValues}
                                />
                            </Grid>
                            <Grid item xs>
                                <FInput name='dispatcherFirstName' required
                                    value={this.state.data.dispatcherFirstName}
                                    hasError={this.state.errorData.dispatcherFirstName}
                                    helpMessage={this.state.errorMessage.dispatcherFirstName}
                                    label="First Name" notifyParent={this.updateValues}
                                />
                            </Grid>  
                            <Grid item xs>
                                <FInput name='dispatcherMiddleName' required
                                    value={this.state.data.dispatcherMiddleName}
                                    hasError={this.state.errorData.dispatcherMiddleName}
                                    helpMessage={this.state.errorMessage.dispatcherMiddleName}
                                    label="Middle Name" notifyParent={this.updateValues}
                                />
                            </Grid>  
                            <Grid item xs>
                                <FInput name='dispatcherLastName' required
                                    value={this.state.data.dispatcherLastName}
                                    hasError={this.state.errorData.dispatcherLastName}
                                    helpMessage={this.state.errorMessage.dispatcherLastName}
                                    label="Last Name" notifyParent={this.updateValues}
                                />
                            </Grid>
                    </Grid>

                    <Grid container className={classes.root} spacing= {2} xs={12} >
                            {/* <Grid item xs={4}>
                                        <FAutoComplete
                                                url={API_CARRIERS_URL}
                                                name='carrier'
                                                hasError={this.state.errorData.carrier}
                                                helpMessage={this.state.errorMessage.carrier}
                                                label='Authority'
                                                // queryParam='search'
                                                value={this.state.data.carrier}
                                                defaultValue={this.state.data.carrier}
                                                notifyParent={this.updateValues}
                                                disabled={!this.state.isNew}
                                                // notifyDependency={this.onCarrierChange}
                                                />
                                
                                    </Grid> */}
                            <Grid item xs={3}>
                                <FInput name='dispatcherPhoneNumber' required
                                    value={this.state.data.dispatcherPhoneNumber}
                                    hasError={this.state.errorData.dispatcherPhoneNumber}
                                    helpMessage={this.state.errorMessage.dispatcherPhoneNumber}
                                    label="Phone Number" notifyParent={this.updateValues}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FInput name='dispatcherEmail' required
                                    value={this.state.data.dispatcherEmail}
                                    hasError={this.state.errorData.dispatcherEmail}
                                    helpMessage={this.state.errorMessage.dispatcherEmail}
                                    label="Email ( unique per account )" notifyParent={this.updateValues}
                                />
                            </Grid>
                            {/* <Grid item xs>
                               <FInput name='dispatcherLocation' required
                                    value={this.state.data.dispatcherLocation}
                                    hasError={this.state.errorData.dispatcherLocation}
                                    helpMessage={this.state.errorMessage.dispatcherLocation}
                                    label="Address"
                                    notifyParent={this.updateValues}
                                />
                            </Grid> */}
                            
                    </Grid>
                    <Grid container className={classes.root} spacing= {2} xs={12} >
                    <Grid item xs>
                        <FAddress 
                                    name='address' 
                                    notifyParent={this.updateValues}
                                    address={this.state.data.address}
                                    hasError={this.state.errorData.address}
                                    place={true}
                                    city={true}
                                    state={true}
                                    zipCode={true}
                        />
                        </Grid>
                    </Grid>

                    <Grid container className={classes.root} spacing= {4} xs={12} >
                            <Grid item xs={6}>
                                 <FInput
                                    name='desc' value={this.state.data.desc} multiline={true}
                                    value={this.state.data.desc}
                                    hasError={this.state.errorData.desc}
                                    helpMessage={this.state.errorMessage.desc}
                                    label="Description" notifyParent={this.updateValues} />
                            </Grid>
                            <Grid item xs={2}>
                                    <FFileUpload
                                        value={this.state.data.dispatcherProfilePicture}
                                        name='dispatcherProfilePicture'
                                        label="Dispatcher Picture"
                                        hasError={this.state.errorData.dispatcherProfilePicture}
                                        helpMessage={this.state.errorMessage.dispatcherProfilePicture}
                                        notifyParent={this.updateValues} />
                            </Grid>
                    
                    </Grid>
                    <Grid container className={classes.root} spacing= {4} xs={12} >
                        {/* <Grid item container xs={8} >
                            <FButton color='primary'
                                label={this.state.isNew ? 'Save' : 'Update'}
                                loading={this.state.loading}
                                onClick={this.onSaveData}
                            />
                            <Grid item style={{ paddingTop: '9px' }}>
                                <FRouteBackLink variant='outlined' color='secondary' className={'backButton'} />
                            </Grid>
                        </Grid> */}
                         <Grid item container xs={8} >
                            {this.getUpdateButton(this.props.profile, ADMIN_DISPATCHERS)}
                        </Grid>
                    </Grid>
                    <Grid item xs className={classes.padding}>
                        {!this.state.isNew &&
                            <DocumentView
                                ownerId={this.props.match.params.id}
                                owner={this.state.data}
                                listUrl={API_DOCUMENTS_URL + `?&dispatcher=${this.props.match.params.id}`}
                                ownerType='dispatcher'
                                policy_id={ADMIN_DISPATCHERS}
                            />}
                    </Grid>
                </Grid >
            </React.Fragment>
        );
    };
}

DispatcherDetial.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(connect(
    mapStateToProps, mapDispatchToProps)(DispatcherDetial))
