import React, { Component } from 'react';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core';


const style = theme => ({
  root: {
    margin: theme.spacing(1),
    width: '100%'
  }
});

class FReloadButton extends Component {
 
  onClick = () => {
    window.location.reload(false);
  }

  render() {
    const { classes } = this.props
    return (
      // <div className="form-group">
      <div>
        <Button 
          className={this.props.className||classes.root}
          disabled={this.props.disabled}
          color={this.props.color || 'primary'}
          variant={this.props.variant || "outlined"}
          onClick={this.onClick}>
          {/* <RefreshIcon/> */}
          {this.props.label || 'Reload'}
      
        </Button>

      </div>);
  }
}

export default withStyles(style)(FReloadButton)