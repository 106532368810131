
const COUNTRY_STATES = [
   {prettyName:'AL', value: 'Alabama'},
   {prettyName:'AK', value: 'Alaska'},
   {prettyName:'AS', value: 'American Samoa'},
   {prettyName:'AZ', value: 'Arizona'},
   {prettyName:'AR', value: 'Arkansas'},
   {prettyName:'CA', value: 'California'},
   {prettyName:'CO', value: 'Colorado'},
   {prettyName:'CT', value: 'Connecticut'},
   {prettyName:'DE', value: 'Delaware'},
   {prettyName:'DC', value: 'District Of Columbia'},
   {prettyName:'FM', value: 'Federated States Of Micronesia'},
   {prettyName:'FL', value: 'Florida'},
   {prettyName:'GA', value: 'Georgia'},
   {prettyName:'GU', value: 'Guam'},
   {prettyName:'HI', value: 'Hawaii'},
   {prettyName:'ID', value: 'Idaho'},
   {prettyName:'IL', value: 'Illinois'},
   {prettyName:'IN', value: 'Indiana'},
   {prettyName:'IA', value: 'Iowa'},
   {prettyName:'KS', value: 'Kansas'},
   {prettyName:'KY', value: 'Kentucky'},
   {prettyName:'LA', value: 'Louisiana'},
   {prettyName:'ME', value: 'Maine'},
   {prettyName:'MH', value: 'Marshall Islands'},
   {prettyName:'MD', value: 'Maryland'},
   {prettyName:'MA', value: 'Massachusetts'},
   {prettyName:'MI', value: 'Michigan'},
   {prettyName:'MN', value: 'Minnesota'},
   {prettyName:'MS', value: 'Mississippi'},
   {prettyName:'MO', value: 'Missouri'},
   {prettyName:'MT', value: 'Montana'},
   {prettyName:'NE', value: 'Nebraska'},
   {prettyName:'NV', value: 'Nevada'},
   {prettyName:'NH', value: 'New Hampshire'},
   {prettyName:'NJ', value: 'New Jersey'},
   {prettyName:'NM', value: 'New Mexico'},
   {prettyName:'NY', value: 'New York'},
   {prettyName:'NC', value: 'North Carolina'},
   {prettyName:'ND', value: 'North Dakota'},
   {prettyName:'MP', value: 'Northern Mariana Islands'},
   {prettyName:'OH', value: 'Ohio'},
   {prettyName:'OK', value: 'Oklahoma'},
   {prettyName:'OR', value: 'Oregon'},
   {prettyName:'PW', value: 'Palau'},
   {prettyName:'PA', value: 'Pennsylvania'},
   {prettyName:'PR', value: 'Puerto Rico'},
   {prettyName:'RI', value: 'Rhode Island'},
   {prettyName:'SC', value: 'South Carolina'},
   {prettyName:'SD', value: 'South Dakota'},
   {prettyName:'TN', value: 'Tennessee'},
   {prettyName:'TX', value: 'Texas'},
   {prettyName:'UT', value: 'Utah'},
   {prettyName:'VT', value: 'Vermont'},
   {prettyName:'VI', value: 'Virgin Islands'},
   {prettyName:'VA', value: 'Virginia'},
   {prettyName:'WA', value: 'Washington'},
   {prettyName:'WV', value: 'West Virginia'},
   {prettyName:'WI', value: 'Wisconsin'},
   {prettyName:'WY', value: 'Wyoming'}]
   


 export const COUNTRY_STATES_NAMES = [
   {value:'AL', prettyName: 'Alabama'},
   {value:'AK', prettyName: 'Alaska'},
   {value:'AS', prettyName: 'American Samoa'},
   {value:'AZ', prettyName: 'Arizona'},
   {value:'AR', prettyName: 'Arkansas'},
   {value:'CA', prettyName: 'California'},
   {value:'CO', prettyName: 'Colorado'},
   {value:'CT', prettyName: 'Connecticut'},
   {value:'DE', prettyName: 'Delaware'},
   {value:'DC', prettyName: 'District Of Columbia'},
   {value:'FM', prettyName: 'Federated States Of Micronesia'},
   {value:'FL', prettyName: 'Florida'},
   {value:'GA', prettyName: 'Georgia'},
   {value:'GU', prettyName: 'Guam'},
   {value:'HI', prettyName: 'Hawaii'},
   {value:'ID', prettyName: 'Idaho'},
   {value:'IL', prettyName: 'Illinois'},
   {value:'IN', prettyName: 'Indiana'},
   {value:'IA', prettyName: 'Iowa'},
   {value:'KS', prettyName: 'Kansas'},
   {value:'KY', prettyName: 'Kentucky'},
   {value:'LA', prettyName: 'Louisiana'},
   {value:'ME', prettyName: 'Maine'},
   {value:'MH', prettyName: 'Marshall Islands'},
   {value:'MD', prettyName: 'Maryland'},
   {value:'MA', prettyName: 'Massachusetts'},
   {value:'MI', prettyName: 'Michigan'},
   {value:'MN', prettyName: 'Minnesota'},
   {value:'MS', prettyName: 'Mississippi'},
   {value:'MO', prettyName: 'Missouri'},
   {value:'MT', prettyName: 'Montana'},
   {value:'NE', prettyName: 'Nebraska'},
   {value:'NV', prettyName: 'Nevada'},
   {value:'NH', prettyName: 'New Hampshire'},
   {value:'NJ', prettyName: 'New Jersey'},
   {value:'NM', prettyName: 'New Mexico'},
   {value:'NY', prettyName: 'New York'},
   {value:'NC', prettyName: 'North Carolina'},
   {value:'ND', prettyName: 'North Dakota'},
   {value:'MP', prettyName: 'Northern Mariana Islands'},
   {value:'OH', prettyName: 'Ohio'},
   {value:'OK', prettyName: 'Oklahoma'},
   {value:'OR', prettyName: 'Oregon'},
   {value:'PW', prettyName: 'Palau'},
   {value:'PA', prettyName: 'Pennsylvania'},
   {value:'PR', prettyName: 'Puerto Rico'},
   {value:'RI', prettyName: 'Rhode Island'},
   {value:'SC', prettyName: 'South Carolina'},
   {value:'SD', prettyName: 'South Dakota'},
   {value:'TN', prettyName: 'Tennessee'},
   {value:'TX', prettyName: 'Texas'},
   {value:'UT', prettyName: 'Utah'},
   {value:'VT', prettyName: 'Vermont'},
   {value:'VI', prettyName: 'Virgin Islands'},
   {value:'VA', prettyName: 'Virginia'},
   {value:'WA', prettyName: 'Washington'},
   {value:'WV', prettyName: 'West Virginia'},
   {value:'WI', prettyName: 'Wisconsin'},
   {value:'WY', prettyName: 'Wyoming'}]
   
export default COUNTRY_STATES
