import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import { Grid } from '@material-ui/core';
import FDetailComponent from '../../util/FDetailComponent';
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import { connect } from "react-redux"
import {  API_AUTH_CHANGE_PASSWORD } from '../../util/Const';
import FButton from '../widgets/FButton';
import { isValid } from '../../util/Validator';
import { VALIDATE_PASSWORD } from '../../util/ValidatorConst';
import FPassword from '../widgets/FPassword';
import FCheckBox from '../widgets/FCheckBox';

const styles = theme => ({
  root: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1)
  }
});


class ChnagePassword extends FDetailComponent {

  constructor(props) {
    super(props, API_AUTH_CHANGE_PASSWORD, 'profile', {})
    this.state = {
      module: props.module || "driver profile",
      loading: false,
      method: 'post',
      url: API_AUTH_CHANGE_PASSWORD,
      formData: {},
      data: {
        profileId: props.profileId,
        profilePassword: '',
        showChangePassword: false,
      },
      touched: {
        profileId: true
      },
      errorData: {
        profilePassword: '',
        showChangePassword: ''
      },
      errorMessage: {
        profileId: 'Invalid Personnel / Driver',
        profilePassword: 'Invalid Password',
        showChangePassword: ''
      },
      validators: {
        profilePassword: [VALIDATE_PASSWORD],
        showChangePassword: []
      }
    }
  }

  fetchDetail = () => {
  }
  
  getSaveMeta = () => {
    return {
       method: "post",
      url: this.state.url
    }
  }
  routeTosignIn = () => {
    this.props.dispatchSignIn()
    window.location.href = '/'
  }

  getErrorData = () => {
    let errorData = {
      profilePassword: !isValid(this.state.data.profilePassword, this.state.validators.profilePassword),
    }
    return errorData
  }



  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
          <Grid container justify='space-between' alignItems='stretch' spacing={3} xs={4}
             className={classes.root} >
          <Grid item >
              <FCheckBox
                  name='showChangePassword'
                  value={this.state.data.showChangePassword}
                  hasError={this.state.errorData.showChangePassword}
                  helpMessage={this.state.errorMessage.expenseIsDeductable}
                  label=" Change Password"
                  notifyParent={this.updateValues} />
          </Grid>
          <Grid item xs = {4} hidden={!this.state.data.showChangePassword}>
            <FPassword name='profilePassword' required
              value={this.state.profilePassword}
              type='password'
              hasError={this.state.errorData.profilePassword}
              helpMessage={this.state.errorMessage.profilePassword}
              label="Password" notifyParent={this.updateValues}
            />
          </Grid>
          <Grid item xs={4} hidden={!this.state.data.showChangePassword}>
            <FButton
              label='Update Password'
              color="primary"
              loading={this.state.loading}
              onClick={this.onSaveData}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}
ChnagePassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

// export default withStyles(styles)(ChnagePassword);

export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps)(ChnagePassword))
