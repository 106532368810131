import React, { Component } from 'react';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { Link } from '@material-ui/core';
import { green } from '@material-ui/core/colors';




class FAttachment extends Component {


  render() {
    return (
      <React.Fragment>
           {this.props.label}
           { this.props.url && <Link href={this.props.url} target="_blank">
              <AttachmentIcon style={{ fontSize: 24, paddingLeft: '2px', color: green[500] }}/>
          </Link>}

    </React.Fragment>
    )
  }
}

export default FAttachment
