import React from 'react';
import { Link } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';


import { API_EXPENSE_URL, ROUTE_EXPENSE_URL } from '../../../util/Const';
import { roundDecimal, getPrettyDate } from '../../../util';
import FMaterialTable from '../../widgets/FMaterialTable';
import FRouterLink from '../../widgets/FRouterLink';

export default class Expenses extends React.Component {

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div>
        <FMaterialTable
          tableRef={this.props.tableRef}
          columns={[
            {
              title: 'Transaciton Id', 
              // field: 'expenseTransactionID',
              render: rowData => {
                return (
                  <React.Fragment>
                    <FRouterLink label={rowData.expenseTransactionID} to={ROUTE_EXPENSE_URL + rowData.id} />
                  </React.Fragment>
                )
              }
            },
            { title: 'Transaction Date', field: 'expenseTransactionDate',
               render: rowData => getPrettyDate(rowData.expenseTransactionDate) },
           
            { title: 'Expense Type', field: 'expenseType'},
          
            { title: 'Total Amount ($)', field: 'expenseAmount',  render: rowData => roundDecimal(rowData.expenseAmount)},
            { title: 'Net Carrier Expense ($)', field: 'netAppliedToCarrier',  render: rowData => roundDecimal(rowData.netAppliedToCarrier)},
    
          ]}
          
          options = {{
            pageSize: this.props.pstate.pageSize,
            pageSizeOptions: this.props.pstate.pageSizeOptions,
            actionsColumnIndex: -1,
            search: false
         }}
          data={this.props.fetchData}
          listErrCode={this.props.listErrCode}
          // title={`Expenses`}
        
        />
      </div>
    );
  }
}


// export default connect(
//   mapStateToProps, mapDispatchToProps)(Expenses)
