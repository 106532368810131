import React from "react";
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";


import { Grid, Paper } from "@material-ui/core";
import FSelect from "../widgets/FSelect";
import FMessage from "../widgets/FMessage";
import FInput from "../widgets/FInput";
import { mapDispatchToProps, mapStateToProps } from "../../fredux/ActionMaker";
import { API_CARRIERS_URL, API_VEHICLES_URL, TAB_SAFETY_AND_COMPLIANCE, API_VEHICLE_MAINTENANCE_SCHEDULE_URL, API_VEHICLE_MAINTENANCE_SCHEDULE_JOB_URL, STG_DATE, STG_MILEAGE, SAFETY_AND_COMPLINACE_MAINTENANCE_SCHEDULES, CHOICE_MAINTENANCE_SCHEDULE } from "../../util/Const";
import { VALIDATE_CARRIER_REQUIRED,VALIDATE_OBJECT_REQUIRED,  VALIDATE_REQUIRED, VALIDATE_POSITIVE } from "../../util/ValidatorConst";
import FTabs from "./ScheduleJobTab"
import FDetailComponent from "../../util/FDetailComponent";
import FDate from "../widgets/FDate";
import { isValid } from "../../util/Validator";
import FAutoComplete from "../widgets/FAutoComplete";
import JobScheduleListDetail from "../schedule_job/JobScheduleListDetail";
import { toFloat } from "../../util";

const styles = theme => ({
    root: {
        display: "flex",

    },
    padding: {
        marginTop: theme.spacing(4),
    },

    header: {
        background: '#f5f5f5'
    },
    button: {
        marginRight: theme.spacing(2),
    },
    root1: {
        backgroundColor: '#fff',
        padding: theme.spacing(2),
    },
});

const showUpcoming = 0
const showHistoryJobs = 1

const PROPS = {
    showJScheduleJobs: showUpcoming, // Show Upcoming tabs
    
    errorData: {
    },
    errorMessage: {
        scheduleType: 'Invalid Schedul Type',
        scheduleName: 'Schedule Name required (e.g Brake check',
        schduleStartDate: 'Invaid Date',
        schduleEndDate: 'Invaid Date',
        scheduleFrequencyStrategyType: 'Invalid schedule interval',
        scheduleFrequencyValue: 'Invalid interval value',
        scheduleStartMileage: 'Invalid  Mileage Interval',
        scheduleEndMileage: 'Invalid  Mileage Interval',
        scheduleDay: 'Invalid Day',
        vehicle: 'Vehicle Required',
        carrier: 'Invalid Carrier'
    },
    touched: {
        scheduleType: true,
    },
    data: {
        scheduleType: 'PREVENTIVE_MAINTENANCE',
        scheduleName: '',
        schduleStartDate: '',
        schduleEndDate: '',
        scheduleStartMileage: '',
        scheduleEndMileage: '',
        scheduleFrequencyStrategyType: '',
        scheduleFrequencyValue: '',
        scheduleDay: '',
        vehicle: {},
        carrier: {}
    },
    validators: {
        scheduleName: [VALIDATE_REQUIRED],
        schduleStartDate: [VALIDATE_REQUIRED],
        schduleEndDate: [VALIDATE_REQUIRED],
        scheduleFrequencyStrategyType: [VALIDATE_REQUIRED],
        scheduleFrequencyValue: [VALIDATE_REQUIRED],
        scheduleStartMileage: [VALIDATE_POSITIVE],
        scheduleEndMileage: [VALIDATE_POSITIVE],
        vehicle: [VALIDATE_OBJECT_REQUIRED],
        carrier: [VALIDATE_CARRIER_REQUIRED],
    }
}



class VehicleMaintenanceDetail extends FDetailComponent {
    constructor(props) {
        super(props, API_VEHICLE_MAINTENANCE_SCHEDULE_URL, CHOICE_MAINTENANCE_SCHEDULE, PROPS)
    }
  
    getErrorData = () => {
        let errorData = {

            scheduleName: !isValid(this.state.data.scheduleName, this.state.validators.scheduleName),
            schduleStartDate: !this.isVAlidScheduleDate(),
            schduleEndDate: !this.isVAlidScheduleDate(),
         
            // scheduleStartMileage:  this.isScheduleStg('MILEAGE') && !isValid(this.state.data.scheduleStartMileage, this.state.validators.scheduleStartMileage),
            // scheduleEndMileage: !this.isVAlidScheduleRange('MILEAGE') && !isValid(this.state.data.scheduleEndMileage, this.state.validators.scheduleEndMileage),
            scheduleStartMileage: !this.isVAlidScheduleDate(),
            scheduleEndMileage: !this.isVAlidScheduleDate(),

            scheduleFrequencyStrategyType:  !isValid(this.state.data.scheduleFrequencyStrategyType, this.state.validators.scheduleFrequencyStrategyType),
            scheduleFrequencyValue: !this.showIntervalFrequencyValue() && !isValid(this.state.data.scheduleFrequencyValue, this.state.validators.scheduleFrequencyValue),
            vehicle: !isValid(this.state.data.vehicle, this.state.validators.vehicle),
            carrier: !isValid(this.state.data.carrier, this.state.validators.carrier)
        }
        return errorData
    }

    onCarrierChange = () => {
        this.updateValues('vehicle', {vehicle: ''})
    }
    
    getCurrentTitle = () => {
        let title = 'Maintenance Schedule'
        this.props.updateCurrentTab(TAB_SAFETY_AND_COMPLIANCE)
        this.props.updateCurrentTitle && this.props.updateCurrentTitle(title)
        return title
    }

    showIntervalFrequencyValue = () => {

        return !(this.state.data.scheduleFrequencyStrategyType == 'DAYS' ||
                this.state.data.scheduleFrequencyStrategyType == 'MILEAGE' )
    }

    showDay = () => {
        return (this.state.data.scheduleFrequencyStrategyType == 'WEEKLY' )
    }

    onJobScheduleChange = (event, newValue) => {
        this.setState({showJScheduleJobs: newValue});
    };

    isScheduleStg = (dateORMileage) => {
        let val = false
        if (dateORMileage == 'MILEAGE') {
            val =   STG_MILEAGE.includes(
                this.state.data.scheduleFrequencyStrategyType
            )
        }
        if (dateORMileage == 'DATE') {
            val =  STG_DATE.includes(
                this.state.data.scheduleFrequencyStrategyType
            )
        }
        return val;
    }
    isVAlidScheduleDate = ()=>{
        if (!!!this.isScheduleStg('DATE')) return true;
        let startDate = isValid(this.state.data.schduleStartDate, this.state.validators.schduleStartDate)
        let endDate = isValid(this.state.data.schduleEndDate, this.state.validators.schduleEndDate)
        let val = (!!startDate && !!endDate)
        return val
    }
    
    isMileageInterval = ()=>{
        if (!!!this.isScheduleStg('MILEAGE')) return true;
        let startMileage = toFloat(this.state.data.scheduleStartMileage)
        let endMileage = toFloat(this.state.data.scheduleEndMileage)    
        return (startMileage > 0 && startMileage < endMileage)
    }

    getFrequencyLabel = () => {
        if (!!this.isScheduleStg('DATE')) {
            return " Frequency (Number of Days)"
        }
        if (!!this.isScheduleStg('MILEAGE')) {
            return "Frequency (Mileage)"
        }
    }


    
    render() {
        const { classes } = this.props;
        this.getCurrentTitle()

        return (
            <Paper className={classes.root1} >
                <Grid container direction='column'  className={classes.root1} spacing={2}  xs={12} >
                    <Grid item xs>
                        <FMessage error={this.props.crudServerError}
                            success={this.props.crudServerSuccess} />
                    </Grid>
                            <Grid container  spacing={2} xs={8} >
                                    <Grid item xs>
                                        <FAutoComplete
                                            url={API_CARRIERS_URL}
                                            name='carrier'
                                            hasError={this.state.errorData.carrier}
                                            helpMessage={this.state.errorMessage.carrier}
                                            label='Authority'
                                            // queryParam='search'
                                            value={this.state.data.carrier}
                                            defaultValue={this.state.data.carrier}
                                            notifyParent={this.updateValues}
                                            disabled={!this.state.isNew}
                                            notifyDependency={this.onCarrierChange}
                                            />
                                    </Grid>
                                    <Grid item xs> 
                                        <FAutoComplete
                                            url={API_VEHICLES_URL}
                                            // queryParam={'vehicleType=TRUCK'}
                                            hasCarrier={true}
                                            carrier = {this.state.data.carrier}
                                            name='vehicle'
                                            hasError={this.state.errorData.vehicle}
                                            helpMessage={this.state.errorMessage.vehicle}
                                            label='Truck / Trailer'
                                            disabled={!this.state.isNew}
                                            // queryParam='search'
                                            value={this.state.data.vehicle}
                                            defaultValue={this.state.data.vehicle}
                                            notifyParent={this.updateValues}
                                            // disabled={!this.state.isNew}
                                            />
                                    </Grid>     
                                  
                                <Grid item xs>
                                    <FSelect
                                        name='scheduleFrequencyStrategyType'
                                        required
                                        value={this.state.data.scheduleFrequencyStrategyType}
                                        hasError={this.state.errorData.scheduleFrequencyStrategyType}
                                        helpMessage={this.state.errorMessage.scheduleFrequencyStrategyType}
                                        label='Schedule By*'
                                        data={this.props.scheduleFrequencyStrategyTypeChoices}
                                        valueRef='prettyName'
                                        keyRef='id'
                                        disabled={!this.state.isNew}
                                        notifyParent={this.updateValues} />
                                </Grid>

                                
                        </Grid>
                        <Grid container  spacing= {2} xs={12} >     
                            <Grid item xs hidden={this.state.isNew}>
                                <FInput
                                        // name='scheduleFrequencyValue'
                                        disabled={true}
                                        value={this.state.data.scheduleInfo && 
                                               this.state.data.scheduleInfo.totalScheduledJobs}
                                        // hasError={this.state.errorData.scheduleFrequencyValue}
                                        // helpMessage={this.state.errorMessage.scheduleFrequencyValue}
                                        label="Total Scheduled"
                                        //  notifyParent={this.updateValues}
                                    />
                            </Grid>  
                            <Grid item xs hidden={this.state.isNew}>
                                <FInput
                                        // name='scheduleFrequencyValue'
                                        disabled={true}
                                        value={this.state.data.scheduleInfo && 
                                               this.state.data.scheduleInfo.totalIncompleteJobs}
                                        // hasError={this.state.errorData.scheduleFrequencyValue}
                                        // helpMessage={this.state.errorMessage.scheduleFrequencyValue}
                                        label="Incomplete Schedules"
                                        //  notifyParent={this.updateValues}
                                    />
                            </Grid>  
                            <Grid item xs hidden={this.state.isNew}>
                                <FInput
                                        // name='scheduleFrequencyValue'
                                        disabled={true}
                                        value={this.state.data.scheduleInfo && 
                                               this.state.data.scheduleInfo.totalCompleteJobs}
                                        // hasError={this.state.errorData.scheduleFrequencyValue}
                                        // helpMessage={this.state.errorMessage.scheduleFrequencyValue}
                                        label="Complete Schedules"
                                        //  notifyParent={this.updateValues}
                                    />
                            </Grid>  
                            <Grid item xs hidden={this.state.isNew}>
                                <FInput
                                        // name='scheduleFrequencyValue'
                                        disabled={true}
                                        value={this.state.data.scheduleInfo && 
                                               this.state.data.scheduleInfo.totalRemoved}
                                        // hasError={this.state.errorData.scheduleFrequencyValue}
                                        // helpMessage={this.state.errorMessage.scheduleFrequencyValue}
                                        label="Discarded Schedules"
                                        //  notifyParent={this.updateValues}
                                    />
                            </Grid>
                        </Grid>
                         
                        {this.isScheduleStg('DATE') &&  <Grid container className={classes.padding} spacing= {2} xs={12}  
                                 disabled={!this.state.isNew} >
                                <Grid item xs={2}  >
                                        <FDate
                                        name='schduleStartDate'
                                        required={true}
                                        defaultValue={this.state.data.schduleStartDate}
                                        hasError={this.state.errorData.schduleStartDate}
                                        helpMessage={this.state.errorMessage.schduleStartDate}
                                        label="Service Start Date"
                                        disabled={!this.state.isNew}
                                        notifyParent={this.updateValues} />
                                </Grid>
                                <Grid item xs={2}>
                                        <FDate
                                        name='schduleEndDate'
                                        required={true}
                                        defaultValue={this.state.data.schduleEndDate}
                                        hasError={this.state.errorData.schduleEndDate}
                                        helpMessage={this.state.errorMessage.schduleEndDate}
                                        label="Service End Date"
                                        disabled={!this.state.isNew}
                                        notifyParent={this.updateValues} />
                                    </Grid>
                            
                            <Grid item xs={2} hidden={this.showIntervalFrequencyValue()} >
                                <FInput name='scheduleFrequencyValue' required
                                        disabled={!this.state.isNew}
                                        type='number'
                                        value={this.state.data.scheduleFrequencyValue}
                                        hasError={this.state.errorData.scheduleFrequencyValue}
                                        helpMessage={this.state.errorMessage.scheduleFrequencyValue}
                                        label={this.getFrequencyLabel()} notifyParent={this.updateValues}
                                    />
                            </Grid>

                            <Grid item xs xs={3} hidden={!this.showDay()}>
                                <FSelect
                                    name='scheduleDay'
                                    value={this.state.data.scheduleDay}
                                    hasError={this.state.errorData.scheduleDay}
                                    helpMessage={this.state.errorMessage.scheduleDay}
                                    label='Day of the Week'
                                    data={this.props.weekDaysChoices}
                                    valueRef='prettyName'
                                    keyRef='id'
                                    disabled={!this.state.isNew}
                                    notifyParent={this.updateValues} />
                            </Grid>
                        </Grid>
                    }
                    {this.isScheduleStg('MILEAGE')  && 
                    <Grid container className={classes.padding} spacing= {3} xs={12}  
                                 disabled={!this.state.isNew} >
                               
                                <Grid item xs={3}>
                                    <FInput name='vehicleCurrentOdometer' required
                                        type='number'
                                        disabled={true}
                                        value={this.state.data.vehicle && this.state.data.vehicle.vehicleCurrentOdometer}
                                        hasError={this.state.errorData.vehicle && this.state.errorData.vehicle.vehicleCurrentOdometer}
                                        helpMessage={this.state.errorMessage.vehicle && this.state.errorMessage.vehicle.vehicleCurrentOdometer}
                                        label="Vehicle's Current Odometer Reading" 
                                        // notifyParent={this.updateValues}
                                    />
                                </Grid>    
                                <Grid item xs={2}  >
                                    <FInput name='scheduleStartMileage' required
                                            disabled={!this.state.isNew}
                                            type='number'
                                            value={this.state.data.scheduleStartMileage}
                                            hasError={this.state.errorData.scheduleStartMileage}
                                            helpMessage={this.state.errorMessage.scheduleStartMileage}
                                            label="Last Service (Mi)" notifyParent={this.updateValues}
                                        />
                                </Grid>
                                <Grid item xs={2}>
                                        <FInput name='scheduleEndMileage' required
                                            disabled={!this.state.isNew}
                                            type='number'
                                            value={this.state.data.scheduleEndMileage}
                                            hasError={this.state.errorData.scheduleEndMileage}
                                            helpMessage={this.state.errorMessage.scheduleEndMileage}
                                            label="Schedule upto Mileage (Mi)" notifyParent={this.updateValues}
                                        />
                                </Grid>
                               
                            <Grid item xs={2} hidden={this.showIntervalFrequencyValue()} >
                                <FInput name='scheduleFrequencyValue' 
                                        required
                                        disabled={!this.state.isNew}
                                        type='number'
                                        value={this.state.data.scheduleFrequencyValue}
                                        hasError={this.state.errorData.scheduleFrequencyValue}
                                        helpMessage={this.state.errorMessage.scheduleFrequencyValue}
                                        label={this.getFrequencyLabel()} notifyParent={this.updateValues}
                                    />
                            </Grid>

    
                        </Grid>
                    }
                    <Grid container spacing= {2} xs={12} className={classes.padding} >
                          <Grid item xs={3}>
                                <FInput name='scheduleName' required
                                    value={this.state.data.scheduleName}
                                    hasError={this.state.errorData.scheduleName}
                                    helpMessage={this.state.errorMessage.scheduleName}
                                    label="Schedule Name"
                                        notifyParent={this.updateValues}
                                />
                            </Grid>
                                       
                            <Grid item xs>
                                 <FInput
                                    name='desc' value={this.state.data.desc} multiline={true}
                                    value={this.state.data.desc}
                                    hasError={this.state.errorData.desc}
                                    helpMessage={this.state.errorMessage.desc}
                                    label="Description" notifyParent={this.updateValues} />
                            </Grid>
                    </Grid>     
                    <Grid item container xs={8} >
                            {this.getUpdateButton(this.props.profile, SAFETY_AND_COMPLINACE_MAINTENANCE_SCHEDULES)}
                    </Grid>
                    {/* <Grid item container xs={8} >
                        <FButton color='primary'
                            label={this.state.isNew ? 'Save' : 'Update'}
                            loading={this.state.loading}
                            onClick={this.onSaveData}
                        />
                        <Grid item style={{ paddingTop: '9px' }}>
                            <FRouteBackLink variant='outlined' color='secondary' className={'backButton'} />
                        </Grid>
                    </Grid> */}

                    {!this.state.isNew && <Grid item container xs={12} justify="flex-end" >
                    
                                <FTabs value={this.state.showJScheduleJobs}
                                       onJobScheduleChange={this.onJobScheduleChange}>
                                    </FTabs>
                    </Grid>
                    }

                    {!this.state.isNew &&  this.state.showJScheduleJobs == showHistoryJobs  && <Grid item xs className={classes.padding}>
                            <JobScheduleListDetail
                                title={'Maintenance History'}
                                ownerId={this.props.match.params.id}
                                owner={this.state.data}
                                // listUrl={API_VEHICLE_MAINTENANCE_SCHEDULE_JOB_URL + `?schedule=${this.props.match.params.id}&${`scheduledJobDate__lt=${new Date().toISOString()}&`}`}
                                listUrl={API_VEHICLE_MAINTENANCE_SCHEDULE_JOB_URL + `?schedule=${this.props.match.params.id}&${`scheduleJobTimeLine=HISTORY`}`}
                                ownerType='schedule'
                            />
                    </Grid>}
                    {/* Upcoming */}
                    {!this.state.isNew && this.state.showJScheduleJobs == showUpcoming  && <Grid item xs className={classes.padding}>
                                        <JobScheduleListDetail
                                            title={'Upcoming Maintenance Schedule upto (5000mi or 3 months time)'}
                                            ownerId={this.props.match.params.id}
                                            owner={this.state.data}
                                            listUrl={API_VEHICLE_MAINTENANCE_SCHEDULE_JOB_URL + `?schedule=${this.props.match.params.id}&${`scheduleJobTimeLine=UPCOMING`}`}
                                            // listUrl={API_VEHICLE_MAINTENANCE_SCHEDULE_JOB_URL + `?schedule=${this.props.match.params.id}&${`scheduledJobDate__gte=${new Date().toISOString()}&`}`}
                                            ownerType='schedule'
                                        />
                        </Grid >
                    }
         </Grid>
                
            </Paper>
        );
    };
}


export default withStyles(styles)(connect(
    mapStateToProps, mapDispatchToProps)(VehicleMaintenanceDetail))
