import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

import {CircularProgress } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { Grid, Link } from '@material-ui/core';
import { API_AUTH_FORGOT_CRED, ZTRUCKING_WEBSITE } from '../../util/Const';
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import Main from '../app'
import { UPDATE, getAuthToken } from '../../util/FAPI';
import Alert from '@material-ui/lab/Alert';

import logo from './logo_zt_square.png'; // Tell webpack this JS file uses this image


const styles = theme => ({
  root: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage: `url(/truck.jpg)`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
  },
  centralized: {
      margin: 'auto',
      // backgroundColor: 'rgba(255,255,255,0.9)',
      backgroundColor: "#F8F8FF",
      padding: theme.spacing(3),
      borderRadius: theme.spacing(1),
  },
  paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(3),
      fontFamily: "'Roboto', sans-serif", // refined font-family
  },
  logo: {
      width: '100px',
      height: '100px',
      marginBottom: theme.spacing(2),
  },
  headingLink: {
      margin: theme.spacing(2, 0),
      fontWeight: 'bold',
      textAlign: 'center',
  },
  link: {
      fontSize: '16px',
      textDecoration: 'none',
      color: theme.palette.primary.main,
      '&:hover': {
          textDecoration: 'underline',
      },
  },
  form: {
      width: '100%',
      marginTop: theme.spacing(1),
  },
  formControl: {
      margin: theme.spacing(3, 2),
  },
  submitButton: {
      margin: theme.spacing(2, 0, 2),
      padding: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
      color: '#ffffff',
      '&:hover': {
          backgroundColor: theme.palette.primary.dark,
      },
  },
  emailSentSuccess: {
    color: 'blue',
    paddingLeft: theme.spacing(1),
    letterSpacing: '2px',
    fontSize: "15px"
  },
  emailSentError: {
    color: 'red',
    paddingLeft: theme.spacing(1),
    letterSpacing: '2px',
    fontSize: "15px"
  },
  footer: {
      marginTop: theme.spacing(3),
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2),
      textAlign: 'center',
  },
});



class ForgotCredential extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      error: false,
      success: false,
      loading: false,
    }
   
  }

  updateLoginAs = event => {
    let loginAs =  event.target.value
    this.setState({loginAs: loginAs});
  };


  routeTosignUp = () => {
    this.props.dispatchSignUp()
    // this.props.history.push('/signup')
    window.location.href = '/signupm'
    // window.location= '/signup'
  }

  routeToLogin = () => {
    // this.props.dispatchSignUp()
    window.location.href = '/login'
  }



  
  onSubmit = (event) => {
    this.setState({loading: true})
    event.preventDefault();
    const data = new FormData(event.target);
    UPDATE(API_AUTH_FORGOT_CRED, 'post', data).then(res => {
      this.setState({error: false, success: true, loading: false});

    }).catch(err => {
      if (err= 'success'){
        this.setState({error: false, success: true, loading: false});
      }else {
        this.setState({error: err, success: false, loading: false});
      }

    })
  }
  render() {
    const { classes } = this.props;

    if (!!getAuthToken()) {
        return (<Main />)
    }
    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square className={classes.centralized}>
                <div className={classes.paper}>
                    <a href={ZTRUCKING_WEBSITE} className={classes.link}>
                        <img src={logo} alt="Carrier Management Software - ZTrucking" className={classes.logo} />
                    </a>
                    <Typography component="h1" variant="h5" className={classes.headingLink}>
                        Reset your password
                    </Typography>
                    
                    <form className={classes.form} onSubmit={this.onSubmit}>
                        <Grid container direction='column'>
                            <Grid item xs style={{ paddingBotom: '10px'}}>
                            <Alert severity="info">Only the administrator account can reset a password. If you are not an administrator, contact your account holder to reset your password or activate your account.</Alert>
                            </Grid>

                            <FormControl margin="normal" fullWidth className={classes.formControl}>
                                <InputLabel htmlFor="profileEmail">Enter the username or email address</InputLabel>
                                <Input id="profileEmail" name="profileEmail" autoComplete="profileEmail" required autoFocus />
                            </FormControl>

                            <Button
                                type="submit"
                                color='primary'
                                variant='contained'
                                fullWidth
                                className={classes.submitButton}
                                disabled={this.state.loading}
                            >
                                Send 
                            </Button>
                            
                            <Link
                                component="button"
                                variant="body2"
                                className={classes.link}
                                onClick={this.routeToLogin}>
                                Take me back to login?
                            </Link>
                            
                            {this.state.loading && <CircularProgress className={classes.progress} />}
                        </Grid>
                        
                        {this.state.success  &&
                        <Grid item>
                            <p className={classes.emailSentSuccess}>
                              Check your inbox
                              <br/><br/>
                              A link was sent to {} to reset your password. If you don’t see it in your inbox, remember to check your spam folder.</p>
                          </Grid>
                          }
                        {this.state.error  &&
                          <Grid item>
                            <p className={classes.emailSentError}>
                              There was an error reseting your password, please try again later or  email  us at service@ztrucking.com </p>
                          </Grid>
                      }
                        
                        <div className={classes.footer}>
                          <Typography variant="body2" align="center" style={{ fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgba(0, 0, 0, 0.54)' }}>
                              &copy; Copyright <a href="https://www.ztrucking.com" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>ZTRUCKING GROUP CORP.</a> &nbsp;| &nbsp;
                              <a href="https://www.ztrucking.com/terms-and-condition" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Terms of Use</a>  &nbsp;|  &nbsp;
                              <a href="https://www.ztrucking.com/privacy-policy" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Privacy Policy</a>
                          </Typography>
                      </div>
                    </form>
                </div>
            </Grid>
        </Grid>
    );
}

}



export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps)(ForgotCredential))

// export default withStyles(styles)(ForgotCredential);