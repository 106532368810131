import {createStore} from 'redux'
import rootReducer from "./Reducer";

// import AppReducer from './AppReducer'
// import ErrorFieldReducer from './reducers/ErrorFieldActionMaker'
// import initialState from './reducers/InitState';
// import initErrorFieldState from './reducers/InitErrorFieldState';
// import {combineReducers} from 'redux'

// const reducers = combineReducers({
//      appReducer: AppReducer,
//     //  errorFieldReducer: ErrorFieldReducer
// });
// const reducers = combineReducers({appReducer: configReducer, customData: customDataReducer})


// const initSates = {appReducer: initialState, errorFieldReducer: initErrorFieldState};

// const store = createStore(reducers, initSates) // at this point redux calls all the reducers with 'undefined' state. Why?


const store = createStore(rootReducer)
export default  store 