import React from 'react';
import { roundDecimal, getPrettyDate } from '../../../util';
import FMaterialTable from '../../widgets/FMaterialTable';
import { getInvoiceStatusIcon } from '../../../util/icon_util';

export default class Invoices extends React.Component {
  constructor(props) {
    super(props)
  }
  

  render() {

    return (
      <div>

        <FMaterialTable
          tableRef={this.props.tableRef}
          columns={[
            { title: 'Invoice #', field: 'payCheckID'},
            {
              title: 'Load #',
              render: rowData => {
                return (
                  <React.Fragment>
                     {rowData.fleet && rowData.fleet.prettyName}
                  </React.Fragment>
                )
              }
            },
            { title: 'Fleet Pickup Date', field: 'payCheckFleetStartDate',
                 render: rowData => getPrettyDate(rowData.payCheckFleetStartDate) },

            // {
            //   title: 'Trip Location', field: 'fleetPickupLocation',
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //          <b>{rowData.fleetPickupLocation}</b> to <b>{rowData.fleetDeliveryLocation}</b>
            //       </React.Fragment>
            //     )
            //   }
            // },
            { title: 'Gross Payment', field: 'payCheckFleetGrossPayment', 
              render: rowData => roundDecimal(rowData.payCheckFleetGrossPayment) },

            {
              title: 'State', 
              width: 250,
              render: rowData => {
                return (
                  <React.Fragment>
                      {getInvoiceStatusIcon(rowData)}
                  </React.Fragment>
                )
              }
            },
            
          ]}
          
          options = {{
              pageSize: this.props.pstate.pageSize,
              pageSizeOptions: this.props.pstate.pageSizeOptions,
              actionsColumnIndex: -1,
              search: false
           }}
          data={this.props.fetchData}
          listErrCode={this.props.listErrCode}
        />

      </div>
    );
  }
}
