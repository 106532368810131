import React, { Component } from 'react';
import { Button, Link, Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import FRouterLink from '../widgets/FRouterLink';
import { ROUTE_CARRIERS_URL } from '../../util/Const';
import { getExpirationDocumentIcon } from '../../util/icon_util';
import { has_edit_permission, isProfileDispatcher } from '../../util/ACL';
import BlockIcon from '@material-ui/icons/Block';
import FTableAction from '../widgets/FTableAction';
import FMaterialTable from '../widgets/FMaterialTable';
import * as CONST from '../../util/Const';
import EditIcon from '@material-ui/icons/Edit';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { green } from '@material-ui/core/colors';

class Documents extends Component {
  constructor(props) {
    super(props)
    this.includeStateAction = false
    this.includeDeleteAction = true 
  }

  //TODO(estifo) Remove use the uti.prettyDate
  getPrettyDate = (d) => {
    try {
      return (d.slice(0, 10))
    } catch (err) {
      return d
    }
  }
  /**
   * Table Aciton Delete / Status / State change since this is on the owner
   * deleting / changing status of a document will be inherited from editing
   * driver's info policy
   * 
   */
  getActions = (rowData, target) => {
    let _actions = []

    if (!!this.includeDeleteAction && !!has_edit_permission(this.props.profile, target)) {
      _actions.push(CONST.ACTION_TABLE_DELETE_ROW)
    }
    if (!!this.includeStateAction && !!rowData.state && !!has_edit_permission(this.props.profile, target)) {
      _actions = _actions.concat(CONST.INVOICE_STATE_MACHINE[rowData.state])
    }
    return _actions
  }

  // getActions = (rowData) => {
  //   let actions = [ACTION_TABLE_DELETE_ROW]
  //   // rowData.state == 'ACTIVE'? actions.push(ACTION_TABLE_DEACTIVATE): actions.push(ACTION_TABLE_ACTIVATE)
  //   return actions
  // }
  
  render() {
    return (
      <div>
        <FMaterialTable
          columns={[
            {
              title: 'Document Type',
               field: 'documentType', render: rowData => {
                return (
                  <React.Fragment>
                    <Button color="primary" onClick={() => this.props.onEdit(rowData)}>
                      {rowData.documentType} <EditIcon style={{fontSize: '20px', paddingLeft: '3px'}}/>
                    </Button>
                  </React.Fragment>
                )
              }
            },
            {
              title: 'State', 
              field: 'state',
              render: rowData => {
                return (
                  <React.Fragment>
                      {getExpirationDocumentIcon(rowData)}
                  </React.Fragment>
                )
              }
            },
            {
              title: 'Id', field: 'id', hidden: true, render: rowData => {
                return (
                  <React.Fragment>
                    <Button color="primary" onClick={() => this.props.onRowEdit(rowData)}>
                      {rowData.id}
                    </Button>
                  </React.Fragment>
                )
              }
            },
            
            { title: 'Document ID', field: 'documentID'},
            {
              title: 'Carrier', field: 'carrier.prettyName',
              render: rowData => {
                // return (<React.Fragment>{rowData.carrier && rowData.carrier.prettyName} </React.Fragment>)
                return (
                  <React.Fragment>
                    <FRouterLink label={rowData.carrier && rowData.carrier.prettyName}
                       to={ROUTE_CARRIERS_URL +  rowData.carrier && rowData.carrier.id}
                      disabled={ isProfileDispatcher(this.props.profile)}/>
                  </React.Fragment>
                )
              }
            },
            // { title: this.props.currentDocument.title, field: this.props.currentDocument.ref, hidden: true },
            { title: 'Issued State', field: 'documentIssuedState'},

            { title: 'Issue Date', field: 'documentIssuedDate', render: rowData => this.getPrettyDate(rowData.documentIssuedDate) },
            { title: 'Expiring', field: 'documentExpirationDate', 
              render: rowData => {
                   let exp = rowData.documentExpires
                   if (!!exp) return (this.getPrettyDate(rowData.documentExpirationDate))
              }
            },
            
            { title: 'Reminder', field: 'documentExpirationRemindDays', hidden: true },
            { title: 'Last Updated', field: 'updated', render: rowData => this.getPrettyDate(rowData.updated) },

            {
              title: 'File Uploads',
              render: rowData => {
                let url = rowData.documentFile
                return (
                  <React.Fragment>
                    {rowData.documentFile && <Link href={url} target="_blank"> 
                       document <AttachmentIcon style={{ fontSize: 24, paddingLeft: '2px', color: green[500] }}/>
                    </Link>}
                  </React.Fragment>
                )
              }
            },
            // { title: 'State', field: 'state'},
            
            { 
              title: 'Description', 
              field: 'desc',
              cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 50}, },
            {
              title: 'Actions', 
              width: '50%',
              render: rowData => {
                let actions  = this.getActions(rowData, this.props.policy_id)

                return (
                  <React.Fragment>
                    {!!actions.length && <FTableAction
                        actions={actions}
                        onTableAction = {this.props.onTableAction}
                        rowData={rowData}
                         />
                    }
                    {!!!actions.length && <BlockIcon color="disabled" />}
                  </React.Fragment>
                )
              }
            },


          ]}
          actions={
            [
              {
                icon: () => (
                  <Fab  
                     disabled={!has_edit_permission(this.props.profile, this.props.policy_id)}
                     size="small" color="secondary" aria-label="Add">
                    <AddIcon />
                  </Fab>
                ),
                onClick: (event, rowData) => {
                  if (!has_edit_permission(this.props.profile, this.props.policy_id)){
                    return
                  }
                  this.props.onEdit()
                },
                isFreeAction: true
              },
            //   rowData => ({
            //     icon: 'delete',
            //     iconProps: {color: 'action'},
            //     tooltip: 'Delete Document',
            //     onClick: (event, rowData) => {
            //         this.props.onDelete(rowData)
            //     },
            //     // disabled: this.state.listData.length  <= 1
            //  })
            ]}
          options={{
            actionsColumnIndex: -1
          }}
          data={this.props.data}
          // title="Documents Center"
          showTitle={false}
          // components={{
          //   Toolbar: props => (
          //       <div style={{ backgroundColor: '' }}>
          //           <MTableToolbar {...props} />
          //       </div>
          //   )
          // }}
        />
      </div>
    );
  }
}

export default Documents;
