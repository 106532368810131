import React, { Component } from 'react';
import MaterialTable from 'material-table';
import { getAlertIcon } from '../../util/icon_util';
import { DashboardLabels } from '../../localizations/Resource';
import { FRouterLink } from './FRouterLink';





class FKeyValTable extends Component {

  getSummary = (rowData) => {
    let key = rowData['description']
    let summary = key && DashboardLabels[key]['summary']
    return summary
  }
  getTitle = (key) => {
    let title = DashboardLabels[key]['summary']
    return title
  }
  getTitleURL = (key) => {
    let titleUrl = DashboardLabels[key]['titleUrl']
    return titleUrl
  }
  getURL = (rowData) => {
    let key = rowData['description']
    let url = key && DashboardLabels[key]['url']
    return url 

  }
  getDiscriptionDisplay = (url, summary) => {
    if (this.props.has_permission && url){
      return  <FRouterLink label={summary} to={url} />
    }
    return summary
  }
  getTitleDisplay = () => {
    let titleUrl = this.getTitleURL(this.props.titleKey)
    let title = this.getTitle(this.props.titleKey)
    if (this.props.has_permission && !!titleUrl) {
      return <FRouterLink label={title} to={titleUrl}></FRouterLink>
    }
    return title
  }

  render() {
    // let titleUrl = this.getTitleURL(this.props.titleKey)
    // let title = this.getTitle(this.props.titleKey)
    // let titleLink = <FRouterLink label={title} to={titleUrl}></FRouterLink>
    return (
      <React.Fragment>
        <MaterialTable 
          columns={[
            {
              title: 'Description', 
              field: 'description',
              render: rowData => {
                let url = this.getURL(rowData)
                let summary = this.getSummary(rowData)
                let display = this.getDiscriptionDisplay(url, summary)
                return (
                  <div style={{ height: '35px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {display}
                  </div>
                )
              }
            },
            {
              title: 'Totals',
              width: "30%",
              render: rowData => {
                return (
                  <div style={{ height: '35px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {getAlertIcon(rowData)}
                  </div>
                )
              }
          },
          ]}
          options = {{
              search: false,
              showTitle: true,
              toolbar: true,
              paging: false,
              sorting: false,
              // maxBodyHeight: 400,
              // pageSize:  3,
              // pageSizeOptions: [3]
              
          }}
          data={this.props.data}
          title={this.getTitleDisplay()}
         
        />

      </React.Fragment>
    );
  }
}
export default FKeyValTable
