import React from 'react'

import { Grid, Paper } from "@material-ui/core"
import FCheckBox from '../widgets/FCheckBox';
import { connect } from 'react-redux'
import { mapDispatchToProps, mapStateToProps } from '../../fredux/ActionMaker';
import { withStyles } from '@material-ui/core/styles'
import FButton from '../widgets/FButton';
import { VALIDATE_CARRIER_REQUIRED, VALIDATE_OBJECT_REQUIRED, VALIDATE_FEE} from '../../util/ValidatorConst';
import { isValid } from '../../util/Validator';
import FInput from '../widgets/FInput';
import { API_CARRIERS_URL, API_FLEET_URL } from '../../util/Const';
import FAutoComplete from '../widgets/FAutoComplete';


const styles = theme => ({
  root: {

  },
  dropDown: {
  },

  textNumber: {
    marginTop: theme.spacing(1),
  },
  generateButon: {
    marginTop: 2 * theme.spacing(1),
  }

});

class PayCheckFilterView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        customer: {},
        carrier: {},
        payCheckExpenseCarrierFee:0,
        fleet: {},
        payCheckFleetStartDate: '',
        payCheckFleetEndDate: '',
        includeInvoicedFleets: '',
        payCheckExpenseStartDate: '',
        payCheckExpenseEndDate: '',
      },
      errorData: {
      },
      errorMessage: {
        customer: 'No Customer Selected/Found  Under Carrier',
        carrier: 'Invalid Carrier',
        payCheckFleetStartDate: 'Invalid Date Interval',
        payCheckFleetEndDate: 'Invalid Date Interval',
        includeInvoicedFleets: '',
        payCheckExpenseStartDate: 'Invalid Date Interval',
        payCheckExpenseEndDate: 'Invalid Date Interval',
        payCheckExpenseCarrierFee: 'Invalid Carrier Fee',
        fleet: 'Invalid Fleet to invoice'
      },
      validators: {
        customer: [VALIDATE_OBJECT_REQUIRED],
        carrier: [VALIDATE_CARRIER_REQUIRED],
        payCheckExpenseCarrierFee: [VALIDATE_FEE],
        payCheckDriverCut: [VALIDATE_FEE],
        fleet: [VALIDATE_OBJECT_REQUIRED],
        // payCheckFleetStartDate: [VALIDATE_DATE],
        // payCheckFleetEndDate: [VALIDATE_DATE],
        // payCheckExpenseStartDate: [VALIDATE_DATE],
        // payCheckExpenseEndDate: [VALIDATE_DATE],
      }
    }
  }
  

  validateIsValidInterval = (dateFrom, dateTo) => {
    return (new Date(dateFrom) <= new Date(dateTo))
  }

  getErrorData = () => {
    let errorData = {
      // customer: !isValid(this.state.data.customer, this.state.validators.customer),
      carrier: !isValid(this.state.data.carrier, this.state.validators.carrier),
      // payCheckExpenseCarrierFee: !isValid(this.state.data.payCheckExpenseCarrierFee, this.state.validators.payCheckExpenseCarrierFee),
      fleet: !isValid(this.state.data.fleet, this.state.validators.fleet),

      // payCheckFleetStartDate: !this.validateIsValidInterval(this.state.data.payCheckFleetStartDate, this.state.data.payCheckFleetEndDate),
      // payCheckFleetEndDate: !this.validateIsValidInterval(this.state.data.payCheckFleetStartDate, this.state.data.payCheckFleetEndDate),
      // payCheckExpenseStartDate: !this.validateIsValidInterval(this.state.data.payCheckExpenseStartDate, this.state.data.payCheckExpenseEndDate),
      // payCheckExpenseStartDate: !this.validateIsValidInterval(this.state.data.payCheckExpenseStartDate, this.state.data.payCheckExpenseEndDate),

    }
    return errorData
  }

  // validate = () => {
  //   return new Promise((resolve, reject) => {
  //     let errorData = this.getErrorData()
  //     this.setState(prevState => {
  //       return { ...prevState, errorData: errorData }
  //     }, () => {
  //       let hasError = false
  //       for (const [key, value] of Object.entries(errorData)) {
  //         if (value) {
  //           hasError = true
  //         }
  //       }
  //       hasError ? reject() : resolve()
  //     })
  //   })
  // }

  // updateValues = (name, obj) => {
  //   this.setState(prevState => {
  //     let newState = {
  //       ...prevState,
  //       data: {
  //         ...prevState.data, ...obj
  //       }
  //     }
  //     return newState
  //   }, () => {
  //     // this.validateSingle(name)
  //   })
  // }
  validate = () => {
    return new Promise((resolve, reject) => {
      let errorData = this.getErrorData()
      this.setState(prevState => {
        return { ...prevState, errorData: errorData }
      }, () => {
        let hasError = false
        for (const [key, value] of Object.entries(errorData)) {
          if (value) {
            hasError = true
          }
        }
        hasError ? reject() : resolve()
      })
    })
  }
  
  validateSingle = (name) => {
    let isV = !isValid(this.state.data[name], this.state.validators[name])
    this.setState(prevState => {
      let newState = {
        ...prevState,
        errorData: {
          ...prevState.errorData, ...{ [name]: isV }
        }
      }
      return newState
    })
  }

  updateValues = (name, obj) => {
    
    this.setState(prevState => {
      let newState = {
        ...prevState,
        data: {
          ...prevState.data, ...obj
        },
        touched: {
          ...prevState.touched,
          [name]: true
        }
      }
      
      return newState
    }, () => {
      this.validateSingle(name)
    })
  }

  onSaveData = (evt) => {
    this.validate().then(succ => {
      this.props.notifyParent(this.state.data)
    }).catch(e => {
    })
  }

  onCarrierChange = () => {
    this.updateValues('customer', {driver: ''})
  }
  
  getInvoicedFleetListQueryParam = () =>{
    if (!!!this.state.data) return ''
    if (!!this.state.data.includeInvoicedFleets){
      return 'status=COMPLETE'
    }
    return 'status=COMPLETE&fleetIsInvoiced=False'

    
  }
  render() {
    const {classes} = this.props
    return (
      <React.Fragment>
        <Grid container className={classes.root} spacing= {2} xs={12} >
         <Grid item xs={2}>
            <FCheckBox
              name='includeInvoicedFleets'
              value={this.state.data.includeInvoicedFleets}
              hasError={this.state.errorData.includeInvoicedFleets}
              helpMessage={this.state.errorMessage.includeInvoicedFleets}
              label="Show Invoiced Fleets"
              notifyParent={this.updateValues} />
          </Grid>
          {/* <Grid item xs>
            <FDate
              name='payCheckFleetStartDate'
              required={true}
              defaultValue={this.state.data.payCheckFleetStartDate}
              hasError={this.state.errorData.payCheckFleetStartDate}
              helpMessage={this.state.errorMessage.payCheckFleetStartDate}
              label="Fleet From "
              notifyParent={this.updateValues} />
          </Grid>
          <Grid item xs>
            <FDate
              name='payCheckFleetEndDate'
              required={true}
              defaultValue={this.state.data.payCheckFleetEndDate}
              hasError={this.state.errorData.payCheckFleetEndDate}
              helpMessage={this.state.errorMessage.payCheckFleetEndDate}
              label="Fleet To "
              notifyParent={this.updateValues} />
          </Grid> */}

          {/* <Grid item xs>
            <FDate
              name='payCheckExpenseStartDate'
              required={true}
              defaultValue={this.state.data.payCheckExpenseStartDate}
              hasError={this.state.errorData.payCheckExpenseStartDate}
              helpMessage={this.state.errorMessage.payCheckExpenseStartDate}
              label="expense From "
              notifyParent={this.updateValues} />
          </Grid>
          <Grid item xs>
            <FDate
              name='payCheckExpenseEndDate'
              required={true}
              defaultValue={this.state.data.payCheckExpenseEndDate}
              hasError={this.state.errorData.payCheckExpenseEndDate}
              helpMessage={this.state.errorMessage.payCheckExpenseEndDate}
              label="expense To "
              notifyParent={this.updateValues} />
          </Grid> */}
           <Grid item xs className={classes.dropDown}>
            <FAutoComplete
                url={API_CARRIERS_URL}
                name='carrier'
                hasError={this.state.errorData.carrier}
                helpMessage={this.state.errorMessage.carrier}
                label='Authority'
                // queryParam='search'
                value={this.state.data.carrier}
                defaultValue={this.state.data.carrier}
                notifyParent={this.updateValues}
                // disabled={!this.state.isNew}
                notifyDependency={this.onCarrierChange}

                />
          </Grid>
          <Grid item xs className={classes.dropDown}>
               <FAutoComplete
                  url={API_FLEET_URL}
                  queryParam={this.getInvoicedFleetListQueryParam()}
                  hasCarrier={true}
                  carrier = {this.state.data.carrier}
                  name='fleet'
                  hasError={this.state.errorData.fleet}
                  helpMessage={this.state.errorMessage.fleet}
                  label='Fleet'
                  prettyName='invoicePrettyName'
                  value={this.state.data.fleet}
                  defaultValue={this.state.data.fleet}
                  notifyParent={this.updateValues}
                  // disabled={!this.state.isNew}
                  />
          </Grid>
          {/* <Grid item xs className={classes.textNumber}>
            <FInput
              name='fleet'
              required={true}
              // type='number'
              value={this.state.data.fleet}
              hasError={this.state.errorData.fleet}
              helpMessage={this.state.errorMessage.fleet}
              label=" Fleet Load # "
              notifyParent={this.updateValues} />
          </Grid> */}
          <Grid item xs className={classes.textNumber}>
            <FInput
              name='payCheckExpenseCarrierFee'
              required={true}
              type='number'
              value={this.state.data.payCheckExpenseCarrierFee}
              hasError={this.state.errorData.payCheckExpenseCarrierFee}
              helpMessage={this.state.errorMessage.payCheckExpenseCarrierFee}
              label="Factory Fee (%) "
              notifyParent={this.updateValues} />
          </Grid>
          
        
         
            {/* <FSelect
              name='carrier'
              value={this.state.data.carrier && this.state.data.carrier.id}
              hasError={this.state.errorData.carrier}
              helpMessage={this.state.errorMessage.carrier}
              label='Authority'
              data={getCarriers(this.props)}
              valueRef='prettyName'
              keyRef='id'
              notifyParent={this.updateValues} />
          </Grid> */}
{/* 
          <Grid item xs className={classes.dropDown}>
               <FAutoComplete
                    url={API_CUSTOMER_URL}
                    // queryParam={`carrier=${this.state.data.carrier && this.state.data.carrier.id}`}
                    hasCarrier={true}
                    carrier={this.state.data.carrier}
                    name='customer'
                    hasError={this.state.errorData.customer}
                    helpMessage={this.state.errorMessage.customer}
                    label='Billing Customer'
                    // queryParam='search'
                    value={this.state.data.customer}
                    defaultValue={this.state.data.customer}
                    notifyParent={this.updateValues}
                    // disabled={!this.state.isNew}
                    />
                     </Grid> 
            <FSelect
              name='customer'
              value={this.state.data.customer && this.state.data.customer.id}
              hasError={this.state.errorData.customer}
              helpMessage={this.state.errorMessage.customer}
              label='Customer'
              data={getCustomers(this.props, this.state.data.carrier)}
              valueRef='prettyName'
              keyRef='id'
              notifyParent={this.updateValues} />
          </Grid> */}
         
          <Grid item xs className={classes.generateButon}>
            <FButton color='primary'
              label='Generate invoice'
              loading={this.state.loading}
              onClick={this.onSaveData}
            />
          </Grid>
      </Grid >
      </React.Fragment>
    )
  }
}


export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps)(PayCheckFilterView))
