import React from 'react'
import ReactDOM from 'react-dom'

import Alert from '@material-ui/lab/Alert';
import { BASE_API_URL, CARRIER_TYPE, CHOICE_CARRIER, CHOICE_DISPATCHER, CHOICE_DRIVER, CHOICE_TRAILER, CHOICE_VEHICLE, getURLDocumentOwner, INVOICE_STATE } from './Const';
import { FRouterLink } from '../components/widgets/FRouterLink';
import { getPrettyDate, getValueFromListOfDict } from '.';
import { has_link_permission } from './ACL';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {isNotNullOrEmpty } from "./Validator";




const INFO_COLOR = "#1976d2"
const ERROR_COLOR = "#9a0036"
const WARNING_COLOR = "#f57c00"
const SUCCESS_COLOR = "#388e3c"




export const stringToColorCode = function(str) {
  var hash = 0;
  if (str.length === 0) return '#000000'; // Default to black if string is empty

  // Generate a hash from the string
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash; // Convert to a 32-bit integer
  }

  // Convert hash to a valid hex color code
  let color = '#';
  for (let i = 0; i < 3; i++) { // Create 3 color components (R, G, B)
    const value = (hash >> (i * 8)) & 0xFF; // Extract 8 bits for each component
    color += ('00' + value.toString(16)).slice(-2); // Convert to hex and pad if necessary
  }
  return color;
}


export const getDataImportSyncStatusIcon = function (obj) {
  if (!!!obj || !!!obj.dataImportSyncStatus) return ''

  if (obj.dataImportSyncStatus == 'INITIAL') {
    return (<Alert severity="info">Initial </Alert>)
  }
  if (obj.dataImportSyncStatus == 'IN_PROGRESS') {
    return (<Alert severity="info">In Progress </Alert>)
  }
  if (obj.dataImportSyncStatus == 'ARCHIVED') {
    return (<Alert severity="error">Failed </Alert>)
  }
  if (obj.dataImportSyncStatus == 'COMPLETE') {
    return (<Alert severity="success">Complete </Alert>)
  }
}


export const getInvoiceStatusIcon = function (invoice) {
  if (!!!invoice || !!!invoice.id) return ''

  if (invoice.invoiceStatus == INVOICE_STATE.CREATED) {
    return (<Alert severity="warning">Created </Alert>)
  }
  if (invoice.invoiceStatus == INVOICE_STATE.INVOICED) {
    return (<Alert severity="info">Invoiced </Alert>)
  }
  if (invoice.invoiceStatus == INVOICE_STATE.PARTIAL_PAID) {
    return (<Alert severity="success">Partially Paid </Alert>)
  }
  if (invoice.invoiceStatus == INVOICE_STATE.PAID) {
    return (<Alert severity="success">Paid </Alert>)
  }
  if (invoice.invoiceStatus == INVOICE_STATE.OVERDUE) {
    return (<Alert severity="error">Over Due </Alert>)
  }
  if (invoice.invoiceStatus == INVOICE_STATE.CANCELED) {
    return (<Alert severity="error">Canceled </Alert>)
  }
}

export const getPayCheckFleetInvoiceStatusIcon = function (metaData) {
  if (!!!metaData || !!!metaData.invoice) return ''
  let  invoiceData = metaData.invoice
  if (invoiceData.totalPaid > 0) {
    return (<Alert severity="success">Paid </Alert>)
  }
  return (<Alert severity="warning">Unpaid </Alert>)
}


export const getEmploymentVerificationStatusIcon = function (obj) {
  if (!!!obj || !!!obj.employmentVerificationStatus) return ''
  let  employmentVerificationStatus = obj.employmentVerificationStatus
  if (employmentVerificationStatus == 'VERIFIED') {
    return (<Alert severity="success">{employmentVerificationStatus} </Alert>)
  }
  return (<Alert severity="warning">{employmentVerificationStatus} </Alert>)
}


export const getResidentVerificationStatusIcon = function (obj) {
  if (!!!obj || !!!obj.residentVerificationStatus) return ''
  let  residentVerificationStatus = obj.residentVerificationStatus
  if (residentVerificationStatus == 'VERIFIED') {
    return (<Alert severity="success">{residentVerificationStatus} </Alert>)
  }
  return (<Alert severity="warning">{residentVerificationStatus} </Alert>)
}

export const getAlarmLevelStatusIcon = function (eventAlarmLevelChoices, event) {
  if (!!!event || !!!event.eventAlarmLevel) return ''
  let  eventAlarmLevel = event.eventAlarmLevel
  let alarmValue = getValueFromListOfDict(eventAlarmLevelChoices, eventAlarmLevel) 
  if (eventAlarmLevel == 0) {
    return (<Alert severity="info">{alarmValue} </Alert>)
  }
  if (eventAlarmLevel == 10) {
    return (<Alert severity="info">{alarmValue} </Alert>)
  }
  if (eventAlarmLevel == 20) {
    return (<Alert severity="warning">{alarmValue} </Alert>)
  }
  if (eventAlarmLevel == 30) {
    return (<Alert severity="error">{alarmValue} </Alert>)
  }
  if (eventAlarmLevel == 40) {
    return (<Alert severity="error">{alarmValue} </Alert>)
  }
}

export const getInvoiceFleetInvoiceStatusIcon = function (metaData) {
  if (!!!metaData || !!!metaData.invoice) return ''
  let invoiceData = metaData.invoice

  if (invoiceData.totalProcessed > 0) {
    return (<Alert severity="warning">Processed </Alert>)
  }
  // return (<Alert severity="success">Unpaid</Alert>)
}

export const getStateIcon = function (obj) {
  if (!!!obj || !!!obj.state) return ''
  
  if (obj.state == 'ACTIVE') {
    return (<Alert severity="success">Active </Alert>)
  }
  if (obj.state == 'INACTIVE') {
    return (<Alert severity="error">Inactive </Alert>)
  }
  if (obj.state == 'ARCHIVED') {
    return (<Alert severity="info">Archived </Alert>)
  }
}

export const getDriverStateIcon = function (obj) {
  if (!!!obj || !!!obj.driver) return ''
  return getStateIcon(obj.driver) 
}

export const getDriverProfileStateIcon = function (obj) {
  return getStateIcon(obj) 
}


export const driverProfileSubscriptionStatus = function (obj) {
  if (!!!obj || !!!obj.subscription || !!!obj.subscription.subscriptionStatus) return ''
  let status = obj.subscription.subscriptionStatus
  let nextPayment =  getPrettyDate( obj.subscription.nextPaymentDate)
  if (status == 'VALID') {
    return (<Alert severity="success">Premium through {nextPayment} </Alert>)
  }
  if (obj.state == 'INACTIVE') {
    return (<Alert severity="error"> No / Limited Access </Alert>)
  }
  if (status == 'IN_VALID' || obj.state == 'INACTIVE') {
    return (<Alert severity="warning"> Basic </Alert>)
  }
  
}



export const getDrugTestResultIcon = function (obj) {
  if (!!!obj || !!!obj.drugTestResult) return ''
  let data = obj.drugTestResult
  if (data == 'NEGATIVE') {
    return (<Alert severity="success">Passed </Alert>)
  }
  else {
    return (<Alert severity="error">Failed </Alert>)
  }
}

export const getVehicleInspectionResultIcon = function (obj) {
  if (!!!obj || !!!obj.vehicleInspectionResult) return ''
  let data = obj.vehicleInspectionResult
  if (data == 'PASS') {
    return (<Alert severity="success">Passed </Alert>)
  }
  if (data == 'FAIL') {
    return (<Alert severity="error">Failed </Alert>)
  }
  else {
    return (<Alert severity="info">N/A </Alert>)
  }
 
}


export const getStatusIcon = function (obj) {
  if (!!!obj || !!!obj.status) return ''
  
  if (obj.status == 'IN_PROGRESS') {
    return (<Alert severity="info">In Progress </Alert>)
  }
  if (obj.status == 'COMPLETE') {
    return (<Alert severity="success">Complete </Alert>)
  }
  if (obj.status == 'IN_COMPLETE') {
    return (<Alert severity="error">In Complete </Alert>)
  }
}


export const getAPIIntegrationStatusIcon = function (obj) {
  if (!!!obj || !!!obj.authStatus) return ''
  let status = obj.authStatus
  
  if (status == 'DRAFT' ) {
    return (<Alert severity="info">Waiting for Authorization </Alert>)
  }
  if (status == 'PROCESSING') {
    return (<Alert severity="info">In Progress </Alert>)
  }
  if (status == 'VALID') {
    return (<Alert severity="success">Valid </Alert>)
  }
  if (status == 'INVALID' || status == 'TIMEOUT' || status == 'EXPIRED') {
    return (<Alert severity="error">In Complete </Alert>)
  }
}

export const getScheduleStatusIcon = function (obj) {
  if (!!!obj || !!!obj.status) return ''
  
  if (obj.status == 'IN_PROGRESS') {
    return (<Alert severity="info"> Scheduled </Alert>)
  }
  if (obj.status == 'COMPLETE') {
    return (<Alert severity="success">Completed </Alert>)
  }
  if (obj.status == 'IN_COMPLETE') {
    return (<Alert severity="error">Incomplete </Alert>)
  }
}


export const getExpirationDocumentIcon = function (document) {
  if (!!!document || !!!document.id) return ''

  if (document.state == 'EXPIRED') {
    return (<Alert severity="error">Expired </Alert>)
  }
  if (document.state == 'DRAFT') {
    return (<Alert severity="info">Draft </Alert>)
  }
  if (document.state == 'ARCHIVED') {
    return (<Alert severity="info">Archived </Alert>)
  }
  if (!!document.documentIsExpiring) {
    return (<Alert severity="warning">Expiring </Alert>)
  }
 
  else {
    return (<Alert severity="success"> Complete</Alert>)
  }
}


export const getComplianceIcon = function (data) {
  if (!!!data || !!!data.message) return ''

  if (data.message == "MISSING_DOCUMENT") {
    return (<Alert severity="error">Missing Document </Alert>)
  }
  else {
    return (<Alert severity="success"> Complete</Alert>)
  }
}


export const getDocumentOwnerLink = function (document, profile) {
  if (!!!document || !!!document.id || !!!document.ownerInfo) return ''
  const ownerInfo = document.ownerInfo
  const url = getURLDocumentOwner(ownerInfo.documentFor)
  const routeUrl = url && url.urlRoute
  const permission_policy = url && url.policy_id

  if (!!routeUrl) {
     let hasPermission = has_link_permission(profile, permission_policy)
     if (hasPermission) {
          return(
                <FRouterLink
                          disabled={!hasPermission}
                          label={ownerInfo.ownerPrettyName}
                          to={routeUrl + ownerInfo.id} />
                  )
          }
      else {
        return ownerInfo.ownerPrettyName
      }
     }
  else 
    return ownerInfo.ownerPrettyName
  
}


export const getOwnerLink = function (data, profile) {
  if (!!!data || !!!data.id ) return ''
  const url = getURLDocumentOwner(data.path)
  const routeUrl = url && url.urlRoute
  const permission_policy = url && url.policy_id
  let hasPermission = has_link_permission(profile, permission_policy)

  if (!!routeUrl) {
      if (hasPermission) {
        return(<FRouterLink 
            disabled={!hasPermission}
            label={data.prettyName} to={routeUrl + data.id} />)
      }else {
        return data.prettyName
      }
    }
  else 
    return data.prettyName
  
}

export const getAlertIcon = function (obj) {
  if (!!!obj || !!!obj.alert) return ''
  let value = obj.value
  


  if (obj.alert == 'SUCCESS') {
     return (<p style={{color: 'black',   display: 'inline'}}>{value}</p>)
    // return (<Alert severity="success">{value} </Alert>)
  }
  if (obj.alert == 'CRITICAL') {
    return (<p style={{color: 'red', fontWeight: 'bold', display: 'inline'}}>{value}</p>)
    // return (<Alert severity="error">{value} </Alert>)
  }
  if (obj.alert == 'ERROR') {
    return (<p style={{color: 'red', fontWeight: 'bold', display: 'inline'}}>{value}</p>)
    // return (<Alert severity="error">{value} </Alert>)
  }
  if (obj.alert == 'WARNING') {
    return (<p style={{color: '#ff9800', display: 'inline'}}>{value}</p>)
    // return (<Alert severity="warning"> {value} </Alert>)
  }
  if (obj.alert == 'INFO') {
    return (<p style={{color: 'black', display: 'inline'}}>{value}</p>)

    // return (<Alert severity="info">{value} </Alert>)
  }
}


export const getEmploymentApplicationIcon = function (obj) {
  if (!!!obj || !!!obj.applicationStatus) return ''
  let applicationStatus = obj.applicationStatus
  
  let label = ''
  let style = {color: '#ff9800', display: 'inline'}

  if (applicationStatus == 'DRAFT') {
    style.color = WARNING_COLOR
    label = ""
  }
  if (applicationStatus == 'IN_PROGRESS') {
    style.color = INFO_COLOR
    label = "In progress"
  }
  if (applicationStatus == 'PENDING_REVIEW') {
    style.color = INFO_COLOR
    label = "In progress"
  }
  if (applicationStatus == 'COMPLETE') {
    style.color = SUCCESS_COLOR
    label = "Complete"
  }
  return (<p style={style}>{label}</p>)
}

export const getDriverAppUploadIcon = function (obj) {
  if (!!!obj || !!!obj.driverAppUploadStatus) return ''
  let driverAppUploadStatus = obj.driverAppUploadStatus
  let label = ''
  let style = {color: '#ff9800', display: 'inline'}

  if (driverAppUploadStatus == 'DRAFT') {
    style.color = WARNING_COLOR
    label = "Draft"
  }
  if (driverAppUploadStatus == 'IN_PROGRESS') {
    style.color = WARNING_COLOR
    label = "In progress"
  }
  if (driverAppUploadStatus == 'COMPLETE') {
    style.color = SUCCESS_COLOR
    label = "Complete"
  }
  return (<p style={style}>{label}</p>)
}

export const getDriverType = function (obj) {
  if (!!!obj || !!!obj.driverType) return ''
  let driverType = obj.driverType
  let label = ''

  if (driverType == 'OWNER_OPERATOR') {
    label = "Owner Operator"
  }
  if (driverType == 'DRIVER_FOR_HIRE') {
    label = "Hired Driver"
  }
  if (driverType == 'DELEGATE_OWNER_OPERATOR') {
    label = "Driver Leasing"
  }
  return label
}


export const getExpenseType = function (obj) {
  if (!!!obj || !!!obj.expenseType) return ''
  let expenseType = obj.expenseType
  let label = expenseType
  let style = {color: '#1a7f37', display: 'inline'}
  if (expenseType == 'FUEL' && !!obj.fuelCardExpenseType) {
    return (<p style={style}>{label} ({obj.fuelCardExpenseType})</p>)
  }

  return (<p style={style}>{label}</p>)
}



export const redForNegative = function (amt) {
  if (!!!amt) return ''
  try {
    if (Number(amt) < 0) return (<p style={{color: 'red', display: 'inline'}}>{amt}</p>)
    return amt
  }catch {
   return amt
  }
}

export const redForPositive = function (amt) {
  if (!!!amt) return ''
  try {
    if (Number(amt) > 0) return (<p style={{color: 'red', display: 'inline'}}>{amt}</p>)
    return amt
  }catch {
   return amt
  }
}

export const redForZero = function (amt) {
  if (!!!amt) return ''
  try {
    if (Number(amt) == 0) return (<p style={{color: 'red', display: 'inline'}}>{amt}</p>)
    return amt
  }catch {
   return amt
  }
}



export const getBankCardStatus = function (card) {
  if (!!!card || !!!card.cardStatus) return (<Alert severity="error">IN_VALID</Alert>)
  let cardStatus = card.cardStatus
  if (cardStatus != 'ACTIVE') {
    return (<Alert severity="error">{card.cardStatus} </Alert>)
  }
  return (<Alert severity="success">{ card.cardStatus}</Alert>)
}

export const getTrueOrFalse = function (val, trueStyle='success', falseStyle='info') {
  if (!isNotNullOrEmpty(val)) return ''
  if (val == true) {
    return (<Alert severity={trueStyle}> Yes</Alert>)
  }
  if (val == false) {
    return (<Alert severity={falseStyle}> No </Alert>)
  }
 
}

export const getAvatorImage = (imageUrl, defaultImageUrl, assetType, hashValue) => {
  const isDefaultImage = imageUrl === defaultImageUrl;
  const borderColor = isDefaultImage ? stringToColorCode(hashValue, assetType) : 'transparent';

  const containerStyle = {
      width: 40,
      height: 40,
      borderRadius: '50%',  // Updated to make it circular
      border: `2px solid ${borderColor}`,
      overflow: 'hidden',
      backgroundColor: borderColor,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
  };

  const imageStyle = {
      width: '100%',
      height: '100%',
      borderRadius: '50%',  // Updated to make it circular
      objectFit: 'cover'  // Ensures the image scales and crops to fit the container
  };

  const handleImageError = (e) => {
      e.target.src = defaultImageUrl;
  };

  return (
      <div style={containerStyle}>
          <img 
              src={imageUrl}
              alt="Carrier"
              onError={handleImageError}
              style={imageStyle}
          />
      </div>
  );
};


// export const getAvatorImage = (imageUrl, defaultImageUrl, assetType, hashValue) => {

//   const isDefaultImage = imageUrl === defaultImageUrl;
//   const borderColor = isDefaultImage ? stringToColorCode(hashValue, assetType) : 'transparent';

//   return (
//         <div 
//           style={{ 
//             width: 42, 
//             height: 42, 
//             borderRadius: '10%', // rectangular shape
//             marginRight: '5px', 
//             border: `2px solid ${borderColor}`,
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             overflow: 'hidden',
//           }}>
//           <img 
//             src={imageUrl}
//             alt="Carrier"
//             style={{ 
//               width: '100%', 
//               height: '100%', 
//               borderRadius: '10%', // rectangular shape
//             }}
//           />
//         </div>
//       );
// }

export const getCarrierAvatarUrl = (carrier) => {
  const defaultImageUrl = "/carrier_ph.png";
  let imageUrl = carrier.carrierProfilePicture || defaultImageUrl;
  return getAvatorImage(imageUrl, defaultImageUrl, CHOICE_CARRIER, carrier.prettyName || JSON.stringify(carrier))

}


export const getDriverAvatarUrl = function (driver) {
  const defaultImageUrl = "/driver_ph_white.png";
  let imageUrl = driver.profilePicture|| defaultImageUrl;
  return getAvatorImage(imageUrl, defaultImageUrl, CHOICE_DRIVER, driver.prettyName || JSON.stringify(driver))

}


export const getTruckAvatarUrl = function (vehicle) {
  
  const defaultImageUrl = "/truck_ph_white.png";
  let imageUrl = vehicle.vehiclePicture || defaultImageUrl;
  return getAvatorImage(imageUrl, defaultImageUrl, CHOICE_VEHICLE, vehicle.prettyName || JSON.stringify(vehicle))

}

export const getTrailerAvatarUrl = function (trailer) {

  const defaultImageUrl = "/trailer_ph_white.png";
  let imageUrl = trailer.vehiclePicture || defaultImageUrl;
  return getAvatorImage(imageUrl, defaultImageUrl, CHOICE_TRAILER, trailer.prettyName || JSON.stringify(trailer))
}


export const getVehicleAvatarUrl = function (vehicle) {
  
  let defaultImageUrl = vehicle.vehicleType == 'TRUCK'?  "/truck_ph_white.png": "/trailer_ph_white.png";
  if (!!!vehicle.vehicleType) {
    defaultImageUrl = '/vehicle_ph.pmg'
  }
  let imageUrl = vehicle.vehiclePicture || defaultImageUrl;
  return getAvatorImage(imageUrl, defaultImageUrl, CHOICE_VEHICLE, vehicle.prettyName || JSON.stringify(vehicle))

}

export const getDispatcherAvatarUrl = function (dispatcher) {

  const defaultImageUrl = "/dispatcher_ph.jpg";
  let imageUrl = dispatcher.dispatcherProfilePicture  || defaultImageUrl;
  return getAvatorImage(imageUrl, defaultImageUrl, CHOICE_DISPATCHER, dispatcher.prettyName || JSON.stringify(dispatcher))
}