import React from 'react'

import { Grid, Paper } from "@material-ui/core"
import { connect } from 'react-redux'
import { mapDispatchToProps, mapStateToProps } from '../../../fredux/ActionMaker';
import { withStyles } from '@material-ui/core/styles'
import FAutoComplete from "../../widgets/FAutoComplete";
import { API_CARRIERS_URL } from '../../../util/Const';
import FSelect from '../../widgets/FSelect';
import FButtonDropDown from '../../widgets/FButtonDropDown';
import FFilterReport from '../../widgets/FFilterReport';

const styles = theme => ({
  root: {

     width: '100%'
  },
  dropDown: {
  },
  dropDownButton: {
    paddingTop:  theme.spacing(4),
  },

  textNumber: {
    marginTop: theme.spacing(1),
  },
  
  date: {
    width: '100%'
  },

  button: {
    marginTop: 2 * theme.spacing(2),
    marginLeft: 2 * theme.spacing(3),
  },
}); 

class DriverReportHeader extends React.Component {

  render() {
    const {classes} = this.props
    return (
      <FFilterReport>
         <Grid container className={classes.root} spacing= {1}  xs={12} >
              <Grid item xs className={classes.dropDown}>
              <FAutoComplete
                    url={API_CARRIERS_URL}
                    name='carrier'
                    // style={{width: 150}}
                    hasError={this.props.errorData.carrier}
                    helpMessage={this.props.errorMessage.carrier}
                    label='Authority'
                    // queryParam='search'
                    value={this.props.data.carrier}
                    defaultValue={this.props.data.carrier}
                    notifyParent={this.props.updateValues}
                    // disabled={!this.props.isNew}
                    notifyDependency={this.onCarrierChange}
                />
              </Grid>
              <Grid item xs>
                    <FSelect
                        name='state'
                        value={this.props.data.state}
                        hasError={this.props.errorData.state}
                        helpMessage={this.props.errorMessage.state}
                        label='Driver State'
                        data={this.props.driverStateChoice}
                        valueRef='prettyName'
                        keyRef='prettyName'
                        notifyParent={this.props.updateValues}
                    />
                </Grid>
              <Grid item xs className={classes.button}>
                <FButtonDropDown
                    options= {['Download FMCSA', 'Download ZTRUCKING']}
                    values= {['&fmt=fmcsa', '&fmt=ztrucking']}
                    onClick={this.props.onDownload}
                />
              </Grid>
            </Grid>
        </FFilterReport>
    )
  }
}


export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps)(DriverReportHeader))
