import React from 'react';
import { Link } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';

import { API_EXPENSE_URL, ROUTE_EXPENSE_URL, ROUTE_CARRIERS_URL } from '../../../util/Const';
import { roundDecimal, getPrettyDate } from '../../../util';
import FMaterialTable from '../../widgets/FMaterialTable';
import DeleteIcon from '@material-ui/icons/Delete';
import { getExpirationDocumentIcon, getDocumentOwnerLink } from '../../../util/icon_util';
import { mapStateToProps } from '../../../fredux/ActionMaker';
import { connect } from 'react-redux';

class Documents extends React.Component {
  tableRef = React.createRef();

  
  render() {
    return (
      <div>
        <FMaterialTable
          tableRef={this.props.tableRef}

          columns={[
            {
              title: 'State', 
              width: 250,
              render: rowData => {
                return (
                  <React.Fragment>
                      {getExpirationDocumentIcon(rowData)}
                  </React.Fragment>
                )
              }
            },
            {
              title: 'Document Owner', 
              render: rowData => {
                return (
                  <React.Fragment>
                    {getDocumentOwnerLink(rowData, this.props.profile)}
                  </React.Fragment>
                )
              }
            },
            // { title: 'Reference ID', field: 'id'},
            { title: 'Document Type', field: 'documentType'},
            // { title: 'Document ID', field: 'documentID'},
            // {
            //   title: 'Carrier', 
            //   field: 'carrier.prettyName',
            //   render: rowData => {
            //     if (rowData.carrier)
            //         return (
            //           <React.Fragment>
            //             <FRouterLink label={rowData.carrier.prettyName} to={ROUTE_CARRIERS_URL + rowData.carrier.id} />
            //           </React.Fragment>
            //         )
            //       }
            // },
            // { title: this.props.currentDocument.title, field: this.props.currentDocument.ref, hidden: true },
            // { title: 'Issued State', field: 'documentIssuedState'},

            { title: 'Issue Date', field: 'documentIssuedDate', render: rowData => getPrettyDate(rowData.documentIssuedDate) },
            { title: 'Expiring', field: 'documentExpirationDate', render: rowData => getPrettyDate(rowData.documentExpirationDate) },
            // { title: 'Expiration Reminder', field: 'documentExpirationRemindDays', render: rowData => rowData.documentExpirationRemindDays || 90 + ' Days' },

            // { title: 'Reminder', field: 'documentExpirationRemindDays', hidden: true },
            // { title: 'Last Updated', field: 'updated', render: rowData => .getPrettyDate(rowData.updated) },

            // {
            //   title: 'File Uploads',
            //   render: rowData => {
            //     let url = rowData.documentFile
            //     return (
            //       <React.Fragment>
            //         {rowData.documentFile && <Link href={url} target="_blank"> document</Link>}
            //       </React.Fragment>
            //     )
            //   }
            // },
            // { title: 'Status', field: 'status' },
            // { title: 'Description', field: 'desc' },


          ]}
          // actions={
          //   [
          //     {
          //       icon: () => (
          //         <Fab size="small" color="secondary" aria-label="Add">
          //           <AddIcon />
          //         </Fab>
          //       ),
          //       onClick: (event, rowData) => {
          //         this.props.onEdit()
          //       },
          //       isFreeAction: true
          //     },
          //     rowData => ({
          //       icon: 'delete',
          //       iconProps: {color: 'action'},
          //       tooltip: 'Delete Carrier',
          //       onClick: (event, rowData) => {
          //           this.props.onDelete(rowData)
          //       },
          //       // disabled: this.state.listData.length  <= 1
          //    })
          //   ]}
            options = {{
              pageSize: this.props.pstate.pageSize,
              pageSizeOptions: this.props.pstate.pageSizeOptions,
              actionsColumnIndex: -1,
              search: false
           }}
          data={this.props.fetchData}
          listErrCode={this.props.listErrCode}

        />
      </div>
    );
  }
}

// export default Documents;
export default connect(mapStateToProps)(Documents)

