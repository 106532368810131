import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles'
import { connect } from "react-redux"
import { mapDispatchToProps, mapStateToProps } from "../../fredux/ActionMaker";
import { Box } from '@material-ui/core';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { ALL_COLORS } from '../../util/Const';


const style = theme => ({
  root: {
    background: '#f5f7f8',
    margin: '5px',
    boxShadow: "3px",
    borderRadius: '10px',
    p: 1,
    minWidth: 200,
    // border: " solid gray;"
    padding: theme.spacing(1) ,
  },
  value: {
    color: '#173A5E', 
    // fontSize: 40, 
    paddingLeft: theme.spacing(2) ,
    fontWeight: 'medium'
  },
  header: {
    color: '#173A5E', 
    // fontSize: 20, 
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    fontWeight: 'medium'
  }
});
class FPieDashboard extends Component {
  
  COLORS = this.props.colors || ALL_COLORS
  
  renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
    index
  }) => {
    const RADIAN = Math.PI / 180;
    // eslint-disable-next-line
    const radius = 30 + innerRadius + (outerRadius - innerRadius);
    // eslint-disable-next-line
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    // eslint-disable-next-line
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text
            x={x}
            y={y}
            // fill="#8884d8"
            fill={this.COLORS[index % this.COLORS.length]}
            textAnchor={x > cx ? "start" : "end"}
            dominantBaseline="central"
          >
        {this.props.chartData[index].name} ({value})
      </text>
    );
  };


  render() {
    const { classes } = this.props

    return (
        <Box className={classes.root}>
          <Box className={classes.header}
             style={{ fontSize: this.props.fontSize || 25}}>{this.props.title|| "Data"}</Box>
          {/* <Box className={classes.value} >
            {this.props.value}
          </Box> */}
         
      <Box>
          <ResponsiveContainer
            width="100%"
            height={this.props.height || 475}
          > 
          <PieChart width='100%'>

              <Pie
                data={this.props.chartData}
                color="#000000"
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={this.props.radius ||170}
                fill="#8884d8"
                label={this.renderCustomizedLabel}
              >
                {this.props.chartData && this.props.chartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={this.COLORS[index % this.COLORS.length]}
                    />
                ))}
          </Pie>
          <Tooltip />

          </PieChart>
          </ResponsiveContainer>
          </Box>
          {this.props.showLatest && <Box>
            <hr/>
            <Box
              sx={{
                color: 'success.dark',
                display: 'inline',
                fontWeight: 'bold',
                mx: 0.5,
                fontSize: 14,
              }}
            >
              Latest: 
            </Box>
            <Box sx={{ color: '#46505A', display: 'inline', fontSize: 14 }}>
              {this.props.latest}
            </Box>
          </Box>}
        </Box>
      );
          }
}


export default withStyles(style)(connect(
  mapStateToProps, mapDispatchToProps)(FPieDashboard))