import React, { Component } from 'react';
import FRouterLink from '../widgets/FRouterLink';
import {API_VEHICLE_INSPECTION, ROUTE_VEHICLE_INSPECTION_URL, ROUTE_VEHICLE_URL, ROUTE_COMPANY_URL, TAB_SAFETY_AND_COMPLIANCE, SAFETY_AND_COMPLINACE_VEHICLE_INSPECTIONS, CHOICE_VEHICLE_INSPECTION } from '../../util/Const';
import FListComponent from '../../util/FListComponent';
import { connect } from "react-redux"
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import FMaterialTable from '../widgets/FMaterialTable';
import { getVehicleInspectionResultIcon } from '../../util/icon_util';
import FTableAction from '../widgets/FTableAction';
import BlockIcon from '@material-ui/icons/Block';

class VehicleInspections extends FListComponent {
  constructor(props) {
    super(props, API_VEHICLE_INSPECTION, CHOICE_VEHICLE_INSPECTION)
    this.currentTitle = 'Vehicle Inspections'
    this.currentTab = TAB_SAFETY_AND_COMPLIANCE
    this.routeUrl = ROUTE_VEHICLE_INSPECTION_URL
    this.policyID = SAFETY_AND_COMPLINACE_VEHICLE_INSPECTIONS
    this.includeStateAction = false
  }
  
  
  render() {
    return (
      <div>
   
        <FMaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: 'Inspection  ', 
              field: 'Accident',
              render: rowData => {
                return (
                  <React.Fragment>
                    <FRouterLink editIcon={true} label={rowData.vehicleInspectionType}
                     to={ROUTE_VEHICLE_INSPECTION_URL + rowData.id} />
                  </React.Fragment>
                )
              }
            },
            // {
            //   title: 'Driver', field: 'driver.prettyName',
            //   render: rowData => {
            //     if (!rowData.driver){
            //       return ''
            //     }
            //     return (
            //       <React.Fragment>
            //         <FRouterLink label={rowData.driver.prettyName} 
            //            to={ROUTE_DRIVER_URL + rowData.driver.id}
            //            disabled={isProfileDispatcher(this.props.profile)}/>
            //       </React.Fragment>
            //     )
            //   }
            // },
            {
              title: 'Driver', 
              render: this.getDriverLink
            },
            // {
            //   title: 'Vehicle #', field: 'vehicle.prettyName',
            //   render: rowData => {
            //     if (!rowData.vehicle){
            //       return ''
            //     }
            //     return (
            //       <React.Fragment>
            //         <FRouterLink label={rowData.vehicle.prettyName} 
            //            to={ROUTE_VEHICLE_URL + rowData.vehicle.id}
            //            disabled={isProfileDispatcher(this.props.profile)}/>
            //       </React.Fragment>
            //     )
            //   }
            // },
            {
              title: 'Truck #', 
              render: this.getVehicleLink
            },
            {
              title: 'Trailer #', 
              render: this.getTrailerLink
            },
            // {
            //   title: 'Trailer #', field: 'vehicle.prettyName',
            //   render: rowData => {
            //     if (!rowData.trailer){
            //       return 'N/A'
            //     }
            //     return (
            //       <React.Fragment>
            //         <FRouterLink label={rowData.trailer && rowData.trailer.prettyName} 
            //            to={ROUTE_VEHICLE_URL + rowData.trailer.id}
            //            disabled={isProfileDispatcher(this.props.profile)}/>
            //       </React.Fragment>
            //     )
            //   }
            // },
            
            // {
            //   title: 'Inspector', field: 'company.prettyName',
            //   render: rowData => {
            //     if (!rowData.company){
            //       return 'N/A'
            //     }
            //     return (
            //       <React.Fragment>
            //         <FRouterLink label={rowData.company.prettyName} 
            //            to={ROUTE_COMPANY_URL + rowData.company.id}
            //            disabled={isProfileDispatcher(this.props.profile)}/>
            //       </React.Fragment>
            //     )
            //   }
            // },
            {
              title: 'Inspected By #', 
              render: this.getCompanyLink
            },
           
            { title: 'Date', field: 'vehicleInspectionDate', render: rowData => this.getPrettyDate(rowData.vehicleInspectionDate) },
            {
              title: 'DOT Inspection', field: 'vehicleDOTInspectionClass',
              render: rowData => {
                if (!rowData.vehicleDOTInspectionClass){
                  return 'N/A'
                }
                return (
                  <React.Fragment>
                      <FRouterLink label={rowData.vehicleDOTInspectionClass}
                           to={ROUTE_VEHICLE_INSPECTION_URL + rowData.id} />
                  </React.Fragment>
                )
              }
            },
            { title: 'Location', field: 'vehicleInspectionLocation' }, 
            // { title: 'Inspection Result', field: 'vehicleInspectionResult' },
            { title: 'Inspection Result', render: rowData => getVehicleInspectionResultIcon(rowData) },

            // {
            //   title: 'Actions', 
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FTableAction 
            //             actions={this.getActions(rowData)}
            //             onTableAction = {this.onTableAction}
            //             rowData={rowData}
            //              />
            //       </React.Fragment>
            //     )
            //   }
            // },
            {
              title: 'Actions', 
              render: rowData => {
                let actions  = this.getActions(rowData, SAFETY_AND_COMPLINACE_VEHICLE_INSPECTIONS)

                return (
                  <React.Fragment>
                    {!!actions.length && <FTableAction 
                        actions={actions}
                        onTableAction = {this.onTableAction}
                        rowData={rowData}
                         />
                    }
                    {!!!actions.length && <BlockIcon color="disabled" />}
                  </React.Fragment>
                )
              }
            },
           
          ]}
          actions={
            [
              // {
              //   icon: () => (
              //     <FRouterAddLink to={ROUTE_VEHICLE_INSPECTION_URL + 'add'} color='secondary' />
              //   ),
              //   isFreeAction: true
              // }
              this.getAddNewModelActions()
            ]}
          options = {{
              pageSize: this.state.pageSize,
              pageSizeOptions: this.state.pageSizeOptions,
              actionsColumnIndex: -1
           }}
          data={this.fetchData}
          title="Vehicle Inspections"
          listErrCode={this.state.listErrCode}
        />

      </div>
    );
  }
}


export default connect(
  mapStateToProps, mapDispatchToProps)(VehicleInspections)
