import React from 'react';
import FRouterLink, { FRouterAddLink } from '../widgets/FRouterLink';
import { API_FUEL_CARDS_URL, CHOICE_FUEL_CARD, ROUTE_FUEL_CARD_URL, TAB_ADMIN, ADMIN_FUEL_CARDS } from '../../util/Const';
import FListComponent from '../../util/FListComponent';
import { connect } from "react-redux"
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import FMaterialTable from '../widgets/FMaterialTable';
import FTableAction, {ACTION_TABLE_ACTIVATE, ACTION_TABLE_DEACTIVATE, ACTION_TABLE_DELETE_ROW } from '../widgets/FTableAction';
import { getStateIcon } from '../../util/icon_util';
import BlockIcon from '@material-ui/icons/Block';


class FuelCards extends FListComponent {

  constructor(props) {
    super(props, API_FUEL_CARDS_URL, CHOICE_FUEL_CARD)
    this.currentTitle = 'Fuel Cards'
    this.currentTab = TAB_ADMIN
    this.routeUrl = ROUTE_FUEL_CARD_URL
    this.policyID = ADMIN_FUEL_CARDS
 
  }

  // getActions = (rowData) => {
  //   let actions = []
  //   rowData.state == 'ACTIVE'? actions.push(ACTION_TABLE_DEACTIVATE): 
  //         actions.push(ACTION_TABLE_ACTIVATE)
  //   actions.push(ACTION_TABLE_DELETE_ROW)
  //   return actions
  // }

  render() {
    return (
      <div>
       
        <FMaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: 'Name', 
              field: 'prettyName',
              render: rowData => {
                return (
                  <React.Fragment>
                    <FRouterLink editIcon={true} label={rowData.prettyName} to={ROUTE_FUEL_CARD_URL + rowData.id} />
                  </React.Fragment>
                )
              }
            },
            {
              title: 'Carrier', 
              render: this.getCarrierLink
            },
            // {
            //   title: 'Driver', field: 'driver.prettyName',
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FRouterLink label={rowData.driver.prettyName} 
            //            to={ROUTE_DRIVER_URL + rowData.driver.id}
            //            disabled={isProfileDispatcher(this.props.profile)}/>
            //       </React.Fragment>
            //     )
            //   }
            // },
            {
              title: 'Driver', 
              render: this.getDriverLink
            },
            {
              title: 'Vehicle', 
              render: this.getVehicleLink
            },
            // {
            //   title: 'Vehicle', field: 'vehicle.prettyName',
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FRouterLink label={rowData.vehicle.prettyName} 
            //            to={ROUTE_VEHICLE_URL + rowData.vehicle.id}
            //            disabled={isProfileDispatcher(this.props.profile)}/>
            //       </React.Fragment>
            //     )
            //   }
            // },
            // {
            //   title: 'Carrier', 
            //   field: 'carrier.prettyName',
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FRouterLink label={rowData.carrier.prettyName} to={ROUTE_CARRIERS_URL + rowData.carrier.id} />
            //       </React.Fragment>
            //     )
            //   }
            // },
           
           
            { title: 'FuelCard Pin', field: 'fuelCardPin' },
            {
              title: 'State', 
              render: rowData => {
                return (
                  <React.Fragment>
                      {getStateIcon(rowData)}
                  </React.Fragment>
                )
              }
            },
            // {
            //   title: 'Actions', 
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FTableAction 
            //             actions={this.getActions(rowData)}
            //             onTableAction = {this.onTableAction}
            //             rowData={rowData}
            //              />
            //       </React.Fragment>
            //     )
            //   }
            // },
            {
              title: 'Actions', 
              render: rowData => {
                let actions  = this.getActions(rowData, ADMIN_FUEL_CARDS)
                return (
                  <React.Fragment>
                    {!!actions.length && <FTableAction 
                        actions={actions}
                        onTableAction = {this.onTableAction}
                        rowData={rowData}
                         />
                    }
                    {!!!actions.length && <BlockIcon color="disabled" />}
                  </React.Fragment>
                )
              }
            },
            
          ]}
          actions={
            [
              this.getAddNewModelActions()
            ]}
          options = {{
              pageSize: this.state.pageSize,
              pageSizeOptions: this.state.pageSizeOptions,
              actionsColumnIndex: -1
           }}
          data={this.fetchData}
          title="Fuel Cards"
          listErrCode={this.state.listErrCode}
        />

      </div>
    );
  }
}

export default connect(
  mapStateToProps, mapDispatchToProps)(FuelCards)
