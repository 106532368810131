import React from 'react';
import {getPrettyDate, getPrettyID} from '../../util';
import { FMaterialSimpleTable } from '../widgets/FMaterialTable';
 
class Trips extends React.Component {
  render() {

    return (
      <div>

        <FMaterialSimpleTable
          columns={[
            // { title: 'Trip ID', field: 'tripID' },
            { title: 'Trip ID', render: rowData => getPrettyID(rowData.tripID, 10) },
            { title: 'Trip Date', render: rowData => getPrettyDate(rowData.tripDate) },
            { title: 'Vehicle #', field: 'vehicleNumber' },

            { title: 'Jurisdiction', field: 'tripJurisdiction' },
            { title: 'Total Distance (Mi)', field: 'tripDistance' },   
          ]}
          data={this.props.data}
          title="Trips (by Jurisdiction)"
        />

      </div>
    );
  }
}

export default Trips;
