import React from 'react'

import { Grid } from "@material-ui/core"
import FSelect from '../widgets/FSelect';
import FDate from '../widgets/FDate';
import { connect } from 'react-redux'
import { mapDispatchToProps, mapStateToProps } from '../../fredux/ActionMaker';
import { withStyles } from '@material-ui/core/styles'
import FButton from '../widgets/FButton';
import { VALIDATE_REQUIRED, VALIDATE_FEE, VALIDATE_0_100_PERCENTAGE, VALIDATE_CARRIER_REQUIRED, VALIDATE_OBJECT_REQUIRED} from '../../util/ValidatorConst';
import { isValid } from '../../util/Validator';
import FInput from '../widgets/FInput';
import FAutoComplete from "../widgets/FAutoComplete";
import { API_CARRIERS_URL, API_VEHICLES_URL, API_DRIVERS_URL } from '../../util/Const';


const styles = theme => ({
  root: {
  },
  dropDown: {
  },

  textNumber: {
    marginTop: theme.spacing(1),
  },
  generateButon: {
    marginTop: 2 * theme.spacing(1),
  }

});

class PayCheckFilterView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        driver: {},
        carrier: {},
        vehicle: {},
        payCheckPaymentStrategy: '',
        payCheckExpenseCarrierFee:0,
        payCheckFleetStartDate: '',
        payCheckFleetEndDate: '',
        payCheckFleetIsCompleted: true,
        payCheckDriverCut: '',
        payCheckExpenseStartDate: '',
        payCheckExpenseEndDate: '',
      },
      errorData: {
      },
      errorMessage: {
        driver: 'No Driver Selected/Found  Under Carrier',
        carrier: 'Invalid Carrier',
        vehicle: 'No Vehicle Selected/Found  Under Carrier',
        payCheckPaymentStrategy: 'Invalid payment type',
        payCheckFleetStartDate: 'Invalid Date Interval',
        payCheckFleetEndDate: 'Invalid Date Interval',
        payCheckFleetIsCompleted: '',
        payCheckDriverCut: '',
        payCheckExpenseStartDate: 'Invalid Date Interval',
        payCheckExpenseEndDate: 'Invalid Date Interval',
        payCheckExpenseCarrierFee: 'Invalid Carrier Fee'
      },
      validators: {
        driver: [VALIDATE_OBJECT_REQUIRED],
        carrier: [VALIDATE_CARRIER_REQUIRED],
        vehicle: [VALIDATE_OBJECT_REQUIRED],
        payCheckPaymentStrategy: [VALIDATE_REQUIRED],
        payCheckExpenseCarrierFee: [VALIDATE_FEE],
        payCheckDriverCut: [VALIDATE_REQUIRED],
        // payCheckFleetStartDate: [VALIDATE_DATE],
        // payCheckFleetEndDate: [VALIDATE_DATE],
        // payCheckExpenseStartDate: [VALIDATE_DATE],
        // payCheckExpenseEndDate: [VALIDATE_DATE],
      }
    }
  }
  
  getPayCheckDriverCutValue = () => {
    if (this.state.data.payCheckPaymentStrategy == 'BY_PERCENTAGE') return 'Driver\'s split percentage [0-100] (%)'
    else if (this.state.data.payCheckPaymentStrategy == 'BY_MILEAGE') return 'Driver payment / Mile'
    else if (this.state.data.payCheckPaymentStrategy == 'CONSTANT') return 'Driver Fixed Payment Amount'
    else return 'Please Select Payment Method'
 }

  validateIsValidInterval = (dateFrom, dateTo) => {
    return (new Date(dateFrom) <= new Date(dateTo))
  }

  getErrorData = () => {
    let errorData = {
      driver: !isValid(this.state.data.driver, this.state.validators.driver),
      carrier: !isValid(this.state.data.carrier, this.state.validators.carrier),
      vehicle: !isValid(this.state.data.vehicle, this.state.validators.vehicle),
      payCheckPaymentStrategy: !isValid(this.state.data.payCheckPaymentStrategy, this.state.validators.payCheckPaymentStrategy),
      payCheckDriverCut: this.getErrorDataDriverCut(),

      payCheckExpenseCarrierFee: !isValid(this.state.data.payCheckExpenseCarrierFee, this.state.validators.payCheckExpenseCarrierFee),
      payCheckFleetStartDate: !this.validateIsValidInterval(this.state.data.payCheckFleetStartDate, this.state.data.payCheckFleetEndDate),
      payCheckFleetEndDate: !this.validateIsValidInterval(this.state.data.payCheckFleetStartDate, this.state.data.payCheckFleetEndDate),
      // payCheckDriverCut: !this.validateIsValidInterval(this.state.data.payCheckDriverCut, this.state.data.payCheckDriverCut),
      payCheckExpenseStartDate: !this.validateIsValidInterval(this.state.data.payCheckExpenseStartDate, this.state.data.payCheckExpenseEndDate),
      payCheckExpenseStartDate: !this.validateIsValidInterval(this.state.data.payCheckExpenseStartDate, this.state.data.payCheckExpenseEndDate),

    }
    return errorData
  }

  getErrorDataDriverCut = () => {
    if (this.state.data.payCheckPaymentStrategy == 'BY_PERCENTAGE') return !isValid(this.state.data.payCheckDriverCut,  [VALIDATE_0_100_PERCENTAGE])
    return  !isValid(this.state.data.payCheckDriverCut,  [VALIDATE_REQUIRED])
  }

  validate = () => {
    return new Promise((resolve, reject) => {
      let errorData = this.getErrorData()
      this.setState(prevState => {
        return { ...prevState, errorData: errorData }
      }, () => {
        let hasError = false
        for (const [key, value] of Object.entries(errorData)) {
          if (value) {
            hasError = true
          }
        }
        hasError ? reject() : resolve()
      })
    })
  }
  
  validateSingle = (name) => {
    let isV = !isValid(this.state.data[name], this.state.validators[name])
    this.setState(prevState => {
      let newState = {
        ...prevState,
        errorData: {
          ...prevState.errorData, ...{ [name]: isV }
        }
      }
      return newState
    })
  }

  updateValues = (name, obj) => {
    
    this.setState(prevState => {
      let newState = {
        ...prevState,
        data: {
          ...prevState.data, ...obj
        },
        touched: {
          ...prevState.touched,
          [name]: true
        }
      }
      
      return newState
    }, () => {
      this.validateSingle(name)
    })
  }

 

  onSaveData = (evt) => {
    this.validate().then(succ => {
      this.props.notifyParent(this.state.data)
    }).catch(e => {
    })
  }

  onCarrierChange = () => {
    this.updateValues('driver', {driver: ''})
    this.updateValues('vehicle', {vehicle: ''})
  }

  render() {
    const {classes} = this.props
    return (
      <React.Fragment>
      <Grid container className={classes.root} spacing= {2} xs={12} >
           {/* <Grid item xs={1}>
            <FCheckBox
              name='payCheckFleetIsCompleted'
              value={this.state.data.payCheckFleetIsCompleted}
              value={this.state.data.payCheckFleetIsCompleted}
              hasError={this.state.errorData.payCheckFleetIsCompleted}
              helpMessage={this.state.errorMessage.payCheckFleetIsCompleted}
              label="Completed Fleets"
              notifyParent={this.updateValues} />
          </Grid> */}
          <Grid item xs className={classes.dropDown}>
          <FAutoComplete
                url={API_CARRIERS_URL}
                name='carrier'
                hasError={this.state.errorData.carrier}
                helpMessage={this.state.errorMessage.carrier}
                label='Authority'
                // queryParam='search'
                value={this.state.data.carrier}
                defaultValue={this.state.data.carrier}
                notifyParent={this.updateValues}
                // disabled={!this.state.isNew}
                notifyDependency={this.onCarrierChange}

             />
            {/* <FSelect
              name='carrier'
              value={this.state.data.carrier && this.state.data.carrier.id}
              hasError={this.state.errorData.carrier}
              helpMessage={this.state.errorMessage.carrier}
              label='Authority'
              data={getCarriers(this.props)}
              valueRef='prettyName'
              keyRef='id'
              notifyParent={this.updateValues} /> */}
          </Grid>
          <Grid item xs className={classes.dropDown}>
          <FAutoComplete
                  url={API_DRIVERS_URL}
                  // queryParam={`carrier=${this.state.data.carrier && this.state.data.carrier.id}`}
                  hasCarrier={true}
                  carrier={this.state.data.carrier}
                  name='driver'
                  hasError={this.state.errorData.driver}
                  helpMessage={this.state.errorMessage.driver}
                  label='Driver'
                  // queryParam='search'
                  value={this.state.data.driver}
                  defaultValue={this.state.data.driver}
                  notifyParent={this.updateValues}
                  // disabled={!this.state.isNew}
            />
            {/* <FSelect
              name='driver'
              value={this.state.data.driver && this.state.data.driver.id}
              hasError={this.state.errorData.driver}
              helpMessage={this.state.errorMessage.driver}
              label='Driver'
              data={getDrivers(this.props, this.state.data.carrier)}
              valueRef='prettyName'
              keyRef='id'
              notifyParent={this.updateValues} /> */}
          </Grid>
          <Grid item xs className={classes.dropDown}>
          <FAutoComplete
                url={API_VEHICLES_URL}
                queryParam={'vehicleType=TRUCK'}
                hasCarrier={true}
                carrier = {this.state.data.carrier}
                name='vehicle'
                hasError={this.state.errorData.vehicle}
                helpMessage={this.state.errorMessage.vehicle}
                label='Truck'
                // queryParam='search'
                value={this.state.data.vehicle}
                defaultValue={this.state.data.vehicle}
                notifyParent={this.updateValues}
                // disabled={!this.state.isNew}
            />
            {/* <FSelect
              name='vehicle'
              value={this.state.data.vehicle && this.state.data.vehicle.id}
              hasError={this.state.errorData.vehicle}
              helpMessage={this.state.errorMessage.vehicle}
              label='Vehicle'
              data={getVehicles(this.props, this.state.data.carrier, 'TRUCK')}
              valueRef='prettyName'
              keyRef='id'
              notifyParent={this.updateValues} /> */}
          </Grid>
         
          <Grid item xs  className={classes.dropDown}>
            <FSelect
              name='payCheckPaymentStrategy'
              value={this.state.data.payCheckPaymentStrategy}
              hasError={this.state.errorData.payCheckPaymentStrategy}
              helpMessage={this.state.errorMessage.payCheckPaymentStrategy}
              label='Payment Method'
              data={this.props.paycheckPaymentChoice}
              valueRef='prettyName'
              keyRef='id'
              notifyParent={this.updateValues} />
          </Grid>
          <Grid item xs className={classes.textNumber}>
            <FInput
              name='payCheckDriverCut'
              required={true}
              type='number'
              value={this.state.data.payCheckDriverCut}
              hasError={this.state.errorData.payCheckDriverCut}
              helpMessage={this.state.errorMessage.payCheckDriverCut}
              label={this.getPayCheckDriverCutValue()}
              notifyParent={this.updateValues} />
          </Grid>
        </Grid>
        <Grid container xs={12}  alignItems='stretch' justify='center' spacing={2}>
          <Grid item xs>
            <FDate
              name='payCheckFleetStartDate'
              required={true}
              defaultValue={this.state.data.payCheckFleetStartDate}
              hasError={this.state.errorData.payCheckFleetStartDate}
              helpMessage={this.state.errorMessage.payCheckFleetStartDate}
              label="Fleet From "
              notifyParent={this.updateValues} />
          </Grid>
          <Grid item xs>
            <FDate
              name='payCheckFleetEndDate'
              required={true}
              defaultValue={this.state.data.payCheckFleetEndDate}
              hasError={this.state.errorData.payCheckFleetEndDate}
              helpMessage={this.state.errorMessage.payCheckFleetEndDate}
              label="Fleet To "
              notifyParent={this.updateValues} />
          </Grid>

          <Grid item xs>
            <FDate
              name='payCheckExpenseStartDate'
              required={true}
              defaultValue={this.state.data.payCheckExpenseStartDate}
              hasError={this.state.errorData.payCheckExpenseStartDate}
              helpMessage={this.state.errorMessage.payCheckExpenseStartDate}
              label="expense From "
              notifyParent={this.updateValues} />
          </Grid>
          <Grid item xs>
            <FDate
              name='payCheckExpenseEndDate'
              required={true}
              defaultValue={this.state.data.payCheckExpenseEndDate}
              hasError={this.state.errorData.payCheckExpenseEndDate}
              helpMessage={this.state.errorMessage.payCheckExpenseEndDate}
              label="expense To "
              notifyParent={this.updateValues} />
          </Grid>
          <Grid item xs  className={classes.textNumber}>
            <FInput
              name='payCheckExpenseCarrierFee'
              required={true}
              type='number'
              value={this.state.data.payCheckExpenseCarrierFee}
              hasError={this.state.errorData.payCheckExpenseCarrierFee}
              helpMessage={this.state.errorMessage.payCheckExpenseCarrierFee}
              label="Carrier Dispatch Fee Percentage (%) "
              notifyParent={this.updateValues} />
          </Grid>
          
          <Grid item xs className={classes.generateButon}>
            <FButton color='primary'
              label='Generate paycheck'
              loading={this.state.loading}
              onClick={this.onSaveData}
            />
          </Grid>
        </Grid>
        
        </React.Fragment>    
    )
  }
}


export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps)(PayCheckFilterView))
