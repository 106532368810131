import React from 'react';
import MaterialTable from 'material-table'
import { Link } from '@material-ui/core';
import FRouterLink from '../widgets/FRouterLink';

import {ROUTE_EXPENSE_URL } from '../../util/Const';
import { getPrettyDate, roundDecimal } from '../../util';
import Icon from '@material-ui/core/Icon';
import FListComponent from '../../util/FListComponent';
import { redForNegative, redForPositive, redForZero } from '../../util/icon_util';
import FMaterialTable, { FMaterialSimpleTable } from '../widgets/FMaterialTable';


class Expenses extends React.Component {

  getExpenseFor = (rowData) => {
   let expenseFor = rowData.expenseFor
   return expenseFor
  }
  render() {
    return (
      <div>
        <FMaterialSimpleTable
          columns={[
            {
              title: 'Transaciton Id', render: rowData => {
                return (
                  <React.Fragment>
                    <FRouterLink label={rowData.expenseTransactionID} to={ROUTE_EXPENSE_URL + rowData.id} />
                  </React.Fragment>
                )
              }
            },
            { title: 'Transaction Date', render: rowData => getPrettyDate(rowData.expenseTransactionDate) },
            { title: 'Paycheck Date', render: rowData => getPrettyDate(rowData.expensePayCheckTransactionDate) },
            //TODO(Estifo)) once ready reactivate this paycheck generation should allow carrier to choose truck and driver
            // Trailer should be associated with the selected truck
            // {
            //   title: 'Truck / Trailer', 
            //   render: this.getVehicleLink
            // },
            { title: 'Expense Type', field: 'expenseType'},
           
            {
              title: 'Deductable',
              field: 'expenseIsDeductable',
              render: rowData => {
                return (
                  <React.Fragment>
                    {/* {rowData.expenseIsDeductable && <Icon> rowData.expenseDeductablePercentage </Icon>} */}
                    {rowData.expenseDeductablePercentage}%

                  </React.Fragment>
                )
              }
            },
            {
              title: 'Reimberse',
              field: 'expenseIsReimbursable',
              render: rowData => {
                return (
                  <React.Fragment>
                    {/* {rowData.expenseIsReimbursable && <Icon>X</Icon>} */}
                    {rowData.expenseReimbursablePercentage}%
                  </React.Fragment>
                )
              }
            },
            
            { title: 'Amount', field: 'expenseAmount'},

            // { title: 'Net Applied to Carrier', field: 'netAppliedToDriver',  render: rowData => redForNegative(roundDecimal(rowData.netAppliedToCarrier))},

            { title: 'Net Applied to Driver', field: 'netAppliedToDriver',  render: rowData => redForPositive(roundDecimal(rowData.netAppliedToDriver))},

            {
              title: 'Receipt',
              render: rowData => {
                let url = rowData.expenseReceipt
                return (
                  <React.Fragment>
                    {url && <Link href={url} target="_blank"> Expense</Link>}
                  </React.Fragment>
                )
              }
            }
          ]}
          data={this.props.data}
          title={`Expensess`}
          showTitle={true}
        />
      </div>
    );
  }
}

export default Expenses;