import jsPDF from "jspdf";
import 'jspdf-autotable';
import { getPrettyDate, roundDecimal } from '../../util';
import React from "react";
import {
    func
} from "prop-types";
import {
    addBeltPayCheckType,
    keyValSummary,
    addFleets,
    addExpenses,
    addLogoAndCompanyInfo,
    addPayCheckInfo,
    payCheckPaymentInfo,
    addFooterToPDF
} from "./PdfCommon"

import { BASE_DOCUMENT_URL } from '../Const';

var GREEN = "#008000"
var BLUE = "#1E90FF"
var BLACK = "#000000"
var LIGHT_DARK = "#AFADAD"
var BELT_COLOR = GREEN


export const invoicePDF = function (fleetData, expData, summaryData) {
    let fleetCol = [
        {
            title: "Load Number",
            dataKey: "fleetLoadNumber"
        },
        {
            title: "Date",
            dataKey: "fleetPickUpDate"
        },
        // {
        //     title: "Customer",
        //     dataKey: "fleetCustomer"
        // },
        // {
        //     title: "From",
        //     dataKey: "fleetPickupLocation"
        // },
        // {
        //     title: "To",
        //     dataKey: "fleetDeliveryLocation"
        // },
        // {
        //     title: "Load Fee",
        //     dataKey: "fleetLoadFee"
        // },
        // {
        //     title: "Descriptions",
        //     dataKey: "desc"
        // },
        // {    
        //     title: "Lumper Fee",
        //     dataKey: "fleetLumperFee"
        // },
        // {
        //     title: "Status",
        //     dataKey: "status"
        // },
        {
            title: "Description",
            dataKey: "invoiceDescription"
        },
        {
            title: "Total",
            dataKey: "totalFee"
        }

    ];
    let expcol = [{
            title: "Transaction",
            dataKey: "expenseTransactionID"
        },
        {
            title: "Date",
            dataKey: "expenseTransactionDate"
        },
        // {
        //     title: "Expense Type",
        //     dataKey: "expenseType"
        // },
        // {
        //     title: "Amount",
        //     dataKey: "expenseAmount"
        // },
        // {
        //     title: "Descriptions",
        //     dataKey: "desc"
        // },
        {
            title: "Description",
            dataKey: "invoiceDescription"
        },
        {
            title: "Total",
            dataKey: "expenseAmount"
        },
    ];


    //    let doc = new jsPDF('l', 'pt', "a4"); // For landscape
    let doc = new jsPDF();

    addLogoAndCompanyInfo(doc, summaryData)
    addPayCheckInfo(doc, getPrettyDate(summaryData.payCheckDate),  summaryData.payCheckID)
    addBeltPayCheckType(doc, 'INVOICE')
    let lastY = payCheckPaymentInfo (doc, "Bill To: ", 
                        summaryData.customerDetailInfo.customerName, 
                        summaryData.customerDetailInfo.customerBillingAddress, 
                        summaryData.customerDetailInfo.customerEmail, 
                        summaryData.customerDetailInfo.customerPhoneNumber)

    addFleets(doc, fleetCol, fleetData, lastY)
    addExpenses(doc, expcol, expData)
    addSummaryPayCheck(doc, summaryData)
    addFooterToPDF(doc)
    return doc;
}



export const addSummaryPayCheck = function (doc, summaryData) {

    let keys = ['gross', 'brokerOrCarrierFee', 
                'toalPayCheckExpense', 'net']
    
    let confs = {
        gross: {
            title: 'Gross',
            color: GREEN,
            value: roundDecimal(summaryData.gross),
            fontSize: {
                keyFontSize: 16,
                valueFontSize: 16
            }
        },
        brokerOrCarrierFee: {
            title: 'Service Fee',
            value: roundDecimal(summaryData.brokerOrCarrierFee),
            hide: true
        },
        toalPayCheckExpense: {
            title: 'Total Expenses',
            value: roundDecimal(summaryData.toalPayCheckExpense),
            hide: true
        },
        net: {
            title: 'Total Net',
            value: roundDecimal(summaryData.net),
            color: BLUE,
            paddingBottom: 3,
            fontSize: {
                keyFontSize: 17,
                valueFontSize: 17
            }
        }
    }
    let finalY = doc.previousAutoTable.finalY;
    keyValSummary(doc, keys, confs, 120, finalY + 5 )
}