import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import FInput from "../widgets/FInput";
import { isValid } from "../../util/Validator";
import FDetailComponent from "../../util/FDetailComponent";
import FDate from "../widgets/FDate";
import FCheckBox from "../widgets/FCheckBox";
import FFileUpload from "../widgets/FFileUpload";

import FAutoComplete from "../widgets/FAutoComplete";


import FSelect from "../widgets/FSelect";
import * as CONST from "../../util/Const"

import RecurringExpenseConf from "./RecurringExpenseConf";
import { getNextDateFrom, validateIsValidInterval, toFloat, roundDisplay } from "../../util";
import FAddress from "../widgets/FAddress";
import CompanyPopUp from "../company/CompanyPopUp";


export default class ExpenseDetailBase extends FDetailComponent {

    constructor(props, url, model, extra_props) {
        super(props, url, model, extra_props)
    }

    getErrorData = () => {
        let errorData = {
            expenseFor: !isValid(this.state.data.expenseFor, this.state.validators.expenseFor),
            expenseTransactionDate: !isValid(this.state.data.expenseTransactionDate, this.state.validators.expenseTransactionDate),
            // Make readonly
            expenseUnitPrice: !isValid(this.state.data.expenseUnitPrice, this.state.validators.expenseUnitPrice),
            expenseQuantity: !isValid(this.state.data.expenseQuantity, this.state.validators.expenseQuantity),
            expenseFee: !isValid(this.state.data.expenseFee, this.state.validators.expenseFee),
            expenseAmount: !isValid(this.state.data.expenseAmount, this.state.validators.expenseAmount),

            driver: this.isExpenseModule('driver') && !isValid(this.state.data.driver, this.state.validators.driver),
            vehicle: this.isExpenseModule('vehicle') && !isValid(this.state.data.vehicle, this.state.validators.vehicle),
            dispatcher: this.isExpenseModule('dispatcher') && !isValid(this.state.data.dispatcher, this.state.validators.dispatcher),
            carrier: !this.isProfileExpense() && !isValid(this.state.data.carrier, this.state.validators.carrier),
            
            expenseType: !isValid(this.state.data.expenseType, this.state.validators.expenseType),

            expenseReimbursablePercentage: this.state.data.expenseIsReimbursable && !isValid(this.state.data.expenseReimbursablePercentage, this.state.validators.expenseReimbursablePercentage),
            expenseDeductablePercentage: this.state.data.expenseIsDeductable && !isValid(this.state.data.expenseDeductablePercentage, this.state.validators.expenseDeductablePercentage),
            expenseInvoicePercentage: this.state.data.expenseIsInvoiceable && !isValid(this.state.data.expenseInvoicePercentage, this.state.validators.expenseInvoicePercentage),
            fleet: this.state.data.expenseIsInvoiceable && !isValid(this.state.data.fleet, this.state.validators.fleet),

            expenseMaintenanceCategory: this.isMaintenanceExpense() && !isValid(this.state.data.expenseMaintenanceCategory, this.state.validators.driver),
            fuelCardExpenseType: this.isFuelExpense() && !isValid(this.state.data.fuelCardExpenseType, this.state.validators.fuelCardExpenseType),
            company: this.isMaintenanceExpense() && !isValid(this.state.data.company, this.state.validators.company),

            // expenseMaintenanceMechanicName: this.isMaintenanceExpense() && !isValid(this.state.data.expenseMaintenanceMechanicName, this.state.validators.driver),
            // expenseMaintenanceMechanicEmail: this.isMaintenanceExpense() && !isValid(this.state.data.expenseMaintenanceMechanicEmail, this.state.validators.driver),
            // expenseMaintenanceMechanicPhoneNumber: this.isMaintenanceExpense() && !isValid(this.state.data.expenseMaintenanceMechanicPhoneNumber, this.state.validators.driver),
            address: this.isFuelExpense() && !isValid(this.state.data.address, this.state.validators.address),
   
            // expenseRecurringStartDate: this.isRecurringExpense() && !this.isValidRecurringExpense(),
            // expenseRecurringEndDate: this.isRecurringExpense() && !this.isValidRecurringExpense(),
            // expenseRecurringDays: this.isRecurringExpense() && !isValid(this.state.data.expenseRecurringDays, this.state.validators.expenseRecurringDays),
        }
        return errorData
    }

    getExpenseChoices = () => {
        let expenseForModule = this.state.data.expenseFor
        switch (expenseForModule) {
            case CONST.CHOICE_DISPATCHER:
            case CONST.CHOICE_DRIVER:
            case CONST.CHOICE_PERSONNEL:
                return this.props.expense_personnel_choices
            case CONST.CHOICE_VEHICLE:
                return this.props.expense_vehicle_choices
            case CONST.CHOICE_CARRIER:
            case CONST.CHOICE_COMPANY:
                return this.props.expense_carrier_choices
            case CONST.CHOICE_FLEET:
                return this.props.expense_fleet_choices
        }
    }

    isMaintenanceExpense = () => {
        return (this.state.data.expenseType) == 'MAINTENANCE' &&
            (this.isExpenseModule(CONST.CHOICE_VEHICLE) || this.isFleetExpense() )
    }

    isFuelExpense = () => {
        return (this.state.data.expenseType) == 'FUEL' &&
            (this.state.data.expenseFor == 'fleet')
    }

    isFleetExpense = () => {
        return (this.state.data.expenseFor == CONST.CHOICE_FLEET)
    }

    isDeductable = () => {
        return (this.isFleetExpense() || this.isExpenseModule('driver'))
    }

    isInvoiceable = () => {
        return (this.isFleetExpense() || this.isExpenseModule('fleet'))
    }

    isExpenseModule = (mod) => {
        return (this.state.data.expenseFor == mod)
    }

    isValidFleetExpense = () => {
        if (!this.state.data.expenseIsRecurring) {
            return true;
        }
        let valid =  validateIsValidInterval(null, this.state.data.expenseRecurringStartDate)
 
        valid =  valid && validateIsValidInterval(this.state.data.expenseRecurringStartDate, this.state.data.expenseRecurringEndDate)
        return valid
     }

    isRecurringExpense = () => {
        return this.state.data.expenseIsRecurring
     }
    
    isProfileExpense = () =>{
        return (this.state.data.expenseFor == 'company')
    }

    isValidRecurringExpense = () => {
       if (!this.state.data.expenseIsRecurring) {
           return true;
       }
       return validateIsValidInterval(this.state.data.expenseRecurringStartDate, this.state.data.expenseRecurringEndDate)
    }

    showModule = (module) => {
        switch (module) {
            case CONST.CHOICE_DRIVER:
                return (this.isExpenseModule(CONST.CHOICE_DRIVER) || this.isFleetExpense())
            case CONST.CHOICE_VEHICLE:
                return (this.isExpenseModule(CONST.CHOICE_VEHICLE) || this.isFleetExpense())
            case CONST.CHOICE_CARRIER:
                return (this.isExpenseModule(CONST.CHOICE_CARRIER) || this.isFleetExpense())
            case CONST.CHOICE_DISPATCHER:
                return (this.isExpenseModule(CONST.CHOICE_DISPATCHER))
            case CONST.CHOICE_CUSTOMER:
                return (this.isExpenseModule(CONST.CHOICE_CUSTOMER) || this.isInvoiceable())
        }
    }
    
    onCarrierChange = () => {
        this.updateValues('driver', {driver: ''})
        this.updateValues('vehicle', {vehicle: ''})

        this.updateValues('fleet', {fleet: ''})
        this.updateValues('dispatcher', {dispatcher: ''})
    }

    getCurrentTitle = () => {
        let title = 'Expense ' + this.state.data.expenseType
        this.props.updateCurrentTab(CONST.TAB_EXPENSE)
        this.props.updateCurrentTitle && this.props.updateCurrentTitle(title)
    }
    
    //@Override
    getExcludeKeys = () => {
          if (!this.state.isNew){
              return ['expenseRecurringStartDate', 'expenseRecurringEndDate', 'expenseRecurringDays']
          }
          return []
    }
    
    hasCustomErrors = () => {
        if (this.isFuelExpense()){
            return (!this.isAddressValid(false, true, true, false) || 
                !isValid(this.state.data.fuelCardExpenseType, this.state.validators.fuelCardExpenseType))
        }
        
     }
    
    // Returns if expense type is recurring expense
    expDetailType = () => {
         return "EXP"
    }

    isRec = () => {
        return  this.expDetailType() == "REC-EXP"
    }
    
    // If the mode is in view mode hide any specific to expense fields. Users can look into detail by directly clicking on the expense transactionid
    isInRecGroupingMode = () => {
       // If it is new / create mode then it is not grouping mode

       if (!this.state.isNew && this.isRec()) {
           return true
       }
       return false
    }

    getDetail = () => {
        const { classes } = this.props;
        this.getCurrentTitle()
        let totalExpenseAmount = toFloat(this.state.data.expenseQuantity) * toFloat(this.state.data.expenseUnitPrice) + toFloat(this.state.data.expenseFee)
        totalExpenseAmount = roundDisplay(totalExpenseAmount, 2)
        // this.props.updateCurrentTitle && this.props.updateCurrentTitle(this.getCurrentTitle())
        return (
            <React.Fragment>
                {/* <FTitle label={'Expense -  ' + this.state.data.expenseType} /> */}
                <Grid container direction='column' justify='space-between' alignItems='stretch' spacing={3} >
                    {/* <Grid item xs>
                        <FMessage error={this.props.crudServerError}
                            success={this.props.crudServerSuccess} />
                    </Grid>
  */}
                    
                    <Grid container spacing= {2} xs={12} >

                                <Grid item xs={2}>
                                    <FSelect
                                        name='expenseFor'
                                        value={this.state.data.expenseFor}
                                        hasError={this.state.errorData.expenseFor}
                                        helpMessage={this.state.errorMessage.expenseFor}
                                        label='Expense For'
                                        data={this.props.expenseForModuleChoices}
                                        valueRef='prettyName'
                                        keyRef='prettyName'
                                        required={true}
                                        disabled={!this.state.isNew || this.isInRecGroupingMode()}
                                        notifyParent={this.updateValues} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FSelect
                                        name='expenseType'
                                        value={this.state.data.expenseType}
                                        hasError={this.state.errorData.expenseType}
                                        helpMessage={this.state.errorMessage.expenseType}
                                        label='Expense Type'
                                        data={this.getExpenseChoices()}
                                        valueRef='prettyName'
                                        keyRef='prettyName'
                                        disabled={!this.state.isNew  || this.isInRecGroupingMode()}
                                        notifyParent={this.updateValues} />
                                </Grid>

                                <Grid item xs hidden={!this.isMaintenanceExpense()}>
                                    <FSelect
                                        name='expenseMaintenanceCategory'
                                        value={this.state.data.expenseMaintenanceCategory}
                                        hasError={this.state.errorData.expenseMaintenanceCategory}
                                        helpMessage={this.state.errorMessage.expenseMaintenanceCategory}
                                        label='Maintenance Type'
                                        data={this.props.vehicle_maintenanceType_choices}
                                        valueRef='prettyName'
                                        keyRef='prettyName'
                                        disabled={this.isInRecGroupingMode()}
                                        notifyParent={this.updateValues} />
                                </Grid>
                               
                                            
                                <Grid item xs hidden={this.state.data.expenseType != 'FUEL'}>
                                    <FSelect
                                        name='fuelCardExpenseType'
                                        value={this.state.data.fuelCardExpenseType}
                                        hasError={this.state.errorData.fuelCardExpenseType}
                                        helpMessage={this.state.errorMessage.fuelCardExpenseType}
                                        label='Fuel Type (used for IFTA )'
                                        data={this.props.fuelCardFuelTypes}
                                        valueRef='prettyName'
                                        keyRef='prettyName'
                                        defaultValue='DIESEL'
                                        disabled={this.isInRecGroupingMode()}
                                        notifyParent={this.updateValues} />
                                </Grid>

                                <Grid item xs hidden={this.state.data.expenseType != 'ADVANCE'}>
                                    <FSelect
                                        name='fuelCardExpenseType'
                                        value={this.state.data.fuelCardExpenseType}
                                        hasError={this.state.errorData.fuelCardExpenseType}
                                        helpMessage={this.state.errorMessage.fuelCardExpenseType}
                                        label='Advance Expense Type'
                                        data={this.props.fuelCardCashAdvanceTypes}
                                        valueRef='prettyName'
                                        keyRef='prettyName'
                                        defaultValue='CASH'
                                        disabled={this.isInRecGroupingMode()}
                                        notifyParent={this.updateValues} />
                                </Grid>

                                <Grid item xs disabled={this.isNew}>
                                    <FInput
                                        name='expenseTransactionID'
                                        value={this.state.data.expenseTransactionID}
                                        hasError={this.state.errorData.expenseTransactionID}
                                        helpMessage={this.state.errorMessage.expenseTransactionID}
                                        label="Transaction ID"
                                        disabled={!this.state.isNew || this.isInRecGroupingMode()}
                                        notifyParent={this.updateValues} />
                                </Grid>
                                <Grid item xs>
                                    <FDate
                                        name='expenseTransactionDate'
                                        required={true}
                                        defaultValue={this.state.data.expenseTransactionDate}
                                        hasError={this.state.errorData.expenseTransactionDate}
                                        helpMessage={this.state.errorMessage.expenseTransactionDate}
                                        label="Transaction Date "
                                        disabled={  this.isInRecGroupingMode()}
                                        notifyParent={this.updateValues} />
                               
                                </Grid>

                                {/* <Grid item xs>
                                    <FInput
                                        name='expenseAmount'
                                        required={true}
                                        type='number'
                                        value={this.state.total}
                                        hasError={this.state.errorData.expenseAmount}
                                        helpMessage={this.state.errorMessage.expenseAmount}
                                        label="Expense Amount "
                                        notifyParent={this.updateValues} />
                                </Grid> */}
                      
                        </Grid>

                        <Grid container spacing= {4} xs={12}>
                                
                  
                                <Grid item xs>
                                    <FInput
                                        name='expenseUnitPrice'
                                        required={true}
                                        type='number'
                                        value={this.state.data.expenseUnitPrice}
                                        hasError={this.state.errorData.expenseUnitPrice}
                                        helpMessage={this.state.errorMessage.expenseUnitPrice}
                                        label="Unit Price "
                                        disabled={  this.isInRecGroupingMode()}
                                        notifyParent={this.updateValues} />
                                </Grid>

                                <Grid item xs>
                                    <FInput
                                        name='expenseQuantity'
                                        required={true}
                                        type='number'
                                        value={this.state.data.expenseQuantity}
                                        hasError={this.state.errorData.expenseQuantity}
                                        helpMessage={this.state.errorMessage.expenseQuantity}
                                        label="Quantity "
                                        disabled={  this.isInRecGroupingMode()}
                                        notifyParent={this.updateValues} />
                                </Grid>
                                <Grid item xs>
                                    <FInput
                                        name='expenseFee'
                                        type='number'
                                        value={this.state.data.expenseFee}
                                        hasError={this.state.errorData.expenseFee}
                                        helpMessage={this.state.errorMessage.expenseFee}
                                        label="Transaction Fee "
                                        disabled={  this.isInRecGroupingMode()}
                                        notifyParent={this.updateValues} />
                                </Grid>

                                <Grid item xs>
                                    <FInput
                                        name='expenseAmount'
                                        // required={true}
                                        // type='number'
                                        disabled
                                        value={totalExpenseAmount}
                                        hasError={this.state.errorData.expenseAmount}
                                        helpMessage={this.state.errorMessage.expenseAmount}
                                        label="Total"
                                        notifyParent={this.updateValues} />
                                </Grid>
                                
                        </Grid>
                       
                       
                        <Grid container spacing= {2} xs={12} >
                                <Grid item xs hidden={!this.showModule(CONST.CHOICE_CARRIER)}>
                                    <FAutoComplete
                                        url={CONST.API_CARRIERS_URL}
                                        name='carrier'
                                        hasError={this.state.errorData.carrier}
                                        helpMessage={this.state.errorMessage.carrier}
                                        label='Authority'
                                        // queryParam='search'
                                        value={this.state.data.carrier}
                                        defaultValue={this.state.data.carrier}
                                        notifyParent={this.updateValues}
                                        disabled={!this.state.isNew || this.isInRecGroupingMode()}
                                        notifyDependency={this.onCarrierChange}

                                        />
                                </Grid>
                                <Grid item xs hidden={!this.showModule(CONST.CHOICE_DRIVER)}>
                                    <FAutoComplete
                                            url={CONST.API_DRIVERS_URL}
                                            // queryParam={`carrier=${this.state.data.carrier && this.state.data.carrier.id}`}
                                            hasCarrier={true}
                                            carrier={this.state.data.carrier}
                                            name='driver'
                                            hasError={this.state.errorData.driver}
                                            helpMessage={this.state.errorMessage.driver}
                                            label='Driver'
                                            // queryParam='search'
                                            value={this.state.data.driver}
                                            defaultValue={this.state.data.driver}
                                            notifyParent={this.updateValues}
                                            disabled={this.isInRecGroupingMode()}
                                            />
                                    
                                </Grid>
                                <Grid item xs hidden={!this.showModule(CONST.CHOICE_VEHICLE)}>
                                   <FAutoComplete
                                        url={CONST.API_VEHICLES_URL}
                                        // queryParam={'vehicleType=TRUCK'}
                                        hasCarrier={true}
                                        carrier = {this.state.data.carrier}
                                        name='vehicle'
                                        hasError={this.state.errorData.vehicle}
                                        helpMessage={this.state.errorMessage.vehicle}
                                        label='Truck'
                                        // queryParam='search'
                                        value={this.state.data.vehicle}
                                        defaultValue={this.state.data.vehicle}
                                        notifyParent={this.updateValues}
                                        disabled={this.isInRecGroupingMode()}
                                        />
                                   
                                </Grid>
                                <Grid item xs hidden={!this.showModule(CONST.CHOICE_DISPATCHER)}>
                                 <FAutoComplete
                                        url={CONST.API_DISPATCHERS_URL}
                                        // queryParam={`carrier=${this.state.data.carrier && this.state.data.carrier.id}`}
                                        hasCarrier={true}
                                        carrier={this.state.data.carrier}
                                        name='dispatcher'
                                        hasError={this.state.errorData.dispatcher}
                                        helpMessage={this.state.errorMessage.dispatcher}
                                        label='Dispatcher'
                                        // queryParam='search'
                                        value={this.state.data.dispatcher}
                                        defaultValue={this.state.data.dispatcher}
                                        notifyParent={this.updateValues}
                                        disabled={this.isInRecGroupingMode()}
                                        />
        
                                </Grid>
                               
                                <Grid item xs hidden={!this.isMaintenanceExpense()}>
                                    <FAutoComplete
                                        url={CONST.API_SERVICE_PROVIDER}
                                        queryParam={'companyType=AUTO_SERVICE_PROVIDER'}
                                        hasCarrier={true}
                                        carrier = {this.state.data.carrier}
                                        name='company'
                                        hasError={this.state.errorData.company}
                                        helpMessage={this.state.errorMessage.company}
                                        label='Auto Service Provider'
                                        // queryParam='search'
                                        value={this.state.data.company}
                                        defaultValue={this.state.data.company}
                                        notifyParent={this.updateValues}
                                        disabled={this.isInRecGroupingMode()}
                                        // Show add button on autocomplete widget
                                        showAdd={true} 
                                        // Onclick the add button 
                                        onEdit={this.onShowAddCompany}
                                        />
                                  <CompanyPopUp dialogEditMode={this.state.showAddCompany}
                                    notifyDialogClose = {() => this.setState({showAddCompany: false})}
                                    choices={this.props.companyServiceProviderContactTypeChoice}
                                    title={'Auto Service Provider'}
                                    carrier = {this.state.data.carrier}
                                  />
                                </Grid>

                                
                        </Grid>

                        <Grid container spacing= {4} xs={12} className={classes.padding}>
                                <Grid item xs>
                                    <FAddress 
                                        name='address' 
                                        notifyParent={this.updateValues}
                                        address={this.state.data.address}
                                        hasError={this.state.errorData.address}
                                        disabled={this.isInRecGroupingMode()}
                                        city={this.state.data.expenseType == 'FUEL'}
                                        state={this.state.data.expenseType == 'FUEL'}
                                        
                                    />
                                </Grid>

                        </Grid>
                        
                        <Grid container xs={12}  spacing={1} className={classes.deductable} >
                            <Grid item xs={1.2}  hidden={!this.isDeductable()}>
                                <FCheckBox
                                        name='expenseIsDeductable'
                                        value={this.state.data.expenseIsDeductable}
                                        disabled={this.isInRecGroupingMode()}
                                        hasError={this.state.errorData.expenseIsDeductable}
                                        helpMessage={this.state.errorMessage.expenseIsDeductable}
                                        label=" Deductable"
                                        notifyParent={this.updateValues} />
                                </Grid>
                                <Grid item  xs={2}  className={classes.space} hidden={!this.isDeductable()}>
                                    <FInput
                                        disabled={!this.state.data.expenseIsDeductable || this.isInRecGroupingMode()}
                                        name='expenseDeductablePercentage' 
                                        required={true}
                                        type='number'
                                        value={this.state.data.expenseDeductablePercentage}
                                        hasError={this.state.errorData.expenseDeductablePercentage}
                                        helpMessage={this.state.errorMessage.expenseDeductablePercentage}
                                        label="Driver's deductable percentage "
                                        notifyParent={this.updateValues} />
                                </Grid>
                               
                                <Grid item xs={1.3}  className={classes.deductable} hidden={!this.isDeductable()}>
                                    <FCheckBox
                                        name='expenseIsReimbursable'
                                        value={this.state.data.expenseIsReimbursable}
                                        disabled={this.isInRecGroupingMode()}
                                        hasError={this.state.errorData.expenseIsReimbursable}
                                        helpMessage={this.state.errorMessage.expenseIsReimbursable}
                                        label=" Reimbursable"
                                        notifyParent={this.updateValues} />
                                </Grid>
                                <Grid item xs={2}  className={classes.reimbursablePercentage}
                                      hidden={!this.isDeductable()} >
                                    <FInput
                                        disabled={!this.state.data.expenseIsReimbursable || this.isInRecGroupingMode()}
                                        name='expenseReimbursablePercentage'
                                        required={true}
                                        type='number'
                                        value={this.state.data.expenseReimbursablePercentage}
                                        hasError={this.state.errorData.expenseReimbursablePercentage}
                                        helpMessage={this.state.errorMessage.expenseReimbursablePercentage}
                                        label=" Driver's reimbursable percentage "
                                        notifyParent={this.updateValues} />
                                </Grid>
                                <Grid item xs={3} hidden={this.state.isNew || !this.showModule(CONST.CHOICE_DRIVER) || this.isInRecGroupingMode()}>
                                    <FDate
                                        name='expensePayCheckTransactionDate'
                                        required={true}
                                        defaultValue={this.state.data.expensePayCheckTransactionDate}
                                        hasError={this.state.errorData.expensePayCheckTransactionDate}
                                        helpMessage={this.state.errorMessage.expensePayCheckTransactionDate}
                                        label="Expense Pay Check Date "
                                        notifyParent={this.updateValues}
                                    />
                                </Grid>                           
                        </Grid>
                        <Grid container xs={12}  spacing={1} className={classes.deductable} >
                                <Grid item xs={1.3} hidden={!this.isInvoiceable()}>
                                    <FCheckBox
                                        name='expenseIsInvoiceable'
                                        value={this.state.data.expenseIsInvoiceable}
                                        value={this.state.data.expenseIsInvoiceable}
                                        hasError={this.state.errorData.expenseIsInvoiceable}
                                        helpMessage={this.state.errorMessage.expenseIsInvoiceable}
                                        label="Invoiceable"
                                        disabled={ this.isInRecGroupingMode()}
                                        notifyParent={this.updateValues} />
                                </Grid>
                               <Grid item xs={1.8} hidden={!this.state.data.expenseIsInvoiceable}
                                  className={classes.invoiceablePercentage}>
                                    <FInput
                                        disabled={!this.state.data.expenseIsInvoiceable || this.isInRecGroupingMode()}
                                        name='expenseInvoicePercentage'
                                        required={true}
                                        type='number'
                                        value={this.state.data.expenseInvoicePercentage}
                                        hasError={this.state.errorData.expenseInvoicePercentage}
                                        helpMessage={this.state.errorMessage.expenseInvoicePercentage}
                                        label=" Customer Invoice Deduct Percentage %"
                                        notifyParent={this.updateValues} />
                                </Grid>
                                
                                <Grid item xs={3}  hidden={!this.state.data.expenseIsInvoiceable}>
                                    <FAutoComplete
                                        disabled={!this.state.data.expenseIsInvoiceable || this.isInRecGroupingMode()}
                                        url={CONST.API_FLEET_URL}
                                        // queryParam={'status=COMPLETE'}
                                        hasCarrier={true}
                                        carrier = {this.state.data.carrier}
                                        name='fleet'
                                        hasError={this.state.errorData.fleet}
                                        helpMessage={this.state.errorMessage.fleet}
                                        label='Fleet'
                                        // queryParam='search'
                                        value={this.state.data.fleet}
                                        defaultValue={this.state.data.fleet}
                                        notifyParent={this.updateValues}
                                        />

        
                                </Grid>
                                <Grid item xs={3} hidden={this.state.isNew || !this.state.data.expenseIsInvoiceable}>
                                        <FDate
                                            name='expenseInvoiceTransactionDate'
                                            disabled={!this.state.data.expenseIsInvoiceable || this.isInRecGroupingMode()}
                                            required={true}
                                            defaultValue={this.state.data.expenseInvoiceTransactionDate}
                                            hasError={this.state.errorData.expenseInvoiceTransactionDate}
                                            helpMessage={this.state.errorMessage.expenseInvoiceTransactionDate}
                                            label="Expense Invoice Date "
                                            notifyParent={this.updateValues}
                                        />
                                    </Grid>
                        </Grid>
                       
                        <Grid item xs hidden={!this.state.isNew}>

                            <RecurringExpenseConf 
                                data={this.state.data}
                                errorData={this.state.errorData}
                                errorMessage={this.state.errorMessage} 
                                notifyParent={this.updateValues}
                                profile={this.props.profile}
                                weekDaysChoices={this.props.weekDaysChoices}
                                scheduleFrequencyStrategyDayIntevalTypeChoices= {this.props.scheduleFrequencyStrategyDayIntevalTypeChoices}
                                />
                            </Grid>
                
                       <Grid container className={classes.expenseReceipt} spacing={1} xs={12} >
                          
                              <Grid item xs={2}>
                                    <FFileUpload
                                        disabled={this.isInRecGroupingMode()}
                                        value={this.state.data.expenseReceipt}
                                        name='expenseReceipt'
                                        label="Upload Expense Receipt"
                                        hasError={this.state.errorData.expenseReceipt}
                                        helpMessage={this.state.errorMessage.expenseReceipt}
                                        
                                        notifyParent={this.updateValues} />
                                </Grid>
                            <Grid item xs className={classes.desc}>
                                <FInput
                                    name='desc' value={this.state.data.desc} multiline={true}
                                    value={this.state.data.desc}
                                    hasError={this.state.errorData.desc}
                                    helpMessage={this.state.errorMessage.desc}
                                    disabled={this.isInRecGroupingMode()}
                                    label="Description" notifyParent={this.updateValues} />
                            </Grid>
                        </Grid>
                    
                    {/* <Grid item container xs={8} >
                            {this.getUpdateButton(this.props.profile, CONST.EXPENSE_EXPNESES)}
                    </Grid> */}

            </Grid>

            </React.Fragment>
        );
    };
}

ExpenseDetailBase.propTypes = {
    classes: PropTypes.object.isRequired
};
