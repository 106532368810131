import React, { Component } from 'react';
import Button from '@material-ui/core/Button';

import { withStyles, CircularProgress } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ArchiveIcon from '@material-ui/icons/Archive';



const style = theme => ({
  root: {
    margin: theme.spacing(1),
    width: '100%'
  },
  paymentButton: {
    padding: theme.spacing(1),
    width: '100%'
  },
  saveOrUpdateButton: {
    marginTop: theme.spacing(1),
    width: 5 * theme.spacing(4),
    // marginLeft:  theme.spacing(2),
  }
});


class FButton extends Component {

  getIcon = () => {
    if (this.props.showIcon == 'add') {
      return <AddIcon style={{fontSize: '20px', paddingLeft: '3px'}}/>
    }
    if (this.props.showIcon == 'edit') {
      return <EditIcon style={{fontSize: '20px', paddingLeft: '3px'}}/>
    }
    if (this.props.showIcon == 'delete') {
      return <DeleteIcon style={{fontSize: '20px', paddingLeft: '3px'}}/>
    }
    if (this.props.showIcon == 'archive') {
      return <ArchiveIcon style={{fontSize: '20px', paddingLeft: '3px'}}/>
    }
  }
  render() {
    const { classes } = this.props
    return (
      // <div className="form-group">
      <div>
        <Button 
          // className={this.props.className||classes.root}
          className={classes[this.props.className] || classes.root}
          disabled={this.props.disabled}
          color={this.props.color}
          variant={this.props.variant || "contained"}
          onClick={this.props.onClick}>
          {this.props.label}
          {this.getIcon()}
        </Button>
         {this.props.loading && <CircularProgress className={classes.progress} />}
        <span style={{ color: 'red' }}> {this.props.errors}</span>

      </div>);
  }
}

export default withStyles(style)(FButton)