import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import ReCAPTCHA from "react-google-recaptcha";

const style = theme => ({
  root: {
    margin: theme.spacing(1.5),
    // width: '100%'
  },
  captcha: {
    color: 'red'
  },
});

class FRecaptcha extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: true
    };
  }

  recaptchaRef = React.createRef();

  resetRecaptcha = () => {
    this.setState({ error: true });
    this.recaptchaRef.current.reset();
  }

  onChange = (value) => {
    if (!value) {
      this.setState({ error: true });
    } else {
      this.setState({ error: false });
    }
    this.props.notifyParent(this.props.name, { [this.props.name]: value });
  }

  handleExpired = () => {
    this.setState({ error: true });
    this.props.notifyParent(this.props.name, { [this.props.name]: '' });
  }

  render() {
    const { classes } = this.props;
    const { error } = this.state;

    return (
      <div className={classes.root}>
        <div style={{ color: error ? 'red' : 'black' }}>
          <ReCAPTCHA
            ref={this.recaptchaRef}
            sitekey={this.props.recapthaSiteKey}
            onChange={this.onChange}
            onExpired={this.handleExpired}  // Ensure this line is present
          />
          {error && <span className={classes.captcha}>{this.props.helpMessage}</span>}
        </div>
      </div>
    );
  }
}

export default withStyles(style)(FRecaptcha);
