import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import FQuickLinks from '../widgets/FQuickLinks';
import { ROUTE_HELP_URL } from '../../util/Const';
import { FRouterLink } from '../widgets/FRouterLink';


const styles = theme => ({
    footer: {
         paddingLeft: 2,
        position: "fixed",
        bottom: 0,
         textAlign: "center",
        paddingBottom: 2,

    },
    withLove: {
        paddingLeft: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    footerLinks: {
        display: 'block',
        marginBottom: theme.spacing(1)
    },
    footerLinkSpacer: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(2),
    }
});
class FooterNavList extends Component {

    render() {
        const { classes } = this.props;
        let mailTo = `mailto:admin@ztrucking.com?subject=Feature / Bug Report | ${this.props.profileName}`
        return (
            <center className={classes.footer}>
                <p className={classes.withLove}>Made with <span style={{color: "#e25555"}}>❤</span> by ZTRUCKING
                    <span  className={classes.footerLinks}/>
                    <a href={mailTo} target='_blank'> Report Issue  </a>
                    {this.props.isAdminOrCarrier && <span  className={classes.footerLinkSpacer}>|</span>}
                    {this.props.isAdminOrCarrier && <FQuickLinks/>} | <br/>
                    <span  className={classes.footerLinkSpacer}>
                       <FRouterLink label={'Help'} to={ROUTE_HELP_URL} /> 
                   </span>         

                </p>
            </center>
        );
    }
}
export default withStyles(styles)(FooterNavList)
