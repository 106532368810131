import React from 'react'
import { Grid } from "@material-ui/core";
import { withStyles } from '@material-ui/core';
import ZLogo from './ZLogo';
import { HEALTH_CHECK_RECOVERY_CHECK_INTERVAL, API_HEALTH_CHECK } from '../../util/Const';
import { GET } from '../../util/FAPI';
import FButton from '../widgets/FButton';
import {  logout } from '../../util/FAPI';


const style = theme => ({
  header1: {
    margin: theme.spacing(1),
    fontSize: '30px',
    paddingBottom: 10,
    textAlign: 'center',

  },
  header2: {
    margin: theme.spacing(1),
    fontSize: '13px',
    textAlign: 'center',
    paddingBottom: 10,
    letterSpacing: 2
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

const erorrc1 = "#E34F4F"
const erorrc2 = "#841515"

/**
 * Page to display for unsupported browser size
 */
 class MaintenanceMode extends React.Component {
   state = {
     errorColor: 'red',
     loading: false
   }

  checkForHealthChange = () => {
    GET(API_HEALTH_CHECK).then(res => {
      window.location.href = '/';
    }).catch(err => {
    })
  };

  checkmanuallyForHealthChange = () => {
    this.setState({loading: true})
    GET(API_HEALTH_CHECK).then(res => {
      window.location.href = '/';
    }).catch(err => {
       this.setState({errorColor: this.state.errorColor == erorrc1? erorrc2: erorrc1, loading: false})
    })
  };

  componentDidMount() {
    this.updateHealthCheckStatus = setInterval(() => this.checkForHealthChange(),
       HEALTH_CHECK_RECOVERY_CHECK_INTERVAL);
  }

  componentWillUnmount() {
    clearInterval(this.updateHealthCheckStatus);
    this.updateHealthCheckStatus = null;
}

  render() {
    const { classes } = this.props
    return (
      <div>
          <Grid container direction='column' spacing={12} justifyContent="center" alignItems="center">
              <Grid item xs>
              <Grid item xs justifyContent="center" alignItems="center"> 
                <ZLogo/>
              </Grid>
                
                <p className={classes.header1} style={{color:this.state.errorColor}}> <b>ZTrucking Down for Maintenance!</b></p>
                <p className={classes.header2}> We're busy updateing ZTrucking for you. We will 
                  check back soon in 15 minutes. Thanks for your pateience!</p>
          </Grid>
          <Grid item className={classes.submit}>
            <FButton color='primary'
                label='Check For An Update'
                loading={this.state.loading}
                onClick={this.checkmanuallyForHealthChange}
              />
        </Grid>

        <Grid item className={classes.submit}>
            <FButton color='primary'
                label='Restart New Session'
                loading={this.state.loading}
                onClick={()=>{logout(this.props)}}
              />
        </Grid>
        </Grid>
      </div>
    )
  }
}

export default withStyles(style)(MaintenanceMode)