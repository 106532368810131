import React from 'react';
import { roundDecimal, getPrettyDate } from '../../../util';
import FMaterialTable from '../../widgets/FMaterialTable';
import { ROUTE_FLEET_URL } from '../../../util/Const';
import FRouterLink from '../../widgets/FRouterLink';
import { getStatusIcon } from '../../../util/icon_util';
import { StyledAssetLink } from '../../../util/FListComponent';

export default class Fleets extends React.Component {
  constructor(props) {
    super(props)
  }
  

  render() {

    return (
      <div>

        <FMaterialTable
          tableRef={this.props.tableRef}
          columns={[
            {
              title: 'Reference #',
              render: rowData => {
                return (
                  <React.Fragment>
                    <FRouterLink label={rowData.fleetLoadNumber} to={ROUTE_FLEET_URL + rowData.id} />
                  </React.Fragment>
                )
              }
            },
            
            
            { title: 'Pickup Date', field: 'fleetPickUpDate',
             render: rowData => getPrettyDate(rowData.fleetPickUpDate) },
            {
              title: 'Trip Location', field: 'fleetPickupLocation',
              render: rowData => {
                return (
                  <React.Fragment>
                     <b>{rowData.fleetPickupLocation}</b> to <b>{rowData.fleetDeliveryLocation}</b>
                  </React.Fragment>
                )
              }
            },
            
           
            { title: 'Lumper Fee', field: 'fleetLumperFee', render: rowData => roundDecimal(rowData.fleetLumperFee) },
            { title: 'Load Fee', field: 'fleetLoadFee', render: rowData => roundDecimal(rowData.fleetLoadFee) },
            { title: 'Detantion Fee', field: 'fleetDetantionFee', render: rowData => roundDecimal(rowData.fleetDetantionFee) },
            { title: 'No Used (TONU)', field: 'fleetNoServiceFee', render: rowData => roundDecimal(rowData.fleetNoServiceFee) },
            { title: 'Mileage', field: 'fleetDistance', render: rowData => roundDecimal(rowData.fleetDistance, 1) },
            {
              title: 'Status', 
              render: rowData => {
                return (
                  <StyledAssetLink>
                    <React.Fragment>
                        
                        {getStatusIcon(rowData)}
                    </React.Fragment>
                  </StyledAssetLink>
                )
              }
            },
          ]}
          
          options = {{
              pageSize: this.props.pstate.pageSize,
              pageSizeOptions: this.props.pstate.pageSizeOptions,
              actionsColumnIndex: -1,
              search: false
           }}
          data={this.props.fetchData}
          listErrCode={this.props.listErrCode}
        
        />

      </div>
    );
  }
}
