import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";


import { Grid } from "@material-ui/core";
import FMessage from "../widgets/FMessage";
import FInput from "../widgets/FInput";
import { mapDispatchToProps, mapStateToProps } from "../../fredux/ActionMaker";
import {  API_USER_URL, PROFILE_USERS_AND_PERMISSIONS, TAB_PROFILE } from "../../util/Const";
import { isValid } from "../../util/Validator";
import { VALIDATE_REQUIRED, VALIDATE_PHONE_NUMBER, VALIDATE_EMAIL, VALIDATE_ADDRESS, VALIDATE_PASSWORD } from "../../util/ValidatorConst";
import FDetailComponent from "../../util/FDetailComponent";
import FSelect from "../widgets/FSelect";
import {  getIdFromURL } from "../../util/FAPI";
import FAddress from "../widgets/FAddress";
import ChangePassword from "../auth/ChangePassword";
import ProfilePermission from "../profile_permission/ProfilePermissions";
import FLabel from "../widgets/FLabel";



const styles = theme => ({
    permission_padding: {
        marginTop: theme.spacing(6),
    },
});

const PROPS = {
    errorData: {
        username: false,
        profileType: false,
        password: false,
        profileFirstName: false,
        profileLastName: false,
        profilePhoneNumber: false,
        profileEmail: false,
        profileMiddleName: false,
        // dispatcherLocation: false,
        carrier: false,
        address: false
    },
    errorMessage: {
        username: 'User Name',
        profileType: 'Invalid User Type',
        password: 'Password',
        profileFirstName: 'Invalid Name',
        profileLastName: 'Invalid Name',
        profilePhoneNumber: 'Invalid Phone Number',
        profileEmail: 'Invalid Email',
        profileMiddleName: 'Invalid Dispatcher ID',
        // dispatcherLocation: 'Invalid Address',
        carrier: 'Invalid Carrier'
    },
    data: {
        username: '',
        profileType: '',
        password: '',
        profileFirstName: '',
        profileLastName: '',
        profilePhoneNumber: '',
        profileEmail: '',
        profileMiddleName: '',
        // dispatcherLocation: '',
        carrier: {},
        address: ''
    },
    validators: {
        username: [VALIDATE_REQUIRED],
        profileType: [VALIDATE_REQUIRED],
        password: [VALIDATE_PASSWORD],
        profileFirstName: [VALIDATE_REQUIRED],
        profileLastName: [VALIDATE_REQUIRED],
        profilePhoneNumber: [VALIDATE_PHONE_NUMBER],
        profileEmail: [VALIDATE_EMAIL],
        profileMiddleName: [],
        // dispatcherLocation: [VALIDATE_REQUIRED],
        carrier: [VALIDATE_REQUIRED],
        address: [VALIDATE_ADDRESS]
    },
    touched: {}
}

class UserDetail extends FDetailComponent {

    constructor(props) {
        super(props, API_USER_URL, 'profile', PROPS)
    }

    getErrorData = () => {
        let errorData = {
            username: this.state.isNew && !isValid(this.state.data.username, this.state.validators.username),
            profileType: !isValid(this.state.data.profileType, this.state.validators.profileType),
            password: this.state.isNew && !isValid(this.state.data.password, this.state.validators.password),
            profileFirstName: !isValid(this.state.data.profileFirstName, this.state.validators.profileFirstName),
            profileLastName: !isValid(this.state.data.profileLastName, this.state.validators.profileLastName),
            profilePhoneNumber: !isValid(this.state.data.profilePhoneNumber, this.state.validators.profilePhoneNumber),
            profileEmail: !isValid(this.state.data.profileEmail, this.state.validators.profileEmail),
            // dispatcherLocation: !isValid(this.state.data.dispatcherLocation, this.state.validators.dispatcherLocation)
            address: !isValid(this.state.data.address, this.state.validators.address),

        }
        return errorData
    }
    getCurrentTitle = () => {
        let title = 'Users ' + this.state.data.profileFirstName + ' ' + this.state.data.profileLastName
        this.props.updateCurrentTab(TAB_PROFILE)
        this.props.updateCurrentTitle && this.props.updateCurrentTitle(title)
    }

    hasCustomErrors = () => {
        return !(this.isAddressValid(true, true, true, true))
     }

    render() {
        // this.props.updateCurrentTitle && this.props.updateCurrentTitle(this.getCurrentTitle())
        this.getCurrentTitle()
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Grid container direction='column' justify='space-between' alignItems='stretch' spacing={3} >
                    <Grid item xs>
                        <FMessage error={this.props.crudServerError}
                            success={this.props.crudServerSuccess} />
                       {this.state.isNew && <FLabel  label="Additional charge of $49.99 per new user may be applied to your account. 
                            Please reach out to admin@ztrucking.com for more information."/>}
                    </Grid>
                    

                    {this.state.isNew && <Grid container className={classes.root} spacing= {2} xs={12} >
                            
                            <Grid item xs>
                                    <FInput name='username' required
                                        value={this.state.data.username}
                                        hasError={this.state.errorData.username}
                                        helpMessage={this.state.errorMessage.username}
                                        label="User Name"
                                         notifyParent={this.updateValues}
                                    />
                            </Grid>  
                            <Grid item xs>
                                <FInput name='password' required
                                        value={this.state.data.password}
                                        type='password'
                                        hasError={this.state.errorData.password}
                                        helpMessage={this.state.errorMessage.password}
                                        label="Password" notifyParent={this.updateValues}
                                />
                            </Grid>
                            <Grid item xs xs>
                                <FSelect
                                    name='profileType'
                                    value={this.state.data.profileType}
                                    hasError={this.state.errorData.profileType}
                                    helpMessage={this.state.errorMessage.profileType}
                                    label='Account Type'
                                    data={this.props.personnelProfileTypeChoices}
                                    valueRef='prettyName'
                                    keyRef='id'
                                    disabled={!this.state.isNew}
                                    notifyParent={this.updateValues} />
                            </Grid>
                    </Grid>}
                    <Grid container className={classes.root} spacing= {2} xs={12} >
                            <Grid item xs>
                                <FInput name='profileFirstName' required
                                    value={this.state.data.profileFirstName}
                                    hasError={this.state.errorData.profileFirstName}
                                    helpMessage={this.state.errorMessage.profileFirstName}
                                    label="First Name" notifyParent={this.updateValues}
                                />
                            </Grid>  
                            <Grid item xs>
                                <FInput name='profileMiddleName' required
                                    value={this.state.data.profileMiddleName}
                                    hasError={this.state.errorData.profileMiddleName}
                                    helpMessage={this.state.errorMessage.profileMiddleName}
                                    label="Middle Name"
                                    notifyParent={this.updateValues}
                                />
                            </Grid>
                          
                            <Grid item xs>
                                <FInput name='profileLastName' required
                                    value={this.state.data.profileLastName}
                                    hasError={this.state.errorData.profileLastName}
                                    helpMessage={this.state.errorMessage.profileLastName}
                                    label="Last Name" notifyParent={this.updateValues}
                                />
                            </Grid>
                            <Grid item xs>
                                <FInput name='profilePhoneNumber' required
                                    value={this.state.data.profilePhoneNumber}
                                    hasError={this.state.errorData.profilePhoneNumber}
                                    helpMessage={this.state.errorMessage.profilePhoneNumber}
                                    label="Phone Number" notifyParent={this.updateValues}
                                />
                            </Grid>
                            <Grid item xs>
                                <FInput name='profileEmail' required
                                    value={this.state.data.profileEmail}
                                    hasError={this.state.errorData.profileEmail}
                                    helpMessage={this.state.errorMessage.profileEmail}
                                    label="Email" notifyParent={this.updateValues}
                                />
                            </Grid>
                    </Grid>

                
                    <Grid container className={classes.root} spacing= {2} xs={12} >
                        <Grid item xs>
                            <FAddress 
                                        name='address' 
                                        notifyParent={this.updateValues}
                                        address={this.state.data.address}
                                        hasError={this.state.errorData.address}
                                        place={true}
                                        city={true}
                                        state={true}
                                        zipCode={true}
                            />
                            </Grid>
                    </Grid>

                        <Grid container className={classes.root} spacing= {4} xs={12} >
                                <Grid item xs={6}>
                                    <FInput
                                        name='desc' value={this.state.data.desc} multiline={true}
                                        value={this.state.data.desc}
                                        hasError={this.state.errorData.desc}
                                        helpMessage={this.state.errorMessage.desc}
                                        label="Description" notifyParent={this.updateValues} />
                                </Grid>
                        </Grid>
                        <Grid item container xs={8} >
                            {this.getUpdateButton(this.props.profile, PROFILE_USERS_AND_PERMISSIONS)}
                        </Grid>
                        {/* <Grid container className={classes.root} spacing= {4} xs={12} >
                            <Grid item container xs={8} >
                                <FButton color='primary'
                                    label={this.state.isNew ? 'Save' : 'Update'}
                                    loading={this.state.loading}
                                    onClick={this.onSaveData}
                                />
                                <Grid item style={{ paddingTop: '9px' }}>
                                    <FRouteBackLink variant='outlined' color='secondary' className={'backButton'} />
                                </Grid>
                            </Grid>
                        </Grid> */}
                        {!this.state.isNew && 
                            <Grid container >
                                <Grid item xs={12}>
                                    <ChangePassword profileId={getIdFromURL(this.props)} module="Personnel Profile" />
                                </Grid>
                            </Grid>
                        }
                </Grid >

                <Grid container direction='column'  spacing={2} className={this.props.classes.permission_padding} >
                    <Grid item xs>
                        {!this.state.isNew && 
                            <ProfilePermission
                                personnel={this.state.data}                            
                            />
                        }
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    };
}

UserDetail.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(connect(
    mapStateToProps, mapDispatchToProps)(UserDetail))
