import React, { Component } from 'react';
import Alert from '@material-ui/lab/Alert';
import FReloadButton from '../widgets/FReloadButton';



export default class FVersionInfo extends Component {
   
    render() {      
        if (!!this.props.showWarnning) {
            return (
                        <React.Fragment>
                          <div style={{
                              'z-index': 1,
                            'left': '35%',
                            'bottom': '0',
                            'position': 'fixed',
                            //  'backgroundColor': 'red'
                          }}>
                            <Alert severity="info">
                              <FReloadButton color='secondary'
                                            label={'New Version Available! Please Reload or Click here to Update the page. If you keep seeing this message, please logout and log back in.' }/>
                            </Alert>
                          </div> 
                        </React.Fragment>
                        )
        }
        else{
          return (<React.Fragment> </React.Fragment>)
        }
      }
    }
