import React from 'react'
import FleetReportHeader from './FleetFilter';
import { TAB_REPORT, API_REPORT_FLEET } from '../../../util/Const';
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";
import { mapDispatchToProps, mapStateToProps } from "../../../fredux/ActionMaker";
import Fleets from './Fleets';
import BaseReport from '../BaseReport';
import { getDate } from '../../../util';


const styles = theme => ({
  reimbursablePercentage : {
      marginRight: theme.spacing(2),

  },
});

class FleetReport extends BaseReport {
  // tableRef = React.createRef();

  constructor(props) {
    let state = {
      reportFileName: 'fleets_report',
      listUrl: API_REPORT_FLEET, 
      downloadUrl: API_REPORT_FLEET + 'csv/?',
      data: {
        fleetPickUpStartDate: getDate(new Date(), 90),
        fleetPickUpEndDate: getDate(new Date()),
        driver: {},
        carrier: {},
        vehicle: {},
        dispatcher: {},
        status: 'COMPLETE'
      },
      errorData: {},
      errorMessage: {},
      validators: {}
    }
    super(props, state)
  }

  

  updateFilter = () => {
    let url =  `fleetPickUpDate__gte=${this.state.data.fleetPickUpStartDate}`
    url += `&fleetPickUpDate__lte=${this.state.data.fleetPickUpEndDate}`
    if (!!(this.state.data.carrier && this.state.data.carrier.id)) url += `&carrier=${this.state.data.carrier.id}`
    if (!!(this.state.data.carrier && this.state.data.driver && this.state.data.driver.id)) url += `&driver=${this.state.data.driver.id}`
    if (!!(this.state.data.carrier &&  this.state.data.vehicle && this.state.data.vehicle.id)) url += `&vehicle=${this.state.data.vehicle.id}`
    if (!!(this.state.data.carrier &&  this.state.data.dispatcher && this.state.data.dispatcher.id)) url += `&dispatcher=${this.state.data.dispatcher.id}`
    if (!!(this.state.data.status)) url += `&status=${this.state.data.status}`

    this.refreshFilter(url)
  }


  render() {
    this.getCurrentTitle( 'Report Fleets', TAB_REPORT )
    const { classes } = this.props;

    return (
      <div >

        {/* {this.state.listErrCode != 403 &&  */}
         <FleetReportHeader 
                disabled={this.state.listErrCode == 403}
                data={this.state.data} 
                errorMessage={this.state.errorMessage}
                errorData={this.state.errorData}
                validators={this.state.validators}
                updateValues={this.updateValues}
                onDownload={this.onDownload}
            />
        {/* } */}

            <Fleets 
                pstate={this.state} 
                tableRef={this.tableRef}
                fetchData={this.fetchData}
                listErrCode={this.state.listErrCode}  />
      </div>
    )
  }
}


export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps)(FleetReport))
