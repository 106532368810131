import React, { Component } from 'react'
import Main from './components/app/';
import Auth from './components/auth/';
import { mapStateToProps, mapDispatchToProps } from './fredux/ActionMaker';
import { connect } from 'react-redux';
import * as CONST from './util/Const';
import * as ACTION from './fredux/ActionTypes';
import * as API from './util/FAPI';
import SignIn from './components/auth/SignIn';
import { FRouteExt } from './components/app/FRoute';

class App extends Component {

  render() {
    // this.props.dispatchSignIn()
    return (
      <div>
        
         <FRouteExt/>
        {/* {this.props.authStatus && <Main />} */}
        {/* {!this.props.authStatus && <Auth />} */}
        {/* <SignIn /> */}

      </div>
    )
  }

}
export default connect(
  mapStateToProps, mapDispatchToProps)(App)
