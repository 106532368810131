import React, { Component } from 'react'
import ListItemIcon from '@material-ui/core/ListItemIcon';
// import HelpIcon from '@material-ui/core/HelpIcon';


import { mapStateToProps } from '../../fredux/ActionMaker';
import { connect } from 'react-redux'
import {  has_view_permission} from '../../util/ACL';
import {has_view_user_permission} from '../../util/ACL';
import { Link } from 'react-router-dom'
import { ActionAccountCircle } from 'material-ui/svg-icons';
import PeopleIcon from '@material-ui/icons/People';
import PaymentIcon from '@material-ui/icons/Payment';
import ListItem from '@material-ui/core/ListItem';
import { isAdminProfile, logout } from '../../util/FAPI';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import ListItemText from '@material-ui/core/ListItemText';
import HelpIcon from '@mui/icons-material/Help';




import * as CONST from "../../util/Const";
import FListItem from '../widgets/FListItem';


class ProfileListItems extends Component {

    render() {
        const profile = this.props.profile
        const profileLink = CONST.ROUTE_MY_ACCOUNT_URL + profile.id

        return (
            <div>
               <FListItem component={Link} to={profileLink}
                 label='My Account' icon={ActionAccountCircle}>
                    <ActionAccountCircle />
                </FListItem>
            
            {has_view_user_permission(this.props, CONST.ROUTE_USERS_URL) && 
            <FListItem  to={CONST.ROUTE_USERS_URL} label='Personnel Account'>
                <PeopleIcon />
            </FListItem>
            }
            
            <FListItem  to={CONST.ROUTE_PAYMENT_URL} label='Billing'>
                <PaymentIcon />
            </FListItem>

            {/* {has_view_user_permission(this.props, CONST.ROUTE_DRIVER_APP_DRIVER_PROFILE_URL) && 
            <FListItem  to={CONST.ROUTE_DRIVER_APP_DRIVER_PROFILE_URL} label='Driver Account'>
                <PeopleIcon />
            </FListItem>
            } */}

         
     <FListItem component={Link} to={CONST.ROUTE_HELP_URL}
                 label='Help' icon={HelpIcon}>
                    <HelpIcon />
                </FListItem>
            

          <ListItem   button onClick={()=>{logout(this.props)}}>
                <ListItemIcon>
                    <ExitToAppRoundedIcon fontSize="small" />
                   </ListItemIcon>
                <ListItemText primary='Logout' />
                </ListItem>
        </div>
        );
    }
}

export default connect(mapStateToProps)(ProfileListItems)
