import React from 'react';
import FRouterLink, { FRouterAddLink } from '../widgets/FRouterLink';
import {ROUTE_DRIVER_URL, ROUTE_CARRIERS_URL, API_TRIPS_URL, CHOICE_TRIP, ROUTE_TRIP_URL, ROUTE_VEHICLE_URL, TAB_FLEET, FLEET_TRIPS } from '../../util/Const';
import FListComponent, { StyledAssetLink } from '../../util/FListComponent';
import { connect } from "react-redux"
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import FMaterialTable from '../widgets/FMaterialTable';
import FTableAction, { ACTION_TABLE_DELETE_ROW } from '../widgets/FTableAction';
import { isProfileDispatcher } from '../../util/ACL';
import { getPrettyDate } from '../../util';
import BlockIcon from '@material-ui/icons/Block';


class Trips extends FListComponent {

  constructor(props) {
    super(props, API_TRIPS_URL, CHOICE_TRIP)
    this.currentTitle = 'Trips ( Mileage )'
    this.currentTab = TAB_FLEET
    this.routeUrl = ROUTE_TRIP_URL
    this.policyID = FLEET_TRIPS
    this.includeStateAction = false
  }

  render() {
    return (
      <div>
       
        <FMaterialTable
          tableRef={this.tableRef}
          columns={[
            
            {
              title: 'Trip ID', 
              field: 'prettyName',
              render: rowData => {
                return (
                  <StyledAssetLink>
                    <React.Fragment>
                      <FRouterLink editIcon={true} label={rowData.prettyName} to={ROUTE_TRIP_URL + rowData.id} />
                    </React.Fragment>
                  </StyledAssetLink>
                )
              }
            },

            {
              title: 'Carrier', 
              render: this.getCarrierLink
            },
            {
              title: 'Driver', 
              render: this.getDriverLink
            },
            {
              title: 'Vehicle', 
              render: this.getVehicleLink
            },
            
            { title: 'Date', render: rowData => getPrettyDate(rowData.tripDate) },

            { title: 'Jurisdiction', field: 'tripJurisdiction' },
            { title: 'Total Distance (Mi)', field: 'tripDistance' },   
            {
              title: 'Actions', 
              render: rowData => {
                let actions  = this.getActions(rowData, FLEET_TRIPS)

                return (
                  <React.Fragment>
                    {!!actions.length && <FTableAction 
                        actions={actions}
                        onTableAction = {this.onTableAction}
                        rowData={rowData}
                         />
                    }
                    {!!!actions.length && <BlockIcon color="disabled" />}
                  </React.Fragment>
                )
              }
            },
            
          ]}
          actions={
            [
              this.getAddNewModelActions()
            ]}
          options = {{
              pageSize: this.state.pageSize,
              pageSizeOptions: this.state.pageSizeOptions,
              actionsColumnIndex: -1
           }}
          data={this.fetchData}
          title="Trips ( Mileage)"
          listErrCode={this.state.listErrCode}
        />

      </div>
    );
  }
}

export default connect(
  mapStateToProps, mapDispatchToProps)(Trips)
