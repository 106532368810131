import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles'
import { connect } from "react-redux"
import { mapDispatchToProps, mapStateToProps } from "../../fredux/ActionMaker";
import { Box } from '@material-ui/core';
import { ALL_COLORS } from '../../util/Const';

import {

  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  ResponsiveContainer
} from "recharts";

const style = theme => ({
  root: {
    background: '#f5f7f8',
    margin: '5px',
    boxShadow: "3px",
    borderRadius: '10px',
    p: 1,
    minWidth: 200,
    // border: " solid gray;"
    padding: theme.spacing(1) ,
  },
  value: {
    color: '#173A5E', 
    fontSize: 40, 
    paddingLeft: theme.spacing(1) ,
    fontWeight: 'medium'
  },
  header: {
    color: '#173A5E', 
    // fontSize: 40, 
    paddingLeft: theme.spacing(2) ,
    paddingBottom: theme.spacing(1),

    fontWeight: 'medium'
  }
});
class FLineDashboardInline extends Component {
  
  


  render() {
    const COLORS = this.props.colors || ALL_COLORS
    const { classes } = this.props

    return (
        <Box className={classes.root}>
          <Box className={classes.header}
           style={{ fontSize: this.props.fontSize || 25 }}>{this.props.title|| "Data"}</Box>
          {/* <Box className={classes.value} >
            {this.props.value}
          </Box> */}
         
      <Box>
          <ResponsiveContainer
            width="95%"
            height={this.props.height || 475}
          > 
          <LineChart width={730} height={250} data={this.props.chartData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={this.props.nameKey || "name"} />
                <YAxis />
                <Tooltip />
                {/* <Legend /> */}
                <Line type="monotone" dataKey={this.props.valueKey || "name"} stroke="#8884d8" />
                {/* <Line type="monotone" dataKey={this.props.nameKey || "name"} stroke="#82ca9d" /> */}
              </LineChart>
          </ResponsiveContainer>
          </Box>
          {this.props.showLatest && <Box>
            <hr/>
            <Box
              sx={{
                color: 'success.dark',
                display: 'inline',
                fontWeight: 'bold',
                mx: 0.5,
                fontSize: 14,
              }}
            >
              Latest: 
            </Box>
            <Box sx={{ color: '#46505A', display: 'inline', fontSize: 14 }}>
              {this.props.latest}
            </Box>
          </Box>}
      </Box>
    );
          }
}


export default withStyles(style)(connect(
  mapStateToProps, mapDispatchToProps)(FLineDashboardInline))