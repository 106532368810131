import React from 'react'
import { connect } from 'react-redux'

import { Grid } from "@material-ui/core";
import FButton from "../widgets/FButton";
import FDialog from '../widgets/FDialog';
import { mapDispatchToProps, mapStateToProps } from '../../fredux/ActionMaker';
import FSelect from "../widgets/FSelect";
import FDate from "../widgets/FDate";
import FFileUpload from "../widgets/FFileUpload";
import * as CONST from "../../util/Const";
import { VALIDATE_REQUIRED, MAX_30_DAYS, VALIDATE_FILE_SIZE, VALIDATE_POSITIVE } from "../../util/ValidatorConst";
import FInput from '../widgets/FInput';
import { FListDetailView } from '../../util/FListDetailView';
import { isValid } from "../../util/Validator";
import { DELETE, getCarriers, getChoice, UPDATE } from '../../util/FAPI';
import FCheckBox from '../widgets/FCheckBox';
import FAutoComplete from '../widgets/FAutoComplete';
import JobSchedules from './ScheduleJobs';
import { showSuccessNotification, showErroNotification } from '../widgets/FMessage';

const PROPS = {
  editMode: false,
  isNew: true,
  url: CONST.API_VEHICLE_MAINTENANCE_SCHEDULE_JOB_URL,
  listData: [],
  formData: {},
  errorData: {
  },
  touched: {
    // status: true,
    // documentIdRequired: true,
    // documentExpires: true,
  },
  errorMessage: {
    scheduledJobName: 'Invalid Name',
    status: 'Invald State',
    scheduledJobDate: 'Invalid DAte',
    maintenance: 'Associate maintenance for complete Schedule',
    desc: '',
  },
  data: {
    scheduledJobName: '',
    status: '',
    scheduledJobDate: '',
    scheduledMileage: '',
    maintenance: {},
    carrier: {},
    desc: '',
  },
  validators: {
    scheduledJobName: [VALIDATE_REQUIRED],
    status: [VALIDATE_REQUIRED],
    scheduledJobDate: [VALIDATE_REQUIRED],
    scheduledMileage: [VALIDATE_POSITIVE],
    maintenance: [VALIDATE_REQUIRED],
    desc: [],
  }
}

class JobScheduleListDetail extends FListDetailView {

  constructor(props) {
    super(props, PROPS)
    this.setState({ data: { [this.props.ownerType]: this.props.ownerId} })
  }
  
  showStg = ( recuStg) => {
    if (recuStg == 'MILEAGE') {
      return this.state.data.scheduleJobFrequencyStrategyType == 'MILEAGE';
    }
    if (recuStg == 'DATE') {
      return this.state.data.scheduleJobFrequencyStrategyType == 'DATE';
    }
  }
  // isMaintenanceValid = () => {
  //   let  scheduleType = this.state.data.schedule.scheduleType
  //   if(this.state.data.status == 'COMPLETE' ) {    
  //     if ((scheduleType == CONST.SCHEDULE_TYPE.PERIODIC_INSPECTION ||
  //          scheduleType == CONST.SCHEDULE_TYPE.PREVENTIVE_MAINTENANCE)){
  //               return isValid(this.state.data.maintenance, this.state.validators.maintenance)
  //       }
  //   }
  //   return true;
   
  //   // if (scheduleType == CONST.SCHEDULE_TYPE.RECURRING_EXPENSE && this.state.data.status == 'COMPLETE' ) {
  //   //   return isValid(this.state.data.mileage, this.state.validators.mileage)
  //   // }
    
  // }
  getErrorData = () => {
    let errorData = {
        scheduledJobName: !isValid(this.state.data.scheduledJobName, this.state.validators.scheduledJobName),
        // status: !isValid(this.state.data.status, this.state.validators.status),
        scheduledJobDate: this.showStg('DATE') && !isValid(this.state.data.scheduledJobDate, this.state.validators.scheduledJobDate),
        scheduledMileage: this.showStg('MILEAGE') && !isValid(this.state.data.scheduledMileage, this.state.validators.scheduledMileage),

        // maintenance: !this.isMaintenanceValid()
    }
    return errorData
  }

  showCarrier = () => {
    return false
  }




  deleteRow = (rowData) => {
      let url = CONST.API_VEHICLE_MAINTENANCE_SCHEDULE_JOB_URL + `${rowData.id}`
      DELETE(url).then(response => {
        showSuccessNotification(response, CONST.DELETE_MAINTENANCE_SCHEDULE_JOBS_MSG)
        this.fetchListData();
      }).catch(error => {
        showErroNotification(error, CONST.DELETE_MAINTENANCE_SCHEDULE_JOBS_MSG)
      })
    }
  
  getCarrier = () => {
       
  }
  

  render() {
    const title = this.isNew ? 'Add Document' : 'Update Document'
    return (
      <div>
        <div>
          <JobSchedules
            profile={this.props.profile}
            listUrl={this.props.listUrl}
            owner={this.props.owner}
            ownerType={this.props.ownerType}
            onEdit={this.onEdit}
            onDelete={this.deleteRow}
            data={this.state.listData}
            title={this.props.title}
            module={CONST.CHOICE_MAINTENANCE_SCHEDULE_JOB}
          />
        </div>

        <div>
          <FDialog
            maxWidth='xl'
            title={this.state.data.scheduledJobName}
            owner={this.props.owner}
            openDialog={this.state.editMode}
            notifyDialogClose={this.notifyDialogClose}>
              <Grid container direction='column' spacing={2}>

                     <Grid item xs>
                          <FInput
                            name='scheduledJobName' value={this.state.data.scheduledJobName} 
                            value={this.state.data.scheduledJobName}
                            hasError={this.state.errorData.scheduledJobName}
                            helpMessage={this.state.errorMessage.scheduledJobName}
                            label="Schedule Name" 
                            notifyParent={this.updateValues} />
                        </Grid>
                     <Grid container  spacing={12}>
                        {this.state.data.scheduleJobFrequencyStrategyType == 'DATE' && <Grid item xs>
                            <FDate
                              name='scheduledJobDate'
                              required={true}
                              defaultValue={this.state.data.scheduledJobDate}
                              hasError={this.state.errorData.scheduledJobDate}
                              helpMessage={this.state.errorMessage.scheduledJobDate}
                              label="Schedule Date"
                              notifyParent={this.updateValues} />
                        </Grid>}
                        
                        {this.state.data.scheduleJobFrequencyStrategyType == 'MILEAGE' && <Grid item xs>
                      
                             <FInput
                                        name='scheduledMileage'
                                        required={true}
                                        type='number'
                                        value={this.state.data.scheduledMileage}
                                        hasError={this.state.errorData.scheduledMileage}
                                        helpMessage={this.state.errorMessage.scheduledMileage}
                                        label="Miles "
                                        notifyParent={this.updateValues} />
                        </Grid>}


                  </Grid>
               
                

             {/* <Grid item xs>
                   <FSelect
                      name='status'
                      value={this.state.data.status}
                      hasError={ this.state.errorData.status}
                      helpMessage={ this.state.errorMessage.status}
                      label='Status'
                      data={this.props.jobScheduleStatusChoice}
                      valueRef='prettyName'
                      keyRef='id'
                      notifyParent={this.updateValues} />
                   </Grid> */}
                       {/* <Grid item xs hidden={this.state.data.status == 'IN_COMPLETE'}> */}
                       <Grid item xs>
                            <FAutoComplete
                                      url={CONST.API_VEHICLE_MAINTENANCE_URL}
                                      queryParam={`carrier=${this.state.data.carrier && 
                                            this.state.data.carrier.id}&expenseType=MAINTENANCE`+
                                          `&vehicle=${this.props.owner.vehicle.id}`}
                                      hasCarrier={true}
                                      carrier={this.state.data.carrier}
                                      prettyName="maintenancePrettyName"
                                      name='maintenance'
                                      // hasError={this.state.errorData.maintenance}
                                      helpMessage={this.state.errorMessage.maintenance}
                                      label='Maintenance'
                                      // queryParam='search'
                                      value={this.state.data.maintenance}
                                      defaultValue={this.state.data.maintenance}
                                      notifyParent={this.updateValues}
                                      // disabled={!this.state.isNew}
                                      />
                    
                </Grid>
              <Grid item xs>
                <FInput
                  name='desc' value={this.state.data.desc} multiline={true}
                  value={this.state.data.desc}
                  hasError={this.state.errorData.desc}
                  helpMessage={this.state.errorMessage.desc}
                  label="Description" 
                  notifyParent={this.updateValues} />
              </Grid>
              <Grid container direction='row' xs={8} justify='space-around'>
                <Grid item xs={4}>
                  <FButton color='primary' label={this.isNew ? 'Save' : 'Update'} 
                      loading={this.state.loading}
                    onClick={this.onSaveData} />
                </Grid>
                <Grid item xs={4}>
                  <FButton color='secondary' label='Cancel'
                    onClick={this.notifyDialogClose} />
                </Grid>
              </Grid>
            </Grid>
          </FDialog>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps, mapDispatchToProps)(JobScheduleListDetail)