import React from "react";
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";


import { Grid, Paper } from "@material-ui/core";
import FSelect from "../widgets/FSelect";
import FMessage from "../widgets/FMessage";
import FInput from "../widgets/FInput";
import { mapDispatchToProps, mapStateToProps } from "../../fredux/ActionMaker";
import {API_DOCUMENTS_URL, API_DRIVER_DISCIPLINARY_EVENT_API, CHOICE_DRIVER_SAFETY_VIOLATION,
        API_CARRIERS_URL,API_DRIVERS_URL,API_VEHICLES_URL, TAB_SAFETY_AND_COMPLIANCE, SAFETY_AND_COMPLINACE_DISCIPLINARY_EVENTS } from "../../util/Const";
import DocumentView from "../document"
import { VALIDATE_REQUIRED,VALIDATE_OBJECT_REQUIRED, VALIDATE_CARRIER_REQUIRED } from "../../util/ValidatorConst";
import FDetailComponent from "../../util/FDetailComponent";
import FAutoComplete from "../widgets/FAutoComplete";
import { getDriverEvents } from "../../util/FAPI";
import FDate from "../widgets/FDate";


const styles = theme => ({
    root: {
        // display: "flex",

    },
    padding: {
        marginTop: theme.spacing(1),
    },
    padding4: {
        marginTop: theme.spacing(4),
    },
    header: {
        background: '#f5f5f5'
    },
    button: {
        marginRight: theme.spacing(2),
    },
    dropDown: {
        marginLeft: theme.spacing(2),
        
    },
    root1: {
        backgroundColor: '#fff',
        padding: theme.spacing(2),
    },
});

const PROPS = {
    data: {
        eventName: '',
        eventType: 'DRIVER_SAFETY_VIOLATION',
        eventValue: '',
        eventCorrectiveAction: '',
        eventAlarmLevel: '',
        eventDate: '',
        driver: {},
        vehicle: {},
        carrier: {}
    },
    errorData: {
        eventName: false,
        eventType: false,
        eventValue: false,
        eventCorrectiveAction: false,
        eventAlarmLevel: false,
        eventDate: false,
        driver: false,
        vehicle: false,
        carrier: false
    },
    errorMessage: {
        eventName: 'Invalid event name',
        eventType: 'Invalid event choice',
        eventValue: 'Invalid Value',
        eventCorrectiveAction: 'Invalid Disiplinary Action',
        eventAlarmLevel: 'Invalid Alarm Level',
        eventDate: 'Invalid Date',
        driver: 'Invalid Driver',
        vehicle: 'Invalid Vehicle',
        carrier: 'Invalid Carrier'
    },
    touched: {
        eventType: true,
     },
    
    validators: {
        eventName: [VALIDATE_REQUIRED],
        eventCorrectiveAction: [VALIDATE_REQUIRED],
        eventValue: [VALIDATE_REQUIRED],
        eventAlarmLevel: [VALIDATE_REQUIRED],
        eventDate: [VALIDATE_REQUIRED],
        driver: [VALIDATE_OBJECT_REQUIRED],
        vehicle: [VALIDATE_OBJECT_REQUIRED],
        carrier: [VALIDATE_CARRIER_REQUIRED]
    }
}

class DriverDisciplinaryEvent extends FDetailComponent {
    constructor(props) {
        super(props, API_DRIVER_DISCIPLINARY_EVENT_API, CHOICE_DRIVER_SAFETY_VIOLATION, PROPS)
        // this.setState({ showDOTInfo: this.showDOTInfo() })
    }

    // getErrorData = () => {
    //     let errorData = {
    //         eventName: !isValid(this.state.data.eventName, this.state.validators.eventName),
    //         eventValue: !isValid(this.state.data.eventValue, this.state.validators.eventValue),
    //         // eventAlarmLevel: !isValid(this.state.data.eventAlarmLevel, this.state.validators.eventAlarmLevel),
    //         companyPhoneNumber: !isValid(this.state.data.companyPhoneNumber, this.state.validators.companyPhoneNumber),
    //         eventType: !isValid(this.state.data.eventType, this.state.validators.eventType),
    //         eventDate: !isValid(this.state.data.eventDate, this.state.validators.eventDate),
    //         // companyIsInvoiceable: !isValid(this.state.data.companyIsInvoiceable, this.state.validators.companyIsInvoiceable),
    //         carrier: !isValid(this.state.data.carrier, this.state.validators.carrier),
    //     }
    //     return errorData
    // }

    
    getCurrentTitle = () => {
        // let title = 'Driver Disciplinary Event - ' + this.state.driver && this.state.driver.driverLastName
        let title = 'Driver Disciplinary Event'

        this.props.updateCurrentTab(TAB_SAFETY_AND_COMPLIANCE)
        this.props.updateCurrentTitle && this.props.updateCurrentTitle(title)
    }

    render() {
        const { classes } = this.props;
        this.getCurrentTitle()
        return (
            <Paper className={classes.root1} >
                {/* <FTitle label={'Former Employer -  ' + this.state.data.eventName} /> */}
                <Grid container direction='column' className={classes.root1} justify='space-between' alignItems='stretch' spacing={4} >
                    <Grid item xs>
                        <FMessage error={this.props.crudServerError}
                            success={this.props.crudServerSuccess} />
                    </Grid>
                    <Grid container  spacing= {2} xs={12} >
                        
                            <Grid item xs={3}>
                                <FInput name='eventName' required
                                    value={this.state.data.eventName}
                                    hasError={this.state.errorData.eventName}
                                    helpMessage={this.state.errorMessage.eventName}
                                    label="Event Name"
                                    notifyParent={this.updateValues}
                                />
                            </Grid>
                            <Grid  item xs className={classes.dropDown} >
                                <FAutoComplete
                                    url={API_CARRIERS_URL}
                                    // required
                                    name='carrier'
                                    hasError={this.state.errorData.carrier}
                                    helpMessage={this.state.errorMessage.carrier}
                                    label='Authority *'
                                    // queryParam='search'
                                    value={this.state.data.carrier}
                                    defaultValue={this.state.data.carrier}
                                    notifyParent={this.updateValues}
                                    disabled={!this.state.isNew}
                                    // notifyDependency={this.onCarrierChange}
                                    />
                            </Grid>
                                <Grid item xs >
                                    <FAutoComplete
                                            url={API_DRIVERS_URL}
                                            // required
                                            // queryParam={`carrier=${this.state.data.carrier && this.state.data.carrier.id}`}
                                            hasCarrier={true}
                                            carrier={this.state.data.carrier}
                                            name='driver'
                                            hasError={this.state.errorData.driver}
                                            helpMessage={this.state.errorMessage.driver}
                                            label='Driver *'
                                            // queryParam='search'
                                            value={this.state.data.driver}
                                            defaultValue={this.state.data.driver}
                                            notifyParent={this.updateValues}
                                            // disabled={!this.state.isNew}
                                            />
                                    
                                </Grid>
                                <Grid item xs>
                                    <FAutoComplete
                                        url={API_VEHICLES_URL}
                                        // required
                                        queryParam={'vehicleType=TRUCK'}
                                        hasCarrier={true}
                                        carrier = {this.state
                                            .data.carrier}
                                        name='vehicle'
                                        hasError={this.state.errorData.vehicle}
                                        helpMessage={this.state.errorMessage.vehicle}
                                        label='Truck *'
                                        // queryParam='search'
                                        value={this.state.data.vehicle}
                                        defaultValue={this.state.data.vehicle}
                                        notifyParent={this.updateValues}
                                        // disabled={!this.state.isNew}
                                        />
                        </Grid>

                           
                           
                    </Grid>
                    <Grid container className={classes.padding} spacing= {2} xs={12} > 
                            <Grid item xs>
                                <FDate
                                name='eventDate'
                                maxDate={true}
                                required={true}
                                defaultValue={this.state.data.eventDate}
                                hasError={this.state.errorData.eventDate}
                                helpMessage={this.state.errorMessage.eventDate}
                                label="Event Date "
                                notifyParent={this.updateValues} />
                            </Grid>
                            <Grid item xs> 
                                <FSelect
                                    name='eventValue'
                                    required
                                    value={this.state.data.eventValue}
                                    hasError={this.state.errorData.eventValue}
                                    helpMessage={this.state.errorMessage.eventValue}
                                    label='Event'
                                    data={getDriverEvents(this.props)}
                                    valueRef='prettyName'
                                    keyRef='prettyName'
                                    notifyParent={this.updateValues}
                                />
                            </Grid>
                            <Grid item xs>
                                <FSelect
                                        name='eventAlarmLevel'
                                        required
                                        value={this.state.data.eventAlarmLevel}
                                        hasError={this.state.errorData.eventAlarmLevel}
                                        helpMessage={this.state.errorMessage.eventAlarmLevel}
                                        label='Alarm Level'
                                        data={this.props.eventAlarmLevelChoices}
                                        valueRef='prettyName'
                                        keyRef='id'
                                        notifyParent={this.updateValues}
                             />
                            </Grid>
                            <Grid item xs>
                                <FSelect
                                        name='eventCorrectiveAction'
                                        required
                                        value={this.state.data.eventCorrectiveAction}
                                        hasError={this.state.errorData.eventCorrectiveAction}
                                        helpMessage={this.state.errorMessage.eventCorrectiveAction}
                                        label='Corrective Action'
                                        data={this.props.eventCorrectiveActions}
                                        valueRef='prettyName'
                                        keyRef='id'
                                        notifyParent={this.updateValues}
                             />
                            </Grid>
                            
                    </Grid>
                    <Grid  container className={classes.padding}  spacing={2} xs={12}>
                            
                            <Grid item xs>
                                <FInput
                                    name='desc' value={this.state.data.desc} multiline={true}
                                    value={this.state.data.desc}
                                    hasError={this.state.errorData.desc}
                                    helpMessage={this.state.errorMessage.desc}
                                    label="Description" notifyParent={this.updateValues} />
                            </Grid>
                    </Grid>
                    <Grid item container xs={8} >
                        {this.getUpdateButton(this.props.profile, SAFETY_AND_COMPLINACE_DISCIPLINARY_EVENTS)}
                    </Grid>   
                </Grid>
                <Grid item xs className={classes.padding4}>
                    {!this.state.isNew &&
                        <DocumentView
                            ownerId={this.props.match.params.id}
                            owner={this.state.data}
                            listUrl={API_DOCUMENTS_URL + `?&event=${this.props.match.params.id}`}
                            ownerType='event'
                            policy_id={SAFETY_AND_COMPLINACE_DISCIPLINARY_EVENTS}
                            documentChoices={this.props.document_event_choices}
                        />}
                </Grid>

            </Paper>
        );
    };
}

export default withStyles(styles)(connect(
    mapStateToProps, mapDispatchToProps)(DriverDisciplinaryEvent))
