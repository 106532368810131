import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";


import { Grid } from "@material-ui/core";
import FMessage from "../widgets/FMessage";
import FInput from "../widgets/FInput";
import { mapDispatchToProps, mapStateToProps } from "../../fredux/ActionMaker";
import { API_CARRIERS_URL, API_DRIVERS_URL, API_DRIVER_PROFILE_URL, PROFILE_USERS_AND_PERMISSIONS, TAB_DRIVER_APP } from "../../util/Const";
import { isValid } from "../../util/Validator";
import { VALIDATE_REQUIRED, VALIDATE_PHONE_NUMBER, VALIDATE_EMAIL, VALIDATE_ADDRESS, VALIDATE_PASSWORD, VALIDATE_ZERO_OR_POSITIVE, VALIDATE_CARRIER_REQUIRED} from "../../util/ValidatorConst";
import FDetailComponent from "../../util/FDetailComponent";
import FSelect from "../widgets/FSelect";
import { getIdFromURL } from "../../util/FAPI";
import FAutoComplete from "../widgets/FAutoComplete";
import FAddress from "../widgets/FAddress";
import ChangePassword from "../auth/ChangePassword";
import FDate from "../widgets/FDate";
import FPassword from "../widgets/FPassword";
import FGroupBox from "../widgets/FGroupBox";
import {Labels} from "../../localizations/Resource"
import FMemo from "../widgets/FMemo";



const styles = theme => ({
    permission_padding: {
        marginTop: theme.spacing(6)
    },
});

const PROPS = {
    postUrl: API_DRIVER_PROFILE_URL,
    getUrl: API_DRIVERS_URL,
    patchUrl: API_DRIVERS_URL,
    errorData: {
        profileType: false,
        username: false,
        password: false,
        profileFirstName: false,
        profileLastName: false,
        profilePhoneNumber: false,
        profileEmail: false,
        profileMiddleName: false,
        // dispatcherLocation: false,
        carrier: false,
        address: false
    },
    errorMessage: {
        username: 'Invalid Username',
        profileType: 'Invalid User Type',
        password: 'Invalid Password',
        profileFirstName: 'Invalid Name',
        profileLastName: 'Invalid Name',
        profilePhoneNumber: 'Invalid Phone Number',
        profileEmail: 'Invalid Email',
        profileMiddleName: 'Invalid Dispatcher ID',
        // dispatcherLocation: 'Invalid Address',
        carrier: 'Invalid Carrier',
    },
    data: {
        profileType: 'DRIVER',
        username: '',
        password: '',
        profileFirstName: '',
        profileMiddleName: '',
        profileLastName: '',
        driverType: '',
        profileDOB: new Date(),
        profilePhoneNumber: '',
        profileEmail: '',
        address: {},
        driverHiredDate: '',
        driverTotalPerviousEmployments: 0,
        driverTotalPerviousEmploymentsDrivingHours: 0,
        driverTotalPerviousEmploymentsDrivingMileages: 0,
        driverLastDroveDateBeforeCurrentEmployment: null,
        driverTotalViolations: 0,
        driverTotalAccidents: 0,

        carrier: {},
    },
    validators: {
        profileType: [VALIDATE_REQUIRED],
        username: [VALIDATE_REQUIRED],
        password: [VALIDATE_PASSWORD],
        profileFirstName: [VALIDATE_REQUIRED],
        profileMiddleName: [],
        profileLastName: [VALIDATE_REQUIRED],
        profilePhoneNumber: [VALIDATE_PHONE_NUMBER],
        profileEmail: [VALIDATE_EMAIL],
        driverType: [VALIDATE_REQUIRED],
        address: [VALIDATE_ADDRESS],
        driverTotalPerviousEmployments: [VALIDATE_ZERO_OR_POSITIVE],
        driverTotalPerviousEmploymentsDrivingHours: [VALIDATE_ZERO_OR_POSITIVE],
        driverTotalPerviousEmploymentsDrivingMileages: [VALIDATE_ZERO_OR_POSITIVE],
        driverLastDroveDateBeforeCurrentEmployment: [],
        driverTotalViolations: [VALIDATE_ZERO_OR_POSITIVE],
        driverTotalAccidents: [VALIDATE_ZERO_OR_POSITIVE],

        carrier: [VALIDATE_CARRIER_REQUIRED],

    },
    touched: {}
}

class UserDetail extends FDetailComponent {

    constructor(props) {
        super(props, API_DRIVER_PROFILE_URL, 'profile', PROPS)
    }
   

    getErrorData1 = () => {
        let errorData = {
            username: this.state.isNew && !isValid(this.state.data.username, this.state.validators.username),
            // profileType: !isValid(this.state.data.profileType, this.state.validators.profileType),
            password: this.state.isNew && !isValid(this.state.data.password, this.state.validators.password),
            profileFirstName: !isValid(this.state.data.profileFirstName, this.state.validators.profileFirstName),
            profileLastName: !isValid(this.state.data.profileLastName, this.state.validators.profileLastName),
            profilePhoneNumber: !isValid(this.state.data.profilePhoneNumber, this.state.validators.profilePhoneNumber),
            profileEmail: !isValid(this.state.data.profileEmail, this.state.validators.profileEmail),
            // dispatcherLocation: !isValid(this.state.data.dispatcherLocation, this.state.validators.dispatcherLocation)
            address: !isValid(this.state.data.address, this.state.validators.address),

        }
        return errorData
    }
    getCurrentTitle = () => {
        let title = 'Driver Account ' + this.state.data.profileFirstName + ' ' + this.state.data.profileLastName
        this.props.updateCurrentTab(TAB_DRIVER_APP)
        this.props.updateCurrentTitle && this.props.updateCurrentTitle(title)
    }

    hasCustomErrors = () => {
        return !(this.isAddressValid(true, true, true, true))
     }

    render() {
        // this.props.updateCurrentTitle && this.props.updateCurrentTitle(this.getCurrentTitle())
        this.getCurrentTitle()
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Grid container direction='column' justify='space-between' alignItems='stretch' spacing={3} >
                    <Grid item xs>
                        <FMessage error={this.props.crudServerError}
                            success={this.props.crudServerSuccess} />
                    </Grid>
                    
                    <FGroupBox groupTitle={Labels.DRIVER_GENERAL_INFO_SUMMARY} >
                    <Grid container className={classes.root} spacing= {2} xs={12} >
                           <Grid item xs hidden={this.state.isNew}>
                           {/* <Grid item xs> */}
                                        <FInput name='driverAccessID' required
                                            value={this.state.data.driverAccessID}
                                            hasError={this.state.errorData.driverAccessID}
                                            helpMessage={this.state.errorMessage.driverAccessID}
                                            label={Labels.DRIVER_ID} 
                                            disabled={true}
                                            notifyParent={this.updateValues}
                                        />
                            </Grid>
                            <Grid item xs>
                                <FAutoComplete
                                            url={API_CARRIERS_URL}
                                            name='carrier'
                                            hasError={this.state.errorData.carrier}
                                            helpMessage={this.state.errorMessage.carrier}
                                            label={Labels.CARRIER} 
                                            // queryParam={'prettyName=true'}
                                            // queryParam='search'
                                            value={this.state.data.carrier}
                                            defaultValue={this.state.data.carrier}
                                            notifyParent={this.updateValues}
                                            disabled={!this.state.isNew}
                                            />
                            </Grid>
                            <Grid item xs>
                                    <FSelect
                                        name='driverType'
                                        value={this.state.data.driverType}
                                        hasError={this.state.errorData.driverType}
                                        helpMessage={this.state.errorMessage.driverType}
                                        label={Labels.DRIVER_TYPE} 
                                        data={this.props.driver_driverType_choices}
                                        valueRef='prettyName'
                                        keyRef='prettyName'
                                        notifyParent={this.updateValues}
                                    />
                                </Grid>
                            <Grid item xs>
                                    <FDate
                                    name='driverHiredDate'
                                    required={true}
                                    defaultValue={this.state.data.driverHiredDate}
                                    hasError={this.state.errorData.driverHiredDate}
                                    helpMessage={this.state.errorMessage.driverHiredDate}
                                    label={Labels.DRIVER_HIRING_DATE} 
                                    notifyParent={this.updateValues} 
                                    />
                                </Grid>
                            
                    </Grid>
                    <Grid container className={classes.root} spacing= {2} xs={12} >
                            <Grid item xs>
                                <FInput name='profileFirstName' required
                                    value={this.state.data.profileFirstName}
                                    hasError={this.state.errorData.profileFirstName}
                                    helpMessage={this.state.errorMessage.profileFirstName}
                                    label={Labels.FIRST_NAME} 
                                     notifyParent={this.updateValues}
                                />
                            </Grid>  
                            <Grid item xs>
                                <FInput name='profileMiddleName' required
                                    value={this.state.data.profileMiddleName}
                                    hasError={this.state.errorData.profileMiddleName}
                                    helpMessage={this.state.errorMessage.profileMiddleName}
                                    label={Labels.MIDDLE_NAME} 
                                    notifyParent={this.updateValues}
                                />
                            </Grid>
                          
                            <Grid item xs>
                                <FInput name='profileLastName' required
                                    value={this.state.data.profileLastName}
                                    hasError={this.state.errorData.profileLastName}
                                    helpMessage={this.state.errorMessage.profileLastName}
                                    label={Labels.LAST_NAME} 
                                    notifyParent={this.updateValues}
                                />
                            </Grid>
                            <Grid item xs>
                                <FInput name='profilePhoneNumber' required
                                    value={this.state.data.profilePhoneNumber}
                                    hasError={this.state.errorData.profilePhoneNumber}
                                    helpMessage={this.state.errorMessage.profilePhoneNumber}
                                    label={Labels.PHONE_NUMBER} 
                                    notifyParent={this.updateValues}
                                />
                            </Grid>
                            <Grid item xs>
                                <FInput name='profileEmail' required
                                    value={this.state.data.profileEmail}
                                    hasError={this.state.errorData.profileEmail}
                                    helpMessage={this.state.errorMessage.profileEmail}
                                    label={Labels.EMAIL} 
                                     notifyParent={this.updateValues}
                                />
                            </Grid>
                    </Grid>

                
                    <Grid container className={classes.root} spacing= {2} xs={12} >
                        <Grid item xs={2}>
                                    <FDate
                                    name='profileDOB'
                                    required={true}
                                    defaultValue={this.state.data.profileDOB}
                                    hasError={this.state.errorData.profileDOB}
                                    helpMessage={this.state.errorMessage.profileDOB}
                                    label={Labels.DATE_OF_BIRTH} 
                                    notifyParent={this.updateValues} 
                                    />
                                </Grid>
                       
                        <Grid item xs={8}>
                            <FAddress 
                                        name='address' 
                                        notifyParent={this.updateValues}
                                        address={this.state.data.address}
                                        hasError={this.state.errorData.address}
                                        place={true}
                                        city={true}
                                        state={true}
                                        zipCode={true}
                            />
                            </Grid>
                    </Grid>
                    </FGroupBox>
                    {this.state.isNew && 
                        
                        <FGroupBox groupTitle={Labels.DRIVER_APP_INFO_SUMMARY} >
                       
                           
                                    <Grid container className={classes.root} spacing= {2} xs={6} >

                                    
                                        <Grid item xs>
                                                <FInput name='username' required
                                                    value={this.state.data.username}
                                                    hasError={this.state.errorData.username}
                                                    helpMessage={this.state.errorMessage.username}
                                                    // label="User Name"
                                                    label={Labels.DRIVER_APP_USERNAME}
                                                    notifyParent={this.updateValues}
                                                    //  autoComplete="off"
                                                />
                                        </Grid>  
                                        <Grid item xs>
                                            <FPassword name='password' required
                                                    value={this.state.data.password}
                                                    type='password'
                                                    hasError={this.state.errorData.password}
                                                    helpMessage={this.state.errorMessage.password}
                                                    label={Labels.DRIVER_APP_PASSWORD}
                                                    notifyParent={this.updateValues}
                                                    // autoComplete="off"
                                                    autoComplete='new-password'
                                            />
                                        </Grid>
                                </Grid>
                    </FGroupBox> }   

                    <FGroupBox groupTitle={Labels.DRIVER_PREVIOUS_EMPLOYMENT_SUMMARY} >
                    <Grid container className={classes.root} spacing= {4} xs={12} >
                             
                               <Grid item xs>
                                    <FInput
                                        name='driverTotalPerviousEmployments'
                                        required={true}
                                        type='number'
                                        value={this.state.data.driverTotalPerviousEmployments}
                                        hasError={this.state.errorData.driverTotalPerviousEmployments}
                                        helpMessage={this.state.errorMessage.driverTotalPerviousEmployments}
                                        // label="Total Employers"
                                        label={Labels.TOTAL_NUM_PREVIOUS_EMPLOYERS} 
                                        notifyParent={this.updateValues} />
                                </Grid>
                                <Grid item xs>
                                    <FInput
                                        name='driverTotalPerviousEmploymentsDrivingHours'
                                        required={true}
                                        type='number'
                                        value={this.state.data.driverTotalPerviousEmploymentsDrivingHours}
                                        hasError={this.state.errorData.driverTotalPerviousEmploymentsDrivingHours}
                                        helpMessage={this.state.errorMessage.driverTotalPerviousEmploymentsDrivingHours}
                                        // label="Total Working Hours "
                                        label={Labels.TOTAL_WORKING_HOURS_PREVIOUS_EMPLOYERS} 
                                        notifyParent={this.updateValues} />
                                </Grid>
                                <Grid item xs>
                                    <FInput
                                        name='driverTotalPerviousEmploymentsDrivingMileages'
                                        required={true}
                                        type='number'
                                        value={this.state.data.driverTotalPerviousEmploymentsDrivingMileages}
                                        hasError={this.state.errorData.driverTotalPerviousEmploymentsDrivingMileages}
                                        helpMessage={this.state.errorMessage.driverTotalPerviousEmploymentsDrivingMileages}
                                        // label="Total Mileage "
                                        label={Labels.TOTAL_WORKING_MILEAGES_PREVIOUS_EMPLOYERS} 
                                        notifyParent={this.updateValues} />
                                </Grid>
                                
                                <Grid item xs>
                                    <FDate
                                    name='driverLastDroveDateBeforeCurrentEmployment'
                                    maxDate={true}
                                    required={true}
                                    defaultValue={this.state.data.driverLastDroveDateBeforeCurrentEmployment}
                                    hasError={this.state.errorData.driverLastDroveDateBeforeCurrentEmployment}
                                    helpMessage={this.state.errorMessage.driverLastDroveDateBeforeCurrentEmployment}
                                    // label="Last Employment Date"
                                    label={Labels.LAST_EMPLOYMENT_DATE_PREVIOUS_EMPLOYER} 
                                    notifyParent={this.updateValues} 
                                    />
                                </Grid>
                    </Grid>
                    <Grid container className={classes.root} spacing= {4} xs={12} >
                               <Grid item xs>
                                    <FInput
                                        name='driverTotalViolations'
                                        type='number'
                                        value={this.state.data.driverTotalViolations}
                                        hasError={this.state.errorData.driverTotalViolations}
                                        helpMessage={this.state.errorMessage.driverTotalViolations}
                                        // label="Total Violations"
                                        label={Labels.TOTAL_VIOLATIONS_PREVIOUS_EMPLOYERS} 
                                        notifyParent={this.updateValues} />
                                </Grid>
                                <Grid item xs>
                                    <FInput
                                        name='driverTotalAccidents'
                                        type='number'
                                        value={this.state.data.driverTotalAccidents}
                                        hasError={this.state.errorData.driverTotalAccidents}
                                        helpMessage={this.state.errorMessage.driverTotalAccidents}
                                        // label="Total Accidents"
                                        label={Labels.TOTAL_ACCIDENTS_PREVIOUS_EMPLOYERS} 
                                        notifyParent={this.updateValues} />
                                </Grid>
                    </Grid>
                    
                        </FGroupBox>
                       
                         
                    <FMemo
                            name='desc' value={this.state.data.desc} multiline={true}
                            value={this.state.data.desc}
                            hasError={this.state.errorData.desc}
                            helpMessage={this.state.errorMessage.desc}
                            // label="Description" 
                            rows={3}
                            // label={Labels.DESCRIPTION_MEMO} 
                            notifyParent={this.updateValues} 
                    />
{/* 
                      <FGroupBox groupTitle="Notes & Memos">
                                                <Grid container className={classes.root} spacing= {4} xs={12} >
                                                        <Grid item xs>
                                                            <FInput
                                                                name='desc' value={this.state.data.desc} multiline={true}
                                                                value={this.state.data.desc}
                                                                hasError={this.state.errorData.desc}
                                                                helpMessage={this.state.errorMessage.desc}
                                                                // label="Description" 
                                                                rows={3}
                                                                // label={Labels.DESCRIPTION_MEMO} 
                                                                notifyParent={this.updateValues} />
                                                        </Grid>
                            </Grid>
                        </FGroupBox>                     */}
                                                 <Grid item container xs={8} >
                            {this.getUpdateButton(this.props.profile, PROFILE_USERS_AND_PERMISSIONS)}
                        </Grid>
                        {/* <Grid container className={classes.root} spacing= {4} xs={12} >
                            <Grid item container xs={8} >
                                <FButton color='primary'
                                    label={this.state.isNew ? 'Save' : 'Update'}
                                    loading={this.state.loading}
                                    onClick={this.onSaveData}
                                />
                                <Grid item style={{ paddingTop: '9px' }}>
                                    <FRouteBackLink variant='outlined' color='secondary' className={'backButton'} />
                                </Grid>
                            </Grid>
                        </Grid> */}
                        <Grid container >
                        {!this.state.isNew && 
                            <Grid item xs={12}>
                                 <ChangePassword profileId={getIdFromURL(this.props)} />
                             </Grid>
                        }
                        </Grid>
                </Grid >
                

                <Grid container direction='column'  spacing={2} className={this.props.classes.permission_padding} >
                    <Grid item xs>
                        {/* {!this.state.isNew && */}
                        {/* <ProfilePermission
                            personnel={this.state.data}                            
                        /> */}
                        {/* } */}
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    };
}

UserDetail.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(connect(
    mapStateToProps, mapDispatchToProps)(UserDetail))
