import React from 'react';
import { API_DRIVER_PROFILE_URL, CHOICE_DRIVER, ROUTE_DRIVER_APP_DRIVER_PROFILE_URL, TAB_DRIVER_APP, ADMIN_DRIVERS, ROUTE_DRIVER_URL, ROUTE_DRIVER_APP_DRIVER_PROFILE_ADD_URL, DRIVER_APP_DRIVER_PROFILES, API_DRIVERS_URL } from '../../util/Const';
import FListComponent from '../../util/FListComponent';
import { connect } from "react-redux"
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import FMaterialTable from '../widgets/FMaterialTable';
import FTableAction, {ACTION_TABLE_DELETE_ROW, ACTION_TABLE_ACTIVATE, ACTION_TABLE_DEACTIVATE } from '../widgets/FTableAction';
import { getDriverProfileStateIcon, getDriverType } from '../../util/icon_util';
class DriverProfiles extends FListComponent {

  constructor(props) {
    super(props, API_DRIVER_PROFILE_URL, CHOICE_DRIVER)

    this.currentTitle = 'Driver Accounts'
    this.currentTab = TAB_DRIVER_APP
    this.routeUrl = ROUTE_DRIVER_APP_DRIVER_PROFILE_ADD_URL
    this.policyID = DRIVER_APP_DRIVER_PROFILES
  }

  getActions = (rowData) => {
    let actions = []
    rowData.state == 'ACTIVE'? actions.push(ACTION_TABLE_DEACTIVATE): 
          actions.push(ACTION_TABLE_ACTIVATE)
    actions.push(ACTION_TABLE_DELETE_ROW)
    return actions
  }

  

  render() {
    return (
      <div>
        <FMaterialTable
          tableRef={this.tableRef}
          columns={[
            // {
            //   title: 'Profile ID', 
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FRouterLink label={rowData.profileId} to={ROUTE_DRIVER_APP_DRIVER_PROFILE_URL + rowData.id} />
            //       </React.Fragment>
            //     )
            //   }
            // },
            {
              title: 'Driver', 
              render: this.getProfileDriverLinkWithEditLink
            },
            {
              title: 'Carrier', 
              render: this.getProfileDriverCarrierLink
            },
            { title: 'Driver Type', field: 'driver.driverType', render: rowData => {
              return (
               <React.Fragment>
                   {getDriverType(rowData.driver)}
               </React.Fragment>
             )
             } },
            { title: 'Username', field: 'username' },
            // { title: 'User Type', field: 'profile_summary.profileType' },
            { title: 'Phone', field: 'driver.driverPhoneNumber' },
            { title: 'Email', field: 'driver.driverEmail'},
            
            // { title: 'Employment Application ', width: 135,
            // render: rowData => {
            //     return (
            //           <FAttachment url={rowData.driver_profile_emp_application && rowData.driver_profile_emp_application.applicationFile}
            //               label= {getEmploymentApplicationIcon(rowData.driver_profile_emp_application)}/>
            //         )
            //     }
            // },
            { title: 'Driver ID', field: 'driver.driverAccessID' },
            {
              title: 'State', 
              render: rowData => {
                return (
                  <React.Fragment>
                      {getDriverProfileStateIcon(rowData)}
                  </React.Fragment>
                )
              }
            },
            // {
            //   title: 'App Subscription Status', 
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //           {driverProfileSubscriptionStatus(rowData)}
            //       </React.Fragment>
            //     )
            //   }
            // },
            // { title: 'Next Payment', render: rowData => getPrettyDate(rowData.subscription && rowData.subscription.nextPaymentDate) },

            {
              title: 'Actions', 
              render: rowData => {
                return (
                  <React.Fragment>
                    <FTableAction 
                        actions={this.getActions(rowData)}
                        onTableAction = {this.onTableAction}
                        rowData={rowData}
                         />
                  </React.Fragment>
                )
              }
            },
            
          ]}
          actions={
            [
              this.getAddNewModelActions()
            ]}
          // actions1={
          //   [
          //     {
          //       icon: () => (
          //         <FRouterAddLink to={ROUTE_DRIVER_APP_DRIVER_PROFILE_ADD_URL + 'add'} color='secondary' />
          //       ),
          //       isFreeAction: true
          //     }
          //   ]}
          options = {{
              pageSize: this.state.pageSize,
              pageSizeOptions: this.state.pageSizeOptions,
              actionsColumnIndex: -1
           }}
          data={this.fetchData}
          title="Accounts"
        />

      </div>
    );
  }
}

export default connect(
  mapStateToProps, mapDispatchToProps)(DriverProfiles)
