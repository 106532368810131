import React from 'react'
import DocumentReportHeader from './DocumentFilter';
import { TAB_REPORT, API_REPORT_DOCUMENT } from '../../../util/Const';
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";
import { mapDispatchToProps, mapStateToProps } from "../../../fredux/ActionMaker";
import Documents from './Documents';
import BaseReport from '../BaseReport';
import { getDate } from '../../../util';


const styles = theme => ({
  reimbursablePercentage : {
      marginRight: theme.spacing(2),

  },
});

class DocumentReport extends BaseReport {
  // tableRef = React.createRef();

  constructor(props) {
    let state = {
      listUrl: API_REPORT_DOCUMENT, 
      downloadUrl: API_REPORT_DOCUMENT + 'csv/?',
      reportFileName: 'Documents_report',
      data: {
        reportStartDate: getDate(new Date(), 120),
        reportEndDate: getDate(new Date()),
        driver: {},
        carrier: {},
        vehicle: {},
        state: '',
        documentType: '',
        documentFor: ''
      },
      errorData: {},
      errorMessage: {},
      validators: {}
    }
    super(props, state)
  }

  

  updateFilter = () => {
    let url =  `documentIssuedDate__gte=${this.state.data.reportStartDate}`
    url += `&documentIssuedDate__lte=${this.state.data.reportEndDate}`
    if (!!(this.state.data.carrier && this.state.data.carrier.id)) url += `&carrier=${this.state.data.carrier.id}`
    if (!!(this.state.data.carrier && this.state.data.driver && this.state.data.driver.id)) url += `&driver=${this.state.data.driver.id}`
    if (!!(this.state.data.carrier &&  this.state.data.vehicle && this.state.data.vehicle.id)) url += `&vehicle=${this.state.data.vehicle.id}`
    if (!!(this.state.data.documentType)) url += `&documentType=${this.state.data.documentType.choiceValue}`
    if (!!(this.state.data.state)) url += `&state=${this.state.data.state}`
    
    this.refreshFilter(url)

  }


  render() {
    this.getCurrentTitle( 'Report Documents', TAB_REPORT )
    const { classes } = this.props;

    return (
      <div >

            <DocumentReportHeader 
                data={this.state.data} 
                errorMessage={this.state.errorMessage}
                errorData={this.state.errorData}
                validators={this.state.validators}
                updateValues={this.updateValues}
                onDownload={this.onDownload}
            />

            <Documents 
                pstate={this.state} 
                tableRef={this.tableRef}
                fetchData={this.fetchData} />
      </div>
    )
  }
}


export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps)(DocumentReport))
