import React from "react";
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";


import { Grid, Paper } from "@material-ui/core";
import FSelect from "../widgets/FSelect";
import FMessage from "../widgets/FMessage";
import FInput from "../widgets/FInput";
import { mapDispatchToProps, mapStateToProps } from "../../fredux/ActionMaker";
import { API_DOCUMENTS_URL, CHOICE_VEHICLE_INSPECTION, API_VEHICLE_INSPECTION, API_VEHICLES_URL, API_DRIVERS_URL, API_CARRIERS_URL, TAB_SAFETY_AND_COMPLIANCE, SAFETY_AND_COMPLINACE_VEHICLE_INSPECTIONS } from "../../util/Const";
import DocumentView from "../document"
import {VALIDATE_CARRIER_REQUIRED, VALIDATE_OBJECT_REQUIRED,  VALIDATE_REQUIRED } from "../../util/ValidatorConst";
import FDetailComponent from "../../util/FDetailComponent";

import FDate from "../widgets/FDate";
import { isValid } from "../../util/Validator";
import FAutoComplete from "../widgets/FAutoComplete";

const styles = theme => ({
    root: {
        display: "flex",
        paddingBottom: theme.spacing(4)
    },
    padding: {
        marginTop: theme.spacing(4),
    },

    header: {
        background: '#f5f5f5'
    },
    button: {
        marginRight: theme.spacing(2),
    },
    root1: {
        backgroundColor: '#fff',
        padding: theme.spacing(2),
    },
});

const PROPS = {
    errorData: {
    },
    errorMessage: {
        vehicleInspectionDate: 'Invalid Date',
        vehicleInspectionLocation: 'Invalid Location',
        vehicleInspectionType: 'Invalid Type',
        vehicleDOTInspectionClass: 'Invalid Data',
        vehicleInspectionResult: 'Invalid Data',
        inspectionIncludesTrailer: 'Invalid Data',
        vehicle: 'Select Truck or Trailer and Try Again',
        trailer: 'Select Trailer or Truck and Try Again',
        driver: 'No Driver Selected/Found  Under Carrier',
        carrier: 'Invalid Carrier'
    },
    touched: {
    },
    data: {
        vehicleInspectionDate: '',
        vehicleInspectionLocation: '',
        vehicleInspectionType: '',
        vehicleInspectionResult:'',
        vehicleDOTInspectionClass: '',
        inspectionIncludesTrailer: '',
        vehicle: {},
        trailer: {},
        driver: {},
        carrier: {}
    },
    validators: {
        vehicleInspectionDate: [VALIDATE_REQUIRED],
        vehicleInspectionLocation: [VALIDATE_REQUIRED],
        vehicleInspectionType: [VALIDATE_REQUIRED],
        vehicleDOTInspectionClass: [VALIDATE_REQUIRED],
        vehicleInspectionResult:[VALIDATE_REQUIRED],
        vehicleInspectionResult:[VALIDATE_REQUIRED],
        // inspectionIncludesTrailer: [VALIDATE_REQUIRED],
        vehicle: [VALIDATE_OBJECT_REQUIRED],
        trailer: [VALIDATE_OBJECT_REQUIRED],
        driver: [],
        carrier: [VALIDATE_CARRIER_REQUIRED],
    }
}



class VehicleInspectionDetail extends FDetailComponent {
    constructor(props) {
        super(props, API_VEHICLE_INSPECTION, CHOICE_VEHICLE_INSPECTION, PROPS)
    }
  
    getErrorData = () => {
        let errorData = {
            vehicleInspectionDate: !isValid(this.state.data.vehicleInspectionDate, this.state.validators.vehicleInspectionDate),
            vehicleInspectionLocation: !isValid(this.state.data.vehicleInspectionLocation, this.state.validators.vehicleInspectionLocation),
            vehicleInspectionType: !isValid(this.state.data.vehicleInspectionType, this.state.validators.vehicleInspectionType),
            vehicleDOTInspectionClass: this.isDotInspection() && !isValid(this.state.data.vehicleDOTInspectionClass, this.state.validators.vehicleDOTInspectionClass),
            vehicleInspectionResult: !isValid(this.state.data.vehicleInspectionResult, this.state.validators.vehicleInspectionResult),            
            // inspectionIncludesTrailer: !isValid(this.state.data.inspectionIncludesTrailer, this.state.validators.inspectionIncludesTrailer),            

            
            vehicle: (!isValid(this.state.data.vehicle, this.state.validators.vehicle) &&
                 !isValid(this.state.data.trailer, this.state.validators.trailer)),
            trailer: (!isValid(this.state.data.vehicle, this.state.validators.vehicle) &&
                 !isValid(this.state.data.trailer, this.state.validators.trailer)),
            driver: !isValid(this.state.data.driver, this.state.validators.driver),
            carrier: !isValid(this.state.data.carrier, this.state.validators.carrier)
        }
        return errorData
    }
    
    onCarrierChange = () => {
        this.updateValues('driver', {driver: ''})
        // this.updateValues('vehicle', {vehicle: ''})
    }

    isTrailer = () => {
        let isTrail = this.state.data.vehicle && this.state.data.vehicle.vehicleType == 'TRAILER'
        return !!isTrail
    }
    isDotInspection = () => {
        return this.state.data.vehicleInspectionType == 'DOT_INSPECTION'
    }
    getCurrentTitle = () => {
        let pName = (this.state.data && this.state.data.driver && this.state.data.driver.prettyName + ' -  ' + this.state.data.vehicleInspectionType)  || ''

        let title = 'Vehicle Inspection ' + pName
        this.props.updateCurrentTab(TAB_SAFETY_AND_COMPLIANCE)
        this.props.updateCurrentTitle && this.props.updateCurrentTitle(title)
        return title
    }

    render() {
        // let pName = (this.state.data.driver.prettyName && this.state.data.driver.prettyName + ' -  ' + this.state.data.vehicleInspectionType)  || ''
        const { classes } = this.props;
        this.getCurrentTitle()
        return (
            <Paper className={classes.root1}>
                {/* <FTitle label={title}/> */}
                <Grid container direction='column' alignItems='stretch' spacing={2} className={classes.root1}>
                    <Grid item xs>
                        <FMessage error={this.props.crudServerError}
                            success={this.props.crudServerSuccess} />
                    </Grid>
                    <Grid container className={classes.root} spacing= {2} xs={12} >
                            <Grid item xs>
                                    <FAutoComplete
                                        url={API_CARRIERS_URL}
                                        required
                                        name='carrier'
                                        hasError={this.state.errorData.carrier}
                                        helpMessage={this.state.errorMessage.carrier}
                                        label='Authority'
                                        // queryParam='search'
                                        value={this.state.data.carrier}
                                        defaultValue={this.state.data.carrier}
                                        notifyParent={this.updateValues}
                                        disabled={!this.state.isNew}
                                        notifyDependency={this.onCarrierChange}

                                       />
                                  
                                </Grid>
                                <Grid item xs>
                                        <FAutoComplete
                                                url={API_VEHICLES_URL}
                                                queryParam={'vehicleType=TRUCK'}
                                                hasCarrier={true}
                                                carrier = {this.state.data.carrier}
                                                name='vehicle'
                                                hasError={this.state.errorData.vehicle}
                                                helpMessage={this.state.errorMessage.vehicle}
                                                label='Truck'
                                                // queryParam='search'
                                                value={this.state.data.vehicle}
                                                defaultValue={this.state.data.vehicle}
                                                notifyParent={this.updateValues}
                                                // disabled={!this.state.isNew}
                                                />
                                 </Grid>
                                 <Grid item xs>
                                        <FAutoComplete
                                                url={API_VEHICLES_URL}
                                                queryParam={'vehicleType=TRAILER'}
                                                hasCarrier={true}
                                                carrier = {this.state.data.carrier}
                                                name='trailer'
                                                hasError={this.state.errorData.trailer}
                                                helpMessage={this.state.errorMessage.trailer}
                                                label='Trailer'
                                                // queryParam='search'
                                                value={this.state.data.trailer}
                                                defaultValue={this.state.data.trailer}
                                                notifyParent={this.updateValues}
                                                // disabled={!this.state.isNew}
                                                />
                                 </Grid>
                               
                            <Grid item xs>
                                    <FAutoComplete
                                        url={API_DRIVERS_URL}
                                        // queryParam={`carrier=${this.state.data.carrier && this.state.data.carrier.id}`}
                                        hasCarrier={true}
                                        carrier={this.state.data.carrier}
                                        name='driver'
                                        hasError={this.state.errorData.driver}
                                        helpMessage={this.state.errorMessage.driver}
                                        label='Driver'
                                        // queryParam='search'
                                        value={this.state.data.driver}
                                        defaultValue={this.state.data.driver}
                                        notifyParent={this.updateValues}
                                        // disabled={!this.state.isNew}
                                        />
                            </Grid>
                            
                            {/* <Grid item xs>
                                <FSelect
                                    name='vehicleInspectionResult'
                                    value={this.state.data.vehicleInspectionResult}
                                    hasError={this.state.errorData.vehicleInspectionResult}
                                    helpMessage={this.state.errorMessage.vehicleInspectionResult}
                                    label='Inspection Result'
                                    data={this.props.vehicleInspectionResultChoices}
                                    valueRef='prettyName'
                                    keyRef='prettyName'
                                    notifyParent={this.updateValues}
                                />
                            </Grid> */}
                            
                           
                         
                    </Grid>
                    <Grid container className={classes.root} spacing= {6} xs={12} >

                               <Grid item xs>
                                    <FDate
                                        name='vehicleInspectionDate'
                                        maxDate={true}
                                        required
                                        required={true}
                                        defaultValue={this.state.data.vehicleInspectionDate}
                                        hasError={this.state.errorData.vehicleInspectionDate}
                                        helpMessage={this.state.errorMessage.vehicleInspectionDate}
                                        label="Vehicle Inspection Date "
                                        notifyParent={this.updateValues} />
                               
                                </Grid>
                               
                            <Grid item xs>
                                <FSelect
                                    name='vehicleInspectionType'
                                    required
                                    value={this.state.data.vehicleInspectionType}
                                    hasError={this.state.errorData.vehicleInspectionType}
                                    helpMessage={this.state.errorMessage.vehicleInspectionType}
                                    label='Vehicle Inspection Type'
                                    data={this.props.vehicleInspectionTypeChoices}
                                    valueRef='prettyName'
                                    keyRef='prettyName'
                                    notifyParent={this.updateValues}
                                />
                            </Grid>
                            <Grid item xs hidden={!this.isDotInspection()}>
                                <FSelect
                                    name='vehicleDOTInspectionClass'
                                    required
                                    value={this.state.data.vehicleDOTInspectionClass}
                                    hasError={this.state.errorData.vehicleDOTInspectionClass}
                                    helpMessage={this.state.errorMessage.vehicleDOTInspectionClass}
                                    label='DOT Inspection Class'
                                    data={this.props.vehicle_inspection_inspection_class_choices}
                                    valueRef='prettyName'
                                    keyRef='prettyName'
                                    notifyParent={this.updateValues}
                                />
                            </Grid>
                            <Grid item xs>
                                <FSelect
                                    name='vehicleInspectionResult'
                                    required
                                    value={this.state.data.vehicleInspectionResult}
                                    hasError={this.state.errorData.vehicleInspectionResult}
                                    helpMessage={this.state.errorMessage.vehicleInspectionResult}
                                    label='Inspection Result'
                                    data={this.props.vehicleInspectionResultChoices}
                                    valueRef='prettyName'
                                    keyRef='prettyName'
                                    notifyParent={this.updateValues}
                                />
                            </Grid>
                            {/* {!this.isTrailer() && <Grid item xs={3} hidden={this.isTrailer()}>
                                    <FCheckBox
                                        name='inspectionIncludesTrailer'
                                        value={this.state.data.inspectionIncludesTrailer}
                                        value={this.state.data.inspectionIncludesTrailer}
                                        hasError={this.state.errorData.inspectionIncludesTrailer}
                                        helpMessage={this.state.errorMessage.inspectionIncludesTrailer}
                                        label="Trailer Included"
                                        notifyParent={this.updateValues} />
                            </Grid>} */}
                           
                    </Grid> 
                    <Grid container className={classes.root} spacing= {2} xs={12} >
                    <Grid item xs={3}>
                               <FInput name='vehicleInspectionLocation' required
                                    value={this.state.data.vehicleInspectionLocation}
                                    hasError={this.state.errorData.vehicleInspectionLocation}
                                    helpMessage={this.state.errorMessage.vehicleInspectionLocation}
                                    label="Location" notifyParent={this.updateValues}
                                />
                            </Grid>
                            <Grid item xs>
                                 <FInput
                                    name='desc' value={this.state.data.desc} multiline={true}
                                    value={this.state.data.desc}
                                    hasError={this.state.errorData.desc}
                                    helpMessage={this.state.errorMessage.desc}
                                    label="Description" notifyParent={this.updateValues} />
                            </Grid>
                    </Grid>     
                    <Grid item container xs={8} >
                        {this.getUpdateButton(this.props.profile, SAFETY_AND_COMPLINACE_VEHICLE_INSPECTIONS)}
                    </Grid>
                    {/* <Grid item container xs={8} >
                        <FButton color='primary'
                            label={this.state.isNew ? 'Save' : 'Update'}
                            loading={this.state.loading}
                            onClick={this.onSaveData}
                        />
                        <Grid item style={{ paddingTop: '9px' }}>
                            <FRouteBackLink variant='outlined' color='secondary' className={'backButton'} />
                        </Grid>
                    </Grid> */}
                </Grid >
                <Grid item xs className={classes.padding}>
                        {!this.state.isNew &&
                            <DocumentView
                                ownerId={this.props.match.params.id}
                                owner={this.state.data}
                                listUrl={API_DOCUMENTS_URL + `?&vehicle_inspection=${this.props.match.params.id}`}
                                ownerType='vehicle_inspection'
                                policy_id={SAFETY_AND_COMPLINACE_VEHICLE_INSPECTIONS}
                                documentChoices={this.props.document_vehicle_inspection_choices}
                            />}
                    </Grid>
             </Paper>
        );
    };
}


export default withStyles(styles)(connect(
    mapStateToProps, mapDispatchToProps)(VehicleInspectionDetail))
