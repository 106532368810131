import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import FRouterLink, { FRouterAddLink } from '../widgets/FRouterLink';
import { API_DRIVER_EMPLOYMENT_APPLICATION_URL,
     CHOICE_DRIVER_EMPLOYMENT_APPLICATION, TAB_DRIVER_APP,
     DRIVER_APP_EMPLOYMENT_APPLICATION, ROUTE_DRIVER_APP_EMPLOYMENT_APPLICATION_URL } from '../../util/Const';
import FListComponent from '../../util/FListComponent';
import { connect } from "react-redux"
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import FTableAction, { ACTION_TABLE_DELETE_ROW } from '../widgets/FTableAction';
import FMaterialTable from '../widgets/FMaterialTable';
import BlockIcon from '@material-ui/icons/Block';
import { getEmploymentApplicationIcon, getStateIcon } from '../../util/icon_util';
import { Link } from '@material-ui/core';
import FAttachment from '../widgets/FAttachment';


const styles = theme => ({
  smallAvatar: {
    width: 10,
    height: 10,
    border: `2px solid ${theme.palette.background.paper}`,

  },
});

class DriverEmploymentApplicatoins extends FListComponent {
  constructor(props) {
    super(props, API_DRIVER_EMPLOYMENT_APPLICATION_URL, CHOICE_DRIVER_EMPLOYMENT_APPLICATION)
    this.currentTitle = 'Employment Applications'
    this.currentTab = TAB_DRIVER_APP
    this.routeUrl = ROUTE_DRIVER_APP_EMPLOYMENT_APPLICATION_URL 
    this.policyID = DRIVER_APP_EMPLOYMENT_APPLICATION
    this.includeStateAction = false
    this.includeDeleteAction = false

  }
  
  // getActions = (rowData) => {
  //   let actions = [ACTION_TABLE_DELETE_ROW]
  //   // rowData.state == 'ACTIVE'? actions.push(ACTION_TABLE_DEACTIVATE): actions.push(ACTION_TABLE_ACTIVATE)
  //   return actions
  // }
  
  render() {
    const { classes } = this.props;
    return (
      <div>
   
        <FMaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: 'Driver / Prospect Driver', 
              render: this.getProfileDriverLink
            },
            {
              title: 'Carrier', 
              render: this.getCarrierLink
            },
            { title: 'Employment Application ', width: 135,
            render: rowData => {
                return (
                      <FAttachment url={rowData.applicationFile}
                          label= {getEmploymentApplicationIcon(rowData)}/>
                    )
                }
            },            
            // {
            //   title: 'ApplicationState', 
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //           {getStateIcon(rowData)}
            //       </React.Fragment>
            //     )
            //   }
            // },
            
            {
              title: 'Actions', 
              render: rowData => {
                let actions  = this.getActions(rowData, DRIVER_APP_EMPLOYMENT_APPLICATION)

                return (
                  <React.Fragment>
                    {!!actions.length && <FTableAction 
                        actions={actions}
                        onTableAction = {this.onTableAction}
                        rowData={rowData}
                         />
                    }
                    {!!!actions.length && <BlockIcon color="disabled" />}
                  </React.Fragment>
                )
              }
            },
          ]}
          // actions={
          //   [
          //     this.getAddNewModelActions()
          //   ]
          //  }
            options = {{
              pageSize: this.state.pageSize,
              pageSizeOptions: this.state.pageSizeOptions,
              actionsColumnIndex: -1
           }}
          data={this.fetchData}
          title="Driver Employment Application"
          listErrCode={this.state.listErrCode}
        />
      </div>
    );
  }
}


export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps)(DriverEmploymentApplicatoins))
