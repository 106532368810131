import React from 'react'
import { withStyles } from '@material-ui/core';

import logo from '../images/logo_zt_square.png';
import logo2 from '../images/zlogo.png';


const style = theme => ({ 
  logo:  {
    marginTop: 80,
    marginBottom: 20,
  }
});

/**
 * Page to display for unsupported browser size
 */
 class ZLogo extends React.Component {
  render() {
    const { classes } = this.props
    let src = !!this.props.logo2 ? logo2: logo
    return (
      <div>
         <img src={src} alt="FMCSA integrated carrier management software" className={classes.logo} 
            style={{width: this.props.width ||200,  height: this.props.height || 200, marginLeft: this.props.marginLeft || '35%',
                   marginTop: this.props.marginTop || 80, marginBottom: this.props.marginBottom|| 20}} />
      </div>
    )
  }
}

export default withStyles(style)(ZLogo)