import React from "react";
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";


import { Grid } from "@material-ui/core";
import FMessage from "../widgets/FMessage";
import FInput from "../widgets/FInput";
import { mapDispatchToProps, mapStateToProps } from "../../fredux/ActionMaker";
import { API_INVOICE_URL, API_FLEET_URL, API_DOCUMENTS_URL, INVOICE_STATE_MACHINE_SELECT, API_CARRIERS_URL, TAB_ACCOUNTING, FINANCE_INVOICE } from "../../util/Const";
import DocumentView from "../document"
import { VALIDATE_REQUIRED} from "../../util/ValidatorConst";
import FDetailComponent from "../../util/FDetailComponent";
import FSelect from "../widgets/FSelect";
import FAutoComplete from "../widgets/FAutoComplete";
import FDate from "../widgets/FDate";



const styles = theme => ({

    padding: {
        marginTop: theme.spacing(4),
    },
});

const PROPS = {
    errorData: {
        payCheckID: false,
        carrier: false,
        payCheckType: false,
        payCheckNet: false,
        payCheckFleetTotalExpense: false,
        payCheckFleetGrossPayment: false,
        // driver: false,
        // vehicle: false,
        invoiceStatus: false,
        fleet: false,
        desc: false,

    },
    errorMessage: {
        payCheckID: "Invalid Driver",
        payCheckType: "Invalid Invoice Type",
        payCheckNet: "Invalid Paycheck",
        payCheckFleetTotalExpense: "Invalid Total Expense",
        payCheckFleetGrossPayment: "Invalid Gross Fleet",
        fleet: 'Invalid Fleet',
        carrier: "Invalid Carrier",
        invoiceStatus: "Invalid Status",
        // driver: "Invalid Driver",
        // vehicle: "Invalid Vehicle",
        desc: "Invalid Desc",

    },
    data: {
        payCheckID: '',
        payCheckType: 'INVOICE',
        payCheckNet: 0.0,
        payCheckFleetTotalExpense: 0.0,
        payCheckFleetGrossPayment: 0.0,
        carrier: {},
        fleet: {},
        invoiceStatus: {},
        // driver: {},
        // vehicle: {},
        invoiceInfo: {},
        desc: ''
    },
    validators: {
        invoiceStatus: [VALIDATE_REQUIRED],
    },
    
    touched: {
        payCheckType: 'INVOICE',
    }
}

class InvoiceDetial extends FDetailComponent {

    constructor(props) {
        super(props, API_INVOICE_URL, 'invoice', PROPS)
    }

    getCurrentTitle = () => {
        let title = 'Invoice:  ' + this.state.data.payCheckID
        this.props.updateCurrentTab(TAB_ACCOUNTING)
        this.props.updateCurrentTitle && this.props.updateCurrentTitle(title)
    }
    getVehicleInfo= () => {
       return this.state.data.invoiceInfo.vehicle && this.state.data.invoiceInfo.vehicle.prettyName
    }
    getDriverInfo= () => {
        return this.state.data.invoiceInfo.driver && this.state.data.invoiceInfo.driver.prettyName
     }
    
    getInvoiceStatusChoices = () => {
        let invoiceStatus  = this.state.data.invoiceStatus
        if (!!!invoiceStatus) {
            return []
        }
        
        let choices =  INVOICE_STATE_MACHINE_SELECT[invoiceStatus]
        return choices
    }
    render() {
        // this.props.updateCurrentTitle && this.props.updateCurrentTitle(this.getCurrentTitle())
        this.getCurrentTitle()
        const { classes } = this.props;
        return (
            <React.Fragment>
                {/* <FTitle label={'Dispatcher -  ' + this.state.data.payCheckNet + ' ' + this.state.data.payCheckFleetGrossPayment} /> */}
                <Grid container direction='column' justify='space-between' alignItems='stretch' spacing={3} >
                    <Grid item xs>
                        <FMessage error={this.props.crudServerError}
                            success={this.props.crudServerSuccess} />
                    </Grid>
                    <Grid container className={classes.root} spacing={2} xs={12} >
                            <Grid item xs  hidden={this.state.isNew}>
                                <FInput name='payCheckID' required
                                    value={this.state.data.payCheckID}
                                    hasError={this.state.errorData.payCheckID}
                                    helpMessage={this.state.errorMessage.payCheckID}
                                    disabled={true}
                                    label="Invoic ID"
                                    notifyParent={this.updateValues}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs>
                                        <FAutoComplete
                                                url={API_CARRIERS_URL}
                                                name='carrier'
                                                hasError={this.state.errorData.carrier}
                                                helpMessage={this.state.errorMessage.carrier}
                                                label='Authority'
                                                // queryParam='search'
                                                value={this.state.data.carrier}
                                                defaultValue={this.state.data.carrier}
                                                notifyParent={this.updateValues}
                                                disabled={true}
                                                
                                                // notifyDependency={this.onCarrierChange}
                                                />
                            </Grid>
                            <Grid item xs >
                                            <FAutoComplete
                                                url={API_FLEET_URL}
                                                // queryParam={'status=COMPLETE'}
                                                hasCarrier={true}
                                                carrier = {this.state.data.carrier}
                                                name='fleet'
                                                hasError={this.state.errorData.fleet}
                                                helpMessage={this.state.errorMessage.fleet}
                                                label='Fleet'
                                                // queryParam='search'
                                                value={this.state.data.fleet}
                                                defaultValue={this.state.data.fleet}
                                                notifyParent={this.updateValues}
                                                // disabled={!this.state.isNew}
                                                disabled={true}
                                                />

                                    
                                        </Grid>
                            <Grid item xs >
                                <FInput name='vechile' required
                                    value={this.getVehicleInfo()}
                                    // hasError={this.state.data.invoiceInfo.vehicle}
                                    // helpMessage={this.state.data.invoiceInfo.vehicle}
                                    disabled={true}
                                    label="Vehicle"
                                    // notifyParent={this.updateValues}
                                />
                            </Grid>
                            <Grid item xs>
                                <FInput name='driver' required
                                        value={this.getDriverInfo()}
                                        // hasError={this.state.data.invoiceInfo.vehicle}
                                        // helpMessage={this.state.data.invoiceInfo.vehicle}
                                        disabled={true}
                                        label="Driver"
                                        // notifyParent={this.updateValues}
                                    />
                            </Grid>
                    </Grid>

                    <Grid container className={classes.root} spacing= {2} xs={12} >
                           <Grid item xs>
                                    <FDate
                                        name='payCheckFleetStartDate'
                                        required={true}
                                        defaultValue={this.state.data.payCheckFleetStartDate}
                                        hasError={this.state.errorData.payCheckFleetStartDate}
                                        helpMessage={this.state.errorMessage.payCheckFleetStartDate}
                                        label="Invoice Scheduled Date "
                                        notifyParent={this.updateValues} 
                                        disabled={true}
                                        />
                               
                            </Grid>
                            <Grid item xs>
                                    <FDate
                                        name='created'
                                        required={true}
                                        defaultValue={this.state.data.created}
                                        hasError={this.state.errorData.created}
                                        helpMessage={this.state.errorMessage.created}
                                        label="Invoice created "
                                        notifyParent={this.updateValues} 
                                        disabled={true}
                                        />
                               
                            </Grid>
                           
                            
                            <Grid item xs>
                                <FInput name='payCheckFleetGrossPayment' required
                                    value={this.state.data.payCheckFleetGrossPayment}
                                    hasError={this.state.errorData.payCheckFleetGrossPayment}
                                    helpMessage={this.state.errorMessage.payCheckFleetGrossPayment}
                                    label="Gross Fleet" notifyParent={this.updateValues}
                                    disabled={true}
                                />
                            </Grid>
                           
                            <Grid item xs>
                                <FInput name='payCheckFleetTotalExpense' required
                                    value={this.state.data.payCheckFleetTotalExpense}
                                    hasError={this.state.errorData.payCheckFleetTotalExpense}
                                    helpMessage={this.state.errorMessage.payCheckFleetTotalExpense}
                                    label="Expense Total" notifyParent={this.updateValues}
                                    disabled={true}
                                />
                            </Grid>  
                            <Grid item xs  >
                                <FInput name='payCheckNet' required
                                    value={this.state.data.payCheckNet}
                                    hasError={this.state.errorData.payCheckNet}
                                    helpMessage={this.state.errorMessage.payCheckNet}
                                    label="Net" notifyParent={this.updateValues}
                                    disabled={true}
                                />
                            </Grid>  
                            
                    </Grid>
                   

                    <Grid container className={classes.root} spacing= {2} xs={12} >
                    <Grid item xs={3}>
                            <FSelect
                                    name='invoiceStatus'
                                    value={this.state.data.invoiceStatus}
                                    hasError={this.state.errorData.invoiceStatus}
                                    helpMessage={this.state.errorMessage.invoiceStatus}
                                    label='Invoice Status'
                                    data={this.getInvoiceStatusChoices()}
                                    valueRef='prettyName'
                                    keyRef='id'
                                    notifyParent={this.updateValues} />
                        </Grid>
                            <Grid item xs>
                                 <FInput
                                    name='desc' value={this.state.data.desc} multiline={true}
                                    value={this.state.data.desc}
                                    hasError={this.state.errorData.desc}
                                    helpMessage={this.state.errorMessage.desc}
                                    label="Description" notifyParent={this.updateValues} />
                            </Grid>
                    </Grid>
                    <Grid item container xs={8} >
                            {this.getUpdateButton(this.props.profile, FINANCE_INVOICE)}
                    </Grid>
                    {/* <Grid container className={classes.root} spacing= {4} xs={12} >
                        <Grid item container xs={8} >
                            <FButton color='primary'
                                label={this.state.isNew ? 'Save' : 'Update'}
                                loading={this.state.loading}
                                onClick={this.onSaveData}
                            />
                            <Grid item style={{ paddingTop: '9px' }}>
                                <FRouteBackLink variant='outlined' color='secondary' className={'backButton'} />
                            </Grid>
                        </Grid>
                    </Grid> */}
                    <Grid item xs className={classes.padding}>
                        {!this.state.isNew &&
                            <DocumentView
                               
                                ownerId={this.props.match.params.id}
                                owner={this.state.data}
                                documentChoices={this.props.document_invoice_choices}
                                listUrl={API_DOCUMENTS_URL + `?&invoice=${this.props.match.params.id}`}
                                ownerType='invoice'
                                policy_id={FINANCE_INVOICE}
                            />}
                    </Grid>
                </Grid >
            </React.Fragment>
        );
    };
}


export default withStyles(styles)(connect(
    mapStateToProps, mapDispatchToProps)(InvoiceDetial))
