import React from 'react'
import DriverDisciplinaryEventReportHeader from './DriverDisciplinaryEventFilter';
import { TAB_REPORT, API_DRIVER_DISCIPLINARY_EVENT_REPORT_URL } from '../../../util/Const';
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";
import { mapDispatchToProps, mapStateToProps } from "../../../fredux/ActionMaker";
import DriverDisciplinaryEvents from './DriverDisciplinaryEvents';
import BaseReport from '../BaseReport';
import { getDate } from '../../../util';


const styles = theme => ({
  reimbursablePercentage : {
      marginRight: theme.spacing(2),

  },
});

class DriverDisciplinaryEventReport extends BaseReport {
  // tableRef = React.createRef();

  constructor(props) {
    let state = {
      reportFileName: 'DriverDisciplinaryEvents_report',
      listUrl: API_DRIVER_DISCIPLINARY_EVENT_REPORT_URL + '?eventType=DRIVER_SAFETY_VIOLATION&', 
      downloadUrl: API_DRIVER_DISCIPLINARY_EVENT_REPORT_URL + 'csv/?eventType=DRIVER_SAFETY_VIOLATION&',
      data: {
        reportStartDate: getDate(new Date(), 90),
        reportEndDate: getDate(new Date()),
        driver: {},
        carrier: {},
        vehicle: {},
        dispatcher: {},
        expenseMaintenanceCategory:''
      },
      errorData: {},
      errorMessage: {},
      validators: {}
    }
    super(props, state)
    this.currentTitle = 'Driver Disciplinary Report'
    this.currentTab = TAB_REPORT
  }

  

  updateFilter = () => {
    let url =  `eventDate__gte=${this.state.data.reportStartDate}`
    url += `&eventDate__lte=${this.state.data.reportEndDate}`
    if (!!(this.state.data.carrier && this.state.data.carrier.id)) url += `&carrier=${this.state.data.carrier.id}`
    if (!!(this.state.data.carrier && this.state.data.driver && this.state.data.driver.id)) url += `&driver=${this.state.data.driver.id}`
    if (!!(this.state.data.carrier &&  this.state.data.vehicle && this.state.data.vehicle.id)) url += `&vehicle=${this.state.data.vehicle.id}`

    const that = this
    this.setState({filter: url}, ()=>{
      that.tableRef.current && that.tableRef.current.onQueryChange();
    })
  }


  render() {
    // this.getCurrentTitle( 'Report Driver Disciplinary Record', TAB_REPORT )
    const { classes } = this.props;

    return (
      <div >

          {/* {this.state.listErrCode != 403 && */}
          
          <DriverDisciplinaryEventReportHeader 
                disabled={this.state.listErrCode == 403}
                data={this.state.data} 
                errorMessage={this.state.errorMessage}
                errorData={this.state.errorData}
                validators={this.state.validators}
                updateValues={this.updateValues}
                onDownload={this.onDownload}
            />
          {/* } */}

            <DriverDisciplinaryEvents 
                pstate={this.state} 
                tableRef={this.tableRef}
                eventAlarmLevelChoices={this.props.eventAlarmLevelChoices}
                eventCorrectiveActions={this.props.eventCorrectiveActions}
                fetchData={this.fetchData}
                listErrCode={this.state.listErrCode} />
      </div>
    )
  }
}


export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps)(DriverDisciplinaryEventReport))
