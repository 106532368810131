import React, { Component } from 'react'
import { Grid, withStyles } from '@material-ui/core';
import FSummaryDash from '../widgets/FSummaryDash';
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import { API_EXPENSE_URL, API_FLEET_URL, API_PAY_CHECK_URL, INCOME_COLORS, EXPENSE_FEE_COLORS } from '../../util/Const';

    


const styles = theme => ({
    root: {
        flexGrow: 1,
        color: theme.palette.text.secondary,
        width: '100%',
        background: '#fff',
    },
});


const getSummaryData = (data, url, latestKey) => {
    data = data || {}
    let total = 0
    if (data.total) {
       total =  Intl.NumberFormat('en-US').format(data.total)
    } 
    return {
        total: total ,
        // total:data.total,
        latest: data.latest && data.latest[latestKey],
        url: data.latest && url + data.latest.id
     }
    }
class TopFinancialDashboard extends  React.Component  {

    render() {

        const { classes, profileDashBoardPreview } = this.props
        const {fleet, expense, payCheck, invoice, carrierFee, scheduledExpense} = profileDashBoardPreview || {}
        return (

                    <Grid container xs={12} className={classes.root}>
                        <Grid item xs >
                            <FSummaryDash title="Dispatch Earnings" 
                               valueColor={INCOME_COLORS[0]}
                                data={getSummaryData(carrierFee, API_PAY_CHECK_URL, 'created')} />
                        </Grid>
                        <Grid item xs >
                            <FSummaryDash title="Fleet Fee"
                               valueColor={INCOME_COLORS[0]}
                               data={getSummaryData(fleet, API_FLEET_URL, 'fleetDeliveryDate')} />
                        </Grid>
                        <Grid item xs >
                            <FSummaryDash title="Paid Invoice" 
                               valueColor={INCOME_COLORS[0]}
                               data={getSummaryData(invoice, API_PAY_CHECK_URL, 'created')} />
                        </Grid>
                        <Grid item xs >
                            <FSummaryDash title="Expense" 
                                 valueColor={EXPENSE_FEE_COLORS[0]}
                                 data={getSummaryData(expense, API_EXPENSE_URL, 'expenseTransactionDate')} />
                        </Grid>
                        <Grid item xs >
                            <FSummaryDash title="Scheduled Expense" 
                               valueColor={EXPENSE_FEE_COLORS[0]}
                                 data={getSummaryData(scheduledExpense, API_EXPENSE_URL, 'expenseTransactionDate')} />
                        </Grid>
                        <Grid item xs >
                            <FSummaryDash title="Driver Payment" 
                                valueColor={EXPENSE_FEE_COLORS[0]}
                                data={getSummaryData(payCheck, API_PAY_CHECK_URL, 'created')} />
                        </Grid>
                        
                </Grid>
            // </div >
        );

    }
}


export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TopFinancialDashboard));
