import React from 'react';
import FRouterLink, { FRouterAddLink } from '../widgets/FRouterLink';
import { API_DRIVER_DISCIPLINARY_EVENT_API, ROUTER_DRIVER_DISCIPLINARY_EVENT_URL, TAB_SAFETY_AND_COMPLIANCE, SAFETY_AND_COMPLINACE_DISCIPLINARY_EVENTS, CHOICE_DRIVER_DISCIPLINARY_EVENT } from '../../util/Const';
import FListComponent from '../../util/FListComponent';

import { connect } from "react-redux"
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import { isProfileDispatcher } from '../../util/ACL';
import FMaterialTable from '../widgets/FMaterialTable';
import FTableAction, { ACTION_TABLE_DELETE_ROW, ACTION_TABLE_DEACTIVATE, ACTION_TABLE_ACTIVATE } from '../widgets/FTableAction';
import { getValueFromListOfDict } from '../../util';
import { getAlarmLevelStatusIcon } from '../../util/icon_util';
import BlockIcon from '@material-ui/icons/Block';


class DriverDisciplinaryEvents extends FListComponent {
  constructor(props) {
    super(props, API_DRIVER_DISCIPLINARY_EVENT_API,
      CHOICE_DRIVER_DISCIPLINARY_EVENT, 'eventType=DRIVER_SAFETY_VIOLATION&')
    this.currentTitle = 'Disciplinable Event'
    this.currentTab = TAB_SAFETY_AND_COMPLIANCE
    this.routeUrl = ROUTER_DRIVER_DISCIPLINARY_EVENT_URL
    this.policyID = SAFETY_AND_COMPLINACE_DISCIPLINARY_EVENTS
    this.includeStateAction = false
  }
  
  // getActions = (rowData) => {
  //   let actions = []
  //   // rowData.state == 'ACTIVE'? actions.push(ACTION_TABLE_DEACTIVATE): 
  //   //       actions.push(ACTION_TABLE_ACTIVATE)
  //   actions.push(ACTION_TABLE_DELETE_ROW)
  //   return actions
  // }

  render() {
    return (
      <div>

        <FMaterialTable
          tableRef={this.tableRef}

          columns={[
            {
              title: 'Event Name', 
              field: 'eventName',
              render: rowData => {
                return (
                  <React.Fragment>
                    <FRouterLink editIcon={true} label={rowData.eventName} to={ROUTER_DRIVER_DISCIPLINARY_EVENT_URL + rowData.id} />
                  </React.Fragment>
                )
              }
            },
            // {
            //   title: 'Carrier', 
            //   field: 'carrier.prettyName',
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FRouterLink label={rowData.carrier.prettyName}
            //           disabled={isProfileDispatcher(this.props.profile)}
            //           to={ROUTE_CARRIERS_URL + rowData.carrier.id} />
            //       </React.Fragment>
            //     )
            //   }
            // },
            {
              title: 'Carrier', 
              render: this.getCarrierLink
            },
            // {
            //   title: 'Driver', field: 'driver.prettyName',
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FRouterLink label={rowData.driver.prettyName} 
            //            to={ROUTE_DRIVER_URL + rowData.driver.id}
            //            disabled={isProfileDispatcher(this.props.profile)}/>
            //       </React.Fragment>
            //     )
            //   }
            // },
            {
              title: 'Driver', 
              render: this.getDriverLink
            },
            {
              title: 'Vehicle', 
              render: this.getVehicleLink
            },
            // {
            //   title: 'Vehicle', hidden: true, 
            //   field: 'fleetVehicle',
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FRouterLink label={rowData.fleetVehicle} to={ROUTE_VEHICLE_URL + rowData.fleetVehicle} />
            //       </React.Fragment>
            //     )
            //   }
            // },
            { title: 'Event', field: 'eventValue' },
            { title: 'Event Date',
                 render: rowData => this.getPrettyDate(rowData.eventDate) },
            { title: 'Alarm Level',
                    render: rowData => 
                    getAlarmLevelStatusIcon(this.props.eventAlarmLevelChoices, rowData) },
                
            { title: 'Corrective Action',
                    render: rowData => 
                        getValueFromListOfDict(this.props.eventCorrectiveActions, rowData.eventCorrectiveAction) },
           
            // {
            //   title: 'Actions', 
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FTableAction 
            //             actions={this.getActions(rowData)}
            //             onTableAction = {this.onTableAction}
            //             rowData={rowData}
            //              />
            //       </React.Fragment>
            //     )
            //   }
            // },
            {
              title: 'Actions', 
              render: rowData => {
                let actions  = this.getActions(rowData, SAFETY_AND_COMPLINACE_DISCIPLINARY_EVENTS)

                return (
                  <React.Fragment>
                    {!!actions.length && <FTableAction 
                        actions={actions}
                        onTableAction = {this.onTableAction}
                        rowData={rowData}
                         />
                    }
                    {!!!actions.length && <BlockIcon color="disabled" />}
                  </React.Fragment>
                )
              }
            },
          ]}
          actions={
            [
              // {
              //   icon: () => (
              //     <FRouterAddLink to={ROUTER_DRIVER_DISCIPLINARY_EVENT_URL + 'add'} color='secondary' />
              //   ),
              //   isFreeAction: true
              // },
              this.getAddNewModelActions()
        
            ]}
          options = {{
              pageSize: this.state.pageSize,
              pageSizeOptions: this.state.pageSizeOptions,
              actionsColumnIndex: -1
           }}
          data={this.fetchData}
          title="Driver Former Employers"
          listErrCode={this.state.listErrCode}
        />

      </div>
    );
  }
}


export default connect(
  mapStateToProps, mapDispatchToProps)(DriverDisciplinaryEvents)
