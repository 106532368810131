import React, { Component } from 'react';
import FRouterLink, { FRouterAddLink } from '../widgets/FRouterLink';
import { API_RESIDENT_HISTORY, ROUTE_RESIDENT_HISTORY_URL, SAFETY_AND_COMPLINACE_RESIDENT_HISTORYS,  TAB_SAFETY_AND_COMPLIANCE, CHOICE_RESIDENT_HISTORY } from '../../util/Const';
import FListComponent from '../../util/FListComponent';
import { connect } from "react-redux"
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import FMaterialTable from '../widgets/FMaterialTable';
import FTableAction from '../widgets/FTableAction';
import { getResidentVerificationStatusIcon } from '../../util/icon_util';
import BlockIcon from '@material-ui/icons/Block';


class ResidentHistories extends FListComponent {
  constructor(props) {
    super(props, API_RESIDENT_HISTORY, CHOICE_RESIDENT_HISTORY)
    this.currentTitle = 'Resident History'
    this.currentTab = TAB_SAFETY_AND_COMPLIANCE
    this.routeUrl = ROUTE_RESIDENT_HISTORY_URL
    this.policyID = SAFETY_AND_COMPLINACE_RESIDENT_HISTORYS
    this.includeStateAction = false
  }



  render() {
    return (
      <div>
   
        <FMaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: 'Reference', 
              field: 'residentVerificationStatus',
              render: rowData => {
                return (
                  <React.Fragment>
                    <FRouterLink editIcon={true} label={rowData.id} to={ROUTE_RESIDENT_HISTORY_URL + rowData.id} />
                  </React.Fragment>
                )
              }
            },
        
            {
              title: 'Driver', 
              render: this.getDriverLink
            },
            
            { title: 'Start Date', field: 'residentStartDate', 
                     render: rowData => this.getPrettyDate(rowData.residentStartDate) },
            { title: 'End Date', field: 'residentEndDate', 
                     render: rowData => this.getPrettyDate(rowData.residentEndDate) },
      
            {title: 'Verification Attempt', field: 'residentVerificationAttemptCount'},
            { title: 'Verification Status',
                    render: rowData => 
                    getResidentVerificationStatusIcon( rowData) },
             
            {
              title: 'Actions', 
              render: rowData => {
                let actions  = this.getActions(rowData, SAFETY_AND_COMPLINACE_RESIDENT_HISTORYS)

                return (
                  <React.Fragment>
                    {!!actions.length && <FTableAction 
                        actions={actions}
                        onTableAction = {this.onTableAction}
                        rowData={rowData}
                         />
                    }
                    {!!!actions.length && <BlockIcon color="disabled" />}
                  </React.Fragment>
                )
              }
            },
          ]}
          actions={
            [
              this.getAddNewModelActions()
           
            ]}
          options = {{
              pageSize: this.state.pageSize,
              pageSizeOptions: this.state.pageSizeOptions,
              actionsColumnIndex: -1
           }}
          data={this.fetchData}
          title="Resident Histories"
          listErrCode={this.state.listErrCode}
          
        />

      </div>
    );
  }
}


export default connect(
  mapStateToProps, mapDispatchToProps)(ResidentHistories)
