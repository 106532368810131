import React, { Component } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table'
import FListComponent from '../../util/FListComponent';
import { API_DASHBOARD_DISPATCHER_SUMMARY } from '../../util/Const';
import { roundDisplay } from '../../util';


class DispatchersPreview extends FListComponent {
  constructor(props) {
    super(props, API_DASHBOARD_DISPATCHER_SUMMARY)
  }

  getData  = () => {
     let listData = this.state.listData
     listData = listData && listData.dispatcherDashboardPreview || []
     return listData
     
  }
  // componentDidMount() {
  //   this.fetchListData()
  // }

  render() {
    return (
      <React.Fragment>
       {/* <div style={{paddingLeft: '30px', maxHeight: 100}}> */}

        <MaterialTable
          columns={[
            {
              title: 'Dispatcher', field: 'dispatcher', render: rowData => {
                return (
                  <React.Fragment>
                   {rowData.dispatcher__dispatcherFirstName} {rowData.dispatcher__dispatcherLastName}
                  </React.Fragment>
                )
              }
            },
            { title: 'Counts', field: 'totalDispatchCount' },
            { title: 'Total Dispatched', field: 'totalDispatchCount', render: rowData => {
              return (
                <React.Fragment>
                  ${roundDisplay(rowData.totalDispatch, 2)}
               </React.Fragment>
              )
            }
          },

          ]}
          options = {{
              search: false,
              showTitle: true,
              toolbar: true,
              paging: true,
              maxBodyHeight: this.props.height || 500,
              pageSize:  7,
              pageSizeOptions: [7,10, 25]
            //   headerStyle: {color: 'red', marginTop: 0, paddingTop: 0}
              
          }}
          data={this.fetchData}
          title="Dispatcher Load Count"

        />

      </React.Fragment>
    );
  }
}

export default DispatchersPreview;
