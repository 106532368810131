import React, { useRef } from 'react';
import {ACTION_ADD_DOCUMENT, API_DOCUMENTS_URL, DELETE_DOCUMENT_RECORD, ACTION_EDIT_DOCUMENT, API_NON_COMPLIANCE, TAB_SAFETY_AND_COMPLIANCE, ACTION_DELETE_DOCUMENT, ACTION_ARCHIVE_DOCUMENT } from '../../../util/Const';
import { connect } from "react-redux"
import { mapDispatchToProps, mapStateToProps } from "../../../fredux/ActionMaker";
import BaseReport from '../BaseReport';
import FMaterialTable from '../../widgets/FMaterialTable';
import DocumentPopUp from '../../document/DocumentPopUp';
import {  getOwnerLink, getComplianceIcon, getExpirationDocumentIcon} from '../../../util/icon_util';
import FListComponent from '../../../util/FListComponent';
import { DELETE, UPDATE } from '../../../util/FAPI';
import { showSuccessNotification, showErroNotification } from '../../widgets/FMessage';
import {  CircularProgress } from '@material-ui/core';
import { Grid } from "@material-ui/core";
import FButton from "../../widgets/FButton";



const COMPLIANCE_EXPIRIING_DOCUMENT = 'COMPLIANCE_EXPIRIING_DOCUMENT'
const COMPLIANCE_EXPIRED_DOCUMENT = 'COMPLIANCE_EXPIRED_DOCUMENT'
const COMPLIANCE_MISSING_DOCUMENT = 'COMPLIANCE_MISSING_DOCUMENT'

export default class  BaseCompliance extends FListComponent {
   
  
   constructor(props, url, title, complianceType) {
    super(props, url, complianceType)
    this.complianceType = complianceType
    this.currentTab = TAB_SAFETY_AND_COMPLIANCE
    this.currentTitle = title
    this.tableRef = React.createRef();
    this.setState({currentRow: {documentType: 'test-document-type'}})

  }

    
    getOwnerLink = (rowData) => {} // Override
  
  
   onDocumentAdd = (rowData) => {
       this.setState({ dialogEditMode: true });
    };
    
       
    onDocumentEdit = (rowData) => {
        this.setState({ dialogEditMode: true });
    };


    getActionss = () => { 
      if (!!this.showAdd){
         return [
            {
              // icon: 'add',
              // iconProps: { style: { fontSize: "30px", color: "#3f51b5" } },

              icon: () => <FButton label='Add' color="primary" showIcon={'add'} />,

              tooltip: 'Add Document',
              onClick: (event, rowData) => {
                  this.setState({
                        currentRow: rowData,  
                        documentAction:  ACTION_ADD_DOCUMENT,
                        documentOwner: rowData.path,
                        ownerId: rowData.id,
                        documentType: rowData.data
                      },  ()=>{
                      this.onDocumentAdd(rowData)
                    })
                
              } 
            }]
      }
      return [
         {
              // icon: 'edit',
              // iconProps: { style: { fontSize: "30px", color: "#3f51b5" } },
              icon: () => <FButton label='edit' color="primary" showIcon={'edit'} />,
              tooltip: 'Update Document',
              onClick: (event, rowData) => {
              this.setState({
                    currentRow: rowData,  
                    documentAction:  ACTION_EDIT_DOCUMENT,
                    documentType: rowData.documentType,
                    documentId: rowData.id
                  },  ()=>{
                  this.onDocumentAdd(rowData)
                })
            
              } 
            },
       {
          // icon: 'archive',
          // iconProps: { style: { fontSize: "30px", color: "#3f51b5" } },
          icon: () => <FButton label='archive' color="primary" showIcon={'archive'} />,
          tooltip: 'Archive',
          color: 'primary',
          onClick: (event, rowData) => {
             this.notifyAction( ACTION_ARCHIVE_DOCUMENT, rowData)
          } 
        },
         {
          // icon: 'delete',
          // iconProps: { style: { fontSize: "30px", color: "#3f51b5" } },
          icon: () => <FButton label='delete' color="primary" showIcon={'delete'} />,
          tooltip: 'Delete Document',
          onClick: (event, rowData) => {
              this.notifyAction( ACTION_DELETE_DOCUMENT,  rowData)
          } 
        },
      ]
    }

  
  notifyDialogClose = () => {
     this.setState({rowData: {}, currentRow: {}, data: {}}, ()=>{
       this.setState( {dialogEditMode: false})
     })
  };
  
  getDocPopUp = () => (
      <DocumentPopUp 
            notifyAction={this.notifyAction}
            title={'Add Document'}
            rowData = {this.state.currentRow}
            documentOwner = {this.state.doocumentOwner}
            ownerId = {this.state.ownerId}
            openDialog={this.state.dialogEditMode}
            rowAction={this.state.documentAction}
            notifyDialogClose={this.notifyDialogClose}
        />
    );


  getDetail = () => {
    const { classes } = this.props;

    return (
     <div>
     <div>
        {this.getDocPopUp()}
     </div>
     <FMaterialTable
          tableRef={this.tableRef}
          isLoading={this.state.isLoading}
          columns={[            
            {
              title: 'Compliance', 
              // width: '50%',
              render: rowData => {
                return (
                  <React.Fragment>
                      {getComplianceIcon(rowData)|| getExpirationDocumentIcon(rowData)}
                  </React.Fragment>
                )
              }
            },
            {
              title: 'Document Type', 
              render: rowData => {
                return (
                  <React.Fragment>
                      {rowData.documentType || rowData.data}
                  </React.Fragment>
                )
              }
            },
            {
              title: 'Document Affiliated With', 
              render: rowData => {
                return (
                  <React.Fragment>
                      {this.getOwnerLink(rowData)}
                     
                  </React.Fragment>
                )
              }
            },
            // {
            //   title: 'id', field: 'id'
            // },
            
          ]}
           actions={this.getActionss()}
           options = {{
                    pageSize: this.state.pageSize,
                    pageSizeOptions: this.state.pageSizeOptions,
                    // actionsColumnIndex: -1,
                    search: false
                }}
                data={this.fetchData}
                listErrCode={this.props.listErrCode}
          />
          </div>
    )
  }

refreshList = (tableRef) => {
    if ( tableRef && tableRef.current) {
          tableRef.current.onQueryChange();
    }
}

 filterUpdatedData = (data, editedKeys) => {
    let cleanData = {};
    for ( const index in editedKeys ) {
       cleanData[editedKeys[index]] = data[editedKeys[index]];
    }

    return cleanData;
  }

deleteRow = (url, msgTitle) => {
    const that = this
    this.setState({isLoading: true})
    DELETE(url).then(response => {
        this.setState({currentAction: 'delete'}, ()=> {
              this.setState({isLoading: false, dialogEditMode: false})
              this.refreshList(that.tableRef)
        })
        showSuccessNotification(response, msgTitle)
    }).catch(err => {
     this.setState({isLoading: false, dialogEditMode: false})
      showErroNotification(err, msgTitle)
    })
  }

createOrUpdate = (url, data, msgTitle, apiMethod) => {
    const that = this
    this.setState({isLoading: true})
    UPDATE(url, apiMethod, this.getFormData(data)).then(res => {
      this.setState({currentAction: 'update'}, ()=>{
            this.refreshList(that.tableRef)
      })
      showSuccessNotification(res, msgTitle)
      this.setState({isLoading: false, dialogEditMode: false})
     
    }).catch(err => {
       this.setState({isLoading: false})
       showErroNotification(err, msgTitle)
    })
}


notifyAction = (action, rowData, editedData) => {
    action = action || this.state.documentAction
    if (action == ACTION_DELETE_DOCUMENT) {
        this.deleteRow(API_DOCUMENTS_URL + rowData.id, 'Document Deleted')
    }
    if (action == ACTION_ARCHIVE_DOCUMENT) {
      let data = {
        state: 'ARCHIVED'
      }
      this.createOrUpdate(API_DOCUMENTS_URL + rowData.id, data, 'Document Updated', 'patch')

    }
    if (action == ACTION_EDIT_DOCUMENT) {
      let updatedKeys = Object.keys(editedData)
      let cleanData = this.filterUpdatedData(rowData, updatedKeys)
      let data = {
        ...cleanData
      }
      this.createOrUpdate(API_DOCUMENTS_URL + this.state.documentId, data, 'Document Updated', 'patch')
    }
    if (action == ACTION_ADD_DOCUMENT ) {

      let updatedKeys = Object.keys(editedData)
      let cleanData = this.filterUpdatedData(rowData, updatedKeys)
      this.createOrUpdate(API_DOCUMENTS_URL, cleanData, 'Document Updated', 'post')
    }
};
  

}


