import React from 'react';
import FRouterLink, { FRouterAddLink } from '../widgets/FRouterLink';
import {ROUTE_CARRIERS_URL, API_FUEL_IMPORT_URL, ROUTE_FUEL_IMPORT_URL, TAB_EXPENSE, EXPENSE_FUEL_IMPORTS } from '../../util/Const';
import FListComponent from '../../util/FListComponent';
import { Link } from '@material-ui/core';
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import { connect } from "react-redux"
import { roundDecimal } from '../../util';
import FMaterialTable from '../widgets/FMaterialTable';
import FTableAction, { ACTION_TABLE_DELETE_ROW} from '../widgets/FTableAction';
import { getDataImportSyncStatusIcon, redForPositive, redForZero } from '../../util/icon_util';
import BlockIcon from '@material-ui/icons/Block';
import FAttachment from '../widgets/FAttachment';
import { Labels } from '../../localizations/Resource';
import { FButtonErrorWithDataLink } from '../widgets/FButtonLink';
import FDialog from '../widgets/FDialog';


class FuelImports extends FListComponent {

  constructor(props) {
    super(props, API_FUEL_IMPORT_URL, null,  `dataImportType=FUEL&dataImportSyncStatus=COMPLETE&`)
    this.currentTitle = 'Fuel Imports'
    this.currentTab = TAB_EXPENSE
    this.routeUrl = ROUTE_FUEL_IMPORT_URL 
    this.policyID = EXPENSE_FUEL_IMPORTS
    this.includeStateAction = false
  }

  // getActions = (rowData) => {
  //   let actions = []
  //   let invoiceStatus = rowData.dataImportSyncStatus
  //   // actions = actions.concat(INVOICE_STATE_MACHINE[dataImportSyncStatus])
    
  //   actions.push(ACTION_TABLE_DELETE_ROW)
    
  //   return actions
  // }
  
  onShowError = (rowData) => {
    let errors = rowData && rowData.dataImportMetaData && rowData.dataImportMetaData.errors || []
    let lengthOfErrors = errors ? errors.length : 0;
    if (lengthOfErrors <= 0) {
      return
     }
    this.setState(prevState => {
      let newState = {
        ...prevState,
        showImportEror: true,
        errors: errors
      }
      return newState
    }, () => {
      this.showErrorSummary(rowData);
    })
  };

  showErrorSummary = () => {
    return (<FDialog
            // maxWidth='xl'
            title={Labels.IMPORT_FUEL_ERROR_TITLE}
            // owner={this.props.owner}
            openDialog={this.state.showImportEror}
            width={700}
            titleColor={'secondary'}
            notifyDialogClose = {() => this.setState({showImportEror: false})}>
          
                <FMaterialTable
                        columns = {[ 
                            {title: 'Error', field: 'error',
                             cellStyle: {
                              // backgroundColor: '#039be5',
                              color: '#f50057'
                            },},
                            {title: Labels.DATE, field: 'date'},
                            {title: Labels.FUEL_CARD, field: 'card'},
                            {title: Labels.FUEL_LOCATION, field: 'location'},
                            {title: Labels.CITY, field: 'city'},
                            {title: Labels.JURISDICTION, field: 'jurisdiction'},
                          ]}
                        
                        data={this.state.errors}
                        options = {{
                            search: true,
                            showTitle: false,
                            toolbar: true,
                            paging: true,
                            sorting: false,
                            // maxBodyHeight: 400,
                            pageSize:  5,
                            pageSizeOptions: [5],
                            headerStyle: {
                              backgroundColor: '#01579b',
                              color: '#FFF'
                            }

                        }}
          />
        </FDialog>)
  }

  render() {
    return (
      <div>
        {this.showErrorSummary()}
        <FMaterialTable
          tableRef={this.tableRef}
          columns={[
            { title: Labels.IMPORTE_FUEL_ID, field: 'dataImportID'},
            {
              title: Labels.CARRIER, 
              render: this.getCarrierLink
            },
            { title: Labels.IMPORTE_FUEL_IMPORT_DATE, field: 'created', render: rowData => this.getPrettyDate(rowData.created) },
            { title: Labels.IMPORT_FUEL_DATA_SOURCE, field: 'prettyName'},

            { title: Labels.IMPORT_FUEL_FROM_DATE, field: 'dataImportStartDate', render: rowData => this.getPrettyDate(rowData.dataImportStartDate) },
            { title: Labels.IMPORT_FUEL_TO_DATE, field: 'dataImportEndDate', render: rowData => this.getPrettyDate(rowData.dataImportEndDate) },

       
           
            // { title: 'Amount ($)', field: 'expenseAmount',  render: rowData => roundDecimal(rowData.expenseAmount)},
            // { title: Labels.IMPORT_FUEL_SUMMARY_TOTAL_TRANSACTIONS, field: 'dataImportMetaData.totalTransactions', render: rowData => redForZero(roundDecimal(rowData.dataImportMetaData.totalTransactions, 0))},

            { title:  Labels.IMPORT_FUEL_SUMMARY_FUEL_TYPE_TOTAL,  field: 'dataImportMetaData.totalFuelAmount', render: rowData => roundDecimal(rowData.dataImportMetaData.totalFuelAmount)},
            { title: Labels.IMPORT_FUEL_SUMMARY_TOTAL_FUEL_FEES,  field: 'dataImportMetaData.totalFuelFees', render: rowData => roundDecimal(rowData.dataImportMetaData.totalFuelFees)},

            { title: Labels.IMPORT_FUEL_SUMMARY_TOTAL_CASH_ADVANCE,  field: 'dataImportMetaData.totalFuelCashAdvance', render: rowData => roundDecimal(rowData.dataImportMetaData.totalFuelCashAdvance)},
            { title: Labels.IMPORT_FUEL_SUMMARY_TOTAL_CASH_ADVANCE_FEES,  field: 'dataImportMetaData.totalAdvanceFees', render: rowData => roundDecimal(rowData.dataImportMetaData.totalAdvanceFees)},
            // { title: Labels.IMPORT_FUEL_SUMMARY_TOTAL_FEES,  field: 'dataImportMetaData.totalFees', render: rowData => roundDecimal(rowData.dataImportMetaData.totalFees)},

            { title:  Labels.IMPORT_FUEL_SUMMARY_TOTAL, field: 'dataImportMetaData.totalAmount', render:   rowData => redForZero(roundDecimal(rowData.dataImportMetaData.totalAmount))},

            { title: Labels.IMPORT_FUEL_SUMMARY_TOTAL_DUPLICATES, field: 'dataImportMetaData.totalDuplicates', render: rowData => redForPositive(roundDecimal(rowData.dataImportMetaData.totalDuplicates, 0))},
            { title: Labels.IMPORT_FUEL_SUMMARY_TOTAL_UNKNOWN_FUEL_CARDS,  field: 'dataImportMetaData.totalUnRecogonizedFuelCards',  render: rowData => redForPositive(roundDecimal(rowData.dataImportMetaData.totalUnRecogonizedFuelCards, 0))},
            // { title: Labels.IMPORT_FUEL_SUMMARY_TOTAL_ERRORS, field: 'dataImportMetaData.totalErrors', render: rowData => redForPositive(roundDecimal(rowData.dataImportMetaData.totalErrors, 0))},
            {
              title: 'Total Errors', 
              render: rowData => {
                let totalErrors = roundDecimal(rowData.dataImportMetaData.totalErrors, 0)
                let errors = redForPositive(totalErrors)
                return (
                  <React.Fragment>
                    {totalErrors > 0 && <FButtonErrorWithDataLink  
                      notifyParent={this.onShowError} data={rowData}
                      label={errors}/>
                    }
                    {totalErrors == 0 && 0}
                  </React.Fragment>
                )
              }
            },
            {
              title: 'Imported Method',
              render: rowData => {
                return (
                  <React.Fragment>
                    {/* {rowData.dataImportRawFile && <Link href={rowData.dataImportRawFile} target="_blank">file</Link>} */}
                    { rowData.dataImportMethod == 'FILE_UPLOADED' && rowData.dataImportRawFile  && <FAttachment url={rowData.dataImportRawFile} label= 'CSV'/> }
                    { rowData.dataImportMethod == 'API'  && 'AUTO_IMPORTED'}
                  </React.Fragment>
                )
              }
            },
            // {title:  Labels.STATUS, render: rowData => getDataImportSyncStatusIcon(rowData)},

            {
              title: 'Actions', 
              render: rowData => {
                let actions  = this.getActions(rowData, EXPENSE_FUEL_IMPORTS)

                return (
                  <React.Fragment>
                    {!!actions.length && <FTableAction 
                        actions={actions}
                        onTableAction = {this.onTableAction}
                        rowData={rowData}
                         />
                    }
                    {!!!actions.length && <BlockIcon color="disabled" />}
                  </React.Fragment>
                )
              }
            },
          ]}
          actions={
            [
              // {
              //   icon: () => (
              //     <FRouterAddLink to={ROUTE_FUEL_IMPORT_URL + 'add'} color='secondary' />
              //   ),
              //   isFreeAction: true
              // }
              this.getAddNewModelActions()
            ]}
          isLoading = {this.state.isLoading}
          options = {{
              // loading: true,
              pageSize: this.state.pageSize,
              pageSizeOptions: this.state.pageSizeOptions,
              actionsColumnIndex: -1
           }}
          data={this.fetchData}
          title="Fuel Imports"
          listErrCode={this.state.listErrCode}
        />

      </div>
    );
  }
}


export default connect(
  mapStateToProps, mapDispatchToProps)(FuelImports)
