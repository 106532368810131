import React, { Component } from 'react'
import Link from '@material-ui/core/Link';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';


export default class FButtonLink extends Component {

    render() {
        return (
            <Link
                component="button"
                variant="body2"
                style={{fontSize: this.props.size || '18px', textDecoration: 'underline', cursor: 'pointer' }}
                onClick={this.props.notifyParent}>
                {this.props.label}
            </Link>
        )
    }
}



export  class FButtonErrorLink extends Component {

    render() {
        return (
            <Link
                component="button"
                variant="body2"
                color='secondary'
                style={{fontSize: this.props.size || '18px', textDecoration: 'underline', cursor: 'pointer' }}
                onClick={this.props.notifyParent}>
                {this.props.label}
            </Link>
        )
    }
}


export  class FButtonErrorWithDataLink extends Component {

    render() {
        return (
            <Link
                component="button"
                variant="body2"
                color='secondary'
                style={{fontSize: this.props.size || '18px', textDecoration: 'underline', cursor: 'pointer' }}
                // onClick={this.props.notifyParent(this.props.data)}
                onClick={() => this.props.notifyParent(this.props.data)}>
                    
                {this.props.label}
            </Link>
        )
    }
}



export class FButtonLinkAdd extends Component {

    render() {
        const { label, disabled, color, variant, onClick, size } = this.props;

        return (
            <Button
                style={{
                    // You can adjust or add more inline styles here
                }}
                disabled={disabled}
                color={color || "secondary"}
                variant={variant || "outlined"}
                size={size || "medium"}
                onClick={onClick}
            >
                {label}
                <AddIcon style={{ fontSize: '20px', paddingRight: '3px' }} />
            </Button>
        );
    }
}