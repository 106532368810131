import React from 'react';
import {API_PAY_CHECK_URL, ROUTE_PAY_CHECK_URL, TAB_ACCOUNTING, FINANCE_PAYCHECK, ACTION_TABLE_EXPENSIFY_PAYCHECK, CHOICE_PAY_CHECK } from '../../util/Const';
import FListComponent from '../../util/FListComponent';
import { Link } from '@material-ui/core';
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import { connect } from "react-redux"
import { roundDecimal } from '../../util';
import FMaterialTable from '../widgets/FMaterialTable';
import FTableAction from '../widgets/FTableAction';
import BlockIcon from '@material-ui/icons/Block';
import { redForNegative } from '../../util/icon_util';

import  FAttachment  from '../widgets/FAttachment';

class PayChecks extends FListComponent {

  constructor(props) {
    super(props, API_PAY_CHECK_URL, CHOICE_PAY_CHECK)
    this.currentTitle = 'Paychecks'
    this.currentTab = TAB_ACCOUNTING
    this.routeUrl = ROUTE_PAY_CHECK_URL
    this.policyID = FINANCE_PAYCHECK
    this.includeStateAction = false
    this.includeInvoiceStatus = false
    this.includePaycheckExpensify = true
  }
  

  render() {
    return (
      <div>

        <FMaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: 'Paycheck ID',
              field: 'payCheckID',
              render: rowData => {
                return (
                  <FAttachment url={rowData.payCheckFile} label= {rowData.payCheckID}/>
                )
              }
            },
            {
              title: 'Carrier', 
              render: this.getCarrierLink
            },

            {
              title: 'Driver', 
              render: this.getDriverLink
            },
            {
              title: 'Vehicle', 
              render: this.getVehicleLink
            },

           
            { title: 'Fleet From', field: 'payCheckFleetStartDate', render: rowData => this.getPrettyDate(rowData.payCheckFleetStartDate) },
            { title: 'Fleet To', field: 'payCheckFleetEndDate', render: rowData => this.getPrettyDate(rowData.payCheckFleetEndDate) },
            { title: 'Expense From', field: 'payCheckExpenseStartDate', render: rowData => this.getPrettyDate(rowData.payCheckExpenseStartDate) },
            { title: 'Expense To', field: 'payCheckExpenseEndDate', render: rowData => this.getPrettyDate(rowData.payCheckExpenseEndDate) },
            { title: 'Gross Fleet', field: 'payCheckFleetGrossPayment', render: rowData => roundDecimal(rowData.payCheckFleetGrossPayment)},
            //TODO (Estifanos) This doesn't make sense since all expenses are some of the deducted or reimbursed
            // { title: 'Total Expense', field: 'payCheckFleetTotalExpense', render: rowData => roundDecimal(rowData.payCheckFleetTotalExpense) },
            { title: 'Carrier Fee (%)', field: 'payCheckExpenseCarrierFee' },
            { title: 'Driver Payment Net', field: 'payCheckNet', render: rowData => redForNegative(roundDecimal(rowData.payCheckNet)) },
           
            {
              title: 'Actions', 
              render: rowData => {
                let actions  = this.getActions(rowData, FINANCE_PAYCHECK)

                return (
                  <React.Fragment>
                    {!!actions.length && <FTableAction 
                        actions={actions}
                        onTableAction = {this.onTableAction}
                        rowData={rowData}
                         />
                    }
                    {!!!actions.length && <BlockIcon color="disabled" />}
                  </React.Fragment>
                )
              }
            },
          ]}
          actions={
            [
              // {
              //   icon: () => (
              //     <FRouterAddLink to={ROUTE_PAY_CHECK_URL + 'add'} color='secondary' />
              //   ),
              //   isFreeAction: true
              // },
              this.getAddNewModelActions()

            ]}
          options = {{
              pageSize: this.state.pageSize,
              pageSizeOptions: this.state.pageSizeOptions,
              actionsColumnIndex: -1
           }}
          data={this.fetchData}
          title="Paychecks"
          listErrCode={this.state.listErrCode}
        />

      </div>
    );
  }
}



export default connect(
  mapStateToProps, mapDispatchToProps)(PayChecks)
