
//Redux way to update first level of anything
export const ACTION_UPDATE_PARENT = 'ACTION_UPDATE_PARENT'

 // Redux Actions 
 export const ACTION_FETCH_PROFILE = 'FETCH_PROFILE'

 export const ACTION_FETCH_PAYCHECK = 'FETCH_PAYCHECK'
 export const ACTION_SET_FILTER_PAYCHECK = 'SET_FILTER_PAYCHECK'
 export const ACTION_FETCH_LOCATIONS = 'FETCH_LOCATIONS'
 export const ACTION_FETCH_CARRIERS = 'FETCH_CARRIERS'
 export const ACTION_FETCH_VEHICLES = 'FETCH_VEHICLES'
 export const ACTION_FETCH_TRAILERS = 'FETCH_TRAILERS'
 export const ACTION_FETCH_DRIVERS = 'FETCH_DRIVERS'
 export const ACTION_FETCH_DISPATCHERS = 'FETCH_DISPATCHERS'
 export const ACTION_FETCH_CUSTOMERS = 'FETCH_CUSTOMERS'
 
 export const ACTION_UPDATE_DOCUMENT = 'UPDATE_DOCUMENT'

 export const ACTION_UPDATE_STATUS_CHOICES = 'ACTION_UPDATE_STATUS_CHOICES'

 export const ACTION_UPDATE_FLEET_DASHBOARD_PREVIEW = 'UPDATE_FLEET_DASHBOARD_PREVIEW'




//Error state
export const ACTION_UPDATE_ERROR_STATE = 'ACTION_UPDATE_ERROR_STATE'
export const ACTION_UPDATE_SERVER_ERROR = 'ACTION_UPDATE_SERVER_ERROR'
export const RESET_SERVER_ERROR_VALIDAITON_STATE = 'RESET_SERVER_ERROR_VALIDAITON_STATE'

export const RESET_ALL_ERROR_VALIDAITON_STATE = 'RESET_ALL_ERROR_VALIDAITON_STATE'

//Auth actions
export const ACTION_AUTH_SIGNED_IN = 'ACTION_AUTH_SIGNED_IN'
export const ACTION_AUTH_SIGN_UP = 'ACTION_AUTH_SIGN_UP'
export const ACTION_AUTH_SIGNED_OUT = 'ACTION_AUTH_SIGNED_OUT'
export const ACTION_AUTHORIZE = 'ACTION_AUTHORIZE'




 
 
