import React from 'react';
import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link, Grid, CircularProgress } from '@material-ui/core';
import FInput from '../widgets/FInput';
import FDetailComponent from '../../util/FDetailComponent';
import FCheckBox from '../widgets/FCheckBox';
import FSelect from '../widgets/FSelect'
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import { connect } from "react-redux"
import { VALIDATE_REQUIRED, VALIDATE_EMAIL, VALIDATE_ADDRESS ,VALIDATE_PHONE_NUMBER, VALIDATE_PASSWORD } from '../../util/ValidatorConst';
import { API_AUTH_SIGN_UP, RECAPCH_SITE_KEY, ZTRUCKING_WEBSITE } from '../../util/Const';
import FButton from '../widgets/FButton';
import { isValid } from '../../util/Validator';
import FAddress from "../widgets/FAddress";
import FRecaptcha from "../widgets/FRecaptcha";
import { getErrroMessage} from '../widgets/FMessage';
import { UPDATE } from '../../util/FAPI';
import logo from './logo_zt_square.png'; // Tell webpack this JS file uses this image
import  SignupSQPaymentForm from '../square_payment/SignupSQPaymentForm';

const styles = theme => ({
  root: {
    minHeight: '100vh',
    backgroundImage: `url('/truck.jpg')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  formContainer: {
    padding: theme.spacing(1),
    // paddingRight: theme.spacing(3), // 

  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: "white",
    margin: theme.spacing(5, 5),
    borderRadius: theme.spacing(1),
    // maxWidth: '800px', // to ensure it doesn’t take the whole width
    width: '50%', // to ensure it takes the full width of its parent
    boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    width: '80px',
    height: '80px',
    marginTop: theme.spacing(2),
  },
  headingLink: {
    margin: theme.spacing(2, 0),
    fontWeight: 'bold',
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  link: {
    fontWeight: 'bold',
    margin: theme.spacing(2, 0),
    textAlign: 'center',
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  footer: {
    padding: theme.spacing(2),
    textAlign: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.9)', // Slightly transparent white
    borderTop: '1px solid #ddd',
  },
  errorMessage: {
    margin: theme.spacing(2, 0),
    textAlign: 'center',
    color: 'red',
    letterSpacing: '2px', 
    fontSize: "15px",
    paddingLeft: theme.spacing(2),
  },
  alreadyMember: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2)
  },
  centralizedErrorContainer: {
    display: 'flex',
    alignItems: 'center', // Centralize vertically
    justifyContent: 'center', // Centralize horizontally
  },
  errorContainer: {
    padding: theme.spacing(2),
    width: '100%', // Or any width you prefer
    // border: '2px solid', // You can adjust this
    borderColor: theme.palette.error.main, // You can adjust the color
    borderRadius: theme.shape.borderRadius, // Optional for rounded corners
    backgroundColor: theme.palette.error.light, // Adjust background color
  },
});




class SignUp extends FDetailComponent {

  constructor(props) {
    super(props, API_AUTH_SIGN_UP, 'profile', {})
    this.state = {
      loading: false,
      errorResponse: '',

      method: 'post',
      url: API_AUTH_SIGN_UP,
      formData: {},
      data: {
        username: '',
        isCarrier: true,
        profileFirstName: '',
        profileMiddleName: '',
        profileLastName: '',
        profileEmail: '',
        password: '',
        confpassword: '',
        profilePhoneNumber: '',
        address: {},
        profileSubscriptionType: '',
        carrierName: '',
        carrierMC: '',
        carrierDOT: '',
        carrierEmail: '',
        carrierPhoneNumber: '',
        carrierAddress: {},
        agreeToTerms: false, 
        reCaptcha: '',
        clientDiscoveryType: '',
        clinetDiscoveryReference: '',
        squareToken: '',
        paymentZipCode: ''

      },
      errorData: {
        username: '',
        profileFirstName: '',
        profileMiddleName: '',
        profileLastName: '',
        profileEmail: '',
        password: '',
        confpassword: '',
        profilePhoneNumber: '',
        address: '',
        profileSubscriptionType: '',
        carrierName: '',
        carrierMC: '',
        carrierDOT: '',
        carrierEmail: '',
        carrierPhoneNumber: '',
        carrierAddress: '',
        agreeToTerms: true, 
        reCaptcha: true,
        isCarrier: false,


      },
      errorMessage: {
        username: 'Please enter a valid username. Note: It may already be taken.',
        profileFirstName: 'Please enter a valid first name.',
        profileMiddleName: '', // No error message provided for middle name.
        profileLastName: 'Please enter a valid last name.',
        profileEmail: 'Please enter a valid email address.',
        password: 'Please enter a valid password.',
        confpassword: 'Your passwords don\'t match. Please try again.',
        profilePhoneNumber: 'Please enter a valid phone number.',
        address: 'Please enter a valid address.',
        profileSubscriptionType: 'Please select a valid subscription type.',
        carrierName: '', // No error message provided for carrier name.
        carrierMC: 'Please enter a valid MC#.',
        carrierDOT: 'Please enter a valid DOT#.',
        carrierEmail: 'Please enter a valid carrier email address.',
        carrierPhoneNumber: 'Please enter a valid carrier phone number.',
        carrierAddress: 'Please enter a valid billing address.',
        agreeToTerms: 'You must accept the terms and conditions to proceed.',
        reCaptcha: 'Please complete the CAPTCHA to prove you are not a robot.',
        clientDiscoveryType: 'Please choose an option or select "Other".',
        squareToken: 'There was an issue with the payment system. Please try again later.'
    },    
    touched: {
        isCarrier: true,
      },
      validators: {
        username: [VALIDATE_EMAIL],
        profileFirstName: [VALIDATE_REQUIRED],
        profileMiddleName: [],
        profileLastName: [VALIDATE_REQUIRED],
        profileEmail: [VALIDATE_EMAIL],
        password: [VALIDATE_PASSWORD],
        confpassword:  [VALIDATE_REQUIRED],
        profilePhoneNumber: [VALIDATE_PHONE_NUMBER],
        address: [VALIDATE_ADDRESS],
        profileSubscriptionType: [VALIDATE_REQUIRED],
        carrierName: [VALIDATE_REQUIRED],
        carrierMC: [VALIDATE_REQUIRED],
        carrierDOT: [VALIDATE_REQUIRED],
        carrierEmail: [VALIDATE_EMAIL],
        carrierPhoneNumber: [VALIDATE_PHONE_NUMBER],
        carrierAddress: [VALIDATE_ADDRESS],
        clientDiscoveryType: [VALIDATE_REQUIRED],
        agreeToTerms:  [VALIDATE_REQUIRED],
        reCaptcha: [VALIDATE_REQUIRED],
        squareToken:  [VALIDATE_REQUIRED]
      }
    }
    // this.sqFormRef = createRef(); // create a ref
    this.recaptchaComponentRef = React.createRef();
  }

  fetchDetail = () => {
  }

  routeTosignIn = () => {
    this.props.dispatchSignIn()
    window.location.href = '/'
  }
  
  getSaveMeta = () => {
    return {
       method: "post",
       url: this.state.url
    }
  }
  
   

  getErrorData = () => {
    let errorData = {
      username: !isValid(this.state.data.username, this.state.validators.username),
      profileFirstName: !isValid(this.state.data.profileFirstName, this.state.validators.profileFirstName),
      profileLastName: !isValid(this.state.data.profileLastName, this.state.validators.profileLastName),
      profileEmail: !isValid(this.state.data.profileEmail, this.state.validators.profileEmail),
      password: !isValid(this.state.data.password, this.state.validators.password),
      confpassword: this.hasConfPasswordErrors(),
      profilePhoneNumber: !isValid(this.state.data.profilePhoneNumber, this.state.validators.profilePhoneNumber),
      address: !isValid(this.state.data.address, this.state.validators.address),
      profileSubscriptionType: !isValid(this.state.data.profileSubscriptionType, this.state.validators.profileSubscriptionType),
      
      carrierName: this.state.data.isCarrier && !isValid(this.state.data.carrierName, this.state.validators.carrierName),
      carrierMC: this.state.data.isCarrier && !isValid(this.state.data.carrierMC, this.state.validators.carrierMC),
      carrierDOT: this.state.data.isCarrier && !isValid(this.state.data.carrierDOT, this.state.validators.carrierDOT),
      
      clientDiscoveryType: !isValid(this.state.data.clientDiscoveryType, this.state.validators.clientDiscoveryType),

      squareToken: !isValid(this.state.data.squareToken, this.state.validators.squareToken),

      reCaptcha: this.state.data.reCaptcha && !isValid(this.state.data.reCaptcha, this.state.validators.reCaptcha),
      agreeToTerms: !!!this.state.data.agreeToTerms,

         // carrierEmail: this.state.data.isCarrier && !isValid(this.state.data.carrierEmail, this.state.validators.carrierEmail),
      // carrierPhoneNumber: this.state.data.isCarrier && !isValid(this.state.data.carrierPhoneNumber, this.state.validators.carrierPhoneNumber),
      // carrierAddress: this.state.data.isCarrier &&  !isValid(this.state.data.carrierAddress, this.state.validators.carrierAddress),

      }
    return errorData
  }
  
  hasConfPasswordErrors = () => {
    return (this.state.data.password != this.state.data.confpassword)
  }

  hasCustomErrors = () => {
    return !(this.isAddressValid(true, true, true, true))
  }
  

  notifyNonce = (squareToken, paymentZipCode) => {
    this.updateValues('paymentZipCode', {paymentZipCode: paymentZipCode});
    this.updateValues('squareToken', {squareToken: squareToken}, this.onSignup);
   };
  
  
  notifyNoanceError = (error) => {
    this.updateValues('squareToken', {squareToken: ''}, this.onSignup);
  };
  
  resetCaptcha = () => {
    this.recaptchaComponentRef.current.resetRecaptcha();

  }
 

  updateValues = (name, obj, callback = () => {}) => {
    this.setState(prevState => {
        let newState = {
            ...prevState,
            data: {
                ...prevState.data, ...obj
            },
            touched: {
                ...prevState.touched,
                [name]: true
            }
        }
        return newState
    }, () => {
        this.validateSingle(name);
        callback();
    });
}

  onSignup = (evt) => {

    this.validate().then(succ => {
        let data = this.getFormData()
        const {url, method} = this.getSaveMeta()
        this.setState({errorResponse: '', loading: true})
        UPDATE(url, method, data).
            then(response => {
                console.log(response)
                this.setState({ loading: false })
                this.routeTosignIn()
            }).catch(error => {
                this.resetCaptcha()
                let errMsg  = getErrroMessage(error)
                this.setState({ loading: false, errorResponse: errMsg})
            });

  }).catch(error => { 
    this.setState({ loading: false, errorResponse: `There were errors in your input. Please review and correct them before proceeding ${error || ''}.`
  })
  })
  }



  render() {
    const { classes } = this.props;
    
    return (
        <Grid container component="main" className={classes.root} style={{ height: 'auto' }}>

        <CssBaseline />
        <Grid item xs={12} md={9} elevation={6} square className={classes.centralized}>
        <Paper className={classes.paper} style={{ width: '100%', margin: 'auto'}}>


            <img src={logo} alt='Carrier Management Software' className={classes.logo} />
              <Link href={ZTRUCKING_WEBSITE} className={classes.link} style={{ fontFamily: "'Roboto', sans-serif" }}>
              <Typography component="h1" variant="h5" >
                  <span>Elevate Your Logistics: AI-Driven Software for Daily FMCSA Audits & Comprehensive Carrier Management Software</span>

               </Typography>
              </Link>
      
            <Grid container className={classes.formContainer} spacing={2} alignItems="center" justify="center">
               <Grid item xs={12} md={3}>
                  <FInput name='profileFirstName' required
                    value={this.state.data.profileFirstName}
                    hasError={this.state.errorData.profileFirstName}
                    helpMessage={this.state.errorMessage.profileFirstName}
                    label="First Name" notifyParent={this.updateValues}
                  />
                </Grid>  
                <Grid item xs={12} md={2}>
                  <FInput name='profileMiddleName'
                    value={this.state.data.profileMiddleName}
                    hasError={this.state.errorData.profileMiddleName}
                    helpMessage={this.state.errorMessage.profileMiddleName}
                    label="Middle Name" notifyParent={this.updateValues}
                  />
                </Grid>  
                <Grid item xs={12} md={2}>
                  <FInput name='profileLastName' required
                    value={this.state.data.profileLastName}
                    hasError={this.state.errorData.profileLastName}
                    helpMessage={this.state.errorMessage.profileLastName}
                    label="Last Name" notifyParent={this.updateValues}
                  />
                </Grid>  
                <Grid item xs={12} md={2}>
                  <FInput name='profilePhoneNumber' required
                    value={this.state.data.profilePhoneNumber}
                    hasError={this.state.errorData.profilePhoneNumber}
                    helpMessage={this.state.errorMessage.profilePhoneNumber}
                    label="Phone Number" notifyParent={this.updateValues}
                  />
                </Grid>  
                <Grid item xs={12} md={3}>
                  <FInput name='profileEmail' required
                    value={this.state.data.profileEmail}
                    hasError={this.state.errorData.profileEmail}
                    helpMessage={this.state.errorMessage.profileEmail}
                    label="Email" notifyParent={this.updateValues}
                  />
                </Grid>  
                </Grid>
              

            <Grid container className={classes.formContainer} spacing={2} alignItems="center" justify="center">
              <Grid item  hidden={!this.state.data.isCarrier}  xs={12} md={3}>
                    <FInput name='carrierName' required
                      value={this.state.data.carrierName}
                      hasError={this.state.errorData.carrierName}
                      helpMessage={this.state.errorMessage.carrierName}
                      label="Carrier Name" notifyParent={this.updateValues}
                    />
              </Grid>
              <Grid item xs={12} md={3}>
                    <FInput name='carrierDOT' required
                      value={this.state.data.carrierDOT}
                      hasError={this.state.errorData.carrierDOT}
                      helpMessage={this.state.errorMessage.carrierDOT}
                      label="DOT" notifyParent={this.updateValues}
                    />
              </Grid>
              <Grid item xs={12} md={3}>
                    <FInput name='carrierMC' required
                      value={this.state.data.carrierMC}
                      hasError={this.state.errorData.carrierMC}
                      helpMessage={this.state.errorMessage.carrierMC}
                      label="MC" notifyParent={this.updateValues}
                    />
              </Grid>

              <Grid item xs={12} md={3}>
                    <FSelect
                        name='profileSubscriptionType'
                        value={this.state.data.profileSubscriptionType}
                        hasError={this.state.errorData.profileSubscriptionType}
                        helpMessage={this.state.errorMessage.profileSubscriptionType}
                        label='Fleet Size *'
                        data={this.props.profileSubscriptionTypeChoices}
                        valueRef='prettyName'
                        keyRef='id'
                        notifyParent={this.updateValues}
                    />
                  </Grid>
             
            </Grid>
         
            <Grid container className={classes.formContainer} spacing={2} alignItems="center" justify="center">
                  <Grid item xs={12} md={4}>
                    <FInput name='username' required
                      value={this.state.data.username}
                      hasError={this.state.errorData.username}
                      helpMessage={this.state.errorMessage.username}
                      label="Carrier Email " notifyParent={this.updateValues}
                    />
                    </Grid>
                <Grid item xs={12} md={4}>
                  <FInput name='password' required
                    value={this.state.data.password}
                    type='password'
                    hasError={this.state.errorData.password}
                    helpMessage={this.state.errorMessage.password}
                    label="Password" notifyParent={this.updateValues}
                    autoComplete='new-password'
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <FInput name='confpassword' required
                    value={this.state.data.confpassword}
                    type='password'
                    hasError={this.state.errorData.confpassword}
                    helpMessage={this.state.errorMessage.confpassword}
                    label="Confirm Password" notifyParent={this.updateValues}
                    autoComplete='new-password'
                  />
                </Grid>
                
               </Grid>

               <Grid container className={classes.formContainer} spacing={2} alignItems="left" justifyContent="left">
                  <Grid item xs={12} md={3}>
                   <FRecaptcha
                            name='reCaptcha'
                            hasError={this.state.errorData.reCaptcha}
                            helpMessage={this.state.errorMessage.reCaptcha}
                            notifyParent={this.updateValues}
                            recapthaSiteKey={RECAPCH_SITE_KEY}
                            ref={this.recaptchaComponentRef}
                          />
                    </Grid>
                <Grid item xs={12} md={3}>
                    <FSelect
                                  name='clientDiscoveryType'
                                  required
                                  value={this.state.data.clientDiscoveryType}
                                  hasError={this.state.errorData.clientDiscoveryType}
                                  helpMessage={this.state.errorMessage.clientDiscoveryType}
                                  label='How did you discover us?'
                                  data={this.props.clinetDiscoverySourceChoices}
                                  valueRef='prettyName'
                                  keyRef='id'
                                  notifyParent={this.updateValues}
                              />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FInput 
                      name='clinetDiscoveryReference' 
                      value={this.state.data.clinetDiscoveryReference}
                      hasError={this.state.errorData.clinetDiscoveryReference}
                      helpMessage={this.state.errorMessage.clinetDiscoveryReference}
                      label="Reference Note (e.g., Coupon, Referred by)" 
                      notifyParent={this.updateValues}
                  />
              </Grid>

                
               </Grid>
              

               <Grid container spacing={2} className={classes.formContainer}>
                  <Grid item xs={12}>
                      
                      <Grid item xs={12}>
                          <FCheckBox
                              name='agreeToTerms'
                              value={this.state.data.agreeToTerms}
                              hasError={!!this.state.errorData.agreeToTerms}
                              helpMessage={this.state.errorMessage.agreeToTerms}
                              label="I have read and agree to Ztrucking terms of service & privacy policy."
                              notifyParent={this.updateValues}
                          />
                      </Grid>

                      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
                          <div style={{
                              marginBottom: '15px',
                              fontSize: '16px',
                              color: '#6c757d',
                              fontStyle: 'italic'
                          }}>
                              Your credit card will not be charged until the trial period concludes. Cancel anytime!
                          </div>
                          
                          <Typography variant="h6" component="h3" gutterBottom>
                              Payment Information
                          </Typography>

                          <Grid item xs>
                              <FAddress
                                  name='address'
                                  notifyParent={this.updateValues}
                                  address={this.state.data.address}
                                  hasError={this.state.errorData.address}
                                  place={true}
                                  city={true}
                                  state={true}
                                  zipCode={true}
                                  place_required={true}
                                  city_required={true}
                                  state_required={true}
                                  zipCode_required={true}
                                  props_label='Billing Address'
                              />
                          </Grid>

                          <Grid container spacing={2} className={classes.formContainer}>
                              <Grid item xs={12}>
                                  <SignupSQPaymentForm
                                      loading={this.state.loading}
                                      validate={this.validate}
                                      notifyNonce={this.notifyNonce}
                                      notifyNonceError={this.notifyNonceError}
                                  />
                              </Grid>
                          </Grid>
                          {this.state.loading && <CircularProgress className={classes.progress} />}
                      </Paper>


                      <Grid item xs={12} className={classes.alreadyMember}>
                          <Link
                              component="button"
                              variant="body2"
                              onClick={this.routeTosignIn}>
                              Already Member? Sign In
                          </Link>
                      </Grid>
                  </Grid>
              </Grid>
             
             
              <Grid container spacing={2}  className={classes.formContainer}>
                     <Paper elevation={0} className={classes.errorConetainer}>
                        <span className={classes.errorMessage}>{this.state.errorResponse} </span>
                      </Paper>

                    </Grid>
              <div className={classes.footer}>
                <Typography variant="body2" align="center" style={{ fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgba(0, 0, 0, 0.54)' }}>
                    &copy; Copyright <a href="https://www.ztrucking.com" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>ZTRUCKING GROUP CORP.</a> &nbsp;| &nbsp;
                    <a href="https://www.ztrucking.com/terms-and-condition" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Terms of Use</a>  &nbsp;|  &nbsp;
                    <a href="https://www.ztrucking.com/privacy-policy" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Privacy Policy</a>
                </Typography>
            </div>
      </Paper>
      </Grid>
      </Grid>
    );
  }

}


SignUp.propTypes = {
  classes: PropTypes.object.isRequired,
};

// export default withStyles(styles)(SignUp);

export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps)(SignUp))
