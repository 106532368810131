import React, { Component } from 'react'
import ListItemIcon from '@material-ui/core/ListItemIcon';

import { mapStateToProps } from '../../fredux/ActionMaker';
import { connect } from 'react-redux'
import {  has_view_permission} from '../../util/ACL';
import * as CONST from "../../util/Const";
import FListItem from '../widgets/FListItem';


class ExpenseListItems extends Component {

   render() {
         const profile = this.props.profile
       return (
       <div>

               <FListItem  
                  disabled={!has_view_permission(profile, CONST.EXPENSE_EXPNESES)}

                  to={CONST.ROUTE_EXPENSE_URL} label='Expenses'>
                   <ListItemIcon>EXP</ListItemIcon>
               </FListItem>

               <FListItem  
                  disabled={!has_view_permission(profile, CONST.EXPENSE_EXPNESES)}

                  to={CONST.ROUTE_REC_EXPENSE_URL} label='Scheduled Expenses'>
                   <ListItemIcon>REXP</ListItemIcon>
               </FListItem>
           
           <hr/> 
               <FListItem 
               disabled={!has_view_permission(profile, CONST.EXPENSE_FUEL_IMPORTS)}

               to={CONST.ROUTE_FUEL_IMPORT_URL} label='Import Fuels'>
                   <ListItemIcon>FU</ListItemIcon>
               </FListItem>
           
       </div>
       );
   }
}

export default connect(mapStateToProps)(ExpenseListItems)
