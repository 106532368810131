import React from 'react'
import { connect } from 'react-redux'

import { Grid } from "@material-ui/core";
import FDialog from '../widgets/FDialog';
import { mapDispatchToProps, mapStateToProps } from '../../fredux/ActionMaker';
import FSelect from "../widgets/FSelect";
import * as CONST from "../../util/Const";
import FInput from '../widgets/FInput';
import { FListDetailView } from '../../util/FListDetailView';
import { isValid } from "../../util/Validator";
import FCheckBox from '../widgets/FCheckBox';

import { withStyles } from "@material-ui/core/styles";

import {VALIDATE_REQUIRED, VALIDATE_FILE} from "../../util/ValidatorConst";
import FDate from "../widgets/FDate";
import FFileUpload from "../widgets/FFileUpload";
import {  showErroNotification } from '../widgets/FMessage';
import { getChoice } from '../../util/FAPI';

const styles = theme => ({
  root: {

  },
  padding: {
      marginTop: theme.spacing(4),
  },

  header: {
      background: '#f5f5f5'
  },
  button: {
      marginRight: theme.spacing(2),
  },
  dropDown: {
    },
});
const PROPS = {
    module: "document",
    editMode: false,
    url: CONST.API_DOCUMENTS_URL,
    documentAction: '',
    listData: [],
    formData: {},
    errorData: {
        // documentFile: true,
    },
    touched: {
    },
    errorMessage: {
      documentID: "Document ID required",
      state: 'Invald State',
      documentType: 'Invalid Document Type',
      documentIssuedState: 'Document Issued State',
      documentIssuedDate: 'Invalid Date',
      documentExpirationDate: 'Invalid Date',
      documentFile: 'Upload File ',
      documentIsGOVIssued: 'Invalid Data',
      documentIdRequired: 'Invalid Data',
      documentExpires: 'Invalid Data',
      desc: '',
    },
    data: {
      carrier: {},
      documentID: '',
      documentType: '',
      state: '',
      documentIssuedState: '',
      documentIssuedDate: '',
      documentExpirationRemindDays: 0,
      documentFile: '',
      documentIsGOVIssued: '',
      documentIdRequired: '',
      documentExpires: '',
      desc: ''
    },
    validators: {
      carrier: [VALIDATE_REQUIRED],
      documentType: [VALIDATE_REQUIRED],
      documentID: [VALIDATE_REQUIRED],
      state: [VALIDATE_REQUIRED],
      documentType: [VALIDATE_REQUIRED],
      documentIssuedState: [VALIDATE_REQUIRED],
      documentIssuedDate: [VALIDATE_REQUIRED],
      documentExpirationDate: [],
      documentFile: [VALIDATE_FILE],
      
      desc: [],
    }
  }
class DocumentPopUpView extends FListDetailView {

    constructor(props) {
        let data = PROPS
        super(props, PROPS)
        let errorData = {}
        this.setState(prevState => {
              let newState = {
                    ...prevState,
                    // errorData: errorData ,
                    data: {
                    ...prevState.data, ...this.props.rowData
                    }
              }
        return newState
        })
        
        // this.setState({data: this.props.rowData}) // this.setState({data by props.rowData})
      }

    componentDidMount = () => {};

    fetchListData = () => {};

    
    getDocumentType = () => {
        let rawData = this.props.rowData
        return  rawData.documentType || rawData.data 
    }
    
    getChoice = () => {
        let dType = this.getDocumentType()
        let documentChoices = this.getDocumentChoices()
        let choiceConf = getChoice(documentChoices, dType)
        return choiceConf || {}
    }

    getOwnerInfo = () => {
        return this.props.rowData.prettyName
    }

    getOwnerData = () => {
        let owner = this.props.rowData.db_owner || 'id'
        // let owner = this.props.rowData.path || 'id'
        return {
            [owner]: this.props.rowData.id,
            documentType: this.getDocumentType()
        }
    }

    getDocumentChoices = () => {
        let documentOwner = this.props.rowData.db_owner
        return this.props.[`document_${documentOwner}_choices`]

        // if (!!!documentOwner){
        //     documentOwner = this.props.rowData.ownerInfo && this.props.rowData.ownerInfo.documentFor
        // }
        // switch (documentOwner) {
        //   case CONST.CHOICE_DISPATCHER:
        //     return this.props.document_dispatcher_choices
        //   case CONST.CHOICE_DRIVER:
        //     return this.props.document_driver_choices
        //   case CONST.CHOICE_VEHICLE:
        //     return this.props.document_vehicle_choices
        //   case CONST.CHOICE_CARRIER:
        //     return this.props.document_carrier_choices
        //   case CONST.CHOICE_FLEET:
        //     return this.props.document_fleet_choices
        //   case CONST.CHOICE_CUSTOMER:
        //     return this.props.document_customer_choices
        // }
    }
    
    isAddAction = () => {
        return (this.props.rowAction == CONST.ACTION_ADD_DOCUMENT)
    }
    documentFileHasError = () => {
            if (this.isAddAction()) {
                  return !isValid(this.state.data.documentFile ,  this.state.validators.documentFile)
            }
    }
    documentIDHasError = () => {
        if (!this.showID()) {
            return false
        }
        if (this.isAddAction()) {
            return !isValid( this.state.data.documentID , this.state.validators.documentID)
        }
        return !isValid(this.getDocumentID(), this.state.validators.documentID)
    }
    getErrorData = () => {
        let errorData = {
    
              documentFile:  this.documentFileHasError(),
              documentID: this.documentIDHasError(),
              documentIssuedState: this.showIssuedState() &&
                            !isValid(this.getIssuedState(), this.state.validators.documentIssuedState),
              documentIssuedDate: !isValid(this.getDocumentIssuedDate(),
                         this.state.validators.documentIssuedDate)
          }
       
        return errorData
    }
    
    onSaveData = (evt) => {
        this.validate().then(succ => {
           let data = this.state.data
           let touched = this.state.touched
           touched = {...touched, ...this.getOwnerData()}
           data = {...data, ...this.getOwnerData()}

           this.props.notifyAction(this.props.documentAction, data, touched)
           this.notifyDialogClose()
            // this.setState({ dialogEditMode: false})
        }).catch(err => {
               showErroNotification(err, 'Invalid Input, please provide valid data!')
        })
    }
 


    notifyDialogClose = () => {
        this.setState({data: PROPS.data, touched: PROPS.touched, errorData: PROPS.errorData }, ()=> {
             this.props.notifyDialogClose()
        })
      };

   

    getDocumentIssuedDate = () => {
        return  this.state.data.documentIssuedDate || this.props.rowData.documentIssuedDate
    }

    getDocumentID = () => {
        return this.state.data.documentID || this.props.rowData.documentID

    }
      
    showIssuedState = () => {
        return this.state.data.documentIsGOVIssued || this.props.rowData.documentIsGOVIssued ||   this.getChoice().choiceIsGOVIssued
    }

    getIssuedState = () => {
        return this.state.data.documentIssuedState || this.props.rowData.documentIssuedState || this.props.rowData.documentIssuedState
    }

    getLabelID = () => {
        return this.getChoice().choiceIdLabel
    }

    getDocumentFile = () => {
        return this.state.data.documentFile || this.props.rowData.documentFile
    }

    getDocumentState = () => {
        return this.state.data.state || this.props.rowData.state
    }
    
    getDesc = () => {
        return this.state.data.desc || this.props.rowData.desc
    }


    showExpirationDate = () => {
       return this.state.data.documentExpires || this.props.rowData.documentExpires || this.getChoice().choiceExpires
    }

    getExpirationDate = () => {
       return this.state.data.documentExpirationDate || this.props.rowData.documentExpirationDate
    }
    

    showID = () => {
        let showId =  this.state.data.documentIdRequired || this.props.rowData.documentIdRequired ||   this.getChoice().choiceIdRequired
        return showId
    }

   
  render() {
    const title = this.props.title || 'Contacts'
    const { classes } = this.props;
    return (
          <div>
          <FDialog
            maxWidth='xl'
            fullWidth={false}
            title={title}
            owner={this.props.owner}
            openDialog={this.props.openDialog}
            notifyDialogClose={this.notifyDialogClose}>
              
                <Grid container direction='column' spacing={12}>
                   <Grid item xs>
                            <FInput
                              name='documentID'
                              disabled ={true}
                              value={this.getOwnerInfo()}
                              label={'Document For'}
                            />
                        </Grid>
                    
                    <Grid item xs >
                                <FInput
                                name='documentType'
                                value={this.getDocumentType()}
                                // value={this.props.rowData.documentType}
                                disabled ={true}
                                hasError={this.state.errorData.documentType}
                                helpMessage={this.state.errorMessage.documentType}
                                label='Document Type'
                                notifyParent={this.updateValues}
                                 />
                        </Grid>

                  <Grid container  spacing={12}>
                 </Grid>
                 <Grid container  spacing={12}>
                      <Grid item xs>
                          <FDate
                            name='documentIssuedDate'
                            required={false}
                            defaultValue={this.getDocumentIssuedDate()}
                            hasError={this.state.errorData.documentIssuedDate}
                            helpMessage={this.state.errorMessage.documentIssuedDate}
                            label="Document Issued Date "
                            notifyParent={this.updateValues} />
                      </Grid>

                  </Grid>
                  <Grid container  spacing={12}>
                       <Grid item xs>
                          <FCheckBox
                              name='documentIdRequired'
                              // value={this.state.data.documentIdRequired ||  this.showID(this.state.data.documentType)}
                              value={ this.showID()}
                              notifyDefault={true}
                              hasError={this.state.errorData.documentIdRequired}
                              helpMessage={this.state.errorMessage.documentIdRequired}
                              label="Document Has ID"
                              notifyParent={this.updateValues} />
                        </Grid>
                        <Grid item xs hidden={!this.showID()}>
                            <FInput
                              name='documentID'
                              disabled ={!(this.showID()) }
                              value={this.getDocumentID()}
                              hasError={this.state.errorData.documentID}
                              helpMessage={this.state.errorMessage.documentID}
                              label={this.getLabelID(this.state.data.documentType)}
                              notifyParent={this.updateValues} />
                        </Grid>
                  </Grid>
                  <Grid container  spacing={12}>
                       <Grid item xs>
                          <FCheckBox
                              name='documentIsGOVIssued'
                              notifyDefault={true}
                              value={this.showIssuedState()}
                              hasError={this.state.errorData.documentIsGOVIssued}
                              helpMessage={this.state.errorMessage.documentIsGOVIssued}
                              label="State issued"
                              notifyParent={this.updateValues} />
                        </Grid>
                      <Grid item xs hidden={!this.showIssuedState()}>
                            <FSelect
                              name='documentIssuedState'
                              disabled ={!(this.showIssuedState()) }
                              value={ this.getIssuedState()}
                              hasError={this.state.errorData.documentIssuedState}
                              helpMessage={this.state.errorMessage.documentIssuedState}
                              label='Document Issued State'
                              data={this.props.countryStates}
                              valueRef='value'
                              keyRef='prettyName'
                              notifyParent={this.updateValues} />
                      </Grid>
               </Grid>
               <Grid container  spacing={12}>
                      <Grid item xs>
                          <FCheckBox
                              name='documentExpires'
                              notifyDefault={true}
                              value={this.showExpirationDate()}
                              hasError={this.state.errorData.documentExpires}
                              helpMessage={this.state.errorMessage.documentExpires}
                              label="Document Expires"
                              notifyParent={this.updateValues} />
                        </Grid>
                      <Grid item xs hidden={!this.showExpirationDate()}>
                        <FDate
                          disabled ={!this.showExpirationDate() }

                          name='documentExpirationDate'
                          defaultValue={this.getExpirationDate()}
                          hasError={this.state.errorData.documentExpirationDate}
                          helpMessage={this.state.errorMessage.documentExpirationDate}
                          label="Document Expire Date "
                          notifyParent={this.updateValues} />
                      </Grid>
              </Grid>
             
              <Grid item xs>
                    <FFileUpload
                        value={this.getDocumentFile()}
                        name='documentFile'
                        label="Upload Document"
                        hasError={this.state.errorData.documentFile}
                        helpMessage={this.state.errorMessage.documentFile}
                        notifyParent={this.updateValues} />
              </Grid>
              <Grid item xs>
                    <FSelect
                        name='state'
                        value={this.getDocumentState() }
                        hasError={this.state.errorData.state}
                        helpMessage={this.state.errorMessage.state}
                        label='Document state'
                        data={this.props.documentStateChoice}
                        valueRef='prettyName'
                        keyRef='prettyName'
                        notifyParent={this.updateValues} />
              </Grid>
              <Grid item xs>
                <FInput
                  name='desc' 
                  value={this.getDesc() } 
                  multiline={true}
                  hasError={this.state.errorData.desc}
                  helpMessage={this.state.errorMessage.desc}
                  label="Description" 
                  notifyParent={this.updateValues} />
              </Grid>
              <Grid container direction='row' xs={8} justify='space-around'>
               
                {this.getUpdateButton(this.props.profile, this.props.policy_id)}

              </Grid>
            </Grid>
          </FDialog>
       </div>
    )
  }
}


export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps)(DocumentPopUpView))
