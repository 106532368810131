import React from 'react';
import MaterialTable from 'material-table'
import { getPrettyDate } from '../../util';
import { FMaterialSimpleTable } from '../widgets/FMaterialTable';
import { getBankCardStatus, getTrueOrFalse } from '../../util/icon_util';


class BankCards extends React.Component {

  

  render() {
    return (
      <div style={{height: 100}}>
        <FMaterialSimpleTable
          columns={[
            { title: 'Last 4', field: 'lastFourDigit' },         
            { title: 'Exp', render: rowData => `${rowData.expiryMonth}/${rowData.expiryYear}` },
            { title: 'ZipCode', field: 'zipCode' },
            { title: 'Reference Id', field: 'referenceId' },
            {
              title: 'Status', 
              render: rowData => {
                return (
                  <React.Fragment>
                      {getBankCardStatus(rowData)}
                  </React.Fragment>
                )
              }
            },
            {
              title: 'Primary Card ? ', 
              render: rowData => {
                return (
                  <React.Fragment>
                      {getTrueOrFalse(rowData && rowData.isDefault, 'success', 'info')}
                  </React.Fragment>
                )
              }
            },
          ]}
           data={this.props.data}
          title={`Payment Methods`}
        />
      </div>
    );
  }
}

export default BankCards;