import React, { Component } from 'react'
import ListItemIcon from '@material-ui/core/ListItemIcon';

import { mapStateToProps } from '../../fredux/ActionMaker';
import { connect } from 'react-redux'
import {  has_view_permission} from '../../util/ACL';
import * as CONST from "../../util/Const";
import FListItem from '../widgets/FListItem';


export class ReportListItems extends Component {
    render() {
        const profile = this.props.profile
        return (
            <div>
            <FListItem  
                disabled={!has_view_permission(profile, CONST.REPORT_DRIVERS)}
               to={CONST.ROUTE_DRIVER_REPORT_URL} label='Drivers'>
               <ListItemIcon>RDR</ListItemIcon>
            </FListItem>

            <FListItem  
                disabled={!has_view_permission(profile, CONST.REPORT_VEHICLES)}
               to={CONST.ROUTE_VEHICLE_REPORT_URL} label='Vehicles'>
               <ListItemIcon>RVH</ListItemIcon>
            </FListItem>
            <FListItem  
            disabled={!has_view_permission(profile, CONST.REPORT_FLEETS)}
              to={CONST.ROUTE_FLEET_REPORT_URL} label='Fleets'>
               <ListItemIcon>RFL</ListItemIcon>
            </FListItem>
            <FListItem  
                disabled={!has_view_permission(profile, CONST.REPORT_EXPENSES)}
                to={CONST.ROUTE_EXPENSE_REPORT_URL} label='Expenses'>
               <ListItemIcon>REXP</ListItemIcon>
            </FListItem>
            
            <FListItem  
                disabled={!has_view_permission(profile, CONST.REPORT_INVOICES)}
               to={CONST.ROUTE_INVOICE_REPORT_URL} label='Invoices'>
               <ListItemIcon>RINVS</ListItemIcon>
            </FListItem>
            
            <FListItem  
               disabled={!has_view_permission(profile, CONST.REPORT_MAINTENANCE_RECORD)}
               to={CONST.ROUTE_MAINTENANCE_RECORD_REPORT_URL} label='Maintenance Records'>
               <ListItemIcon>RMR</ListItemIcon>
            </FListItem>

            <FListItem  
                disabled={!has_view_permission(profile, CONST.REPORT_MAINTENANCE_SCHEDULE)}
               to={CONST.ROUTE_MAINTENANCE_SCHEDULE_REPORT_URL} label='Maintenance Schedules'>
               <ListItemIcon>RMR</ListItemIcon>
            </FListItem>

            <FListItem  
                disabled={!has_view_permission(profile, CONST.REPORT_DRIVER_DISCIPLINARY)}
                to={CONST.ROUTER_DRIVER_DISCIPLINARY_EVENT_REPORT_URL} label='Disciplinary Report'>
               <ListItemIcon>DDR</ListItemIcon>
            </FListItem>

            {/* {has_view_permission(this.props, CONST.ROUTE_DOCUMENT_REPORT_URL) && 
            <FListItem  to={CONST.ROUTE_DOCUMENT_REPORT_URL} label='Documents'>
               <ListItemIcon>RDOC</ListItemIcon>
            </FListItem>} */}

            
            <FListItem 
              disabled={!has_view_permission(profile, CONST.REPORT_IFTA_SUMMARY)}
              to={CONST.ROUTE_IFTA_SUMMARY_REPORT_URL} label='Ifta State Summary'>
               <ListItemIcon>RIS</ListItemIcon>
            </FListItem>

            
        </div>
        );
    }
}


export default connect(mapStateToProps)(ReportListItems)
