import React from 'react'
import ExpenseReportHeader from './ExpenseFilter';
import { TAB_REPORT, API_REPORT_EXPENSE } from '../../../util/Const';
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";
import { mapDispatchToProps, mapStateToProps } from "../../../fredux/ActionMaker";
import Expenses from './Expenses';
import BaseReport from '../BaseReport';
import { getDate } from '../../../util';


const styles = theme => ({
  reimbursablePercentage : {
      marginRight: 2*theme.spacing(1),

  },
});

class ExpenseReport extends BaseReport {
  // tableRef = React.createRef();

  constructor(props) {
    let state = {
      listUrl: API_REPORT_EXPENSE, 
      downloadUrl: API_REPORT_EXPENSE + 'csv/?',
      reportFileName: 'Expenses_report',
      data: {
        reportStartDate: getDate(new Date(), 90),
        reportEndDate: getDate(new Date()),
        driver: {},
        carrier: {},
        vehicle: {},
        dispatcher: {},
        expenseType: '',
        expenseFor: ''
      },
      errorData: {},
      errorMessage: {},
      validators: {}
    }
    super(props, state)
    this.currentTitle = 'Report Expenses'
    this.currentTab = TAB_REPORT
  }

  

  updateFilter = () => {
    let url =  `expenseTransactionDate__gte=${this.state.data.reportStartDate}`
    url += `&expenseTransactionDate__lte=${this.state.data.reportEndDate}`
    if (!!(this.state.data.carrier && this.state.data.carrier.id)) url += `&carrier=${this.state.data.carrier.id}`
    if (!!(this.state.data.carrier && this.state.data.driver && this.state.data.driver.id)) url += `&driver=${this.state.data.driver.id}`
    if (!!(this.state.data.carrier &&  this.state.data.vehicle && this.state.data.vehicle.id)) url += `&vehicle=${this.state.data.vehicle.id}`
    if (!!(this.state.data.expenseFor)) url += `&expenseFor=${this.state.data.expenseFor}`
    if (!!(this.state.data.expenseType)) url += `&expenseType=${this.state.data.expenseType.prettyName}`

    const that = this
    this.setState({filter: url}, ()=>{
      that.tableRef.current && that.tableRef.current.onQueryChange();
    })
  }


  render() {
    // this.getCurrentTitle( 'Report Expenses', TAB_REPORT )
    const { classes } = this.props;

    return (
      <div >

          {/* {this.state.listErrCode != 403 &&   */}
          
          <ExpenseReportHeader 
                disabled={this.state.listErrCode == 403}
                data={this.state.data} 
                errorMessage={this.state.errorMessage}
                errorData={this.state.errorData}
                validators={this.state.validators}
                updateValues={this.updateValues}
                onDownload={this.onDownload}
            />
          {/* } */}

            <Expenses 
                pstate={this.state} 
                tableRef={this.tableRef}
                fetchData={this.fetchData} 
                listErrCode={this.state.listErrCode}/>
      </div>
    )
  }
}


export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps)(ExpenseReport))
