import React from 'react'
import FuelExpenses from './FuelExpenses'
import Advances from './Advances'

import { Grid, Paper } from "@material-ui/core"
import FuelImportSummary from './Summary';
import FuelImportFilterView from './FuelImportFilterView';
import { API_FUEL_IMPORT_UPLOAD_URL, API_FUEL_IMPORT_SYNC, TAB_EXPENSE, SAVE_FUEL_IMPORT } from '../../util/Const';
import { getErrroMessage, getSuccessMessage,  showSuccessNotification, showErroNotification } from '../widgets/FMessage';
import { UPDATE,  GET_RESULT} from '../../util/FAPI';
import FButton from '../widgets/FButton';
import { connect } from 'react-redux'
import { mapDispatchToProps, mapStateToProps } from '../../fredux/ActionMaker';
import { isValidFee } from '../../util/Validator';
import { FRouteBackLink } from '../widgets/FRouterLink';
import FDialog from '../widgets/FDialog';
import FMaterialTable from '../widgets/FMaterialTable';
import {Labels} from "../../localizations/Resource"

const initState = {
  dataImportFilter: {},
  dataImportMetaData: {},
  dataSrc: 'UN_KNOWN',
  showErrors: false
}

class FuelImportDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = initState
  }

  getFormData = () => {
    let formData = new FormData();
    formData.append('dataImportStartDate', this.state.dataImportFilter.dataImportStartDate)
    formData.append('dataImportEndDate', this.state.dataImportFilter.dataImportEndDate)
    formData.append('dataImportSource', this.state.dataImportFilter.dataImportSource)
    formData.append('dataImportRawFile', this.state.dataImportFilter.dataImportRawFile)
    formData.append('dataImportType', this.state.dataImportFilter.dataImportType)
    formData.append('carrier', this.state.dataImportFilter.carrier.id)

    return formData
  }

  saveFuelImport = () => {
    let data = new FormData();
    data.append('dataImport', this.state.id)

    this.setState({ loading: true })
    UPDATE(API_FUEL_IMPORT_SYNC, 'post', data).
      then(response => {
        this.setState({ loading: false })
        showSuccessNotification(response, SAVE_FUEL_IMPORT)
      }).catch(error => {
        showErroNotification(error, SAVE_FUEL_IMPORT)
        this.setState({ loading: false })
        // this.props.updateServerError({ msg: getErrroMessage(error) })
      });
  }

  getPaycheckFilter = () => {
    let url = `?carrier=${this.state.dataImportFilter.carrier.id}&`
        url += `status=${'COMPLETE'}`
        
       return url
      }

  fetchFilter = (dataImportFilter) => {
    this.setState(s => {
      return { ...initState, dataImportFilter: dataImportFilter }
     }, () => {
      UPDATE(API_FUEL_IMPORT_UPLOAD_URL, 'post', this.getFormData()).then(res => {
        let data = GET_RESULT(res)
        this.setState(prevState => {
          let newState = { ...prevState, ...data }
          return newState
        }, () => {

        }, this)
      }).catch(error => {
         showErroNotification(error, SAVE_FUEL_IMPORT)
      })
    })
  }

  isValidFuelImport = () =>{
    if (!!!this.state.dataImportMetaData) {
      return false
    }
    const hasFuelTrans =  isValidFee(this.state.dataImportMetaData.totalAmount)
    const hasAdvance = isValidFee(this.state.dataImportMetaData.totalFuelCashAdvance)

    return (hasFuelTrans || hasAdvance)
    
  }

  getFuelTransactions = () =>{
    const data =  this.state.dataImportParsedData || []
    return data.filter(tran => !tran.hasFuelCashAdvance)
  }

  getAdvanceTransactions = () =>{
    const data =  this.state.dataImportParsedData || []
    return data.filter(tran => tran.hasFuelCashAdvance)
  }

  getFuelTransactionsSummary = () =>{
    return this.state.dataImportMetaData || {}
  }

  getCurrentTitle = () => {
    let title = Labels.IMPORT_FUEL_PAGE_TITLE
    this.props.updateCurrentTab(TAB_EXPENSE)
    this.props.updateCurrentTitle && this.props.updateCurrentTitle(title)
  }

  onDataSrcUpdate = (src) => {
    this.setState({dataSrc: src})
  }
  
  onShowErrors =  (show) => {
    this.setState({showErros: show})
  }

  fleetOneEdgeFormatDisplay = () => {
    return (
      <Grid container  xs={12} > 
          <Grid item xs={12}>
            <p dangerouslySetInnerHTML={{__html: Labels.IMPORT_FLEET_ONE_EDGE_HELP}}/>
          </Grid>
      </Grid>
    )
  }

  rtsFormatDisplay = () => {
    return (
      <Grid container  xs={12} > 
          <Grid item xs={12}>
            <p dangerouslySetInnerHTML={{__html: Labels.IMPORT_FLEET_ONE_EDGE_HELP}}/>
          </Grid>
      </Grid>
    )
  }

  flatRateFormatDisplay = () => {
    return (
      <Grid container  xs={12} > 
          <Grid item xs={12}>
            <p dangerouslySetInnerHTML={{__html: Labels.IMPORT_FLEET_RATE_V1_HELP}}/>
          </Grid>
      </Grid>
    )
  }

  flatRateV1FormatDisplay = () => {
    return (
      <Grid container  xs={12} > 
          <Grid item xs={12}>
            <p dangerouslySetInnerHTML={{__html: Labels.IMPORT_FLEET_RATE_HELP}}/>
          </Grid>
      </Grid>
    )
  }


  tcsFormatDisplay = () => {
    return (
      <Grid container  xs={12} > 
          <Grid item xs={12}>
            <p dangerouslySetInnerHTML={{__html: Labels.IMPORT_TCS_HELP}}/>
          </Grid>
      </Grid>
    )
  }


  ztruckingFormatDisplay = () => {
    return (
      <Grid container  xs={12} > 
          <Grid item xs={12}>
            <p dangerouslySetInnerHTML={{__html: Labels.IMPORT_UNKOWN_FUEL_HELP}}/>
          </Grid>
      </Grid>
    )
  }

  getErrorSummary = () => {
    return (<FDialog
            // maxWidth='xl'
            title={Labels.IMPORT_FUEL_ERROR_TITLE}
            // owner={this.props.owner}
            openDialog={this.state.showErros}
            width={700}
            titleColor={'secondary'}
            notifyDialogClose = {() => this.setState({showErros: false})}>
          
                <FMaterialTable
                        columns = {[ 
                            {title: 'Error', field: 'error',
                             cellStyle: {
                              // backgroundColor: '#039be5',
                              color: '#f50057'
                            },},
                            {title: Labels.DATE, field: 'date'},
                            {title: Labels.FUEL_CARD, field: 'card'},
                            {title: Labels.FUEL_LOCATION, field: 'location'},
                            {title: Labels.CITY, field: 'city'},
                            {title: Labels.JURISDICTION, field: 'jurisdiction'},
                            {title: Labels.FUEL_QUANTITY, field: 'qty'},
                            {title: Labels.FUEL_AMOUNT, field: 'amt'},
                            {title: Labels.FUEL_TRANSACTION_ID, field: 'transactionId'}
                          ]}
                        
                        data={this.state.dataImportMetaData && this.state.dataImportMetaData.errors || []}
                        options = {{
                            search: true,
                            showTitle: false,
                            toolbar: true,
                            paging: true,
                            sorting: false,
                            // maxBodyHeight: 400,
                            pageSize:  5,
                            pageSizeOptions: [5],
                            headerStyle: {
                              backgroundColor: '#01579b',
                              color: '#FFF'
                            }

                        }}
          />
        </FDialog>)
  }

  render() {
    this.getCurrentTitle()
    return (
      <React.Fragment>
       
        <Grid container xs={12} spacing={2} direction='column'>
        {this.getErrorSummary()}
          <Grid item xs>
           {(this.state.dataSrc == 'FLAT_RATE_V1') && this.flatRateV1FormatDisplay()}
            {(this.state.dataSrc == 'FLAT_RATE') && this.flatRateFormatDisplay()}
            {(this.state.dataSrc == 'FLEET_ONE_EDGE') && this.fleetOneEdgeFormatDisplay()}
            {(this.state.dataSrc == 'TCS') && this.tcsFormatDisplay()}
            {(this.state.dataSrc == 'RTS') && this.rtsFormatDisplay()}
            {(this.state.dataSrc == 'ZTRUCKING' || this.state.dataSrc == '') && this.ztruckingFormatDisplay()}
          </Grid>
        
          <Grid container xs={12} spacing={2}  direction='column'>

            <Grid item xs>
            <FuelImportFilterView notifyParent={this.fetchFilter} notifyDataSrcUpdate={this.onDataSrcUpdate}/>
            </Grid>
            <Grid item xs>
              <FuelExpenses data={this.getFuelTransactions()} />
            </Grid>
            <Grid container xs={12} spacing={2}>
                <Grid item xs={8}>
                  <Advances data={this.getAdvanceTransactions()} />
                </Grid>
                <Grid item xs={4}>
                  <FuelImportSummary dataImportMetaData={this.getFuelTransactionsSummary()} onShowErros={this.onShowErrors}  />
                </Grid>
            </Grid>
          </Grid>
          <Grid container xs={12} justify="center" style={{paddingTop: '10px'}}>
          
            {/* <Grid item xs={3} > */}
              <FButton color='primary'
                disabled={!this.isValidFuelImport()}
                label={Labels.IMPORT_FUEL_SAVE}
                loading={this.state.loading}
                onClick={this.saveFuelImport}
              />
          {/* </Grid> */}

            <Grid item style={{ paddingTop: '9px' }}>
                <FRouteBackLink variant='outlined' label={Labels.BACK_BUTTON}  onShowErros={this.onShowErrors}  color='secondary' className={'backButton'} />
            </Grid>
          </Grid>
        </Grid>
        </React.Fragment>
    )
  }
}


export default connect(
  mapStateToProps, mapDispatchToProps)(FuelImportDetail)
