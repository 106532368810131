import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@material-ui/core/Typography';
import FolderIcon from '@material-ui/icons/Folder';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { IconButton } from 'material-ui';


// sx={{
//   display: 'flex',
//   flexWrap: 'wrap',
//   '& > :not(style)': {
//     m: 1,
//     width: 128,
//     height: 128,
//   },
// }}
// >

// export default function FGroupBox(props) {
//   return (
//     <Box
//       sx={{
//         display: 'flex',
//         flexWrap: 'wrap',
//         '& > :not(style)': {
//           m: 1,
//           width: '100%',
//           height: '100%',
//         },
//       }}
//       >
//        <Paper elevation={props.elevation ||0} style={{width:'100%'}} >
//         <Typography variant="h5" color="primary"
//          style={{ backgroundColor: '#f7f8f9', paddingLeft: props.titlePaddingLeft}}>{props.groupTitle}</Typography>
//         &nbsp;
//         {props.children}
//         </Paper>
//     </Box>
//   );
// }

export default function FGroupBox(props) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 1,
          width: '100%',
          height: '100%',
        },
      }}
    >
      <Paper elevation={props.elevation || 0} >
        <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#fff', paddingLeft: props.titlePaddingLeft}}>
          <Typography variant="h5" color="primary">{props.groupTitle}</Typography>
          
          {props.info && 
            <div style={{ marginLeft: '8px', display: 'flex', alignItems: 'center' }}>
              <Tooltip title={props.info} placement="top-end">
                <InfoIcon 
                    color="primary" 
                    style={{ cursor: 'pointer' }} 
                />
              </Tooltip>
            </div>
          }
        </div>
        <div style={{ padding: '0rem' }}>
          {props.children}
        </div>
      </Paper>
    </Box>
  );
}


export function FGroupDocumentBox(props) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 1,
          width: '100%',
          height: '100%',
        },
      }}
      >
       <Paper elevation={props.elevation ||4} style={{width:'100%'}} >
        
        <Typography variant="h4"
          style={{  color:"#3366CC" , backgroundColor: '#FFF8DC', paddingLeft: '5px', paddingRight: '10px', paddingBottom: '7px'}}>
            <FolderIcon    style={{ fontSize: '2.2rem' }} />   &nbsp;
            {props.groupTitle || "Document Center"}
        </Typography>
       
        {props.children}
        </Paper>
    </Box>
  );
}