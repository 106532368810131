import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import FRouterLink, { FRouterAddLink } from '../widgets/FRouterLink';
import { ROUTE_CARRIERS_URL, API_CARRIERS_URL, CHOICE_CARRIER, API_CUSTOMER_URL, TAB_ADMIN, ADMIN_CARRIERS } from '../../util/Const';
import FListComponent from '../../util/FListComponent';
import { connect } from "react-redux"
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import FTableAction, { ACTION_TABLE_DELETE_ROW } from '../widgets/FTableAction';
import FMaterialTable from '../widgets/FMaterialTable';
import BlockIcon from '@material-ui/icons/Block';
import { Avatar } from 'material-ui';


const styles = theme => ({
  smallAvatar: {
    width: 10,
    height: 10,
    border: `2px solid ${theme.palette.background.paper}`,

  },
});

class Carriers extends FListComponent {
  constructor(props) {
    super(props, API_CARRIERS_URL, CHOICE_CARRIER)
    this.currentTitle = 'Carriers'
    this.currentTab = TAB_ADMIN
    this.routeUrl = ROUTE_CARRIERS_URL 
    this.policyID = ADMIN_CARRIERS
    this.includeStateAction = false

  }
  
  // getActions = (rowData) => {
  //   let actions = [ACTION_TABLE_DELETE_ROW]
  //   // rowData.state == 'ACTIVE'? actions.push(ACTION_TABLE_DEACTIVATE): actions.push(ACTION_TABLE_ACTIVATE)
  //   return actions
  // }
  
  render() {
    const { classes } = this.props;
    return (
      <div>
   
        <FMaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: 'Carrier', 
              render: this.getSelfCarrierLink
            },
            // {
            //   title: 'Carrier', 
            //   render: rowData => {
            //     const imageUrl = rowData.carrierProfilePicture ? rowData.carrierProfilePicture : "/truck.jpg";
            //     return (
            //       <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    
            //         <img 
            //             src={imageUrl}
            //             style={{ width: 40, height: 40, borderRadius: '10%', marginRight: '5px' }}
            //             />
            //         <FRouterLink editIcon={true} label={rowData.carrierName} to={ROUTE_CARRIERS_URL + rowData.id} />
            //       </div>
            //     )
            //   }
            // },
            // {
            //   title: 'Carrier Name', 
            //   render: rowData => {
                
            //     return (
            //       <React.Fragment>
            //         {/* <img src={rowData.carrierProfilePicture} style={{width: 50, borderRadius: '15%'}}/>
            //         &nbsp;&nbsp; */}
            //         <FRouterLink editIcon={true} label={rowData.carrierName} to={ROUTE_CARRIERS_URL + rowData.id} />
            //       </React.Fragment>
            //     )
            //   }
            // },
            { title: 'MC', field: 'carrierMC' },
            { title: 'DOT', field: 'carrierDOT'},
            { title: 'Email', field: 'carrierEmail' },
            { title: 'Phone', field: 'carrierPhoneNumber' },
            // { title: 'Operating Status', field: 'status' },
            { title: 'Carrier Type', field: 'carrierType' },
            // {
            //   title: 'Actions', 
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FTableAction 
            //             actions={this.getActions(rowData)}
            //             onTableAction = {this.onTableAction}
            //             rowData={rowData}
            //              />
            //       </React.Fragment>
            //     )
            //   }
            // },
            // {
            //   title: 'Actions', 
            //   render: rowData => {
            //     let actions  = this.getActions(rowData, ADMIN_CARRIERS)

            //     return (
            //       <React.Fragment>
            //         {!!actions.length && <FTableAction 
            //             actions={actions}
            //             onTableAction = {this.onTableAction}
            //             rowData={rowData}
            //              />
            //         }
            //         {!!!actions.length && <BlockIcon color="disabled" />}
            //       </React.Fragment>
            //     )
            //   }
            // },
          ]}
          actions={
            [
              this.getAddNewModelActions()
            ]
           }
            options = {{
              pageSize: this.state.pageSize,
              pageSizeOptions: this.state.pageSizeOptions,
              actionsColumnIndex: -1
           }}
          data={this.fetchData}
          title="Carriers"
          listErrCode={this.state.listErrCode}
        />
      </div>
    );
  }
}


export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps)(Carriers))
