
import * as ACTION from './ActionTypes'
import { isValid } from '../util/Validator';
import { AUTH_SIGNED_IN, AUTH_SIGN_OUT, AUTH_SIGN_UP, AUTH_SIGN_IN } from '../util/Const';


export const mapDispatchToProps = (dispatch) => {
  return {
    fetch: (data) => dispatch({
      type: ACTION.ACTION_UPDATE_DOCUMENT,
      payload: data
    }),
    
    updateCurrentDocument: (data) => dispatch({
      type: ACTION.ACTION_UPDATE_DOCUMENT,
      payload: data
    }),

    updateCurrentCarrier: (data) => dispatch({
          type: ACTION.ACTION_UPDATE_PARENT,
          payload: data
      }),

    updateServerSuccess: (data) => {
      const payloadData = { crudServerError: false, crudServerSuccess: data.msg }
      dispatch({
        type: ACTION.ACTION_UPDATE_SERVER_ERROR,
        payload: payloadData
      })
    },
    updateServerError: (data) => {
      const payloadData = { crudServerError: data.msg, crudServerSuccess: false }
      dispatch({
        type: ACTION.ACTION_UPDATE_SERVER_ERROR,
        payload: payloadData
      })
    },
    clearServerError: () => {
      const payloadData = { crudServerError: false, crudServerSuccess: false }
      dispatch({
        type: ACTION.ACTION_UPDATE_SERVER_ERROR,
        payload: payloadData
      })
    },
    updateCurrentTitle: (data) => {
      const payloadData = { currentTitle: data}
      dispatch({
        type: ACTION.ACTION_UPDATE_PARENT,
        payload: payloadData
      })
    },
    updateCurrentTab: (data) => {
      const payloadData = { currentTab: data}
      dispatch({
        type: ACTION.ACTION_UPDATE_PARENT,
        payload: payloadData
      })
    },
    resetServerValidation: () => {
      const payloadData = { crudServerError: '', crudServerSuccess: '' }
      dispatch({
        type: ACTION.RESET_SERVER_ERROR_VALIDAITON_STATE,
        payload: payloadData
      })
    },
    updateErrorState: (data) => {
      Object.entries(data).map(keyVal => {
        const payloadData = { [keyVal[0]]: !isValid(keyVal[0], keyVal[1]) }
        dispatch({
          type: ACTION.ACTION_UPDATE_ERROR_STATE,
          payload: payloadData
        })
      })
    },
    dispatchSignIn: () => {
      dispatch({
        type: ACTION.ACTION_UPDATE_PARENT,
        payload: {authStatus: AUTH_SIGN_IN, authToken:null}
      })
    },
    dispatchSignedIn: (authToken) => {
      dispatch({
        type: ACTION.ACTION_UPDATE_PARENT,
        payload: {authStatus: AUTH_SIGNED_IN, authToken:authToken}

      })
    },

    dispatchSignOut: () => {
      dispatch({
        type: ACTION.ACTION_UPDATE_PARENT,
        payload: {authStatus: AUTH_SIGN_OUT, authToken:null}
      })
    },
    dispatchSignUp: () => {
      dispatch({
        type: ACTION.ACTION_UPDATE_PARENT,
        payload: {authStatus: AUTH_SIGN_UP, authToken:null}
      })
    },
    updateParent: (data) => {
      dispatch({
        type: ACTION.ACTION_UPDATE_PARENT,
        payload: data
      })
    }

  }
}


export const mapStateToProps = (store) => {
  return store.appReducer
  let { drivers, carriers, vehicles, locations,
    expenses, expenseTypes, currentDocument, documentTypes } = store.appReducer
  locations = locations.slice(0, 10)
  return { drivers, carriers, vehicles, locations, expenses, expenseTypes, currentDocument, documentTypes }
}
