import React from 'react'
import { API_EXPIRING_DOCUMENT } from '../../../util/Const';
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";
import { mapDispatchToProps, mapStateToProps } from "../../../fredux/ActionMaker";
import { getDocumentOwnerLink } from '../../../util/icon_util';

import BaseCompliance from './index'


const styles = theme => ({
  reimbursablePercentage : {
      marginRight: theme.spacing(2),

  },
});

class ExpiringDocuments  extends BaseCompliance {
  // tableRef = React.createRef();
    constructor(props) {
    super(props, API_EXPIRING_DOCUMENT, 'Expiring Compliance Documents', 'EXPIRING_DOCUMENTS')
    this.showEdit = true
    this.showDelete = true
    this.showArchive = true
  }
   getOwnerLink = (rowData) => {
     return  getDocumentOwnerLink(rowData, this.props.profile)
  }

  render() {

    return (
      <React.Fragment> 
            {this.getDetail()}
        </React.Fragment>
    )
  }
}



export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps)(ExpiringDocuments))
