import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Paper } from 'material-ui';
import { roundDecimal } from '../../util';
import {Labels} from "../../localizations/Resource"
import { FButtonErrorLink } from '../widgets/FButtonLink';

const styles = theme => ({
  root: {
    margin: theme.spacing(2),
  },
  key: {
    margin: theme.spacing(2),    
  },
  val: {
    margin: theme.spacing(2),
  }
});

const getTotalLists = function (key, val, classes, valColor) {
  return (
    <Grid container xs={12} spacing={24} classname={classes.root}>
      <Grid item xs={8} >
        <Typography variant="h7" gutterBottom className={classes.key}>
          {key}
        </Typography>
      </Grid>
      <Grid item xs >
        <Typography variant="h7" color={valColor} gutterBottom className={classes.val}>
          {val}
        </Typography>
      </Grid>
       <hr/>
    </Grid>)
}

const getNet = function (key, val, classes, hasError) {
  let color = !!hasError? 'secondary': 'primary'
  return (
    <Grid container xs={12} spacing={24} classname={classes.root}>
      <Grid item xs={8} >
        <Typography variant="h6" gutterBottom className={classes.key} color={color}>
          {key}
        </Typography>
      </Grid>
      <Grid item xs >
        <Typography variant="h6" gutterBottom className={classes.val}  color={color}>
          {val}
        </Typography>
      </Grid>
       <hr/>
    </Grid>)
}


class FuelImportSummary extends React.Component {

  render() {
    const { classes } = this.props;
    const hasErrors = this.props.dataImportMetaData && !!this.props.dataImportMetaData.totalErrors

    // let transactionTotalAmount = `Total `
    return (
      <React.Fragment>
        <Paper>

          <Grid container justify="flex-start">
            <Typography variant="h5" gutterBottom className={classes.root}  fontSize={12}>
            {Labels.IMPORT_FUEL_SUMMARY}
            </Typography>
          </Grid>
          <hr />
          <Grid container xs={12} spacing={24} direction='column'>
            { hasErrors && 
                <div style={{padding: '10px'}}> <FButtonErrorLink   fontSize={12} notifyParent={this.props.onShowErros} label={Labels.IMPORT_FUEL_ERORR_MESSAGE}/></div>
            }
          {getTotalLists(Labels.IMPORT_FUEL_SUMMARY_TOTAL_FUEL_QUANTITY, roundDecimal(this.props.dataImportMetaData && this.props.dataImportMetaData.totalFuelQuantity), classes)}
          
          {getTotalLists(Labels.IMPORT_FUEL_SUMMARY_TOTAL_TRANSACTIONS, this.props.dataImportMetaData && this.props.dataImportMetaData.totalTransactions, classes)}
          {getTotalLists(Labels.IMPORT_FUEL_SUMMARY_TOTAL_FEES, roundDecimal(this.props.dataImportMetaData && this.props.dataImportMetaData.totalFees), classes)}
          {getTotalLists(Labels.IMPORT_FUEL_SUMMARY_TOTAL_CASH_ADVANCE, roundDecimal(this.props.dataImportMetaData && this.props.dataImportMetaData.totalFuelCashAdvance), classes)}
         
          <br/>
          {getTotalLists(Labels.IMPORT_FUEL_SUMMARY_TOTAL_DUPLICATES, this.props.dataImportMetaData && this.props.dataImportMetaData.totalDuplicates, classes, 'error')}
          {getTotalLists(Labels.IMPORT_FUEL_SUMMARY_TOTAL_UNKNOWN_FUEL_CARDS, this.props.dataImportMetaData && this.props.dataImportMetaData.totalUnRecogonizedFuelCards, classes, 'error')}
          {getTotalLists(Labels.IMPORT_FUEL_SUMMARY_TOTAL_ERRORS, this.props.dataImportMetaData && this.props.dataImportMetaData.totalErrors, classes, 'error')}

          {getNet(Labels.IMPORT_FUEL_SUMMARY_TOTAL, roundDecimal(this.props.dataImportMetaData.totalAmount), classes, hasErrors)}
          </Grid>
        </Paper>
        
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(FuelImportSummary);