import React from 'react'
import IftaSummaryReportHeader from './IftaSummaryFilter';
import { TAB_REPORT, API_REPORT_IFTA_SUMMARY } from '../../../util/Const';
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";
import { mapDispatchToProps, mapStateToProps } from "../../../fredux/ActionMaker";
import IftaSummarys from './IftaSummarys';
import BaseReport from '../BaseReport';
import { getDate } from '../../../util';
import { Grid } from "@material-ui/core";
import { GET, handleApiError } from '../../../util/FAPI';
import FGroupBox from "../../widgets/FGroupBox";
import {Labels} from "../../../localizations/Resource"
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  reimbursablePercentage : {
      marginRight: theme.spacing(2),

  },
  summary: {
    marginTop:  theme.spacing(11),
  }, 
  summaryKey: {
    paddingLeft:  theme.spacing(1),
    paddingRight:  theme.spacing(4),
  },
  summaryValue: {
    paddingLeft:  theme.spacing(1),
    paddingRight:  theme.spacing(2),
  }
});

class  IftaSummaryReport extends BaseReport {
  // tableRef = React.createRef();

  constructor(props) {
    let state = {
      pageSize: 57,
      pageSizeOptions: 57,
      reportFileName: 'ifta_summary_report',
      listUrl: API_REPORT_IFTA_SUMMARY, 
      downloadUrl: API_REPORT_IFTA_SUMMARY + 'csv/?',
      apiData: {},
      data: {
        showAllStates: false,
        iftaSummaryStartDate: getDate(new Date(), 90),
        iftaSummaryEndDate: getDate(new Date()),
        carrier: {},
      },
      errorData: {},
      errorMessage: {},
      validators: {}
    }
    super(props, state)
    this.currentTitle = 'Ifta Report'
    this.currentTab = TAB_REPORT
    
  }
  // Not Paginated since all th USA state data is popuulated
  fetchData = (query) => {
    query = query || {page: this.state.page}
    return new Promise((resolve, reject) => {
        // let url = ''
        // url = this.state.listUrl
        // if (!url.includes('?')) {
        //   url += '?'
        // }
        // let page = query.page + 1
        // url += `${this.state.filter}&per_page=${this.state.pageSize}&page=${page}`
      const that = this
      GET(`${this.state.listUrl}?${this.state.filter}`)                
        .then(response => {
         
          const res = response.data;  
          resolve({
            data: res.detail,
            page: 0,  
            totalCount: res.detail.length,
          })
          this.setState({apiData : res})
        }).catch(error => {
          let err = handleApiError(that.props, error)
          if (!!err.errCode) {
            this.setState({listErrCode :err.errCode})
          }
          this.setState({apiData : []})
          resolve({
            data: [],
            page: 0,  
            totalCount: 0,
          })
        })
    })
  }
  updateFilter = () => {
    let url = `ifta_summary_startDate__gte=${this.state.data.iftaSummaryStartDate}`
    url += `&ifta_summary_endDate__lte=${this.state.data.iftaSummaryEndDate}`
    url += `&showAllStates=${this.state.data.showAllStates}`
    if (!!(this.state.data.carrier && this.state.data.carrier.id)) url += `&carrier=${this.state.data.carrier.id}`
    this.refreshFilter(url)
        
  }

  render() {
    const { classes } = this.props;
    const { summary} = this.state.apiData || {}
    return (
      <Grid container className={classes.root} justify='space-between'  spacing= {2} xs={12} >
        <Grid item xs={8}>
          <Grid container className={classes.root}  direction='column' justify='space-between'  spacing= {2} xs={12} >
                <Grid item xs style={{height: '500px'}}>
                    <IftaSummaryReportHeader 
                      disabled={this.state.listErrCode == 403}
                        data={this.state.data} 
                        errorMessage={this.state.errorMessage}
                        errorData={this.state.errorData}
                        validators={this.state.validators}
                        updateValues={this.updateValues}
                        onDownload={this.onDownload}
                    />
                    </Grid>
                    <Grid item xs >

                        <IftaSummarys 
                            showAllStates = {this.state.data.showAllStates}
                            pstate={this.state} 
                            tableRef={this.tableRef}
                            fetchData={this.fetchData}
                            listErrCode={this.state.listErrCode} />
                      </Grid>
           </Grid>
           </Grid>
           <Grid item xs className={classes.summary}>
           <FKeyValList summary={summary} classes={classes}/>
          </Grid>
     </Grid>
    )
  }
}



function FKeyValList(props) {
  let summary = props.summary 
  const { classes } = props
  return (
    <FGroupBox groupTitle={Labels.IFTA_SUMMARY} elevation={1} >
    <Grid container direction='column' justify='space-between'  spacing= {2} xs={12} >
          <Grid item xs>
              <Typography variant="h8" gutterBottom className={classes.summaryKey} display="inline">Total Distance (Mi)   </Typography>
              <Typography variant="h6" className={classes.summaryValue}   display="inline">{summary && summary.totalDistance || 0}</Typography>
          </Grid>
          <Grid item xs>
              <Typography variant="h8" gutterBottom className={classes.summaryKey} display="inline">Total Fuel Quantity   </Typography>
              <Typography variant="h6" className={classes.summaryValue}   display="inline">{summary && summary.totalFuelQuantities || 0}</Typography>
          </Grid>
          <Grid item xs>
              <Typography variant="h8" gutterBottom className={classes.summaryKey} display="inline">Total Fuel Expense   </Typography>
              <Typography variant="h6" className={classes.summaryValue}   display="inline">{summary && summary.totalFuelExpense || 0}</Typography>
          </Grid>
    </Grid>
     </FGroupBox>
  );
}

export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps)( IftaSummaryReport))
