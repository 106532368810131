import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Snackbar } from 'material-ui';
import { store } from 'react-notifications-component';

const style = theme => ({
  root: {
    margin: theme.spacing(1),
  },
  succ: {
    color: 'primary'
  },
  err: {
    color: 'secondary'
  }
});

export const ERROR_CODE = {
  ERROR_PAGINATION: 'ERROR_PAGINATION',
  ERROR_NON_UNIQUE: 'ERROR_NON_UNIQUE',
  ERROR_REFERENCE_PROTECTION: 'ERROR_REFERENCE_PROTECTION',
  ERROR_MISSING_REQUIRED_FIELD: 'ERROR_MISSING_REQUIRED_FIELD',
  ERROR_INVOICE_NO_FLEET_ASSOCIATED_WITH_INVOICE: 'ERROR_INVOICE_NO_FLEET_ASSOCIATED_WITH_INVOICE',
  ERROR_ENTRY_TOO_LARGE:'ERROR_FILE_SIZE_TOO_LARGE_OVER_20MB',
  ERROR_INVALID_PHONE_NUMBER:'ERROR_INVALID_PHONE_NUMBER',
  ERROR_AUTHENTICATION:'ERROR_AUTHENTICATION',
  ERR_UPLOAD_FILE_CHANGED: 'ERR_UPLOAD_FILE_CHANGED',
  ERR_AUTH : 'ERR_AUTH'

  
}

export function getErrroMessage(errData) {
  let  msg = 'General - Error'
  if (errData && errData.response) {
    let resp =  JSON.stringify(errData.response.data)
    if(resp.includes('Invalid page')) msg =  ERROR_CODE.ERROR_PAGINATION
    if(resp.includes('unique set')) msg =  ERROR_CODE.ERROR_NON_UNIQUE
    if(resp.includes('protected foreign key')) msg =  ERROR_CODE.ERROR_REFERENCE_PROTECTION
    if(resp.includes('Required')) msg =  ERROR_CODE.ERROR_MISSING_REQUIRED_FIELD
    if(resp.includes('No Fleet Found on Invoice')) msg =  ERROR_CODE.ERROR_INVOICE_NO_FLEET_ASSOCIATED_WITH_INVOICE
    if(resp.includes('Too Large')) msg =  ERROR_CODE.ERROR_ENTRY_TOO_LARGE
    if(resp.includes('Invalid Phone Number')) msg =  ERROR_CODE.ERROR_INVALID_PHONE_NUMBER
    if(resp.includes('dispatcherEmail')) msg =  ERROR_CODE.ERROR_INVALID_PHONE_NUMBER
    if(resp.includes('MultiValueDictKeyError')) msg =  ERROR_CODE.ERROR_MISSING_REQUIRED_FIELD
    if(resp.includes('Invalid token')) {
      msg =  ERROR_CODE.ERR_AUTH
    }

    if(resp.includes('Incorrect authentication credentials')) {
      msg =  ERROR_CODE.ERROR_AUTHENTICATION
    }
    if(resp.includes('ERR_UPLOAD_FILE_CHANGED') || String(errData).includes('ERR_UPLOAD_FILE_CHANGED')) {
      msg =  "Please Reload Page, File in your system is changed"
    }

  }
  if (errData && errData.response && errData.response.data && errData.response.data.default_detail) {
     msg =  errData.response.data.default_detail
  }
  
  return msg
  // return JSON.stringify(errData.response.data)
} 

export function getSuccessMessage(res) {
  return 'Success !'
}


// success danger info default warning
export const openNotification = (description, title="",  type="default", placement="bottom-left") => {
  description = description || ""
  try{
      store.addNotification({
        title: title,
        message: description,
        type: type,
        insert: "top",
        container: placement,
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        showIcon: true,
        // width: description.length > 40 ? 400: 400,
        width: 400,
        dismiss: {
          duration: type == "danger"? 5000: 4500,
          pauseOnHover: true,
          // onScreen: true,
          showIcon: true
        }
      });
  } catch(e) {
     alert(`${title} - ${description}`)
  }
}

export const showSuccessNotification = (description, title="") => {
  description = getSuccessMessage(description)
  openNotification (description, title, "success", "bottom-left")
}
export const showErroNotification = (description, title="") => {
  description = getErrroMessage(description)
  openNotification (description, title, "danger", "bottom-left")
}
export const showInfoNotification = (description, title="") => {
  openNotification (description, title, "info", "bottom-left")
}
export const showWarningNotification = (description, title="") => {
  openNotification (description, title, "warning", "bottom-left")
}

export const showWarningPaymentNotification = (profile) => {
  openNotification ("description", "title", "warning", "top-right")
}

class FMessage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      vertical: 'top',
      horizontal: 'center',
    }
  }

  
  render() {
    // const { classes } = this.props
    const { vertical, horizontal } = this.state;
    const col = this.props.error ? 'red' : ''
    return (
      <React.Fragment>
        <Snackbar
          anchorOriginTopLeft
          open={this.props.error || this.props.success}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.props.error || this.props.success}</span>}
        />
      </React.Fragment>);

  }
}


export default withStyles(style)(FMessage)