import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import FButtonLink from './FButtonLink'
import FRouterLink from './FRouterLink';

import * as CONST from "../../util/Const";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: '100%',
    minHeight: 650,
    fontSize: theme.typography.pxToRem(12),
    border: '2px solid #dadde9',
  },
}))(Tooltip);




const styles = theme => ({
  vpSpacer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },

  hSpacer: {
    paddingTop: theme.spacing(2),
  },
  header: {
    color: 'green',
    paddingTop: theme.spacing(2),
  }
});

class FQuickLinks extends Component {
  constructor(props) {
    super(props)
    this.state ={
       open:false
    }
  }
  
  onOpen = () =>{
    this.setState({open:true})
  }
  
  getVSpacer = () =>{
    const { classes } = this.props;
    return (
        <span  className={classes.vpSpacer}>|</span> 
    )
  }

  onClose = () =>{
    this.setState({open:false})
  }


  getContent = () => {
    const { classes } = this.props;

    return (
              <React.Fragment> 
              <div>
                  <p>
                      <h2 className={classes.header}>  Admin & Record Keeping </h2>
                     <FRouterLink  notifyParent={this.onClose}   label={'Driver'} to={CONST.ROUTE_DRIVER_APP_DRIVER_PROFILE_URL} />  {this.getVSpacer()}

                     <FRouterLink  notifyParent={this.onClose}   label={'Truck & Trailers'} to={CONST.ROUTE_VEHICLE_URL} />  {this.getVSpacer()}

                     <FRouterLink  notifyParent={this.onClose}   label={'Dispatchers'} to={CONST.ROUTE_DISPATCHER_URL} />  {this.getVSpacer()}

                     <FRouterLink  notifyParent={this.onClose}   label={'Fuel Cards'} to={CONST.ROUTE_FUEL_CARD_URL} />  {this.getVSpacer()}

                     <FRouterLink  notifyParent={this.onClose}   label={'Carriers'} to={CONST.ROUTE_CARRIERS_URL} />  {this.getVSpacer()}

                     <FRouterLink  notifyParent={this.onClose}   label={'Fuel Card Provider'} to={CONST.ROUTE_FUEL_CARD_PROVIDER_URL} />  {this.getVSpacer()}

                     <FRouterLink  notifyParent={this.onClose}   label={'ELD Providers'} to={CONST.ROUTE_ELD_PROVIDER_URL} />  {this.getVSpacer()}

                     <FRouterLink  notifyParent={this.onClose}   label={'Drug Test'} to={CONST.ROUTE_DRUG_TEST_PROVIDER_URL} />  {this.getVSpacer()}

                     <FRouterLink  notifyParent={this.onClose}   label={'Auto Service '} to={CONST.ROUTE_AUTO_SERVICE_PROVIDER_URL} />  {this.getVSpacer()}

                     <FRouterLink  notifyParent={this.onClose}   label={'Former Driver Employers'} to={CONST.ROUTE_FORMER_EMPLOYER_URL} />  {this.getVSpacer()}

                     <FRouterLink  notifyParent={this.onClose}   label={'Generic Service Provider'} to={CONST.ROUTE_GENERIC_SERVICE_PROVIDER_URL} />  {this.getVSpacer()}

                     <FRouterLink  notifyParent={this.onClose}   label={'Freelancers & Contractors '} to={CONST.ROUTE_FREELANCER_AND_CONTRACTOR_URL} />

                  </p>

                  <p>
                      <h2  className={classes.header}> Fleet & Dispatching </h2>
                     <FRouterLink  notifyParent={this.onClose}   label={'Fleets'} to={CONST.ROUTE_FLEET_URL} />  {this.getVSpacer()}
                     <FRouterLink  notifyParent={this.onClose}   label={'Broker & Customers'} to={CONST.ROUTE_CUSTOMER_URL} />  {this.getVSpacer()}
                     <FRouterLink  notifyParent={this.onClose}   label={'ELD Trips'} to={CONST.ROUTE_TRIP_URL} />
                  </p>


                  <p>
                      <h2  className={classes.header}> Safety And Compliance </h2>
                     <FRouterLink  notifyParent={this.onClose}   label={'Expiring Documents'} to={CONST.ROUTER_EXPIRING_URL} />  {this.getVSpacer()}
                     <FRouterLink  notifyParent={this.onClose}   label={'Expired Documents'} to={CONST.ROUTER_EXPIRED_URL} />  {this.getVSpacer()}
                     <FRouterLink  notifyParent={this.onClose}   label={'Missing Documents'} to={CONST.ROUTER_MISSING_DOCUMENTS_URL} />  {this.getVSpacer()}

                     <FRouterLink  notifyParent={this.onClose}   label={'Drug Tests'} to={CONST.ROUTE_DRUG_TEST_URL} />  {this.getVSpacer()}
                     <FRouterLink  notifyParent={this.onClose}   label={'Driver Employment History'} to={CONST.ROUTE_EMPLOYEMENT_HISTORY_URL} />  {this.getVSpacer()}
                     <FRouterLink  notifyParent={this.onClose}   label={'Driver Resident History'} to={CONST.ROUTE_RESIDENT_HISTORY_URL} />  {this.getVSpacer()}
                      
                    <p>
                     <FRouterLink  notifyParent={this.onClose}   label={'Traffic Violations'} to={CONST.ROUTE_TRAFFIC_VIOLATION_URL} />  {this.getVSpacer()}
                     <FRouterLink  notifyParent={this.onClose}   label={'Accident Register'} to={CONST.ROUTE_ACCIDENT_REGISTER_URL} />  {this.getVSpacer()}
                     <FRouterLink  notifyParent={this.onClose}   label={'Driver Disciplinary Action'} to={CONST.ROUTE_DRIVER_DISCIPLINARY_EVENT_URL} />  {this.getVSpacer()}

                     <FRouterLink  notifyParent={this.onClose}   label={'Vehicle Inspection'} to={CONST.ROUTE_VEHICLE_INSPECTION_URL} />  {this.getVSpacer()}
                     <FRouterLink  notifyParent={this.onClose}   label={'Vehicle Maintenance'} to={CONST.ROUTE_VEHICLE_MAINTENANCE_URL} />  {this.getVSpacer()}
                     <FRouterLink  notifyParent={this.onClose}   label={'Preventive Maintenace Schedule'} to={CONST.ROUTE_MAINTENANCE_SCHEDULE_URL} />

                    </p>
                  </p>

                  <p>
                      <h2  className={classes.header}> Finance & Accounting </h2>
                     <FRouterLink  notifyParent={this.onClose}   label={'Driver Paycheck'} to={CONST.ROUTE_PAY_CHECK_URL} />  {this.getVSpacer()}
                     <FRouterLink  notifyParent={this.onClose}   label={'Customer Invoice'} to={CONST.ROUTE_INVOICE_URL} />  {this.getVSpacer()}
                     <FRouterLink  notifyParent={this.onClose}   label={'Expenses'} to={CONST.ROUTE_EXPENSE_URL} />  {this.getVSpacer()}

                     <FRouterLink  notifyParent={this.onClose}   label={'Recurring Expenses'} to={CONST.ROUTE_REC_EXPENSE_URL} />  {this.getVSpacer()}
                     <FRouterLink  notifyParent={this.onClose}   label={'Financial Dashboard'} to={CONST.ROUTE_FINANCIAL_DASHBOARD_URL} />  {this.getVSpacer()}

                  </p>

                  <p>
                      <h2  className={classes.header}> Reports & Downlods </h2>
                     <FRouterLink  notifyParent={this.onClose}   label={'Export Drivers / FMCSA'} to={CONST.ROUTE_DRIVER_REPORT_URL} />  {this.getVSpacer()}
                     <FRouterLink  notifyParent={this.onClose}   label={'Export Vehicles / FMCSA'} to={CONST.ROUTE_VEHICLE_REPORT_URL} />  {this.getVSpacer()}
                     <FRouterLink  notifyParent={this.onClose}   label={'Export Fleets'} to={CONST.ROUTE_FLEET_REPORT_URL} />  {this.getVSpacer()}

                     <FRouterLink  notifyParent={this.onClose}   label={'Export Expense / Quickbooks'} to={CONST.ROUTE_EXPENSE_REPORT_URL} />  {this.getVSpacer()}
                     <FRouterLink  notifyParent={this.onClose}   label={'Export Invoice History'} to={CONST.ROUTE_INVOICE_REPORT_URL} />  {this.getVSpacer()}
                     <FRouterLink  notifyParent={this.onClose}   label={'Export Maintenance'} to={CONST.ROUTE_MAINTENANCE_RECORD_REPORT_URL} />  {this.getVSpacer()}
                     <FRouterLink  notifyParent={this.onClose}   label={'Export Disciplinary Report'} to={CONST.ROUTER_DRIVER_DISCIPLINARY_EVENT_REPORT_URL} />  
                      
                  </p>

                  <p>
                      <h2  className={classes.header}> Taxes & Filing </h2>
                     <FRouterLink  notifyParent={this.onClose}   label={'IFTA Reporting'} to={CONST.ROUTE_IFTA_SUMMARY_REPORT_URL} /> 

                  </p>

                  <p>
                      <h2  className={classes.header}>Integration  & Data uploads</h2>
                     <FRouterLink  notifyParent={this.onClose}   label={'API Integration'} to={CONST.ROUTE_INTEGRATION_URL} />  {this.getVSpacer()}
                     <FRouterLink  notifyParent={this.onClose}   label={'Fuel Transaction Integration'} to={CONST.ROUTE_FUEL_IMPORT_URL} />  {this.getVSpacer()}
                     <FRouterLink  notifyParent={this.onClose}   label={'Trips & Mileage Integration'} to={CONST.ROUTE_MILEAGE_IMPORT_URL} />  
                  </p>

              </div>
              </React.Fragment>
    )
  }
  render() {
      const { classes } = this.props;
      return (
        <HtmlTooltip
              arrow={true}
              interactive = {true}
              open={this.state.open}
              onClose={this.onClose}
              placement={'top-start'}
              show={'click'}
              title={
                this.getContent()
              }
              >
            {/* <a color='primary' onClick={this.onOpen}>Quick Links</a> */}
            <FButtonLink notifyParent={this.onOpen} label='Quick Links'/>
            </HtmlTooltip>
      );
  }
}
export default withStyles(styles)(FQuickLinks)
