import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { withStyles } from '@material-ui/core/styles'
import { Typography, FormControl, FormHelperText } from '@material-ui/core';

import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';

import { FMAX_FILE_ALLOWED} from "../../util/Const";


const styles = theme => ({

  root: {
    marginLeft: theme.spacing(1),
    width: '100%',
    display: 'flex',
    // flexDirection: 'column'
  },

  file: {
    align: 'left',
    // maxWidth: '50px'
  },

  title: {
    margin: theme.spacing(1)
  },

  label: {
    width: '96%',

    align: 'left',
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    width: '96%'
  },
});


class FFileUpload extends Component {
  constructor(props) {
    super(props)
    this.state = {
      [props.name]: props.value,
      file_too_large: false
    }
  }

  onChange = (evt) => {
    const file = evt.target.files[0] || {}
    this.setState(__filename => {
      return { [this.props.name]: file.name, file: file, file_too_large: file.size > FMAX_FILE_ALLOWED}
    }, () => {
  
        this.props.notifyParent(this.props.name, { [this.props.name]: file })
      
    })
  }

  // componentDidMount = () => {
  //   this.props.notifyParent(this.props.name, { [this.props.name]: null })
  // }

  getPrettyName = () => {
    if (this.props.value) {
      let fName = typeof (this.props.value) === 'string' ?
        this.props.value : this.props.value && this.props.value.name
      
      return fName && fName.replace(/^.*(\\|\/|\:)/, '')
    }
  }

  render() {
    const { classes } = this.props
    return (

      <FormControl required={this.props.required} className={classes.formControl} disabled={this.props.disabled}>
        <Typography variant='caption' className={classes.title}>{this.props.label} </Typography>
        <div className={classes.root}>
          <TextField
            id={this.props.name}
            style={{ display: 'none' }}
            onChange={this.onChange}
            name={this.props.name}
            type="file"
          />
          <label htmlFor={this.props.name}
            className={classes.file}>
            <Button variant="outlined" className={classes.file} disabled={this.props.disabled}
              htmlFor={this.props.name}
              component="span"
              sizeSmall>
              <CloudUploadIcon className={classes.file} />
            </Button>
          </label>
          <TextField
            errorName={this.props.errorName}
            error={this.props.hasError}
            className={classes.label}
            value={this.getPrettyName()}
            InputProps={{
              readOnly: true,
              disabled: true
            }}
          />
        </div>
        <FormHelperText error>
         {this.state.file_too_large && `Max file size allowed ${FMAX_FILE_ALLOWED/1000000}MB!  `}
         {this.props.hasError && this.props.helpMessage}
          
        </FormHelperText>
      </FormControl>
    );

  }
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps)(FFileUpload)
)