import React from 'react'
import ExpenseReportHeader from './ExpenseFilter';
import { TAB_REPORT, API_REPORT_MAINTENANCE_RECORD } from '../../../util/Const';
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";
import { mapDispatchToProps, mapStateToProps } from "../../../fredux/ActionMaker";
import Expenses from './Expenses';
import BaseReport from '../BaseReport';
import { getDate } from '../../../util';


const styles = theme => ({
  reimbursablePercentage : {
      marginRight: theme.spacing(2),

  },
});

class MaintenanceReport extends BaseReport {
  // tableRef = React.createRef();

  constructor(props) {
    let state = {
      reportFileName: 'Expenses_report',
      listUrl: API_REPORT_MAINTENANCE_RECORD + '?expenseType=MAINTENANCE&', 
      downloadUrl: API_REPORT_MAINTENANCE_RECORD + 'csv/?expenseType=MAINTENANCE&',
      data: {
        reportStartDate: getDate(new Date(), 90),
        reportEndDate: getDate(new Date()),
        driver: {},
        carrier: {},
        vehicle: {},
        dispatcher: {},
        expenseMaintenanceCategory:''
      },
      errorData: {},
      errorMessage: {},
      validators: {}
    }
    super(props, state)
    this.currentTitle = 'Maintenance Record Report'
    this.currentTab = TAB_REPORT
  }

  

  updateFilter = () => {
    let url =  `expenseTransactionDate__gte=${this.state.data.reportStartDate}`
    url += `&expenseTransactionDate__lte=${this.state.data.reportEndDate}`
    if (!!(this.state.data.carrier && this.state.data.carrier.id)) url += `&carrier=${this.state.data.carrier.id}`
    if (!!(this.state.data.carrier && this.state.data.driver && this.state.data.driver.id)) url += `&driver=${this.state.data.driver.id}`
    if (!!(this.state.data.carrier &&  this.state.data.vehicle && this.state.data.vehicle.id)) url += `&vehicle=${this.state.data.vehicle.id}`
    if (!!(this.state.data.expenseMaintenanceCategory)) url += `&expenseMaintenanceCategory=${this.state.data.expenseMaintenanceCategory.choiceValue}`

    const that = this
    this.setState({filter: url}, ()=>{
      that.tableRef.current && that.tableRef.current.onQueryChange();
    })
  }


  render() {
    // this.getCurrentTitle( 'Report Maintenance Record', TAB_REPORT )
    const { classes } = this.props;

    return (
      <div >

            {/* {this.state.listErrCode != 403 &&  */}
            <ExpenseReportHeader 
                  disabled={this.state.listErrCode == 403}
                  data={this.state.data} 
                  errorMessage={this.state.errorMessage}
                  errorData={this.state.errorData}
                  validators={this.state.validators}
                  updateValues={this.updateValues}
                  onDownload={this.onDownload}
              />
             {/* } */}
            <Expenses 
                pstate={this.state} 
                tableRef={this.tableRef}
                fetchData={this.fetchData} 
                listErrCode={this.state.listErrCode}/>
      </div>
    )
  }
}


export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps)(MaintenanceReport))
