import React from 'react'
import { API_EXPIRED_DOCUMENT } from '../../../util/Const';
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";
import { mapDispatchToProps, mapStateToProps } from "../../../fredux/ActionMaker";
import { getDocumentOwnerLink } from '../../../util/icon_util';
import BaseCompliance from './index'


const styles = theme => ({
  reimbursablePercentage : {
      marginRight: 2*theme.spacing(1),

  },
});

class ExpiredDocuments  extends BaseCompliance {
  // tableRef = React.createRef();
    constructor(props) {
    super(props, API_EXPIRED_DOCUMENT, 'Expired Compliance Documents', 'EXPIRED_DOCUMENTS')
    this.showEdit = true
    this.showDelete = true
    this.showArchive = true
  }
   
  getOwnerLink = (rowData) => {
    return getDocumentOwnerLink(rowData, this.props.profile)
  }
 
  render() {

    return (
       <React.Fragment> 
            {this.getDetail()}
        </React.Fragment>
    )
  }
}

export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps)(ExpiredDocuments))
