import React from 'react';
import { Link } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';

// import FRouterLink, { FRouterAddLink } from '../widgets/FRouterLink';

import { getPrettyDate, getValueFromListOfDict } from '../../../util';
import FMaterialTable from '../../widgets/FMaterialTable';
import { getAlarmLevelStatusIcon } from '../../../util/icon_util';
// import FTableAction, { ACTION_TABLE_DELETE_ROW } from '../widgets/FTableAction';

export default class DriverDisciplinaryEvents extends React.Component {


  render() {
    return (
      <div>
        <FMaterialTable
          tableRef={this.props.tableRef}
          columns={[
            { title: 'Alarm Level',
                    render: rowData => 
                    getAlarmLevelStatusIcon(this.props.eventAlarmLevelChoices, rowData) },
                
            { title: 'Event', field: 'eventValue' },
            { title: 'Event Date',
                 render: rowData => getPrettyDate(rowData.eventDate) },
            
            { title: 'Corrective Action',
                    render: rowData => 
                        getValueFromListOfDict(this.props.eventCorrectiveActions, rowData.eventCorrectiveAction) },  
            
            { title: 'Desc', field: 'desc' },
                      ]
            }
          
          options = {{
            pageSize: this.props.pstate.pageSize,
            pageSizeOptions: this.props.pstate.pageSizeOptions,
            actionsColumnIndex: -1,
            search: false
         }}
          data={this.props.fetchData}
          listErrCode={this.props.listErrCode}
          // title={`DriverDisciplinaryEvents`}
        
        />
      </div>
    );
  }
}
