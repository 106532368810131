import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { mapDispatchToProps, mapStateToProps } from '../../fredux/ActionMaker';

const styles = theme => ({
  root: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  textLabel: {
    paddingTop: theme.spacing(1),
  },
  label: {
    marginBottom: theme.spacing(2), 
  },
  labelMultiline: {
    marginBottom: theme.spacing(4), 
  },
});


class FInput extends React.Component {

  onChange = (evt) => {
    const value = evt.target.value
    this.props.notifyParent(this.props.name, { [this.props.name]: value })
  }

  componentDidMount = () => {
    // this.props.notifyParent(this.props.name, { [this.props.name]: this.props.defaultValue })
     if (!!this.props.defaultValue){
        this.props.notifyParent(this.props.name, { [this.props.name]: this.props.defaultValue })
     }
  }

  render() {
    const { classes, multiline} = this.props
    return (
      <TextField
        name={this.props.name}
        error={this.props.hasError}
        helperText={
          this.props.hasError && this.props.helpMessage}
        disabled={this.props.disabled}
        required={this.props.required}
        label={this.props.label}
        onChange={this.onChange}
        type={this.props.type}
        multiline={this.props.multiline}
        rows={this.props.rows}
        onBlur={this.onBlur}
        style={{
          // backgroundColor: "blue",
        }}
        className={classes.text}
        InputLabelProps={{
          shrink: true,
          className: multiline ? classes.labelMultiline : classes.label, // Apply different class based on multiline

          // style: {
          //   marginBottom: '10px',
          //   // color: "white"
          // }
        }}
        InputProps={{
          className: classes.textLabel
        }}
        defaultValue={this.props.defaultValue}
        value={this.props.value}
        margin="normal"
        className={classes.root}
      />);
  }
}


export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps)(FInput)
)