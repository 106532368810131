// FFilterReport.js
import React from 'react';
import { Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
  },
});

const FFilterReport = ({ classes, children }) => {
  return (
    <Paper className={classes.paper} elevation={2}>
      {children}
    </Paper>
  );
};

export default withStyles(styles)(FFilterReport);
