import React from 'react'
import InvoiceReportHeader from './InvoiceFilter';
import { TAB_REPORT, API_REPORT_INVOICE } from '../../../util/Const';
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";
import { mapDispatchToProps, mapStateToProps } from "../../../fredux/ActionMaker";
import Invoices from './Invoices';
import BaseReport from '../BaseReport';
import { getDate } from '../../../util';


const styles = theme => ({
  reimbursablePercentage : {
      marginRight: theme.spacing(2),

  },
});

class InvoiceReport extends BaseReport {
  // tableRef = React.createRef();

  constructor(props) {
    let state = {
      reportFileName: 'invoices_report',
      listUrl: API_REPORT_INVOICE, 
      downloadUrl: API_REPORT_INVOICE + 'csv/?',
      data: {
        payCheckFleetStartDate: getDate(new Date(), 90),
        payCheckFleetEndDate: getDate(new Date()),

        customer: {},
        carrier: {},
        invoiceStatus: ''
      },
      errorData: {},
      errorMessage: {},
      validators: {}
    }
    super(props, state)
    this.currentTitle = 'Report Invoices'
    this.currentTab = TAB_REPORT
  }

  

  updateFilter = () => {
    let url = `payCheckType=INVOICE&payCheckFleetStartDate__gte=${this.state.data.payCheckFleetStartDate}`
    url += `&payCheckFleetStartDate__lte=${this.state.data.payCheckFleetEndDate}`
    if (!!(this.state.data.carrier && this.state.data.carrier.id)) url += `&carrier=${this.state.data.carrier.id}`
    if (!!(this.state.data.carrier && this.state.data.customer && this.state.data.customer.id)) url += `&customer=${this.state.data.customer.id}`
    if (!!(this.state.data.invoiceStatus)) url += `&invoiceStatus=${this.state.data.invoiceStatus}`

    this.refreshFilter(url)
  }


  render() {
    // this.getCurrentTitle( 'Report Invoices', TAB_REPORT )
    const { classes } = this.props;

    return (
      <div >

          {/* {this.state.listErrCode != 403 && */}
           <InvoiceReportHeader 
                disabled={this.state.listErrCode == 403}
                data={this.state.data} 
                errorMessage={this.state.errorMessage}
                errorData={this.state.errorData}
                validators={this.state.validators}
                updateValues={this.updateValues}
                onDownload={this.onDownload}
            />
          {/* } */}

            <Invoices 
                pstate={this.state} 
                tableRef={this.tableRef}
                fetchData={this.fetchData}
                listErrCode={this.state.listErrCode} />
      </div>
    )
  }
}


export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps)(InvoiceReport))
