import React from 'react'
import { connect } from 'react-redux'

import { Grid } from "@material-ui/core";
import FInput from './FInput';
import {isNotNullOrEmpty, isValidZipCode } from '../../util/Validator';
import {COUNTRY_STATES, COUNTRY_STATES_NAMES} from '../../fredux/Const';
import FSelect from './FSelect';



export default class FAddress extends React.Component {

  constructor(props) {
    super(props)
      this.state = props.address
  }
  
  updateValues = (name, obj) => {
    this.setState(prevState => {
      let newState = this.getAddress(this.props.address) || {}
      newState[name] = obj[name]
      return newState;
    }, ()=>{
      this.props.notifyParent(this.props.name, {[this.props.name] : JSON.stringify(this.state)})
    })
  }
  
  // componentDidMount = () => {
  //   // if (this.props.value)
  //       this.props.notifyParent(this.props.name, {[this.props.name]: JSON.stringify(this.props.address)})
  //   }
  
  getAddress = (val) => {
    if (isNotNullOrEmpty(val) && typeof val === 'string' || val instanceof String) {
      return JSON.parse(val) 
    }
    return val
  }

  hasErr = (val) => {
    let address = this.getAddress(this.props.address)
    let hasVal =  !!address[val]
    if (val === 'zipCode') {
      return  !isValidZipCode(address.zipCode)
    }
    return !hasVal
  }

  getValue = (val) => {
    let address = this.props.address
    if (!isNotNullOrEmpty(address)) {
      return ''
    }
    if (typeof address === 'string' || address instanceof String) {
       return JSON.parse(address)[val]
    }
    return address[val]
  }

  render() {
    const place_label = `${this.props.props_label || "Address"}`
    const city_label = `${this.props.label_city || "City"}`
    const state_label = `${this.props.label_state || "Province / State"}`
    const zipCode_label = `${this.props.label_zipCode || "Zip Code" }`
    
    return(
      <Grid container spacing= {4} xs={12} >
                                
                  
      <Grid item xs={12} md>
      <FInput
          name={'place'}
          required={this.props.place_required}
          disabled={this.props.disabled}
          value={this.getValue('place')}
          hasError={this.props.place && this.hasErr('place')}
          helpMessage={'Invalid Place'}
          label={place_label}
          notifyParent={this.updateValues} />
      </Grid>

      <Grid item xs={12} md>
      <FInput
            name='city'
            required={this.props.city_required}
            disabled={this.props.disabled}
            value={this.getValue('city')}
            hasError={this.props.city && this.hasErr('city')}
            helpMessage={'Invalid City'}
            label={city_label}
            notifyParent={this.updateValues} />
      </Grid>
      <Grid item xs={12} md={3} >
          <FSelect
              name='state'
              required={this.props.state_required}
              disabled={this.props.disabled}
              value={this.getValue('state')}
              hasError={this.props.state && this.hasErr('state')}
              helpMessage={'Invalid Province / State'}
              label={state_label}
              data={COUNTRY_STATES_NAMES}
              valueRef='prettyName'
              keyRef='prettyName'
              notifyParent={this.updateValues}
          />
      </Grid>
      <Grid item xs={12} md>
      <FInput
          name='zipCode'
          required={this.props.zipCode_required}
          disabled={this.props.disabled}
          value={this.getValue('zipCode')}
          hasError={this.props.zipCode && this.hasErr('zipCode')}
          helpMessage={'Invalid Zip'}
          label={zipCode_label}
          notifyParent={this.updateValues} />
      </Grid>

      
</Grid>
    )
  }
}

// export default connect(
//   mapStateToProps, mapDispatchToProps)(FAddress)