import React from 'react';
import FRouterLink, { FRouterAddLink } from '../widgets/FRouterLink';
import { ROUTE_CUSTOMER_URL, API_CUSTOMER_URL, CHOICE_CUSTOMER, ROUTE_CARRIERS_URL, TAB_FLEET, FLEET_CUSTOMERS } from '../../util/Const';
import FListComponent, { StyledAssetLink } from '../../util/FListComponent';

import { connect } from "react-redux"
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import FMaterialTable from '../widgets/FMaterialTable';
import FTableAction, { ACTION_TABLE_DELETE_ROW, ACTION_TABLE_DEACTIVATE, ACTION_TABLE_ACTIVATE } from '../widgets/FTableAction';
import BlockIcon from '@material-ui/icons/Block';
import { getStateIcon } from '../../util/icon_util';


class Customers extends FListComponent {
  constructor(props) {
    super(props, API_CUSTOMER_URL, CHOICE_CUSTOMER)
    this.currentTitle = 'Customers'
    this.currentTab = TAB_FLEET
    this.routeUrl = ROUTE_CUSTOMER_URL
    this.policyID = FLEET_CUSTOMERS
    // this.includeStateAction = true
  }

  render() {
    return (
      <div>
        <FMaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: 'Name', 
              field: 'customerName',
              render: rowData => {
                return (
                  <StyledAssetLink>
                    <React.Fragment>
                      <FRouterLink editIcon={true} label={rowData.customerName} to={ROUTE_CUSTOMER_URL + rowData.id} />
                    </React.Fragment>
                  </StyledAssetLink>
                )
              }
            },
            {
              title: 'Carrier', 
              render: this.getCarrierLink
            },
            { title: 'Customer Type', field: 'customerType' },
            { title: 'MC', field: 'customerMC', hidden:true },
            { title: 'DOT', field: 'customerDOT', hidden:true },
            { title: 'Email', field: 'customerEmail' },
            { title: 'Phone', field: 'customerPhoneNumber' },
            {
              title: 'State', 
              render: rowData => {
                return (
                  <React.Fragment>
                      {getStateIcon(rowData)}
                  </React.Fragment>
                )
              }
            },
            {
              title: 'Actions', 
              render: rowData => {
                let actions  = this.getActions(rowData, FLEET_CUSTOMERS)

                return (
                  <React.Fragment>
                    {!!actions.length && <FTableAction 
                        actions={actions}
                        onTableAction = {this.onTableAction}
                        rowData={rowData}
                         />
                    }
                    {!!!actions.length && <BlockIcon color="disabled" />}
                  </React.Fragment>
                )
              }
            },
          ]}
          actions={
            [
              this.getAddNewModelActions()
            ]}
          options = {{
              pageSize: this.state.pageSize,
              pageSizeOptions: this.state.pageSizeOptions,
              actionsColumnIndex: -1
           }}
          data={this.fetchData}
          title="Customers"
          listErrCode={this.state.listErrCode}
        />

    
      </div>
    );
  }
}


export default connect(
  mapStateToProps, mapDispatchToProps)(Customers)
