import React from 'react'
import DriverReportHeader from './DriverFilter';
import { TAB_REPORT, API_REPORT_DRIVER } from '../../../util/Const';
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";
import { mapDispatchToProps, mapStateToProps } from "../../../fredux/ActionMaker";
import Drivers from './Drivers';
import BaseReport from '../BaseReport';


const styles = theme => ({
  reimbursablePercentage : {
      marginRight: theme.spacing(2),

  },
});

class DriverReport extends BaseReport {
  // tableRef = React.createRef();

  constructor(props) {
    let state = {
      reportFileName: 'drivers_report',
      listUrl: API_REPORT_DRIVER, 
      downloadUrl: API_REPORT_DRIVER + 'csv/?',
      data: {
        // fleetPickUpStartDate: '01/01/2020',
        // fleetPickUpEndDate: '02/25/2020',
        // driver: {},
        carrier: {},
        // vehicle: {},
        // dispatcher: {},
        status: 'ACTIVE'
      },
      errorData: {},
      errorMessage: {},
      validators: {}
    }
    super(props, state)
    this.currentTitle = 'Report Drivers'
    this.currentTab = TAB_REPORT
  }

  

  updateFilter = () => {
    let url =  ``
    // url += `&fleetPickUpDate__lte=${this.state.data.fleetPickUpEndDate}`
    if (!!(this.state.data.carrier && this.state.data.carrier.id)) url += `&carrier=${this.state.data.carrier.id}`
    // if (!!(this.state.data.carrier && this.state.data.driver && this.state.data.driver.id)) url += `&driver=${this.state.data.driver.id}`
    // if (!!(this.state.data.carrier &&  this.state.data.vehicle && this.state.data.vehicle.id)) url += `&vehicle=${this.state.data.vehicle.id}`
    // if (!!(this.state.data.carrier &&  this.state.data.dispatcher && this.state.data.dispatcher.id)) url += `&dispatcher=${this.state.data.dispatcher.id}`
    if (!!(this.state.data.state)) url += `&state=${this.state.data.state}`

    this.refreshFilter(url)
  }


  render() {
    // this.getCurrentTitle( 'Report Drivers', TAB_REPORT )
    const { classes } = this.props;

    return (
      <div >

        {/* {this.state.listErrCode != 403 &&  */}
          <DriverReportHeader 
                disabled={this.state.listErrCode == 403}
                data={this.state.data} 
                errorMessage={this.state.errorMessage}
                errorData={this.state.errorData}
                validators={this.state.validators}
                updateValues={this.updateValues}
                onDownload={this.onDownload}
            />
        {/* } */}
            <Drivers 
                pstate={this.state} 
                tableRef={this.tableRef}
                fetchData={this.fetchData}
                listErrCode={this.state.listErrCode} />
      </div>
    )
  }
}


export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps)(DriverReport))
