import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles'
import { Labels } from '../../localizations/Resource';
import FGroupBox from "../widgets/FGroupBox";

const styles = theme => ({
  root: {
    width: '100%'
  },
  textLAbel: {
    marginBottom: 10,
  }
});


class FMemo extends React.Component {

  onChange = (evt) => {
    const value = evt.target.value
    this.props.notifyParent(this.props.name, { [this.props.name]: value })
  }

  componentDidMount = () => {
    // this.props.notifyParent(this.props.name, { [this.props.name]: this.props.defaultValue })
     if (!!this.props.defaultValue){
        this.props.notifyParent(this.props.name, { [this.props.name]: this.props.defaultValue })
     }
  }

  render() {
    const { classes } = this.props
    return (
      <FGroupBox elevation={this.props.elevation} groupTitle={Labels.DESCRIPTION_MEMO}>
                  <TextField
                    name={this.props.name}
                    error={this.props.hasError}
                    helperText={
                      this.props.hasError && this.props.helpMessage}
                    disabled={this.props.disabled}
                    required={this.props.required}
                    label={this.props.label}
                    onChange={this.onChange}
                    type={this.props.type}
                    multiline={this.props.multiline}
                    rows={this.props.rows}
                    onBlur={this.onBlur}
                    style={{
                      // backgroundColor: "blue"
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        // color: "white"
                      }
                    }}
                    InputProps={{
                      style: {
                          paddingTop: 3,
                          // color: "red"
                      }
                    }}
                    defaultValue={this.props.defaultValue}
                    value={this.props.value}
                    margin="normal"
                    className={classes.root}
                  />
      </FGroupBox>    
      );
  }
}


export default withStyles(styles)(FMemo)
